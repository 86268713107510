<template>
    <v-card class="pa-4 pt-0 mt-2">
        <v-row no-gutters class="mb-4">
            <v-col cols="12" md="4">
                <h4>SOBRE O PRODUTO </h4>
            </v-col>
            <v-col cols="12" class="pa-1" offset="7" align="right" md="1">
                <v-icon @click="saveAbout()" title="Salvar" :color="$prdStyles('color-success')">mdi-content-save</v-icon>
            </v-col>
        </v-row>
        <v-row no-gutters class="pb-2">
            <v-col class="pa-0 mt-3">
                <label>Segmento</label>
                <v-text-field hide-details outlined dense class="mt-2" v-model="segmento"></v-text-field>
            </v-col>
            <v-col class="pl-1 mt-3">
                <span><label>Classificação</label></span>
                <v-text-field hide-details outlined dense class="mt-2" v-model="classificacao"></v-text-field>
            </v-col>
            <v-col class="pl-1 mt-3">
                <label>Cultura driver</label>
                <v-text-field hide-details outlined dense class="mt-2" v-model="culturaDriver"></v-text-field>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import ServiceUPL from "@/service/upl/index";
export default {
    props: ["product"],
    data() {
        return {
            segmento: "",
            classificacao: "",
            culturaDriver: "",
            service: new ServiceUPL(),
        };
    },
    methods: {
        async saveAbout() {
            let sobre = {
                segmento: this.segmento,
                classificacao: this.classificacao,
                culturaDriver: this.culturaDriver
            };
            await this.service.updateSobreProduct(this.product.id, sobre).then(() => {
                alert("Salvo com sucesso");
            });
        }
    },
    beforeMount() {
        if (this.product.sobre != null) {
            this.segmento = this.product.sobre.segmento;
            this.classificacao = this.product.sobre.classificacao;
            this.culturaDriver = this.product.sobre.culturaDriver;
        }
    }
};
</script>