<template>
  <div class="mt-6">
    <h4>MÉTRICAS</h4>
    <div>
      <v-card
        v-for="(card, index) in cardData"
        :key="index"
        class="pa-4 mb-4 mt-4"
      >
        <h4>{{ card.title }}</h4>
        <div class="recipe-box">
          <div>
            <label>Ùltimos 7 dias</label>
            <p>{{ card.lastWeek }}</p>
          </div>
          <div>
            <label>Projeção para 7 dias</label>
            <p>{{ card.lastWeekProjection }}</p>
          </div>
          <div>
            <label>Projeção do mês atual</label>
            <p>{{ card.lastMonthProjection }}</p>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardData: [
        {
          title: "RECEITA TOTAL",
          lastWeek: "R$ 150.00,00",
          lastWeekProjection: "R$ 155.000,00",
          lastMonthProjection: "R$ 354.00,00",
        },
        {
          title: "MARGEM",
          lastWeek: "56 %",
          lastWeekProjection: "67 %",
          lastMonthProjection: "68 %",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.recipe-box {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  label {
    font-size: 14px;
  }

  p {
    font-size: 20px;
    color: #3c5ca7;
    margin-bottom: 0;
  }
}
</style>