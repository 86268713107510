<template>
  <v-container fluid>
    <titleBreadCrumbsComponent
      :breadCrumbs="breadCrumbs"
      :title="'PRECIFICAÇÃO'"
      :showTooltip="false"
      :hasTooltip="false"
    />
    <!-- FILTERS --->
    <search-filters
      :recordsPerPage="recordsPerPage"
      :currentPage="currentPage"
      :productListLoading="productListLoading"
      @products="setProductsList"
      @setLoading="productListLoading = $event"
      @showSnackbar="showSnackbar"
    />
    <!-- PRODUCT LIST -->
    <product-list-main
      v-show="products.length != 0"
      :products="products"
      :recordsPerPage="recordsPerPage"
      :totalRecords="totalRecords"
      :productListLoading="productListLoading"
      @setRecordsPerPage="recordsPerPage = $event"
      @enableErp="enableErp"
    />
    <prd-pagination
      v-model="currentPage"
      v-show="products.length != 0"
      :totalRegisters="totalRecords"
      :length="totalPages"
      :showingRegistersLen="numberOfRecords"
      :resultLabel="$t('TXT_PAGINATION')"
      :pageLabel="$t('TXT_PAGE_LABEL')"
      class="mt-4"
    />
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import SearchFilters from "./components/search-filters.vue";
import ProductListMain from "./components/product-list/product-list-main.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
export default {
  name: "new-ai-pricing",
  components: {
    titleBreadCrumbsComponent,
    SearchFilters,
    ProductListMain,
    PrdPagination,
  },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Precificação",
        },
      ],
      productListLoading: false,

      // Pagination vars
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,
      recordsPerPage: 5,

      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      products: [],
    };
  },

  methods: {
    setProductsList(data) {
      this.products = data.records;
      this.currentPage = data.currentPage;
      this.totalRecords = data.totalRecords;
      this.totalPages = data.totalPages;
      this.numberOfRecords = data.numberOfRecords;
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    enableErp(index) {
      console.log(this.products[index]);
      this.products[index].workflowApproval.lastAction.toSend = !this.products[index].workflowApproval.lastAction.toSend ;
    },
  },
};
</script>