<template>
  <v-card
    class="pa-4 d-flex flex-column gap-4 font-size-12"
    flat
    :disabled="disabled"
  >
    <div
      class="d-flex align-center"
      style="cursor: pointer"
      @click="$emit('select')"
    >
      <progress-component
        v-if="isLoadingResetOverride"
        class="mr-2"
        size="16"
        color="primary"
        :width="2"
      />
      <v-radio
        v-else
        value="COMPETITIVE"
        style="transform: scale(0.6)"
        class="ma-0"
      />
      <strong> {{ this.$i18n.t("TXT_COMPETITIVE_PRICE") }}:</strong>
    </div>
    <text-field
      :title="$t('TXT_COMPETITIVE_PRICE')"
      class="text gap-2-important"
      :value="price"
      :formatOnBlur="setToIsoString"
      disabled
    />
    <text-field
      :title="$t('TXT_MARGIN')"
      class="text gap-2-important"
      :value="margin"
      :formatOnBlur="formatNumberPercentage"
      disabled
    />
    <text-field
      :title="$t('TXT_COMPETITIVE_INDEX')"
      class="text gap-2-important"
      :value="ic"
      :formatOnBlur="formatNumberPercentage"
      disabled
    />
  </v-card>
</template>
<script>
import { calculateIC } from "../../../../../calculate-competitivity-index";
import textField from "@/Design_System/common/text-field";
import progressComponent from "@/Design_System/common/progressComponent";
import { formatNumberPercentage } from "@/utils/format-numbers";

export default {
  props: [
    "productRegion",
    "productName",
    "isSelected",
    "isLoadingResetOverride",
    "disabled",
  ],
  components: {
    textField,
    progressComponent,
  },
  data: () => ({}),
  methods: {
    formatNumberPercentage(value) {
      return formatNumberPercentage(value, { fractionLength: 2 });
    },
    setToIsoString(value) {
      let currency = localStorage.getItem("currency");
      if (!value) return "-";
      if (value == "-") return "-";

      let rounded = (Math.trunc(value * 100) / 100).toFixed(2);
      let formated = rounded.replace(".", ",");

      return currency + " " + formated;
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    price() {
      if (this.productRegion?.price?.override != null) {
        if (this.productRegion?.price?.override.price != null)
          return this.productRegion?.price?.override.price;
        else return this.productRegion?.price?.salePrice;
      } else return this.productRegion?.price?.salePrice;
    },
    margin() {
      if (this.idCompany == 2857)
        //Feito temporariamente para a Suzano
        return null;

      return this.productRegion?.price?.suggestedPrice_Margin ?? null;
    },
    ic() {
      return calculateIC(
        this.price,
        this.productRegion?.price.getAvgPriceMarketResult
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.gap-2-important {
  column-gap: 8px !important;
  row-gap: 8px !important;
}
</style>
