<template>
  <prd-action-button
    borderColor="#6792F8"
    @click="$emit('click', $event)"
    class="rounded"
  >
    <v-icon small>mdi-history</v-icon>
  </prd-action-button>
</template>
<script>
import prdActionButton from "@/Design_System/common/prd-action-button";

export default {
  props: [],
  components: { prdActionButton },
  data: () => ({}),
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped></style>
