<template>
  <div class="card pa-4 rounded-lg">
    <div class="d-flex flex-column align-center gap-6 w-full">
      <div>
        <h3>{{ event.championship }}</h3>
        <div class="text-caption">{{ eventDate }}</div>
      </div>
      <div
        class="d-flex flex-row justify-space-between gap-4 w-full align-center"
      >
        <div class="team-card pa-2">{{ event.home_team }}</div>
        X
        <div class="team-card pa-2">{{ event.guest_team }}</div>
      </div>
      <prd-btn title="Simular partida" v-on:click="click"></prd-btn>
    </div>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn";
import { formatDate } from "@/utils/format-date";

export default {
  props: ["event"],
  components: {
    prdBtn,
  },
  data() {
    return {
      eventDate: formatDate(new Date(this.event.date), "dd/MM/yyyy - hh:mm:ss"),
    };
  },
  methods: {
    click() {
      const idEnterpriseEventHistory = this.event.idEnterpriseEventHistory;
      this.$store.commit("setEnterpriseEventHistory", this.event);
      this.$router.push(`./simulate/${idEnterpriseEventHistory}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $neutral-color-high-pure;
  width: 392px;
}
.gap-4 {
  row-gap: 16px;
  column-gap: 16px;
}
.gap-6 {
  row-gap: 24px;
  column-gap: 24px;
}
.team-card {
  width: 100%;
  background-color: var(--background-gray);
}
.w-full {
  width: 100%;
}
</style>
