<template>
  <div>
    <prd-btn
      :title="this.$i18n.t('TXT_NEW_CRAWLER')"
      :icon="'mdi-plus'"
      @click="toggleDialog"
      :leftIcon="true"
    />
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <v-card class="pa-4">
        <h4 class="mb-4">{{ this.$i18n.t("TXT_NEW_CRAWLER") }}</h4>

        <v-row no-gutters justify="space-between">
          <div>
            <label for="id">ID</label>
            <v-text-field
              single-line
              outlined
              :value="'-'"
              :disabled="true"
              style="width: 80px"
              hide-details
              dense
            ></v-text-field>
          </div>

          <div>
            <label for="description">{{
              this.$i18n.t("TXT_DESCRIPTION")
            }}</label>
            <v-text-field
              single-line
              outlined
              v-model="description"
              style="width: 450px"
              hide-details
              dense
            ></v-text-field>
          </div>
        </v-row>

        <v-row no-gutters justify="space-between" class="mt-4">
          <prd-combo-box
            :title="this.$i18n.t('TXT_PLAIN_TYPE')"
            v-model="selectedType"
            :items="typeList"
            :placeholder="`${this.$i18n.t('TXT_SELECT')}...`"
            style="width: 23%"
          />
          <prd-combo-box
            :title="this.$i18n.t('TXT_SOURCE')"
            v-model="selectedSource"
            :items="sourceList"
            :placeholder="`${this.$i18n.t('TXT_SELECT')}...`"
            style="width: 23%"
          />
          <prd-combo-box
            :title="this.$i18n.t('TXT_COUNTRY')"
            v-model="selectedCountry"
            :items="countryList"
            :placeholder="`${this.$i18n.t('TXT_SELECT')}...`"
            style="width: 23%"
          />
          <prd-combo-box
            :title="this.$i18n.t('TXT_LANGUAGE')"
            v-model="selectedLanguage"
            :items="languageList"
            :placeholder="`${this.$i18n.t('TXT_SELECT')}...`"
            style="width: 23%"
          />
        </v-row>

        <v-row no-gutters align="center" class="mt-4 pl-1 pr-1">
          <label for="isOnline" class="mb-1 me-4">Offline:</label>
          <v-switch v-model="isOffline" inset></v-switch>
        </v-row>

        <v-row no-gutters justify="end">
          <prd-btn
            class="me-4"
            :title="this.$i18n.t('TXT_CANCEL')"
            :outlined="true"
            @click="toggleDialog"
          />
          <prd-btn
            :title="this.$i18n.t('TXT_ADD')"
            @click="addCrawler"
            :disabled="
              !description ||
              !selectedCountry ||
              !selectedSource ||
              !selectedLanguage ||
              !selectedType
            "
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import CrawlerConfigService from "@/service/crawlers/crawler-config.js";

export default {
  components: {
    PrdBtn,
    PrdComboBox,
  },
  props: { countryList: [], sourceList: [], languageList: [], typeList: [] },
  data() {
    return {
      service: new CrawlerConfigService(),
      showDialog: false,
      description: null,
      selectedType: null,
      selectedSource: null,
      selectedCountry: null,
      selectedLanguage: null,
      isOffline: null,
    };
  },
  methods: {
    buildRequest() {
      return {
        idMonitoringCrawler: 0,
        description: this.description,
        crawlerType: this.selectedType?.value || null,
        idCountry: this.selectedCountry?.value || null,
        isOffline: this.isOffline,
        language: this.selectedLanguage,
        source: this.selectedSource?.value || null,
        isDeleted: false,
      };
    },
    async addCrawler() {
      try {
        let request = this.buildRequest();
        let response = await this.service.InsertUpdateDeleteCrawler(request);
        if (response.status != 500) {
          this.$emit("ActualizarTable");
          this.$emit(
            "showSnackbar",
            `${this.$i18n.t("TXT_SUCCESS_ADD")} Crawler !`,
            "success"
          );
          this.toggleDialog();
        } else {
          this.$emit(
            "showSnackbar",
            `${this.$i18n.t("TXT_ERROR_ADD")} Crawler !`,
            "error"
          );
          this.toggleDialog();
        }
      } catch (error) {
        console.log(this.$i18n.t("TXT_ERROR_METHOD"), " 'addCrawler':", error);
      }
    },

    toggleDialog() {
      //cleaning modal
      this.description = null;
      this.selectedType = null;
      this.selectedSource = null;
      this.selectedCountry = null;
      this.selectedLanguage = null;
      this.isOnline = false;

      //open or close modal
      this.showDialog = !this.showDialog;
    },
  },
};
</script>