<template>
  <v-card class="pa-4">
    <h4 class="mb-4">FILTROS</h4>

    <v-row no-gutters class="mb-4">
      <v-col cols="12" md="4" v-for="(filter, index) in filters" :key="index">
        <label>{{ filter.label }}</label>
        <v-combobox
          v-model="filter.selected"
          :items="filter.items"
          :class="filter.margin"
          :disabled="
            isLoading || products.length == 0 || filter.value == 'price'
          "
          @change="applyFilters"
          deletable-chips
          small-chips
          outlined
          dense
          hide-details
          append-icon="mdi-chevron-down"
          class="mt-1"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row v-if="isLoading" align="center" justify="center">
      <v-progress-circular indeterminate :color="$prdStyles('color-primary')">
      </v-progress-circular>
    </v-row>

    <div v-else>
      <v-row
        v-show="products.length != 0"
        no-gutters
        align="center"
        justify="space-between"
      >
        <h4 class="mb-4">RESULTADOS</h4>
        <prd-btn
          :title="'Extrair relatório'"
          @click="extractReport(products)"
        />
      </v-row>
      <v-expansion-panels accordion>
        <product-panel-content
          v-for="product in filteredProducts"
          :key="product.idMonitoringItem"
          :product="product"
          class="mt-2"
        />
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import ProductPanelContent from "./product-panel-content.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: ["products", "isLoading"],
  components: { ProductPanelContent, PrdBtn },
  data() {
    return {
      filters: [
        {
          label: "Marketplace",
          value: "crawler_name",
          margin: "mr-4",
          selected: null,
          items: [],
        },
        {
          label: "Seller",
          value: "seller_name",
          margin: "mr-4",
          selected: null,
          items: [],
        },
        // {
        //   label: "Marca",
        //   value: "price",
        //   margin: "",
        //   selected: null,
        //   items: [],
        // },
      ],
      filteredProducts: [],
      originalItems: [],
    };
  },

  watch: {
    products: {
      handler(values) {
        if (!values || values.length == 0) return;

        this.filters.forEach((filter) => {
          filter.items = [];
          filter.selected = null;
        });

        this.fillFiltersItems(this.products, this.filters);
        this.filteredProducts = this.products;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    fillFiltersItems(data, filters) {
      data.forEach(({ tableData }) => {
        tableData.forEach((item) => {
          filters.forEach((filter) => {
            const filterValue = item[filter.value];
            const existingItem = filter.items.find(
              (i) => i.value === filterValue
            );
            if (!existingItem) {
              filter.items.push(filterValue);
            }
          });
        });
      });
    },

    applyFilters() {
      const filteredData = this.products.map((dataItem) => {
        const filteredTableData = dataItem.tableData.filter((product) => {
          return this.filters.every((filter) => {
            const selectedValue = filter.selected;
            const productValue = product[filter.value];
            if (selectedValue === null) {
              return true;
            } else {
              return String(productValue) === String(selectedValue);
            }
          });
        });

        return {
          ...dataItem,
          tableData: filteredTableData,
        };
      });

      this.filteredProducts = filteredData;
    },

    arrayToCSV(data) {
      // Cabeçalho do CSV com os nomes das colunas
      const header = Object.keys(data[0]).join(",") + "\n";

      // Função para formatar um objeto em uma linha CSV
      const formatRow = (row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? `"${value}"` : value))
          .join(",");

      // Transformar cada objeto em uma linha CSV formatada
      const csvRows = data.map(formatRow);

      // Juntar cabeçalho e linhas em uma única string CSV
      const csvString = header + csvRows.join("\n");

      return csvString;
    },

    extractReport(products) {
      const formatedArray = products.flatMap((obj) => obj.tableData);

      const csvString = this.arrayToCSV(formatedArray);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "arquivo.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);

      // Limpar o URL.createObjectURL depois do download
      URL.revokeObjectURL(url);
    },
  },
};
</script>