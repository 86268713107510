<template>
  <div>
    <v-icon @click="isModalOpen = true" color="#1D8527"> mdi-plus-box </v-icon>
    <v-dialog
      max-width="600"
      v-model="isModalOpen"
      persistent
      :disabled="isLoading"
    >
      <v-card class="pa-4">
        <h4 class="mb-4">ITEMS DE MONITORAMENTO</h4>
        <v-row v-if="monitoringItemsLoading" no-gutters justify="center">
          <v-progress-circular
            indeterminate
            :color="$prdStyles('color-primary')"
          ></v-progress-circular>
        </v-row>

        <div v-else>
          <v-row no-gutters>
            <v-col cols="12" md="4" lg="5" sm="6">
              <label>Buscar</label>
              <v-text-field
                v-model="search"
                :outlined="true"
                append-icon="mdi-magnify"
                dense
                rounded
                class="mt-1 mb-n4"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :items-per-page="5"
            :headers="headers"
            :items="monitoringItems"
            :search="search"
          >
            <!-- Price -->
            <template v-slot:[`item.similiarityType`]="{ item }">
              <prd-combo-box
                v-model="item.similiarityType"
                :items="similiarityItems"
                :loading="isLoading"
                :disabled="isLoading"
                deletable-chips
                small-chips
              />
            </template>
          </v-data-table>

          <v-row no-gutters>
            <v-spacer></v-spacer>
            <prd-btn
              :title="'Cancelar'"
              :outlined="true"
              :disabled="isLoading"
              @click="isModalOpen = false"
              class="mr-4"
            />
            <prd-btn :title="'Salvar'" @click="saveMonitoringItems" />
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/service/new-ai-pricing/panel.js";
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const Service = new service();
export default {
  props: ["idEnterprisePricesProjection", "tableSimilarity"],
  components: { prdComboBox, PrdBtn },
  data() {
    return {
      isModalOpen: false,
      monitoringItemsLoading: false,
      isLoading: false,
      monitoringItems: [],
      monitoringItemsOriginal: [],
      search: "",
      headers: [
        {
          text: "Produto",
          value: "getDescription",
          align: "center",
          sortable: true,
        },
        {
          text: "Tipo de similiaridade",
          value: "similiarityType",
          align: "center",
          sortable: true,
          width: "250px",
        },
      ],
      similiarityItems: ["Direta", "Inferior", "Superior", "Pariforme"],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    isModalOpen() {
      this.listMonitoringItems();
    },
  },

  methods: {
    listMonitoringItems() {
      this.monitoringItemsLoading = true;

      let request = {
        filters: { description: null },
        idCompany: this.idCompany,
        page: 1,
        recordsPerPage: 100000,
      };

      Service.getMonitoringItems(request)
        .then((res) => {
          console.log("Resposta do buscar monitoring items", res);
          this.monitoringItems = this.transformResponse(res);

          // Cria uma cópia rasa do array
          let arrayCopia = this.monitoringItems.slice();

          // Cria uma cópia dos objetos individuais
          this.monitoringItemsOriginal = arrayCopia.map((objeto) => ({
            ...objeto,
          }));

          this.monitoringItemsLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar monitoring items", error);
          this.monitoringItemsLoading = false;
        });
    },

    transformResponse(res) {
      const response = res.data.result.records;

      const transformedResponse = response.map((el) => {
        const matchingSimilarityItem = this.tableSimilarity.find(
          (similarityItem) => similarityItem.description === el.getDescription
        );
        const similiarityType = matchingSimilarityItem
          ? matchingSimilarityItem.typeSimilarity
          : null;

        return {
          getDescription: el.getDescription,
          similiarityType,
          idMonitoringItem: el.idMonitoringItem,
          idEnterprise_Prices_Projection_MarketSimilarity:
            matchingSimilarityItem?.idEnterprise_Prices_Projection_MarketSimilarity ??
            null,
        };
      });

      return transformedResponse;
    },

    async saveMonitoringItems() {
      try {
        this.isLoading = true;

        const listMarketSimilarity = this.buildRequest();

        const requestToSave = {
          idEnterprisePriceProjection: this.idEnterprisePricesProjection,
          listMarketSimilarity: listMarketSimilarity.itemsToSave,
        };

        const requestToDelete = {
          idEnterprisePriceProjection: this.idEnterprisePricesProjection,
          listMarketSimilarity: listMarketSimilarity.itemsToDelete,
        };

        await Service.deleteMonitoringItems(requestToDelete);
        console.log("Itens excluídos com sucesso");

        await Service.saveMonitoringItems(requestToSave);
        console.log("Itens de monitoramento salvos com sucesso");

        this.$emit("callSimiliarityTable");
        this.isModalOpen = false;
      } catch (error) {
        console.log("Erro ao salvar ou excluir itens:", error);
      } finally {
        this.isLoading = false;
      }
    },

    buildRequest() {
      let changedItems = this.compareArrays(
        this.monitoringItemsOriginal,
        this.monitoringItems
      );

      let itemsToDelete = [];
      let itemsToSave = [];

      changedItems.forEach((el) => {
        if (
          el.idEnterprise_Prices_Projection_MarketSimilarity &&
          !el.similiarityType
        ) {
          let itemObj = {
            idEnterprise_Prices_Projection_MarketSimilarity:
              el.idEnterprise_Prices_Projection_MarketSimilarity,
            isDeleted: true,
          };

          itemsToDelete.push(itemObj);
        } else {
          let itemObj = {
            idEnterprise_Prices_Projection_MarketSimilarity:
              el.idEnterprise_Prices_Projection_MarketSimilarity
                ? el.idEnterprise_Prices_Projection_MarketSimilarity
                : 0,
            idMonitoringItem: el.idMonitoringItem,
            typeSimilarity: el.similiarityType,
            isDeleted: false,
          };

          itemsToSave.push(itemObj);
        }
      });

      let filteredObj = {
        itemsToDelete,
        itemsToSave,
      };

      return filteredObj;
    },

    compareArrays(original, modified) {
      const changedItems = [];

      for (let i = 0; i < original.length; i++) {
        const originalItem = original[i];
        const modifiedItem = modified[i];

        if (
          originalItem.getDescription !== modifiedItem.getDescription ||
          originalItem.similiarityType !== modifiedItem.similiarityType
        ) {
          changedItems.push(modifiedItem);
        }
      }

      return changedItems;
    },
  },
};
</script>