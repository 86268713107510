<template>
  <v-container fluid>
    <v-row class="d-flex flex-row justify-space-between">
      <h2 class="mb-4 text-body-1 font-weight-bold">SIMULAR PARTIDA</h2>
      <prd-btn
        @click="clickBack"
        :title="'Voltar'"
        :icon="'mdi-arrow-left'"
        :leftIcon="true"
        :smallIcon="true"
      />
    </v-row>
    <v-row v-if="enterpriseEvent" align="center">
      <div class="pa-4 card rounded-lg full-width">
        <div class="gap-7 full-width combobox-grid">
          <h3 class="text-body-2 font-weight-bold grid-column-span-4">
            {{ enterpriseEvent.championship }} |
            {{ enterpriseEvent.home_team }} X {{ enterpriseEvent.guest_team }} |
            {{ enterpriseEventDate }}
          </h3>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.listPlaces.state() === 'ERROR'"
          >
            Erro ao buscar os estádios:
            {{ service.listPlaces.error.message || service.listPlaces.error }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.listCrowding.state() === 'ERROR'"
          >
            Erro ao buscar os cenários:
            {{
              service.listCrowding.error.message || service.listCrowding.error
            }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.listTickets.state() === 'ERROR'"
          >
            Erro ao buscar a lista de ingressos:
            {{ service.listTickets.error.message || service.listTickets.error }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.place.state() === 'ERROR'"
          >
            Erro ao buscar informações sobre esse estádio:
            {{ service.place.error.message || service.place.error }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.crowding.state() === 'ERROR'"
          >
            Erro ao buscar informações sobre esse cenário:
            {{ service.crowding.error.message || service.crowding.error }}
          </div>
          <combo-box
            :items="comboboxPlace"
            hideIcon
            placeholder="Selecione"
            @input="selectPlace"
            :multiple="false"
            :loading="service.listPlaces.isLoading"
            :disabled="service.listPlaces.state() !== 'DATA_READY'"
          >
            <template v-slot:title> Estádio </template>
          </combo-box>
          <combo-box
            :items="comboboxCrowding"
            hideIcon
            placeholder="Selecione"
            @input="selectCrowding"
            :multiple="false"
            :loading="service.listCrowding.isLoading"
            :disabled="service.listCrowding.state() !== 'DATA_READY'"
          >
            <template v-slot:title>
              Cenário
              <tooltip-informations :title="$t('TXT_PREDIFUT_SCENARIO')" />
            </template>
          </combo-box>
          <text-field
            placeholder="xxxx,xx"
            v-model="minPrice"
            :format-on-blur="formatMoneyOnBlur"
            :disabled="!selectedPlace || !selectedCrowding"
          >
            <template v-slot:prepend-inner>
              <span class="mt-1">R$</span>
            </template>
            <template v-slot>
              Preço Mínimo
              <tooltip-informations :title="$t('TXT_PREDIFUT_MIN_PRICE')" />
            </template>
          </text-field>
          <text-field
            placeholder="xxxx,xx"
            v-model="maxPrice"
            :format-on-blur="formatMoneyOnBlur"
            :disabled="!selectedPlace || !selectedCrowding"
          >
            <template v-slot:prepend-inner>
              <span class="mt-1">R$</span>
            </template>
            <template v-slot>
              Preço Máximo
              <tooltip-informations :title="$t('TXT_PREDIFUT_MAX_PRICE')" />
            </template>
          </text-field>
          <text-field-with-suggestion
            v-model="publicPrediction"
            :suggestionState="service.getHistoryCalculated.state()"
            :disabled="!selectedPlace || !selectedCrowding"
            :format-on-blur="formatNumberOnBlur"
          >
            Previsão de Público
            <tooltip-informations
              :title="$t('TXT_PREDIFUT_PUBLIC_PREVISION')"
            />
            <template v-slot:suggestion>
              <span
                v-if="
                  service.getHistoryCalculated.data &&
                  service.getHistoryCalculated.data.answer
                "
                @click="publicPredictionSuggestionClick()"
                class="cursor"
              >
                Previsão de público por IA:
                {{ audiencePrediction }}
                pessoas
              </span>
            </template>
            <template v-slot:error>
              Erro ao buscar previsão de público por IA
            </template>
          </text-field-with-suggestion>
          <text-field-with-suggestion
            v-model="expenses"
            :suggestionState="service.getHistoryCalculated.state()"
            :disabled="!selectedPlace || !selectedCrowding"
            :format-on-blur="formatMoneyOnBlur"
          >
            Despesas
            <tooltip-informations :title="$t('TXT_PREDIFUT_EXPENSES')" />

            <template v-slot:prepend-inner>
              <span class="mt-1">R$</span>
            </template>
            <template v-slot:suggestion>
              <span
                v-if="
                  service.getHistoryCalculated.data &&
                  service.getHistoryCalculated.data.answer
                "
                @click="expensesSuggestionClick"
                class="cursor"
              >
                Despesas por IA:
                {{
                  formatMoney(service.getHistoryCalculated.data.answer.expense)
                }}
              </span>
            </template>
            <template v-slot:error>
              Erro ao buscar previsão de despesas por IA
            </template>
          </text-field-with-suggestion>
          <text-field
            v-model="contributionMargin"
            :disabled="!selectedPlace || !selectedCrowding"
          >
            Margem de Contribuição
            <tooltip-informations :title="$t('TXT_PREDIFUT_MARGIN')" />
          </text-field>

          <div class="grid-column-span-4 actions-grid full-width">
            <checkbox-component
              label="Usar tabela informativa"
              class="ma-0"
              v-model="shouldUseInformativeTable"
            ></checkbox-component>
            <div
              v-if="shouldUseInformativeTable"
              class="grid-column-span-2 overflow-x-auto"
            >
              <informative-table
                :idCompany="idCompany"
                :isLoading="
                  this.service.listTickets.isLoading ||
                  this.service.place.isLoading ||
                  this.service.crowding.isLoading
                "
                :tickets="
                  this.service.listTickets.data &&
                  this.service.listTickets.data.answer
                "
                :place="
                  this.service.place.data && this.service.place.data.answer
                "
                :crowding="
                  this.service.crowding.data &&
                  this.service.crowding.data.answer
                "
                :error="
                  this.service.listTickets.error ||
                  this.service.place.error ||
                  this.service.crowding.error
                "
              ></informative-table>
            </div>
            <div v-if="shouldUseInformativeTable"></div>
            <prd-btn
              title="Simular partida"
              @click="simulateGame"
              class="justify-self-end"
            ></prd-btn>
          </div>
        </div>
      </div>
      <progress-component v-if="service.simulateGame.isLoading" class="ma-4" />
      <div v-else-if="service.simulateGame.error">
        Falha ao simular o jogo:
        <div>{{ service.simulateGame.error }}</div>
      </div>
      <results-table
        v-else
        :simulationResponse="service.simulateGame.data"
        :publicPrediction="requestedPublicPrediction"
        :sectors="service.place.data && service.place.data.answer.sectors"
        :tickets="service.listTickets.data && service.listTickets.data.answer"
      />
    </v-row>
  </v-container>
</template>
<script>
import prdBtn from "@/Design_System/common/prd-btn";
import checkboxComponent from "@/Design_System/common/checkBoxComponent";
import comboBox from "@/Design_System/common/comboBox";
import textField from "@/Design_System/common/text-field";
import { formatDate } from "@/utils/format-date";
import PredifutService from "@/service/predifut-service";
import textFieldWithSuggestion from "./components/text-field-with-suggestion";
import informativeTable from "./components/informative-table";
import resultsTable from "./components/results-table";
import { formatMoney } from "@/utils/format-money";
import { validateMatchSimulation } from "./validation/simulate-match";
import progressComponent from "@/Design_System/common/progressComponent";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";

export default {
  components: {
    prdBtn,
    textFieldWithSuggestion,
    checkboxComponent,
    comboBox,
    textField,
    informativeTable,
    resultsTable,
    progressComponent,
    PrdBtn,
    TooltipInformations,
  },
  data: () => ({
    service: new PredifutService(),
    enterpriseEvent: null,
    selectedPlace: null,
    minPrice: null,
    maxPrice: null,
    publicPrediction: null,
    contributionMargin: null,
    expenses: null,
    shouldUseInformativeTable: false,
    selectedCrowding: null,

    requestedPublicPrediction: null,
  }),
  methods: {
    clickBack() {
      this.$router.push("../select-game");
    },
    selectPlace({ value }) {
      this.selectedPlace = value;
    },
    selectCrowding({ value }) {
      this.selectedCrowding = value;
    },
    formatMoney,
    formatMoneyOnBlur(value) {
      if (!value) return null;

      let onlyNumbers = value.replace(/[^0-9.,]/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?!\d\d$)/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?=\d\d$)/, ".");
      return formatMoney(Number(onlyNumbers)).replace(/R\$(\s)*/g, "");
    },
    formatNumberOnBlur(value) {
      if (!value) return null;

      let onlyNumbers = value.replace(/[^0-9.,]/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?!\d\d$)/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?=\d\d$)/, ",");
      onlyNumbers = onlyNumbers.replace(
        /(\d)(?=(?:\d\d\d)+(?:,\d\d){0,1}$)/g,
        "$1."
      );
      return onlyNumbers;
    },
    publicPredictionSuggestionClick() {
      this.publicPrediction =
        this.service.getHistoryCalculated.data.answer.audience.toString();
    },
    expensesSuggestionClick() {
      this.expenses =
        this.service.getHistoryCalculated.data.answer.expense.toString();
    },
    async fetchPlaces() {
      await this.service.listPlaces.safeCall(this.idCompany);
    },
    async fetchCrowdings() {
      await this.service.listCrowding.safeCall(
        this.idCompany,
        this.selectedPlace.idEnterpriseEventPlace
      );
    },
    async fetchHistoryCalculated() {
      await this.service.getHistoryCalculated.safeCall(
        this.idCompany,
        this.enterpriseEvent.idEnterpriseEventHistory
      );
    },
    async simulateGame() {
      const idEnterpriseEventHistory =
        this.enterpriseEventHistory.idEnterpriseEventHistory;

      const errors = validateMatchSimulation(this);
      if (errors) {
        return this.$store.commit("snackbar-error", errors);
      }

      function prepareNumber(numString) {
        return Number(
          numString.replace(/\D(?!\d\d$)/g, "").replace(/,(?=\d\d$)/, ".")
        );
      }
      const data = {
        max_price: prepareNumber(this.maxPrice),
        min_price: prepareNumber(this.minPrice),
        audience: prepareNumber(this.publicPrediction),
        profit: prepareNumber(this.contributionMargin),
        expense: prepareNumber(this.expenses),
        idEnterpriseEventHistory,
        idCompany: this.idCompany,
        crowding: this.service.crowding.data.answer,
        place: this.service.place.data.answer,
        tickets: this.service.listTickets.data.answer,
        sectors: this.service.place.data.answer.sectors.map((sector) => ({
          idEnterpriseEventSector: sector.idEnterpriseEventSector,
          // TODO - actually place the selected tickets here
          selected_tickets: [1, 2, 3],
        })),
      };

      this.requestedPublicPrediction = data.audience;
      this.service.simulateGame.call(data);
    },
    async fetchTickets() {
      this.service.listTickets.safeCall(this.idCompany);
    },
    async fetchPlace() {
      if (!this.selectedPlace) return;
      this.service.place.safeCall(
        this.idCompany,
        this.selectedPlace.idEnterpriseEventPlace
      );
    },
    async fetchCrowding() {
      if (!this.selectedCrowding) return;
      this.service.crowding.safeCall(
        this.idCompany,
        this.selectedCrowding.idEnterpriseEventCrowding
      );
    },
  },
  watch: {
    selectedPlace() {
      this.fetchCrowdings();
      this.fetchPlace();
    },
    selectedCrowding() {
      this.fetchCrowding();
    },
  },
  computed: {
    audiencePrediction() {
      if (
        !this.service.getHistoryCalculated.data ||
        !this.service.getHistoryCalculated.data.answer
      )
        return 0;
      return this.formatNumberOnBlur(
        Math.round(
          this.service.getHistoryCalculated.data.answer.audience
        ).toString()
      );
    },
    enterpriseEventDate() {
      // Cannot continue in this case
      if (!this.enterpriseEvent) return "";

      return formatDate(
        new Date(this.enterpriseEvent.date),
        "dd/MM/yyyy - hh:mm:ss"
      );
    },
    comboboxPlace() {
      if (this.service.listPlaces.state() !== "DATA_READY") return [];

      return this.service.listPlaces.data.answer.map((place) => ({
        text: place.name,
        value: place,
      }));
    },
    comboboxCrowding() {
      if (this.service.listCrowding.state() !== "DATA_READY") return [];

      return this.service.listCrowding.data.answer.map((crowding) => ({
        text: crowding.name,
        value: crowding,
      }));
    },

    // Store accesses
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    enterpriseEventHistory() {
      return this.$store.state.predifutStore.enterpriseEventHistory;
    },
  },
  created() {
    const enterpriseEvent = this.enterpriseEventHistory;

    // Cannot continue in this case. Return to the select-game screen
    if (!enterpriseEvent) {
      this.$router.push("/main/predifut/select-game");
      return;
    }

    this.enterpriseEvent = enterpriseEvent;

    this.fetchPlaces();
    this.fetchTickets();
    this.fetchHistoryCalculated();
  },
};
</script>
<style lang="scss" scoped>
.card {
  background-color: $neutral-color-high-pure;
}
.button-text {
  font-size: 18px;
}
.gap-7 {
  column-gap: 28px;
  row-gap: 28px;
}
.combobox-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid-column-span-4 {
  grid-column: span 4;
}
.grid-column-span-2 {
  grid-column: span 2;
}
.full-width {
  width: 100%;
}
.actions-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.overflow-x-auto {
  overflow-x: auto;
}
.justify-self-end {
  justify-self: flex-end;
}
.color-error {
  color: $feedback-color-warning-pure;
}
.cursor {
  cursor: pointer;
}
</style>
