<template>
  <v-card class="pa-4 mt-4 mb-6" id="table-result">
    <v-expansion-panels
      v-model="panel"
      :disabled="isDisable"
      accordion
      flat
      multiple
      class="pa-0"
    >
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header
          expand-icon="mdi-chevron-down"
          class="ma-0 pa-0"
        >
          <label
            class="font-weight-bold font-size-16"
            v-t="'TXT_PRODUCTS_UPPERCASE'"
          ></label>
        </v-expansion-panel-header>

        <!-- SKELETON LOADERS -->
        <div v-if="isDisable && panel.length != 0">
          <v-skeleton-loader
            class="mx-auto mt-2"
            type="card"
            height="50px"
            v-for="(skeleton, index) in skeletons"
            :key="index"
          ></v-skeleton-loader>
        </div>

        <v-expansion-panel-content
          v-for="(product, index) in products"
          :key="index"
          class="mb-4 pa-0"
        >
          <!--TABLE -->
          <product-panel-table
            v-if="!isDisable"
            :productItem="product"
            :resourceGroup="resourceGroup"
            :userList="userList"
            :priceCardLoading="priceCardLoading"
            :listaDeAlertas="product.listaDeAlertas"
            @showSnackbar="showSnackbar"
            @attData="$emit('attData')"
            @seila="$emit('seila')"
            @showSnackBarItemsSave="$emit('showSnackBarItemsSave', $event)"
            class="mt-2"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import productPanelTable from "./components/product-panel-table.vue";
import CircularJSON from "circular-json";
export default {
  components: { productPanelTable },
  props: [
    "requestData",
    "idCompraAgora",
    "tableResultData",
    "isLoading",
    "isDisable",
    "closePanel",
    "resourceGroup",
    "userList",
    "priceAlertList",
    "priceCardLoading",
  ],
  data() {
    return {
      // Array to assemble the products
      products: [],
      dataProducts: [],

      panel: [],
      skeletons: 5,
    };
  },

  watch: {
    tableResultData() {
      this.dataProducts = [];
      this.$store.commit("setPredimonitorResults", []);
      this.newValue = this.tableResultData;
      this.formatTableResults();
      this.$emit("setLoading", false);
    },

    closePanel() {
      if (this.closePanel) {
        this.panel = [];
        this.$store.commit("setPredimonitorResults", []);
      }
    },
  },

  methods: {
    formatTableResults() {
      this.$store.commit("setPredimonitorResults", []);
      this.dataProducts = [];
      this.products = [];

      this.dataProducts = this.newValue;

      // Filter only the most current add
      this.filterAdds();

      // Format date to default aaa/mm/dd
      this.formatDate(this.products);

      // Sets the min max e avg of product
      this.maxMinAvg(this.products);

      // save the result in vueX to show in the us chart
      this.$store.commit("setPredimonitorResults", this.products);
      this.$emit("setLoading", false);
    },

    filterAdds() {
      this.dataProducts.forEach((product) => {
        let addsGroup = [];

        let findAddGroup = function (priceResult) {
          for (let i in addsGroup) {
            let ag = addsGroup[i];
            if (
              ag.productLink == priceResult.productLink &&
              ag.sellerName == priceResult.sellerName &&
              ag.productName == priceResult.productName &&
              ag.marketplace == priceResult.marketplace
            ) {
              return ag;
            }
          }
          return null;
        };

        product.priceResults.forEach((adds) => {
          let addGroup = findAddGroup(adds);

          if (addGroup) {
            let newDate = new Date(adds.crawlerDate);
            let addGroupDate = new Date(addGroup.newest.crawlerDate);

            if (newDate > addGroupDate) {
              addGroup.olders.push(addGroup.newest);
              addGroup.newest = adds;
            } else {
              addGroup.olders.push(adds);
            }
          } else {
            addsGroup.push({
              productLink: adds.productLink,
              sellerName: adds.sellerName,
              productName: adds.productName,
              marketplace: adds.marketplace,
              newest: adds,
              olders: [],
            });
          }
        });

        let newProduct = Object.assign({}, product);
        newProduct.priceResults = [];

        addsGroup.forEach((group) => {
          group.newest.olderAdds = group.olders;
          newProduct.priceResults.push(group.newest);
        });

        let json = CircularJSON.stringify(newProduct);
        let obj = CircularJSON.parse(json);
        this.products.push(obj);

        this.products.forEach((el) => {
          el.listaDeAlertas = null;
        });
      });
    },

    formatDate(date) {
      for (let i in date) {
        date[i].priceResults.forEach((el) => {
          el.crawlerDate = el.crawlerDate.slice(0, 10);
        });
      }
    },

    maxMinAvg(data) {
      for (let i in data) {
        var prices = [];
        if (data[i].priceResults != 0) {
          data[i].priceResults.forEach((el) => {
            if (el.disregarded == false) {
              prices.push(el.price);

              let soma = 0;
              for (let s in prices) {
                soma += prices[s];
              }

              data[i].maxPrice = Math.max(...prices);
              data[i].minPrice = Math.min(...prices);
              data[i].avgPrice = soma / prices.length;
            }
          });
        }
      }
    },

    setHeadersToCompraAgora() {
      if (this.idCompraAgora == true) {
        let compraAgoraHeaders = [
          {
            text: "Tipo de preço",
            align: "center",
            sortable: true,
            value: "price_unit_type",
          },
          {
            text: "Qtde. na caixa",
            align: "center",
            sortable: true,
            value: "price_unit_quantity",
          },
        ];

        this.headers.splice(5, 0, compraAgoraHeaders[0]);
        this.headers.splice(6, 0, compraAgoraHeaders[1]);
      }
    },

    formatPriceAlert(priceAlertList) {
      let groupedPriceAlerts = {};

      priceAlertList.forEach((alert) => {
        const { idMonitoringItem, isParentItem } = alert;

        if (!groupedPriceAlerts[idMonitoringItem]) {
          groupedPriceAlerts[idMonitoringItem] = {
            idMonitoringItem,
            products: [],
            adds: [],
          };
        }

        if (isParentItem) {
          groupedPriceAlerts[idMonitoringItem].products.push(alert);
        } else {
          groupedPriceAlerts[idMonitoringItem].adds.push(alert);
        }
      });
      const groupedPriceAlertsArray = Object.values(groupedPriceAlerts);

      this.products.map((product) => {
        product.listaDeAlertas = null;
        const { idMonitoringItem } = product;

        const correspondingAlert = groupedPriceAlertsArray.find(
          (alert) => alert.idMonitoringItem === idMonitoringItem
        );

        if (correspondingAlert) {
          product.listaDeAlertas = correspondingAlert;
        }
        return product;
      });
    },

    showSnackbar(text, type) {
      this.$emit("showSnackbar", text, type);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 18px;
  font-weight: 600;
}
</style>