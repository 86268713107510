import { render, staticRenderFns } from "./variation-card.vue?vue&type=template&id=b80fa374&scoped=true&"
import script from "./variation-card.vue?vue&type=script&lang=js&"
export * from "./variation-card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80fa374",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VChip,VCol,VCombobox,VIcon,VRow,VSwitch,VTextField})
