<template>
  <div>
    <div
      class="color-error mb-4"
      v-if="service.getAllPriceProjectionFilters.error"
    >
      {{ this.$i18n.t("TXT_ERROR_FIND_FILTERS") }}:
      {{ service.getAllPriceProjectionFilters.error }}
    </div>
    <div class="filters-grid gap-6 align-end">
      <combo-box
        v-for="item in filters.customFilters"
        v-model="item.model"
        :key="item.IdEnterprisePriceProjection_DefaultFilter"
        :title="item.fieldName"
        :style="`grid-column: span ${item.displaySpan}`"
        :placeholder="$t('TXT_SELECT')"
        :hideIcon="true"
        :multiple="false"
        :disabled="
          item.values.length == 0 ||
          !service.listProducts.state() === 'DATA_READY' ||
          disableFilter
        "
        clearable
        :loading="service.getAllPriceProjectionFilters.isLoading"
        :items="
          item.dbField == '900000'
            ? newValuesFilterSemaphore
            : item.dbField == '900001'
            ? newValuesFilterWorkflow
            : item.values
        "
        :text="'description'"
        @input="selectItem(item, $event)"
      >
      </combo-box>
    </div>

    <div ng-controller>
      <div
        style="display: flex; flex-direction-row;justify-content:flex-end; align-items:center"
      >
        <div style="display:flex;flex-direction-row"></div>
      </div>
    </div>
  </div>
</template>
<script>
import comboBox from "@/Design_System/common/comboBox";
import AiPricingService from "@/service/ai-pricing";
export default {
  props: ["idPricingGroup"],
  components: {
    comboBox,
  },
  data() {
    return {
      filters: {},
      service: new AiPricingService(),
      firstSearch: true,
      filterItems: [],
      selectedFilters: [],
      disableFilter: true,
      newValuesFilterWorkflow: [],
      newValuesFilterSemaphore: [],
    };
  },

  methods: {
    // Every time the filter changes it triggers this function
    selectItem(item, event) {
      // console.log("item", item, "event", event);
      let filterRequest = {};
      if (event == null) {
        const index = this.selectedFilters.findIndex(
          (el) => el.dbField === item.dbField
        );
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }

        this.sendFilters(
          this.selectedFilters
          // this.workFlowFilter,
          // this.semaphoreFilter
        );
      } else {
        filterRequest = {
          idEnterprisePriceProjectionDefaultFilter: null,
          dbField: item.dbField,
          value: event.value,
        };

        // Verifica se há algum objeto no array selectedFilters com value nulo e remove o objeto caso exista
        const nullIndex = this.selectedFilters.findIndex(
          (el) => el.value == null
        );
        if (nullIndex !== -1) {
          this.selectedFilters.splice(nullIndex, 1);
        }

        // Verifica se há algum objeto no array selectedFilters com o mesmo valor em dbField e remove o objeto caso exista
        const sameDbFieldIndex = this.selectedFilters.findIndex(
          (el) => el.dbField === item.dbField
        );
        if (sameDbFieldIndex !== -1) {
          this.selectedFilters.splice(sameDbFieldIndex, 1);
        }

        this.selectedFilters.push(filterRequest);
        this.sendFilters(
          this.selectedFilters,
          this.workFlowFilter,
          this.semaphoreFilter
        );
      }
    },

    sendFilters(filters) {
      this.values = {
        idEnterprisePricesProjection: null,
        customFilter: filters,
        // semaphoreNameFilter: semaphore ? semaphore : null,
        // workFlowNameFilter: workflow ? workflow : null,
      };
      // console.log(this.values);

      this.$emit("input", this.values);
    },

    async setFilters() {
      if (!this.idPricingGroup) return;

      this.disableFilter = true;

      this.filters = await this.service.getAllPriceProjectionFilters.call(
        this.idCompany,
        this.idPricingGroup
      );

      this.filters.customFilters.sort((a, b) => {
        return a.order - b.order;
      });

      // console.log('ordenado', this.filters.customFilters.map(x => x.order))

      this.disableFilter = false;
    },

    async getFilters() {
      this.filters = await this.service.getAllPriceProjectionFilters.call(
        this.idCompany
      );
      this.filters.customFilters.sort((a, b) => {
        return a.order - b.order;
      });
    },

    async init() {
      await this.getFilters();
    },
    verifyContentTerm(term, mainBody) {
      return term.includes(mainBody);
    },
    buildBpdyToFilter(term) {
      return {
        description: this.$i18n.t(term.description),
        value: term.value,
      };
    },
    translateTermsInCustomFilter(terms, mainBody) {
      let filterValue = [];
      if (terms.length > 0) {
        terms.forEach((term) => {
          let isValid = this.verifyContentTerm(term.description, mainBody);
          if (isValid == true) {
            filterValue.push(this.buildBpdyToFilter(term));
          }
        });
      }
      return filterValue;
    },
  },

  watch: {
    // Every time someone selects a pricing it calls this endpoint and active the function
    async idPricingGroup() {
      this.setFilters();
    },

    filters: {
      handler(value) {
        let customFilters = value.customFilters;

        const sena = customFilters.find(x => x.dbField == 900000) 

        if (sena != null && sena.values.length > 0) {
          this.newValuesFilterSemaphore = this.translateTermsInCustomFilter(
            sena.values,
            "TXT_SEMAPHORE_"
          );
        }

        const work = customFilters.find(x => x.dbField == 900001) 

        if (work != null && work.values.length > 0) {
          this.newValuesFilterWorkflow = this.translateTermsInCustomFilter(
            work.values,
            "TXT_WORKFLOW_"
          );
        }

      },
    },
  },

  mounted() {
    this.init();
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
};
</script>
<style lang="scss">
.filters-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.color-error {
  color: $brand-color-secondary-pure;
  display: flex;
  flex-direction: row;
}
</style>
