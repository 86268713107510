<template>
  <v-container id="search-market" class="ma-0 pa-0 py-10 mx-auto">
    <v-row class="ma-0 pa-0 mb-4">
      <p class="card-title">Monitoramento por Produto</p>
    </v-row>
    <!-- Pesquisar Produto -->
    <v-card flat class="ma-0 pa-4">
      <v-row class="ma-0 pa-0" align-content="center">
        <!-- Produto -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
          <div>
            <label style="cursor: pointer" class="input-label">
              <input :disabled="selectAllProducts" class="mr-2" type="radio" v-model="radioProduct"
                @click="setProducts" />Produto</label>
          </div>
          <v-select :items="products" v-model="product" :disabled="disableProducts" class="ma-0 pa-0 mt-2"
            item-value="id" item-text="nome" outlined dense hide-details append-icon="mdi-chevron-down">
          </v-select>
        </v-col>
        <!-- Grupo de Produtos -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
          <div>
            <label style="cursor: pointer" class="input-label">
              <input :disabled="selectAllProducts" class="mr-2" type="radio" v-model="radioGroupProducts"
                @click="setGroupProducts" />Grupo de produtos</label>
          </div>

          <v-row class="ma-0 pa-0 mt-2">
            <v-select :items="groupProducts" v-model="groupProduct" :disabled="disableGroupProducts"
              class="input-plus ma-0 pa-0" outlined item-value="id" item-text="nome" dense hide-details
              append-icon="mdi-chevron-down">
            </v-select>
            <div style="position: relative">
              <v-icon class="plus-icon">mdi-plus-box</v-icon>
            </div>
          </v-row>
        </v-col>
        <!-- Periodo de Referencia -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 px-6">
          <div>
            <label style="cursor: pointer" class="input-label">
              Periodo de referência</label>
            <span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon style="cursor: pointer" small v-bind="attrs" v-on="on" color="#6792F8" right>
                    mdi-information-outline</v-icon>
                </template>
                <span>Aqui vai alguma informação</span>
              </v-tooltip>
            </span>
          </div>
          <v-menu ref="menu" v-model="menu" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="ma-0 pa-0 pr-3 mt-2" v-model="date" hide-details outlined dense readonly
                v-bind="attrs" v-on="on" append-icon="mdi-calendar"></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable></v-date-picker>
          </v-menu>
        </v-col>
        <!-- Comfiguração de TAG -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0">
          <br />
          <router-link to="/main/market-intelligence/tags-configure">
            <v-btn color="#3C5CA7" class="white--text mt-2 ml-4 tags-btn" height="41px">
              <v-icon small left>mdi-tag-outline</v-icon>
              Configurar Tags
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <!-- Selecionar todos os produtos -->
      <v-row class="ma-0 pa-0 mb-6">
        <v-checkbox v-model="selectAllProducts" dense hide-details label="Selecionar todos os produtos"
          @click="selectAllProductsFunction">
        </v-checkbox>
      </v-row>
      <!-- Botões de pesquisar e exportar -->
      <v-card-actions class="ma-0 pa-0">
        <v-btn @click="search" color="#3C5CA7" class="mr-4 white--text">
          <v-icon>mdi-magnify</v-icon>
          Pesquisar
        </v-btn>
        <v-btn @click="exportFiles" color="#3C5CA7" outlined class="mr-4">
          <v-icon>mdi-upload</v-icon>
          Exportar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- RESULTS --->
    <v-row class="ma-0 pa-0 mb-4 mt-6">
      <p class="card-title">RESULTADOS</p>
    </v-row>
    <v-card flat class="ma-0 py-4 pl-4">
      <!-- Busca -->
      <v-row class="ma-0 pa-0" align-items="center">
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0">
          <label class="input-label">Buscar</label>
          <v-autocomplete class="mb-6 mt-2" placeholder="Buscar" rounded outlined dense hide-details
            append-icon="mdi-magnify" @change="findItem">
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- Filtros -->
      <p class="card-title mb-4" style="font-size: 14px">FILTRAR</p>
      <v-row class="ma-0 pa-0 mb-6">
        <!-- Filtro por Marca -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
          <label class="input-label">Marca</label>
          <v-select class="ma-0 pa-0 mt-2" outlined dense hide-details append-icon="mdi-chevron-down" :items="brands"
            v-model="filterBrand" :disabled="brandList.length > 0">
          </v-select>
        </v-col>
        <!-- Filtro por modelo -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
          <label class="input-label">Modelo</label>
          <v-select class="ma-0 pa-0 mt-2" outlined dense hide-details append-icon="mdi-chevron-down"
            :items="marketplace" v-model="filterMarketplace" :disabled="!marketplaceList.length > 0">
          </v-select>
        </v-col>
        <!-- Filtro por site -->
        <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
          <label class="input-label">Site</label>
          <v-select class="ma-0 pa-0 mt-2" outlined dense hide-details append-icon="mdi-chevron-down" :items="seller"
            v-model="filterSeller" :disabled="!sellerList.length > 0">
          </v-select>
        </v-col>
      </v-row>
      <!-- Botçao de Limpar Filtro -->
      <v-card-actions class="ma-0 pa-0">
        <v-btn @click="cleanFilters" color="#3C5CA7" outlined class="change-button">
          Limpar filtros
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- TABLES -->
    <table-results />
  </v-container>
</template>

<script>
import tableResults from "./table-results.vue";
import marketIntelligenceService from "@/service/market-intelligence-service";
export default {
  name: "monitoring-by-product",
  components: { tableResults },
  data() {
    return {
      products: [],
      brandList: [],
      marketplaceList: [],
      sellerList: [],

      //----------- SMART PRICING VARS -------------
      radioProduct: false,
      product: null,
      radioGroupProducts: false,
      groupProduct: null,
      radioReferencePeriod: false,
      selectAllProducts: false,
      disableProducts: true,
      disableGroupProducts: true,

      //------------ DATA PICKER -------------------
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toLocaleDateString()
        .substr(0, 10),

      //----------- FILTER VARS -------------
      filterBrand: null,
      filterMarketplace: null,
      filterSeller: null,

    };
  },

  methods: {
    setProducts() {
      this.radioGroupProducts = false;
      this.radioProduct = true;
      this.groupProduct = null;
      this.disableProducts = false;
      this.disableGroupProducts = true;
    },

    setGroupProducts() {
      this.radioGroupProducts = true;
      this.radioProduct = false;
      this.product = null;
      this.disableProducts = true;
      this.disableGroupProducts = false;
    },

    selectAllProductsFunction() {
      this.product = null;
      this.groupProduct = null;
      this.radioGroupProducts = false;
      this.radioProduct = false;
      this.disableProducts = true;
      this.disableGroupProducts = true;
    },

    search() {
      
    },

    exportFiles() {
      console.log("Função export");
    },

    findItem() {
      console.log("Função buscar produto");
    },

    // This function clean the filter
    cleanFilters() {
      (this.filterBrand = null),
        (this.filterMarketplace = null),
        (this.filterSeller = null),
        (this.filterOrderByPrice = null);
    },
    // Função para pegar a lista do db
    getListOfProducts() {
      var ms = new marketIntelligenceService();

      var idCompany = 0;
      idCompany = 1106;
      ms.GetProductsToSearch(idCompany)
        .then((r) => {
          this.products = r.data;
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => { });
    },
  },

  created() {
    document.title = "PREDIFY | INTELIGÊNCIA DE MERCADO";
    this.getListOfProducts();
  },
};
</script>

<style scoped>
.card-title {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bolder;
}

.input-label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.plus-icon {
  font-size: 30px;
  position: absolute;
  top: 10%;
  cursor: pointer;
  color: var(--msg-success);
}

.tags-btn {
  width: 15rem !important;
}

/* ------ TABLE ------ */
::-webkit-scrollbar {
  width: 50px !important;
}
</style>