var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"padding-all-30"},[(_vm.showSearch || _vm.showItemsPerPage)?_c('v-row',{attrs:{"justify":'space-between'}},[_c('v-col',{attrs:{"cols":"3"}},[(_vm.showSearch)?_c('prd-search',_vm._b({model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'prd-search',_vm.searchProps,false)):_vm._e()],1),(_vm.showItemsPerPage)?_c('v-col',{attrs:{"cols":"3"}},[_c('prd-combo',_vm._b({attrs:{"items":_vm.itemsPerPageItems,"item-text":'text',"item-value":'value'},model:{value:(_vm.itemsPerPageSelected),callback:function ($$v) {_vm.itemsPerPageSelected=$$v},expression:"itemsPerPageSelected"}},'prd-combo',_vm.itemsPerPageProps,false))],1):_vm._e()],1):_vm._e(),_c('v-container',{staticClass:"mt-5 mb-5"},[_c('v-row',{attrs:{"align":"center"}},[(_vm.showingSubtitles)?_c('div',_vm._l((_vm.indicatorsValues),function(indicator,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"py-0 mb-1 legend-text",class:indicator.class,attrs:{"col-12":""}},[_vm._v(_vm._s(indicator.title))])],1)}),1):_vm._e(),_vm._t("actions-left"),_c('v-spacer'),_vm._t("actions-right")],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',_vm._g(_vm._b({ref:"datatable",staticClass:"elevation-1",attrs:{"headers":_vm.sortedHeaders,"search":_vm.getSearch,"items-per-page":-1,"items":_vm.items,"page":_vm.page,"disable-pagination":_vm.disablePagination,"hide-default-footer":"","hide-default-header":"","options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event},"input":function (v) { return _vm.$emit('input', v); },"page-count":_vm.onPageCount,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
                  'text-center sortable',
                  _vm.getHeaderSortDesc(header.value) ? 'desc' : 'asc',
                  _vm.pagination.sortBy.includes(header.value) ? 'active' : '' ],attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('span',[_vm._v(_vm._s(header.text))]),_c('v-icon',{staticClass:"sort-icon",class:_vm.pagination.sortBy.includes(header.value)
                      ? 'sort-icon-active'
                      : '',attrs:{"small":""}},[_vm._v(_vm._s(_vm.getHeaderSortDesc(header.value) ? "mdi-triangle-down" : "mdi-triangle"))]),(
                    _vm.pagination.sortBy.indexOf(header.value) >= 0 &&
                    _vm.pagination.multiSort
                  )?_c('span',{staticClass:"v-data-table-header__sort-badge"},[_vm._v(_vm._s(_vm.pagination.sortBy.indexOf(header.value) + 1))]):_vm._e()],1)}),0)])]}},_vm._l((_vm.parentSlots),function(slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}}),_vm._l((_vm.parentScopedSlots),function(col){return {key:col,fn:function(data){return [_vm._t(col,null,null,data)]}}}),(
            !_vm.parentScopedSlots.find(function (f) { return f == 'item.' + _vm.getFirstHeaderValue; })
          )?{key:_vm.getName(),fn:function(data){return [_c('td',{staticClass:"text-center px-0"},[_c('div',{class:_vm.GetLegendColumn(data.item, data.header)},[_vm._t('item.first.content',function(){return [_vm._v(" "+_vm._s(data.item[_vm.getFirstHeaderValue])+" ")]},null,data)],2)])]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)],1),(_vm.showPagination)?_c('v-row',[_c('v-col',{staticClass:"py-0 col-12"},[_c('prd-pagination',_vm._b({attrs:{"length":_vm.getTotalPages,"showing-registers-len":_vm.showingRegistersLen,"total-registers":_vm.getTotalRegisters,"total-visible":7},model:{value:(_vm.paginationPage),callback:function ($$v) {_vm.paginationPage=$$v},expression:"paginationPage"}},'prd-pagination',_vm.paginationProps,false))],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }