<template>
  <div style="display: flex; gap: 1rem" v-if="oportunitiesData != null">
    <v-card style="width: 50%" class="pa-4">
      <v-row no-gutters align="center">
        <h4>AUMENTO DE LUCRO</h4>
        <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
        <v-spacer></v-spacer>
        <!-- <v-icon :color="$prdStyles('color-primary')">
          mdi-arrow-right-bold-box-outline
        </v-icon> -->
      </v-row>

      <div class="my-flex mt-4">
        <div>
          <label>Total de itens</label>
          <h4 class="number-destaq">{{ profitItems }}</h4>
        </div>

        <div>
          <label>Lucro com preço atual</label>
          <h4 class="number-destaq">
            {{ setCurrency(profitActualPrice) }}
          </h4>
        </div>

        <div>
          <label>Lucro com adoção da IA</label>
          <h4 class="number-destaq-green">
            {{ setCurrency(profitItemsValue) }}
          </h4>
        </div>
      </div>
    </v-card>

    <v-card style="width: 50%" class="pa-4">
      <v-row no-gutters align="center">
        <h4>AUMENTO DE MARGEM</h4>
        <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
        <v-spacer></v-spacer>
        <!-- <v-icon :color="$prdStyles('color-primary')">
          mdi-arrow-right-bold-box-outline
        </v-icon> -->
      </v-row>

      <div class="my-flex mt-4">
        <div>
          <label>Total de itens</label>
          <h4 class="number-destaq">{{ marginItems }}</h4>
        </div>

        <div>
          <label>Margem com preço atual</label>
          <h4 class="number-destaq">{{ marginActualPrice.toString().replace(".", ",") }}%</h4>
        </div>

        <div>
          <label>Margem com adoção da IA</label>
          <h4 class="number-destaq-green">{{ marginItemsValue.toString().replace(".", ",") }}%</h4>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { setCurrency } from "@/utils/format-currency";
// import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";

export default {
  props: ["oportunitiesData"],
  components: {
    /*tooltipInformations*/
  },
  data() {
    return {};
  },

  computed: {
    profitItems() {
      return this.oportunitiesData?.increaseProfit?.totalItems ?? null;
    },
    profitActualPrice() {
      return this.oportunitiesData?.increaseProfit?.actualPriceProfit ?? null;
    },
    profitItemsValue() {
      return this.oportunitiesData?.increaseProfit?.totalItemsProfit ?? null;
    },

    marginItems() {
      return this.oportunitiesData?.increaseMargin?.totalItems ?? null;
    },
    marginActualPrice() {
      return this.oportunitiesData?.increaseMargin?.actualPriceProfit ?? null;
    },
    marginItemsValue() {
      return this.oportunitiesData?.increaseMargin?.totalItemsProfit ?? null;
    },
  },

  methods: {
    setCurrency,
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  justify-content: space-between;
}

.number-destaq {
  color: $brand-color-primary-pure;
  font-size: 20;
}

.number-destaq-green {
  color: $feedback-color-success-pure;
}
</style>