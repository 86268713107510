<template>
  <router-view />
</template>

<script>
import CompanyService from "@/service/company-service";
import LoginService from "@/service/login-service.js";

export default {
  name: "mainRouter",
  methods: {
    decodeBase64: function (string) {
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
      var result = "";
      var i = 0;
      do {
        var b1 = characters.indexOf(string.charAt(i++));
        var b2 = characters.indexOf(string.charAt(i++));
        var b3 = characters.indexOf(string.charAt(i++));
        var b4 = characters.indexOf(string.charAt(i++));
        var a = ((b1 & 0x3f) << 2) | ((b2 >> 4) & 0x3);
        var b = ((b2 & 0xf) << 4) | ((b3 >> 2) & 0xf);
        var c = ((b3 & 0x3) << 6) | (b4 & 0x3f);
        result +=
          String.fromCharCode(a) +
          (b ? String.fromCharCode(b) : "") +
          (c ? String.fromCharCode(c) : "");
      } while (i < string.length);
      return result;
    },
  },
  async created() {
    var params = location.href.split("?")[1].split("&");
    if (params) {
      let idCompany = null;
      let token = null;
      let lang = "pt-BR";

      params.forEach((e) => {
        var splitted = e.split("=");

        if (splitted.length == 2) {
          if (splitted[0].toLowerCase() == "idcompany") {
            idCompany = splitted[1];
          } else if (splitted[0].toLowerCase() == "token") {
            token = splitted[1];
          } else if (splitted[0].toLowerCase() == "lang") {
            lang = splitted[1];
          }
        }
      });

      if (idCompany && token) {
        this.$store.dispatch("setUser", {
          token: this.decodeBase64(decodeURIComponent(token)),
          idCompany: idCompany,
          adminRoles: []
        });

        
        let service = null

        service = new LoginService()
        let userRoles = await service.getUserRoles();
        this.$store.dispatch("setUser", {
          token: this.decodeBase64(decodeURIComponent(token)),
          idCompany: idCompany,
          adminRoles: userRoles?.data.adminRoles || []
        });
		
        let companyService = new CompanyService();
        await companyService
          .getResources(idCompany)
          .then((response) => {
            this.$store.dispatch("setResources", response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      let correctLang = lang.split("-");
      lang = correctLang[0] + "-" + correctLang[1].toUpperCase();
      localStorage.setItem("lang", lang);
    }
  },
};
</script>
