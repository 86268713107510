/**
 * Transformas a number into a money string.
 *
 * @example
 *   ```
 *   const value = formatMoney(12.52);
 *   console.log(value) // "R$ 12,52"
 *   ```;
 */
export function formatMoney(value) {
  if (typeof value !== "number" && !Number.isNaN(value)) return null;
  const valueString = value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  // These regexes replace the commas with dot, and the dot with a comma.
  return valueString.replace(/,/g, ".").replace(/\.(?=\d{2}$)/g, ",");
}

/**
 * Given a string generated by the above formatMoney function, return it's
 * number equivalent
 *
 * @param {string} value
 * @returns {number}
 */
export function parseMoney(value) {
  if (value === "") return NaN;
  const onlyNumbers = value.replace(/[^\-0-9,]/g, "").replace(/,/g, ".");
  return Number(onlyNumbers);
}
