<template>
  <v-tooltip class="tootip" top content-class="tooltip" color="#f5f5f5">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :left="leftIcon"
        :right="rightIcon"
        class="icon"
        v-bind="attrs"
        v-on="on"
      >
        mdi-information-outline</v-icon
      >
    </template>
    <label class="span">
      <slot><span style="color: black" v-html="title"></span></slot>
    </label>
  </v-tooltip>
</template>

<script>
export default {
  name: "toolTipInformationComponent",
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    leftIcon: {
      type: Boolean,
    },
    rightIcon: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
#tooltip {
  font-family: "Ubuntu";
}

.icon {
  cursor: pointer;
  color: #6792f8;
  position: relative;
  font-size: medium;
}

.span {
  color: #cbdafe;
  font-family: "Ubuntu";
  font-size: 12px;
}
</style>
