<template>
  <div>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :hasTooltip="false"
        :breadCrumbs="breadCrumbs"
        :title="'HOME'"
      />
    </v-row>

    <main-filters
      :isLoading="isLoading"
      @promotionalCalendarEvent="promotionalCalendarData = $event"
      @geralVisionDataEvent="geralVisionData = $event"
      @totalProjection="totalProjectionData = $event"
      @oportunitiesDataEvent="oportunitiesData = $event"
      @alertsDataEvent="alertsData = $event"
      @setLoading="isLoading = $event"
      @loadingProjection="loadingProjection = $event"
      @promotionalCalendarLoading="promotionalCalendarLoading = $event"
      @chartIALoading="chartIALoading = $event"
      ref="main-filters"
    />

    <promotional-calendar
      :promotionalCalendarData="promotionalCalendarData"
      :promotionalCalendarLoading="promotionalCalendarLoading"
      @updatePromotionalCalendar="updatePromotionalCalendar"
    />
    <geral-vision-main
      v-if="geralVisionData != null"
      :geralVisionData="geralVisionData"
      :totalProjectionData="totalProjectionData"
      :isLoading="isLoading"
      :loadingProjection="loadingProjection"
      :chartIALoading="chartIALoading"
    />

    <opportunities-main :oportunitiesData="oportunitiesData" />

    <alerts-main :alertsData="alertsData" />
  </div>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import MainFilters from "./components-vem/main-filters.vue";
import PromotionalCalendar from "./components-vem/promotional-calendar/promotional-calendar.vue";
import GeralVisionMain from "./components-vem/geral-vision/geral-vision-main.vue";
import OpportunitiesMain from "./components-vem/opportunities/opportunities-main.vue";
import AlertsMain from "./components-vem/alerts/alerts-main.vue";
import ServiceHome20 from "@/service/home20/index";

export default {
  components: {
    titleBreadCrumbsComponent,
    MainFilters,
    PromotionalCalendar,
    GeralVisionMain,
    OpportunitiesMain,
    AlertsMain,
  },
  data() {
    return {
      isLoading: false,
      loadingProjection: false,
      service: new ServiceHome20(),
      breadCrumbs: [
        {
          text: "Dashboard",
        },
      ],
      geralVisionData: null,
      totalProjectionData: null,
      oportunitiesData: null,
      alertsData: null,
      promotionalCalendarData: null,

      promotionalCalendarLoading: false,
      chartIALoading: false,
    };
  },

  methods: {
    updatePromotionalCalendar() {
      this.$refs["main-filters"].getPromotionCalendar();
    },
  },
  beforeMount() {
    // this.service.getRecipe().then((res) => {
    //   //this.geralVisionData = res.data;
    // })
  },
};
</script>

<style lang="scss" scoped></style>