<template>
  <v-card flat>
    <div class="pa-4 font-size-12 grid height-max-content">
      <div class="teste span-column-2 rounded">
        {{ this.$i18n.t("TXT_SUGGESTED_PRICE_INFO") }}
      </div>
      <div :class="cardClasses">
        <label v-t="'TXT_MARGIN'">Margem</label>
        <div v-if="margin != null" class="d-flex align-center gap-1">
          <span :class="makeCardValueClasses(margin)">
            {{ Math.trunc(margin) + "%" }}
          </span>
          <span
            :class="[
              marginVariation < 0 ? 'red-value' : 'green-value',
              'font-size-14',
            ]"
            v-if="marginVariation !== null"
          >
            ({{ marginVariation }})
          </span>
          <span v-else />
        </div>
      </div>
      <div :class="cardClasses">
        <label v-t="'TXT_DEMAND_VARIABILITY'"></label>
        <div class="d-flex">
          <span :class="makeCardValueClasses(demandVariation)">
            {{ formatNumberPercentage1(demandVariation) }}
          </span>
        </div>
      </div>
      <div :class="cardClasses">
        <label>IC</label>
        <span :class="makeCardValueClasses(ic)">{{ ic }}</span>
      </div>
      <div :class="cardClasses">
        <label v-t="'TXT_CURVE'"></label>
        <span :class="makeCardValueClasses()">{{ curveABC }}</span>
      </div>
      <div :class="cardClasses">
        <label v-t="'TXT_SALLES_VARIATION'"></label>
        <div class="d-flex">
          <span :class="makeCardValueClasses(salesVariation)">
            {{ setCurrency(salesVariation) }}
          </span>
        </div>
      </div>
      <div :class="cardClasses">
        <label v-t="'TXT_PROFIT_VARIATION_LOWER'"></label>
        <div class="d-flex">
          <span :class="makeCardValueClasses(profitVariation)">
            {{ setCurrency(profitVariation) }}
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { formatNumberPercentage } from "@/utils/format-numbers";

import { setCurrency } from "@/utils/format-currency";
export default {
  props: ["productRegion", "productName"],
  components: {},
  data: () => ({
    removerDepois1: 2,

    cardClasses:
      "pa-2 d-flex flex-column shadowed-box rounded justify-space-between min-width-130",
  }),
  methods: {
    setCurrency,
    makeCardValueClasses(value) {
      return [
        "font-size-16",
        "font-weight-medium",
        value === undefined || value === 0
          ? "blue-value"
          : value < 0
          ? "red-value"
          : value > 0
          ? "green-value"
          : "blue-value",
      ];
    },
    formatNumberPercentage(value) {
      return formatNumberPercentage(value, {
        fractionLength: 0,
        alwaysRenderSign: true,
      });
    },
    formatNumberPercentage1(value) {
      return formatNumberPercentage(value, {
        fractionLength: 1,
        alwaysRenderSign: true,
      });
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    margin() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      if (this.productRegion?.price?.override != null) {
        if (this.productRegion?.price?.override?.isManual)
          return this.productRegion?.price?.override.manual_Price_Margin;
        else return this.productRegion?.price?.override.price_Margin;
      } else return this.productRegion?.price?.salePrice_Margin;
    },

    demandVariation() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      // let meanPrice = null;
      let meanQuantity = null;
      let calculatedDemand = null;

      if (this.productRegion?.price?.elasticity) {
        calculatedDemand = this.productRegion?.price?.suggestedPrice_Demand;
        // meanPrice = this.productRegion?.price?.elasticity.meanPrice;
        meanQuantity = this.productRegion?.price?.elasticity.meanQuantity;
      } else if (this.productRegion?.elasticity) {
        if (this.productRegion?.price.override != null) {
          if (this.productRegion?.price.override.isManual)
            calculatedDemand =
              this.productRegion?.price.override?.manual_Price_Demand;
          else
            calculatedDemand = this.productRegion?.price.override?.price_Demand;
        } else
          calculatedDemand = this.productRegion?.elasticity?.calculatedDemand;
        // meanPrice = this.productRegion?.elasticity?.priceDemand;
        meanQuantity = this.productRegion?.elasticity?.averageDemand;
      }

      // if (
      //   typeof this.productRegion?.elasticity?.averageDemand !== "number" ||
      //   typeof this.productRegion?.elasticity?.calculatedDemand !== "number"
      // ) {
      //   return null;
      // } else {
      return (calculatedDemand / meanQuantity - 1) * 100;
      // }
    },
    ic() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      if (typeof this.productRegion?.price.getAvgPriceMarketResult !== "number")
        return null;
      // const override = this.productRegion?.price?.override;
      // const isManual = override?.isManual || null;
      // const isBlocked = override?.isBlocked || null;
      // const price =
      //   (isManual && override?.manual_Price) ??
      //   (isBlocked && override?.price) ??
      //   this.productRegion?.price?.salePrice ??
      //   null;
      // if (price === null) return null;

      const price = this.productRegion?.price?.suggestedPrice;

      let calculated =
        (price * 100) / this.productRegion?.price.getAvgPriceMarketResult;

      return Math.trunc(calculated) + "%";
    },
    curveABC() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      if (this.productRegion?.price?.elasticity)
        return this.productRegion?.price?.salePrice_Demand_CurveABC;
      return this.productRegion?.elasticity?.curveAbc ?? null;
    },
    salesVariation() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      let meanPrice = null;
      let meanQuantity = null;
      let calculatedDemand = null;

      if (this.productRegion?.price?.elasticity) {
        calculatedDemand = this.productRegion?.price?.suggestedPrice_Demand;
        meanPrice = this.productRegion?.price?.elasticity.meanPrice;
        meanQuantity = this.productRegion?.price?.elasticity.meanQuantity;
      } else if (this.productRegion?.elasticity) {
        if (this.productRegion?.price.override != null) {
          if (this.productRegion?.price.override.isManual)
            calculatedDemand =
              this.productRegion?.price.override?.manual_Price_Demand;
          else
            calculatedDemand = this.productRegion?.price.override?.price_Demand;
        } else
          calculatedDemand = this.productRegion?.elasticity?.calculatedDemand;

        meanPrice = this.productRegion?.elasticity?.priceDemand;
        meanQuantity = this.productRegion?.elasticity?.averageDemand;
      }

      // if (
      //   typeof this.productRegion?.price?.lastSalePrice !== "number" ||
      //   typeof this.productRegion?.elasticity?.averageDemand !== "number" ||
      //   typeof this.productRegion?.elasticity?.calculatedDemand !== "number" ||
      //   typeof this.productRegion?.price?.suggestedPrice !== "number"
      // )
      //   return null;
      // else {
      return (
        this.productRegion.price.suggestedPrice * calculatedDemand -
        meanPrice * meanQuantity
      );
      // }
    },
    profitVariation() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      let meanPrice = null;
      let meanQuantity = null;
      let calculatedDemand = null;

      if (this.productRegion?.price?.elasticity) {
        calculatedDemand = this.productRegion?.price?.suggestedPrice_Demand;
        meanPrice = this.productRegion?.price?.elasticity.meanPrice;
        meanQuantity = this.productRegion?.price?.elasticity.meanQuantity;
      } else if (this.productRegion?.elasticity) {
        if (this.productRegion?.price.override != null) {
          if (this.productRegion?.price.override.isManual)
            calculatedDemand =
              this.productRegion?.price.override?.manual_Price_Demand;
          else
            calculatedDemand = this.productRegion?.price.override?.price_Demand;
        } else
          calculatedDemand = this.productRegion?.elasticity?.calculatedDemand;

        meanPrice = this.productRegion?.elasticity?.priceDemand;
        meanQuantity = this.productRegion?.elasticity?.averageDemand;
      }

      if (
        calculatedDemand == null ||
        meanPrice == null ||
        meanQuantity == null
      ) {
        return null;
      }

      // if (
      //   typeof this.productRegion?.elasticity?.averageDemand !== "number" ||
      //   typeof this.productRegion?.elasticity?.calculatedDemand !== "number" ||
      //   typeof this.productRegion?.price?.salePrice !== "number" ||
      //   typeof this.productRegion?.elasticity?.priceDemand !== "number" ||
      //   typeof this.productRegion?.price?.productCost !== "number"
      // ) {
      //   return null;
      // } else {

      return (
        calculatedDemand *
          (this.productRegion?.price?.suggestedPrice -
            this.productRegion?.price?.saleCost) -
        meanQuantity * (meanPrice - this.productRegion?.price?.saleCost)
      );
      // }
    },

    marginVariation() {
      // TODO - Implement this
      return null;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.shadowed-box {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.flex-column.last {
  width: 45%;
}
.blue-value {
  color: $brand-color-primary-pure;
}
.green-value {
  color: $feedback-color-success-pure;
}
.red-value {
  color: $feedback-color-warning-pure;
}
.flex-column .curv {
  color: #3c5ca7;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr 1fr 1fr;
  column-gap: 8px;
  row-gap: 16px;
}
.height-max-content {
  height: max-content;
}
.min-width-130 {
  min-width: 130px;
}

.teste {
  font-weight: 600;
}
</style>
