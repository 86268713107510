export function validateMatchSimulation(context) {
  const errors = [];
  if (!context.selectedPlace) errors.push("Você deve selecionar um estádio");
  if (!context.selectedCrowding) errors.push("Você deve selecionar um cenário");
  if (!context.minPrice) errors.push("Você deve fornecer um preço mínimo");
  if (!context.maxPrice) errors.push("Você deve fornecer um preço máximo");
  if (!context.publicPrediction)
    errors.push("Você deve fornecer uma previsão de público");
  if (!context.expenses)
    errors.push("Você deve fornecer um valor para despesas");
  if (!context.contributionMargin)
    errors.push("Você deve fornecer uma margem de contruibuição");
  if (context.service.crowding.error) {
    errors.push("Houve um erro ao buscar informações sobre este cenário");
  }
  if (context.service.place.error) {
    errors.push("Houve um erro ao buscar informações sobre este estádio");
  }
  if (context.service.listTickets.error) {
    errors.push("Houve um erro ao buscar a lista de ingressos");
  }
  return errors.join("\n");
}
