<template>
  <div>
    <v-card
      class="ma-auto transparent rounded-0"
      width="400px"
      elevation="0"
    >
      <p class="font-weight-bold margin-bottom-30 login-form-title">
        CRIAR MINHA CONTA
      </p>
      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <v-col cols="1" class="pa-0">
          <font-awesome-icon icon="fa-regular fa-user" class="fa-icon" />
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            type="text"
            class="pa-2 ma-0 form-input"
            placeholder="NOME COMPLETO"
            v-model="fullname"
          />
        </v-col>
      </v-row>
      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <v-col cols="1" class="pa-0">
          <font-awesome-icon icon="fa-regular fa-building" class="fa-icon" />
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            type="text"
            class="pa-2 ma-0 form-input"
            placeholder="NOME DA EMPRESA"
            v-model="companyName"
          />
        </v-col>
      </v-row>

      <v-row
        class="mx-0 mt-0 pa-0 align-center login-input-row"
        :class="showEmailErrorMessage ? 'mb-2' : 'margin-bottom-30'"
      >
        <v-col cols="1" class="pa-0">
          <!-- <v-icon color="var(--white)">mdi-domain</v-icon> -->
          <v-icon color="var(--white)" dense>mdi-at</v-icon>
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            type="text"
            class="pa-2 ma-0 form-input"
            placeholder="E-MAIL"
            v-model="email"
            @keyup="emailToLowerCase()"
          />
        </v-col>
      </v-row>
      <p
        class="pa-1 mx-0 mt-0 margin-bottom-30 error white--text rounded-sm"
        v-if="showEmailErrorMessage"
      >
        Preencha o e-mail corretamente
      </p>
      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <v-col cols="1" class="pa-0">
          <font-awesome-icon
            icon="fa-regular fa-eye-slash"
            class="fa-icon"
            v-if="!showPassword"
            @click="displayPassword()"
          />
          <font-awesome-icon
            icon="fa-regular fa-eye"
            class="fa-icon"
            v-if="showPassword"
            @click="displayPassword()"
          />
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            :type="showPassword ? 'text' : 'password'"
            class="pa-2 ma-0 form-input"
            placeholder="SENHA"
            v-model="password"
          />
        </v-col>
      </v-row>

      <v-row
        class="mx-0 mt-0 pa-0 align-center login-input-row"
        :class="showPasswordErrorMessage ? 'mb-2' : 'margin-bottom-30'"
      >
        <v-col cols="1" class="pa-0">
          <font-awesome-icon
            icon="fa-regular fa-eye-slash"
            class="fa-icon"
            v-if="!showConfirmPassword"
            @click="displayConfirmPassword()"
          />
          <font-awesome-icon
            icon="fa-regular fa-eye"
            class="fa-icon"
            v-if="showConfirmPassword"
            @click="displayConfirmPassword()"
          />
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            class="pa-2 ma-0 form-input"
            placeholder="REPETIR SENHA"
            v-model="confirmPassword"
            @keyup="checkPassword()"
          />
        </v-col>
      </v-row>

      <p
        class="pa-1 mx-0 mt-0 margin-bottom-30 error white--text rounded-sm"
        v-if="showPasswordErrorMessage"
      >
        As senhas não estão iguais
      </p>

      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <v-col cols="1" class="pa-0">
          <v-icon color="var(--white)" dense>mdi-phone-outline</v-icon>
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            type="text"
            class="pa-2 ma-0 form-input"
            placeholder="TELEFONE"
            v-model="phone"
          />
        </v-col>
      </v-row>
      <btn :title="'PROSSEGUIR'" @click.native="handleSubmit()" />
      <v-row class="mx-0 mt-0 margin-bottom-30 align-center">
        <router-link
          to="sign-in"
          style="color: var(--white); text-decoration: none"
        >
          ACESSAR MINHA CONTA
        </router-link>
        <v-spacer />
        <router-link
          to="sign-in"
          style="color: var(--white); text-decoration: none"
        >
          <v-icon class="" color="var(--white)"> mdi-chevron-right</v-icon>
        </router-link>
      </v-row>
    </v-card>
  </div>
</template>


<script>
import loginFormButton from "@/components/common/loagin-form-button.vue";

export default {
  name: "signUp",
  components: {
    btn: loginFormButton,
  },
  data() {
    return {
      fullname: null,
      companyName: null,
      email: null,
      password: null,
      confirmPassword: null,
      phone: null,

      showPassword: false,
      showConfirmPassword: false,
      showEmailErrorMessage: false,
      showPasswordErrorMessage: false,
    };
  },
  methods: {
    displayPassword() {
      this.showPassword = !this.showPassword;
    },

    displayConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    isValidEmail() {
      if (!this.email) return false;
      if (!this.email.includes("@")) return false;
      if (!this.email.split("@")[1].includes(".")) return false;
      return true;
    },

    emailToLowerCase() {
      if (this.email) this.email = this.email.toLowerCase();
    },

    checkPassword() {
      if (this.confirmPassword && this.password != this.confirmPassword) {
        this.showPasswordErrorMessage = true;
      } else {
        this.showPasswordErrorMessage = false;
      }
    },

    handleSubmit() {
      let validation = this.isValidEmail();
      if (validation) {
        let data = {
          fullname: this.fullname,
          companyName: this.companyName,
          email: this.email,
          password: this.password,
          phone: this.phone,
        };
        console.log(data);
        this.$router.push("/login/company-information");
      } else {
        this.showEmailErrorMessage = true;
        setTimeout(() => {
          this.showEmailErrorMessage = false;
        }, 5000);
      }
    },
  },
  created() {
    document.title = "PREDIFY | CRIAR MINHA CONTA";
  },
};
</script>

<style scoped>
::selection {
  background-color: var(--bright-red);
  color: var(--white);
}
</style>