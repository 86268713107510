<template>
  <div>
    <v-expansion-panel class="mx-4 pa-0">
      <v-expansion-panel-header
        append-icon="meid-chevron-down"
        color="#F5F5F5"
        class="py-0 px-4"
      >
        <v-row
          v-if="hasResource('predimonitor.table.sellerpercent')"
          no-gutters
        >
          <v-col cols="7">
            <h4>{{ product.productName.replace("/", "-") }}</h4>
          </v-col>
          <v-spacer />
          <div class="d-flex mr-2">
            <span class="mr-1">
              {{
                `${$t("TXT_AVERAGE_PRICE")}: ${currency} ${product.avgPrice
                  .toFixed(2)
                  .replace(".", ",")}`
              }}
            </span>
            <tooltip-informations
              :title="$t('TXT_AVERAGE_PRICE_SELECTED_PERIOD')"
            />
          </div>
        </v-row>
        <div v-else class="flex-space-between">
          <!-- PRODUCT NAME -->
          <div class="my-flex">
            <v-icon
              @click.stop="copyFunction(product.productName)"
              :color="$prdStyles('color-primary')"
              left
              small
              class="copy-button"
              >mdi-content-copy</v-icon
            >
            <h4>{{ product.productName }}</h4>
          </div>

          <!-- HEADER ACTIONS -->
          <div class="my-flex font-weight-bold">
            <div class="my-flex mr-4">
              <v-icon color="#37B352" left small
                >mdi-arrow-up-bold-outline</v-icon
              >
              {{ product.maxPrice | setToIsoString }}
            </div>
            <div class="my-flex mr-4">
              <v-icon :color="$prdStyles('color-primary')" left small
                >mdi-minus-circle-outline</v-icon
              >
              {{ product.avgPrice | setToIsoString }}
            </div>
            <div class="my-flex mr-4">
              <v-icon color="#B00020" left small
                >mdi-arrow-down-bold-outline</v-icon
              >
              {{ product.minPrice | setToIsoString }}
            </div>
            <!-- <div class="my-flex mr-4">
            <v-row no-gutters justify="center" align="center">
              <v-switch
                v-model="temAlerta"
                @click.stop="openModalProductMonitoring(product)"
                :color="$prdStyles('color-primary')"
                dense
                inset
                flat
              ></v-switch>

              <v-icon
                @click.stop="modalProductMonitoring = true"
                :color="$prdStyles('color-primary')"
                >mdi-cog-outline</v-icon
              >
            </v-row>
          </div> -->
          </div>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <!-- Table Percentage -->
        <percentage-table
          v-if="hasResource('predimonitor.table.sellerpercent')"
          :product="product"
          class="mt-2"
        />
        <div v-else>
          <!-- TABLE -->
          <v-data-table
            :headers="headers"
            :items="consideredProducts.priceResults"
            class="elevation-1"
            :footer-props="footerProps"
          >
            <!-- product name -->
            <template v-slot:[`item.productName`]="{ item }">
              <td class="table-product-name">
                <div class="copy-box">
                  <v-icon
                    left
                    @click.stop="copyFunction(item.productName)"
                    small
                    :color="$prdStyles('color-primary')"
                    class="copy-button"
                    >mdi-content-copy</v-icon
                  >
                  <a :href="item.productLink" target="_blank">
                    {{ item.productName }}</a
                  >
                </div>
              </td>
            </template>

            <!-- seller name -->
            <template v-slot:[`item.sellerName`]="{ item }">
              <td class="text-center">
                <div
                  style="max-height: 60px; overflow: hidden; text-align: center"
                >
                  {{ item.sellerName }}
                </div>
              </td>
            </template>

            <!-- price -->
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-center">
                {{ item.price | setToIsoString }}
              </td>
            </template>

            <!-- Crawler date -->
            <template v-slot:[`item.crawlerDate`]="{ item }">
              <td class="text-center">
                {{ item.crawlerDate.replace("-", "/").replace("-", "/") }}
              </td>
            </template>

            <!-- disregarded -->
            <template v-slot:[`item.disregarded`]="{ item }">
              <td class="text-center">
                <v-simple-checkbox
                  :ripple="false"
                  color="#3C5CA7"
                  v-model="item.disregarded"
                ></v-simple-checkbox>
              </td>
            </template>

            <!-- isMonitored -->
            <!-- <template v-slot:[`item.isMonitored`]="{ item }">
            <td class="text-center">
              <v-row justify="center" align="center">
                <v-switch
                  v-model="item.priceAlert"
                  @click.stop="openModalAddTracking(item)"
                  :color="$prdStyles('color-primary')"
                  dense
                  inset
                  flat
                ></v-switch>
  
                <v-icon
                  @click.stop="openModalAddTracking(item, 'cog')"
                  :color="$prdStyles('color-primary')"
                  >mdi-cog-outline</v-icon
                >
              </v-row>
            </td>
          </template> -->

            <!-- price_unit_type -->
            <template v-slot:[`item.price_unit_type`]="{ item }">
              {{ item.price_unit_type | unityToString }}
            </template>
          </v-data-table>

          <!--DESCONSIDERED TABLE -->
          <disregard-table
            :disregardedProducts="disregardedProducts"
            :headers="headers"
            @saveProducts="saveProducts"
          />
        </div>
      </v-expansion-panel-content>

      <v-dialog
        :max-width="500"
        persistent
        v-model="modalAddTracking"
        v-if="modalAddTracking"
      >
        <ad-tracking-dialog
          :add="addTrackingParams"
          :idMonitoring="product.idMonitoringItem"
          :isModalOpen="modalAddTracking"
          :resourceGroup="resourceGroup"
          :userList="userList"
          :priceCardLoading="priceCardLoading"
          :listaDeAlertas="addAlert !== null ? addAlert : null"
          @closeModal="closeModalAddTracking"
          @seila="$emit('seila')"
          @showSnackBarItemsSave="$emit('showSnackBarItemsSave', $event)"
          ref="add-monitoring-dialog"
          class="mb-1"
        />
      </v-dialog>
      <v-dialog
        v-model="modalProductMonitoring"
        v-if="modalProductMonitoring"
        :max-width="1100"
        persistent
      >
        <product-monitoring-dialog
          :product="product"
          :resourceGroup="resourceGroup"
          :userList="userList"
          :priceCardLoading="priceCardLoading"
          :listaDeAlertas="
            listaDeAlertas !== null ? listaDeAlertas.products : []
          "
          @closeModal="closeModalProductMonitoring"
          @seila="$emit('seila')"
          @showSnackBarItemsSave="$emit('showSnackBarItemsSave', $event)"
          ref="product-monitoring-dialog"
        />
      </v-dialog>
    </v-expansion-panel>
  </div>
</template>

<script>
import disregardTable from "./disregard-table.vue";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";
import PriceAlertService from "@/service/predimonitor/price-alert-service";
import ProductMonitoringDialog from "./monitoring/product-monitoring-dialog.vue";
import AdTrackingDialog from "./monitoring/components/ad-tracking-dialog.vue";
import PercentageTable from "./percentage-table.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";

export default {
  components: {
    disregardTable,
    ProductMonitoringDialog,
    AdTrackingDialog,
    PercentageTable,
    TooltipInformations,
  },
  props: [
    "productItem",
    "resourceGroup",
    "userList",
    "priceCardLoading",
    "listaDeAlertas",
  ],
  data() {
    return {
      newPredimonitorService: new PredimonitorService(),
      priceAlertService: new PriceAlertService(),
      headers: [],
      incomingHeaders: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "300px",
        },
        {
          text: this.$i18n.t("TXT_BRAND"),
          align: "center",
          sortable: true,
          value: "productBrand",
          width: "170px",
        },

        {
          text: this.$i18n.t("TXT_MARKETPLACE"),
          align: "center",
          sortable: true,
          value: "marketplace",
          width: "200px",
        },
        {
          text: this.$i18n.t("TXT_SELLER"),
          align: "center",
          sortable: true,
          value: "sellerName",
          width: "180px",
        },
        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: this.$i18n.t("TXT_PAYMENT"),
          align: "center",
          sortable: true,
          value: "paymentType",
          width: "150px",
        },
        {
          text: this.$i18n.t("TXT_COLECT_DATE"),
          align: "center",
          sortable: true,
          value: "crawlerDate",
          width: "180px",
        },
        {
          text: this.$i18n.t("TXT_DISREGARD"),
          align: "center",
          sortable: true,
          value: "disregarded",
          width: "180px",
        },
        // {
        //   text: this.$i18n.t("TXT_TO_MONITOR"),
        //   align: "center",
        //   sortable: true,
        //   value: "isMonitored",
        //   width: "180px",
        // },
      ],
      items: [],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      product: {},

      modalAddTracking: false,
      addTrackingParams: null,
      addAlert: null,

      modalProductMonitoring: false,

      temAlerta: false,
    };
  },

  watch: {
    productItem: {
      handler(newValue) {
        this.product = newValue;
      },
      immediate: true,
      deep: true,
    },

    listaDeAlertas: {
      handler() {
        this.verifyAlert();
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    // Updates the array to show only those consideredProducts in the table
    consideredProducts: function () {
      let products = [];

      let newProducts = Object.assign({}, this.product);
      newProducts.priceResults = [];

      this.product.priceResults.forEach((item) => {
        if (!item.disregarded) {
          newProducts.priceResults.push(Object.assign({}, item));
        }
      });

      products.push(newProducts);

      this.maxMinAvg(products);

      return products[0];
    },

    // Updates the array to show only those disregardProducts in the table
    disregardedProducts: function () {
      let products = [];

      let newProducts = Object.assign({}, this.product);

      newProducts.priceResults = [];

      this.product.priceResults.forEach((item) => {
        if (item.disregarded) {
          newProducts.priceResults.push(Object.assign({}, item));
        }
      });
      products.push(newProducts);

      this.maxMinAvg(products);

      return products[0];
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },

  methods: {
    openModalProductMonitoring() {
      if (this.temAlerta == true) {
        this.modalProductMonitoring = true;
      } else {
        this.saveChanges("product");
      }
    },
    closeModalProductMonitoring() {
      this.modalProductMonitoring = false;
      this.$emit("seila");
    },

    openModalAddTracking(item, cog) {
      if (cog) {
        this.modalAddTracking = true;
        this.addTrackingParams = item;
        let foundedAdd = this.listaDeAlertas?.adds.find((el) => {
          return (
            el.productLink === item.productLink &&
            el.productSeller === item.sellerName
          );
        });
        this.addAlert = foundedAdd;
      } else {
        if (item.priceAlert == true) {
          this.modalAddTracking = true;
          this.addTrackingParams = item;
          let foundedAdd = this.listaDeAlertas?.adds.find((el) => {
            return (
              el.productLink === item.productLink &&
              el.productSeller === item.sellerName
            );
          });
          this.addAlert = foundedAdd;
        } else {
          this.saveChanges("add");
        }
      }
    },
    closeModalAddTracking() {
      this.addTrackingParams = null;
      this.modalAddTracking = false;
    },

    maxMinAvg(data) {
      for (let i in data) {
        var prices = [];
        if (data[i].priceResults != 0) {
          data[i].priceResults.forEach((el) => {
            if (el.disregarded == false) {
              prices.push(el.price);

              let soma = 0;
              for (let s in prices) {
                soma += prices[s];
              }

              data[i].maxPrice = Math.max(...prices);
              data[i].minPrice = Math.min(...prices);
              data[i].avgPrice = soma / prices.length;
            }
          });
        }
      }
    },

    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);

      this.$emit("showSnackbar", this.$i18n.t("TXT_COPY"), "info");
    },

    saveProducts(type) {
      let tipo =
        type == "considerar"
          ? this.consideredProducts
          : this.disregardedProducts;

      let data = tipo.priceResults.map((serie) => ({
        idMonitoringItem: this.disregardedProducts.idMonitoringItem,
        productSeller: serie.sellerName,
        productLink: serie.productLink,
        disregarded: serie.disregarded,
      }));

      let newPredimonitorService = new PredimonitorService();
      newPredimonitorService
        .tableResultsDisregarded(data)
        .then((res) => {
          if (res.data == "Items salvos com sucesso.") {
            this.$emit(
              "showSnackbar",
              `${this.$i18n.t("TXT_ADD_UPDATE_SUCCESS")}`,
              "success"
            );
            this.$emit("attData");
          } else {
            this.$emit(
              "showSnackbar",
              `${this.$i18n.t("TXT_ADD_UPDATE_ERROR")}`,
              "error"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(this.$emit("updateGraphics", this.requestData));
    },

    verifyAlert() {
      if (this.product.listaDeAlertas) {
        if (this.product.listaDeAlertas.products.length != 0) {
          this.temAlerta = true;
        } else {
          this.temAlerta = false;
        }
      } else {
        this.temAlerta = false;
      }
    },

    saveChanges(tipo) {
      const type = tipo === "product" ? "products" : "adds";
      let priceAlertService = new PriceAlertService();
      let requests = this.listaDeAlertas[type]
        .filter((el) => el)
        .map((el) => this.buildRequest(el));

      Promise.all(
        requests.map((request) => priceAlertService.savePriceAlert(request))
      )
        .then((res) => {
          console.log("respostas do salvar", res);
          this.$emit("seila");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    buildRequest(el) {
      let request = {};
      let resourceGroup = [];

      request = {
        idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
          ? el.idMonitoringItemPriceAlert
          : 0,
        idMonitoringItem: this.productItem.idMonitoringItem,
        productLink: null,
        productSeller: null,
        variationType: el.variationType.value,
        monitoringType: this.setMonitoringType(el.monitoringType),
        increaseValue: parseFloat(el.increaseValue),
        decreaseValue: parseFloat(el.decreaseValue),
        isParentItem: true,
        userOrResouceGroup: [],
        isDeleted: true,
      };

      el.users?.forEach((item) => {
        let teste = {};
        teste = {
          idUser: item.idResourceGroup ? null : item.idUsuario,
          idResourceGroup: item.idResourceGroup ? item.idResourceGroup : null,
          idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
            ? el.idMonitoringItemPriceAlert
            : 0,
          idMonitoringItemPriceAlertUsers: item.idMonitoringItemPriceAlertUsers
            ? item.idMonitoringItemPriceAlertUsers
            : 0,
          isDeleted: true,
        };

        resourceGroup.push(teste);
      });

      request.userOrResouceGroup = resourceGroup;
      return request;
    },

    setMonitoringType(item) {
      switch (item) {
        case "maxPrice":
          return 1;
        case "avgPrice":
          return 2;
        case "minPrice":
          return 3;
        default:
          return null;
      }
    },
   hasResource(value) {
      return this.$store.getters.hasResource(value);
    },
  },

  created() {
    this.headers = this.incomingHeaders;
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex !important;
  align-items: center !important;
}

.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.text-center {
  text-align: center !important;
}

.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}

.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>