<template>
    <v-container id="search-market" class="ma-0 mr-16 pa-0 py-10 mx-auto">
        <v-row class="ma-0 pa-0 mb-4">
            <p class="card-title">Monitoramento por Lead</p>
        </v-row>

        <v-card flat class="ma-0 pa-4">
            <!-- Calendario -->
            <v-row class="ma-0 pa-0" align-content="center" style="justify-content:space-between">
                <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
                    <label style="cursor: pointer" class="input-label">
                        Periodo de referência
                        <span>
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon style="cursor: pointer" small v-bind="attrs" v-on="on" color="#6792F8"
                                        right>mdi-information-outline</v-icon>
                                </template>
                                <span>Selecione uma data <b>inicial</b> e uma data <b>final</b> para busca</span>
                            </v-tooltip>
                        </span>
                    </label>
                    <div class="mt-4">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-row no-gutters class="flex">
                                    <v-col cols="auto">
                                        <v-text-field v-model="dateFormatted" readonly v-bind="attrs" v-on="on" outlined
                                            :disabled="wait" dense hide-details></v-text-field>
                                    </v-col>
                                    <v-col class=" calendary">
                                        <v-icon class="icon-calendar" color="white">mdi-calendar</v-icon>
                                    </v-col>
                                </v-row>
                            </template>
                            <v-date-picker no-title scrollable v-model="dates" range :disabled="wait">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <!-- Configuração de Tag -->
                <!-- <v-col cols="12" md="3" sm="6" class="ma-0 pa-0">
                    <br />
                    <router-link to="/main/market-intelligence/tags-configure">
                        <v-btn color="#3C5CA7" class="white--text mt-2 ml-4 tags-btn" height="41px">
                            <v-icon small left>mdi-tag-outline</v-icon>
                            Configurar Tags
                        </v-btn>
                    </router-link>
                </v-col> -->
            </v-row>
           <!-- Exportar e Pesquisar produtos -->
           <v-card-actions class="pa-0" style="justify-content:flex-start">
                <v-btn @click="searchClick" color="#3C5CA7" class="mt-4 white--text" :disabled="wait">
                    <v-icon>mdi-magnify</v-icon>
                    <div class="text-center">
                        <label> Pesquisar </label>
                    </div>
                </v-btn>
                <v-btn @click="exportFiles" color="#3C5CA7" outlined class=" mt-4" :disabled="wait">
                    <v-icon>mdi-upload</v-icon>
                    <div class="text-center">
                        <label>Exportar</label>
                    </div>
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- RESULTS --->

        <v-row class="ma-0 pa-0 mb-4 mt-6">
            <p class="card-title">RESULTADOS</p>
        </v-row>

        <v-card flat class="ma-0 py-4 pl-4" v-if="!disableFilter">
            <!-- Buscar por Produto -->
            <v-row class="ma-0 pa-0" align-items="center">
                <v-col cols="12" md="3" sm="6" class="ma-0 pa-0">
                    <label class="input-label">Buscar</label>
                    <v-autocomplete class="mb-6 mt-2" placeholder="Buscar" rounded outlined dense hide-details
                        append-icon="mdi-magnify" @change="findItem">
                    </v-autocomplete>
                </v-col>
            </v-row>
            <!-- Filtros -->
            <p class="card-title mb-4" style="font-size: 14px">FILTRAR</p>
            <v-row class="ma-0 pa-0 mb-6">
                <!-- Filtar por Marca -->
                <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
                    <label class="input-label">Marca</label>
                    <v-select class="ma-0 pa-0 mt-2" outlined dense hide-details append-icon="mdi-chevron-down"
                        :items="products" item-text="brand" item-value="id" v-model="filterBrand"
                        :disabled="brandList.length > 0">
                    </v-select>
                </v-col>
                <!-- Filtrar por Status -->
                <!-- <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
                    <label class="input-label">Status</label>
                    <v-select class="ma-0 pa-0 mt-2" outlined dense hide-details append-icon="mdi-chevron-down"
                        :items="products" item-value="id" item-text="status" v-model="filterStatus" :disabled="statusList.length > 0">
                    </v-select>
                </v-col> -->
                <!-- Filtrar por Modelo -->
                <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-6">
                    <label class="input-label">Modelo</label>
                    <v-select class="ma-0 pa-0 mt-2" outlined dense hide-details append-icon="mdi-chevron-down"
                        :items="products" item-text="model" item-value="id" v-model="filterModel"
                        :disabled="modelList.length > 0">
                    </v-select>
                </v-col>
                <!-- Range de valor
                <v-col cols="12" md="3" sm="6" class="ma-0 pa-0 pr-5">
                    <label class="input-label">Range de valor médio
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon style="cursor: pointer" small v-bind="attrs" v-on="on" color="#6792F8" right>
                                    mdi-information-outline</v-icon>
                            </template>
                            <span>Aqui vai alguma informação</span>
                        </v-tooltip>
                    </label>
                    <v-row>
                        <v-col>
                            <v-text-field class="ma-0 pa-0 mt-2" outlined type="text" placeholder="valor máx" dense
                                hide-details :items="maxPrice" v-model="maxPrice">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field class="ma-0 pa-0 mt-2" outlined type="text" placeholder="valor min" dense
                                hide-details :items="minPrice" v-model="minPrice">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col> -->
            </v-row>

            <v-card-actions class="ma-0 pa-0">
                <v-btn @click="cleanFilters" color="#3C5CA7" outlined class="change-button">
                    Limpar filtros
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- TABLES -->
        <table-results :searchResult="searchResult" :wait="wait" />
        <div class="pagination">
            <v-pagination v-model="page" :length="searchResult.totalPages" :total-visible="5" @input="pagination"
                :disabled="wait">
                </v-pagination>
        </div>
    </v-container>
</template>

<script>
import tableResults from "./table-results.vue";
import marketIntelligenceService from "@/service/market-intelligence-service";
const MarketIntelligenceService = new marketIntelligenceService()
export default {
    name: "monitoring-by-product",
    components: { tableResults},
    data() {
        return {
            page: 1,
            disableFilter: true,
            wait: false,

            //------------ SEARCH ---------------------
            searchResult: {},

            //------------ DATA PICKER -------------------
            dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            dateFormatted: "",
            menu: false,
            starDate: null,
            endDate: null,
            //----------- FILTER VARS -------------
            brandList: [],
            statusList: [],
            modelList: [],
            filterBrand: null,
            filterStatus: null,
            filterModel: null,
            minPrice: null,
            maxPrice: null,
        };
    },

    methods: {
        //funcões de emit para a pesquisa e paginação
        searchClick() {
            this.search(1)
        },
        pagination(value) {
            this.search(value)
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        formatDates() {
            if (this.dates.length == 2) {
                let nd = [...this.dates]
                nd.sort()
                this.dateFormatted = this.formatDate(nd[0]) + " - " + this.formatDate(nd[1])
            } else if (this.dates.length == 1) {
                this.dateFormatted = this.formatDate(this.dates[0])
            }
        },
        search(page = 1) {
            // fazendo verificação de campo
            if (this.dates.length != 2) {
                return
            }
            let sortedDate = this.dates.sort()
            let data = {
                // filterBrand: this.filterBrand,
                // filterModel: this.filterModel,
                startDate: sortedDate[0],
                endDate: sortedDate[1],
                idCompany: this.$store.getters.userData.idCompany,
                page: page,
                recordsPerPage: 10,
            }
            this.wait = true
            MarketIntelligenceService.PostSearchByLeadPaginated(data).then((res) => {
                this.searchResult = res.data.answer
                console.log(this.searchResult)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.wait = false
            })
        },

        exportFiles() {
            let sortedDate = this.dates.sort()
            let data = {
                startDate: sortedDate[0],
                endDate: sortedDate[1],
                idCompany: 2403
            }
            this.wait = true
            MarketIntelligenceService.PostExport(data).then((res) => {
                var downloadLink = document.createElement("a");
                var blob = new Blob(["\ufeff", res.data]);
                var url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = "aprioriSimulation.csv";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
                .catch((err) => {
                    console.log(err)
                }).finally(() => {
                    this.wait = false
                })
        },

        // findItem() {
        //     console.log("Função buscar produto");
        // },

        // This function clean the filter
        // cleanFilters() {
        //     (this.filterBrand = null),
        //         (this.filterStatus = null),
        //         (this.filterModel = null);
        //     (this.maxPrice = null);
        //     (this.minPrice = null);
        // },
    },
    watch: {
        dates() {
            this.formatDates()
        },
    },
    created() {
        document.title = "PREDIFY | INTELIGÊNCIA DE MERCADO";
    },
    mounted() {
        this.formatDates()
    }
};

</script>

<style scoped>
#search-market * {
    font-family: "Ubuntu", sans-serif;
}

.card-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: bolder;
}

.input-label {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.tags-btn {
    width: 15rem !important;
}

.flex {
    display: flex;
    flex-wrap: nowrap;
}

/* ------ TABLE ------ */
::-webkit-scrollbar {
    width: 50px !important;
}

.icon-calendar {
    align-items: center;
    background-color: #3C5CA7;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 30px;
    height: 40px;
}
</style>
