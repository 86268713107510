<template>
  <v-card flat class="bamaq-dashboard">
    <label class="card-title">FILTROS</label>

    <!-- date picker -->
    <v-row class="pt-6">
      <v-col cols="12" sm="6" md="3" class="data-picker-range py-0">
        <v-row class="ma-0 pa-0 mb-2" align-items="center">
          <div style="display: flex; align-items: center">
            <label>Periodo de referência</label>
            <v-tooltip right color="#F5F5F5">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="cursor: pointer; pointer-events: all"
                  v-bind="attrs"
                  v-on="on"
                  color="#6792F8"
                  right
                  small
                >
                  mdi-information-outline</v-icon
                >
              </template>
              <span style="color: #000">Alguma informação</span>
            </v-tooltip>
          </div>
        </v-row>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="isLoading"
              class="input-datapicker mt-2"
              v-model="dateRangeText"
              hide-details
              outlined
              single-line
              dense
              readonly
              v-bind="attrs"
              v-on="on"
              :label="$t('TXT_SELECT_PERIOD')"
            >
              <template v-slot:append>
                <v-icon class="append-calendar" color="white" small>
                  mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <!-- TODO - properly use user's locale -->
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            range
            :min="minDay"
            :max="new Date().toISOString().slice(0, 10)"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="error"
              @click="menu = false && dateRangeText == null"
              v-t="'TXT_CANCEL'"
            ></v-btn>
            <v-btn
              color="primary"
              @click="$refs.menu.save(date)"
              v-t="'TXT_OK'"
            ></v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <!--filters-->
    <v-row>
      <v-col
        ><label>Produto</label>
        <v-autocomplete
          class="mt-2"
          v-model="products"
          label="Pesquisar produto"
          :disabled="isLoading"
          single-line
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-details
          item-text="description"
          multiple
          return-object
          :placeholder="$t('TXT_SELECT_ONE_OR_MORE_PRODUCTS')"
          outlined
          dense
          smalle-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              close
              small-chips
              @click:close="products = ''"
              dark
              v-if="index === 0"
              style="
                background: var(--dark-moderate-blue);
                color: white;
                padding: 0px 10px;
              "
            >
              <span
                style="
                  font-size: 14px;
                  max-width: 50ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.description }}
              </span>
            </v-chip>
          </template>

          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <span
                v-if="products.length > 1"
                style="
                  background: var(--dark-moderate-blue);
                  color: white;
                  font-size: 12px;
                  border-radius: 32px;
                  padding: 10px 4px;
                  margin-top: -4px;
                "
              >
                &nbsp; + {{ products.length - 1 }} &nbsp;
              </span>
            </v-fade-transition>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong v-t="'TXT_NO_DATA'"></strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="false"
        ><label>Categoria</label>
        <v-autocomplete
          class="mt-2"
          v-model="categorys"
          :items="categorysList"
          item-text="description"
          label="Pesquisar categoria"
          single-line
          :disabled="isLoading"
          :search-input.sync="search"
          hide-details
          multiple
          return-object
          placeholder="Selecione uma ou mais categorias"
          outlined
          dense
          smalle-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              close
              small-chips
              @click:close="categorys = ''"
              dark
              v-if="index === 0"
              style="
                background: var(--dark-moderate-blue);
                color: white;
                padding: 0px 10px;
              "
            >
              <span
                style="
                  font-size: 14px;
                  max-width: 50ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.description }}
              </span>
            </v-chip>
          </template>

          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <span
                v-if="categorys.length > 1"
                style="
                  background: var(--dark-moderate-blue);
                  color: white;
                  font-size: 12px;
                  border-radius: 32px;
                  padding: 10px 4px;
                  margin-top: -4px;
                "
              >
                &nbsp; + {{ categorys.length - 1 }} &nbsp;
              </span>
            </v-fade-transition>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong v-t="'TXT_NO_DATA'"></strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        ><label>Loja</label>
        <v-autocomplete
          class="mt-2"
          v-model="stores"
          :items="storeList"
          item-text="description"
          label="Pesquisar Loja"
          :disabled="isLoading"
          single-line
          :search-input.sync="search"
          hide-details
          multiple
          return-object
          placeholder="Selecione uma ou mais lojas"
          outlined
          dense
          smalle-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              close
              small-chips
              @click:close="stores = ''"
              dark
              v-if="index === 0"
              style="
                background: var(--dark-moderate-blue);
                color: white;
                padding: 0px 10px;
              "
            >
              <span
                style="
                  font-size: 14px;
                  max-width: 50ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.description }}
              </span>
            </v-chip>
          </template>

          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <span
                v-if="stores.length > 1"
                style="
                  background: var(--dark-moderate-blue);
                  color: white;
                  font-size: 12px;
                  border-radius: 32px;
                  padding: 10px 4px;
                  margin-top: -4px;
                "
              >
                &nbsp; + {{ stores.length - 1 }} &nbsp;
              </span>
            </v-fade-transition>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong v-t="'TXT_NO_DATA'"></strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="false"
        ><label>Estado</label>
        <v-autocomplete
          class="mt-2"
          v-model="states"
          :items="stateList"
          item-text="description"
          label="Pesquisar estado"
          :disabled="isLoading"
          single-line
          :search-input.sync="search"
          hide-details
          multiple
          return-object
          placeholder="Selecione um ou mais estados"
          outlined
          dense
          smalle-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              close
              small-chips
              @click:close="states = ''"
              dark
              v-if="index === 0"
              style="
                background: var(--dark-moderate-blue);
                color: white;
                padding: 0px 10px;
              "
            >
              <span
                style="
                  font-size: 14px;
                  max-width: 50ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.description }}
              </span>
            </v-chip>
          </template>

          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <span
                v-if="states.length > 1"
                style="
                  background: var(--dark-moderate-blue);
                  color: white;
                  font-size: 12px;
                  border-radius: 32px;
                  padding: 10px 4px;
                  margin-top: -4px;
                "
              >
                &nbsp; + {{ states.length - 1 }} &nbsp;
              </span>
            </v-fade-transition>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong v-t="'TXT_NO_DATA'"></strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" v-if="false"
        ><label>Marca</label>
        <v-autocomplete
          class="mt-2"
          v-model="brands"
          :items="brandList"
          item-text="description"
          label="Pesquisar marca"
          :disabled="isLoading"
          single-line
          :search-input.sync="search"
          hide-details
          multiple
          return-object
          placeholder="Selecione uma ou mais marcas"
          outlined
          dense
          smalle-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              close
              small-chips
              @click:close="brands = ''"
              dark
              v-if="index === 0"
              style="
                background: var(--dark-moderate-blue);
                color: white;
                padding: 0px 10px;
              "
            >
              <span
                style="
                  font-size: 14px;
                  max-width: 50ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.description }}
              </span>
            </v-chip>
          </template>

          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <span
                v-if="brands.length > 1"
                style="
                  background: var(--dark-moderate-blue);
                  color: white;
                  font-size: 12px;
                  border-radius: 32px;
                  padding: 10px 4px;
                  margin-top: -4px;
                "
              >
                &nbsp; + {{ brands.length - 1 }} &nbsp;
              </span>
            </v-fade-transition>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong v-t="'TXT_NO_DATA'"></strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3" v-if="false"
        ><label>Ano</label>
        <v-autocomplete
          class="mt-2"
          v-model="years"
          :items="yearList"
          item-text="description"
          label="Pesquisar ano"
          :disabled="isLoading"
          single-line
          :search-input.sync="search"
          hide-details
          multiple
          return-object
          placeholder="Selecione um ou mais anos"
          outlined
          dense
          smalle-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              close
              small-chips
              @click:close="years = ''"
              dark
              v-if="index === 0"
              style="
                background: var(--dark-moderate-blue);
                color: white;
                padding: 0px 10px;
              "
            >
              <span
                style="
                  font-size: 14px;
                  max-width: 50ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.description }}
              </span>
            </v-chip>
          </template>

          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <span
                v-if="years.length > 1"
                style="
                  background: var(--dark-moderate-blue);
                  color: white;
                  font-size: 12px;
                  border-radius: 32px;
                  padding: 10px 4px;
                  margin-top: -4px;
                "
              >
                &nbsp; + {{ years.length - 1 }} &nbsp;
              </span>
            </v-fade-transition>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong v-t="'TXT_NO_DATA'"></strong>
              </v-list-item-title>
            </v-list-item>
          </template> </v-autocomplete
      ></v-col>
      <v-col v-if="false"
        ><label>Horímetro</label>
        <v-range-slider
          :disabled="isLoading"
          v-model="range"
          :max="max"
          :min="min"
          hide-details
          class="align-center"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="range[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(range, 0, $event)"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              :value="range[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(range, 1, $event)"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>

    <v-card-actions class="ma-0 pa-0">
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0 mt-6" cols="2">
        <v-btn
          @click="searchProducts"
          :disabled="isLoading"
          block
          class="white--text"
          color="#3C5CA7"
          >Aplicar Filtros</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import DashboardBamaqService from "@/service/predimonitor/dashboard-bamaq-service";

export default {
  name: "filters-card",

  data() {
    return {
      isLoading: false,
      idCompany: 0,

      // Date picker vars
      menu: false,
      date: [],
      minDay: null,
      maxDay: null,

      //Horimetro vars
      min: 0,
      max: 200000,
      range: [0, 200000],

      search: "",

      // Filter list
      categorysList: [],
      storeList: [],
      stateList: [],
      brandList: [],
      yearList: [],

      //Filter  selected vars
      products: [],
      categorys: [],
      stores: [],
      states: [],
      brands: [],
      years: [],

      items: [],
    };
  },

  methods: {
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },

    loadProducts() {
      let dashboardBamaqService = new DashboardBamaqService();

      dashboardBamaqService
        .getProducts({ idCompany: this.idCompany })
        .then((res) => {
          this.items = res.data.result;
        });
    },

    loadFilters() {
      let dashboardBamaqService = new DashboardBamaqService();

      dashboardBamaqService
        .getFilters({ idCompany: this.idCompany })
        .then((res) => {
          this.categorysList = res.data.categories;
          this.storeList = res.data.networks;
          this.brandList = res.data.brands;
          this.stateList = res.data.localState;
        });

      let currentYear = new Date().getFullYear();

      for (let i = 1980; i <= currentYear; i++) {
        this.yearList.push({
          id: i,
          description: i,
        });
      }
    },

    searchProducts() {
      this.isLoading = true;

      let request = {
        startDate: this.date[0],
        endDate: this.date[1],
        filters: {
          idProducts: [],
        },
        idCompany: this.idCompany,
      };

      this.products.forEach((x) => {
        if (x.idMonitoringItem)
          request.filters.idProducts.push("I_" + x.idMonitoringItem);
        else request.filters.idProducts.push("P_" + x.idProductPredify);
      });

      request.filters.categories = this.categorys.map((x) => x.id);
      request.filters.networks = this.stores.map((x) => x.id);
      request.filters.localStates = this.states.map((x) => x.id);
      request.filters.brands = this.brands.map((x) => x.id);
      request.filters.manufactureYears = this.years.map((x) => x.id);

      request.filters.workedHours = {
        min: this.range[0],
        max: this.range[1],
      };

      this.$emit("searchProducts", request);
      this.isLoading = false;
    },
  },

  watch: {
    date() {
      const range = 120;
      var baseDay = new Date(this.date[0]);
      //var finalDay = new Date();
      var startDay = new Date();
      //      finalDay.setDate(baseDay.getDate() + range);
      startDay.setDate(baseDay.getDate() - range);
      this.minDay = startDay.toISOString().slice(0, 10);
      //this.maxDay = finalDay.toISOString().slice(0, 10)
    },
  },

  computed: {
    dateRangeText() {
      return this.date.join("  ~  ");
    },
  },

  created() {
    this.idCompany = this.$store.getters.userData.idCompany;
    var startDay = new Date();
    startDay.setDate(startDay.getDate() - 7);
    this.minRange = startDay.toISOString().slice(0, 10);

    this.date[0] = this.minRange;
    this.date[1] = new Date().toISOString().slice(0, 10);

    //Carrega os produtos na combobox
    this.loadProducts();

    //Carrega os Filtros na combobox
    this.loadFilters();
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
}

.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>