<template>
  <v-card class="pa-4 mt-2">
    <h4>FILTROS</h4>
    <general-vision-filters />

    <h4>RESULTADOS</h4>
    <div class="results-main">
      <total-recipe style="width: 45%" />
      <total-metrics style="width: 45%" />
    </div>

    <div class="my-flex mt-6">
      <div style="width: 40%">
        <price-changes class="mb-4" />
      </div>

      <div style="width: 60%">
        <alert-board />
      </div>
    </div>

    <div style="display: flex;gap:1rem">
      <chart-price-ia style="width: 50%" />
      <chart-external-vars style="width: 50%" />
    </div>
  </v-card>
</template>

<script>
import AlertBoard from "./components/alert-board.vue";
import ChartExternalVars from './components/chart-external-vars.vue';
import ChartPriceIa from "./components/chart-price-ia.vue";
import generalVisionFilters from "./components/general-vision-filters.vue";
import priceChanges from "./components/price-changes.vue";
import TotalMetrics from "./components/total-metrics.vue";
import totalRecipe from "./components/chart-total-recipe.vue";

export default {
  components: {
    generalVisionFilters,
    totalRecipe,
    TotalMetrics,
    priceChanges,
    AlertBoard,
    ChartPriceIa,
    ChartExternalVars
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.results-main {
  display: flex;
  justify-content: space-between;
  margin: 0px 16px;
  height: 300px;
}

.my-flex {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}
</style>