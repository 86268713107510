export function setCurrency(value) {
    if (typeof value !== "number" && !Number.isNaN(value)) return '-';
    let currency = localStorage.getItem("currency");
    if (!currency) currency = "R$"

    const formated = value.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return currency + ' ' + formated;

}