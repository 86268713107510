<template>
  <v-expansion-panel class="mx-4 pa-0">
    <v-expansion-panel-header
      append-icon="meid-chevron-down"
      color="#F5F5F5"
      class="py-0 px-4"
    >
      <v-row no-gutters>
        <v-icon
          @click.stop="copyFunction(product.productName)"
          :color="$prdStyles('color-primary')"
          left
          small
          class="copy-button"
          >mdi-content-copy</v-icon
        >
        <span class="font-weight-bold">{{ product.productName }}</span>
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="pa-0">
      <!--  MAPA  -->
      <v-col class="ma-0 pa-0" cols="12">
        <div v-if="isLoading">
          <v-skeleton-loader
            class="my-6 px-6"
            type="image, divider, table-row"
          ></v-skeleton-loader>
        </div>
        <v-row no-gutters v-else>
          <eu-map :mapOptions="mapOptions" />
          <eu-table :data="itemsTable" :currency="currency" />
        </v-row>
      </v-col>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import axios from "axios";
import EuMap from "./eu-map.vue";
import EuTable from "./eu-table.vue";
export default {
  components: { EuMap, EuTable },
  props: ["product", "isLoading", "currency"],
  data() {
    return {
      selectedItem: null,
      ming: null,
      maxg: null,
      templateMap: null,
      graphData: [],
      itemsTable: null,
      data: [
        {
          country: "Denmark",
          id: "dk",
          value: null,
        },
        {
          country: "Faroe Islands",
          id: "fo",
          value: null,
        },
        {
          country: "Croatia",
          id: "hr",
          value: null,
        },
        {
          country: "Netherlands",
          id: "nl",
          value: null,
        },
        {
          country: "Estonia",
          id: "ee",
          value: null,
        },
        {
          country: "Bulgaria",
          id: "bg",
          value: null,
        },
        {
          country: "Spain",
          id: "es",
          value: null,
        },
        {
          country: "Italy",
          id: "it",
          value: null,
        },
        {
          country: "San Marino",
          id: "sm",
          value: null,
        },
        {
          country: "Vatican City",
          id: "va",
          value: null,
        },
        {
          country: "Turkey",
          id: "tr",
          value: null,
        },
        {
          country: "Malta",
          id: "mt",
          value: null,
        },
        {
          country: "France",
          id: "fr",
          value: null,
        },
        {
          country: "Norway",
          id: "no",
          value: null,
        },
        {
          country: "Germany",
          id: "de",
          value: null,
        },
        {
          country: "Ireland",
          id: "ie",
          value: null,
        },
        {
          country: "Ukraine",
          id: "ua",
          value: null,
        },
        {
          country: "Finland",
          id: "fi",
          value: null,
        },
        {
          country: "Sweden",
          id: "se",
          value: null,
        },
        {
          country: "Russia",
          id: "ru",
          value: null,
        },
        {
          country: "United Kingdom",
          id: "gb",
          value: null,
        },
        {
          country: "Cyprus",
          id: "cy",
          value: null,
        },
        {
          country: "Portugal",
          id: "pt",
          value: null,
        },
        {
          country: "Greece",
          id: "gr",
          value: null,
        },
        {
          country: "Lithuania",
          id: "lt",
          value: null,
        },
        {
          country: "Slovenia",
          id: "si",
          value: null,
        },
        {
          country: "Bosnia and Herzegovina",
          id: "ba",
          value: null,
        },
        {
          country: "Monaco",
          id: "mc",
          value: null,
        },
        {
          country: "Albania",
          id: "al",
          value: null,
        },
        {
          country: "Montenegro",
          id: "cnm",
          value: null,
        },
        {
          country: "North Macedonia",
          id: "nc",
          value: null,
        },
        {
          country: "Serbia",
          id: "rs",
          value: null,
        },
        {
          country: "Romania",
          id: "ro",
          value: null,
        },
        {
          country: "Moldova",
          id: "me",
          value: null,
        },
        {
          country: "Liechtenstein",
          id: "li",
          value: null,
        },
        {
          country: "Austria",
          id: "at",
          value: null,
        },
        {
          country: "Slovakia",
          id: "sk",
          value: null,
        },
        {
          country: "Hungary",
          id: "hu",
          value: null,
        },
        {
          country: "Andorra",
          id: "ad",
          value: null,
        },
        {
          country: "Luxembourg",
          id: "lu",
          value: null,
        },
        {
          country: "Switzerland",
          id: "ch",
          value: null,
        },
        {
          country: "Belgium",
          id: "be",
          value: null,
        },
        {
          country: "Kosovo",
          id: "kv",
          value: null,
        },
        {
          country: "Poland",
          id: "pl",
          value: null,
        },
        {
          country: "Macedonia",
          id: "mk",
          value: null,
        },
        {
          country: "Latvia",
          id: "lv",
          value: null,
        },
        {
          country: "Belarus",
          id: "by",
          value: null,
        },
        {
          country: "Iceland",
          id: "is",
          value: null,
        },
        {
          country: "Moldova",
          id: "md",
          value: null,
        },
        {
          country: "Czech Republic",
          id: "cz",
          value: null,
        },
      ],
    };
  },

  created() {
    this.getTopologyMap();
  },

  computed: {
    mapOptions() {
      return {
        plotOptions: {
          series: {
            point: {
              events: {},
            },
            cursor: "pointer",
          },
        },
        chart: {
          map: this.templateMap, // template map
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false, // hidden export buttons
            },
          },
        },
        title: {
          text: null, // country name
        },
        subtitle: {
          text: null,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            align: "right",
            alignTo: "plotBox",
            verticalAlign: "bottom",
          },
        },
        colorAxis: {
          min: this.ming,
          max: this.maxg,
        },
        series: [
          {
            name: this.$i18n.t("TXT_AVERAGE_PRICE"),
            states: {
              hover: {
                color: "#EC4C37",
              },
            },
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            allAreas: true,
            data: this.graphData, // states and average price source
            tooltip: {
              valueDecimals: 2,
              valuePrefix: this.currency,
            },
          },
        ],
      };
    },
  },

  watch: {
    mapEuLoading() {
      this.graphDataFiltered = this.graphData;
    },
  },

  methods: {
    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);

      this.$emit("showSnackbar", this.$i18n.t("TXT_COPY"), "info");
    },
    //Carrega o mapa
    getTopologyMap() {
      axios
        .get("https://code.highcharts.com/mapdata/custom/europe.topo.json")
        .then((res) => {
          this.templateMap = res.data;
          this.createItemsToTableAndGraphicData();
        });
    },

    buildArrayToMap(id, value) {
      return [id, value];
    },
    buildBodyToItemsTable(
      country,
      maxPrice,
      averagePrice,
      minPrice,
      amountAds
    ) {
      return {
        country: country,
        maxPrice: maxPrice,
        averagePrice: averagePrice,
        minPrice: minPrice,
        amountAds: amountAds,
      };
    },
    setMinAndMaxPrice() {
      this.maxg = this.data[0].value;
      this.ming = this.data[0].value;
      this.data.forEach((element) => {
        if (element.value > this.maxg) {
          this.maxg = element.value;
        } else if (element.value < this.ming) {
          this.ming = element.value;
        }
      });
    },
    createItemsToTableAndGraphicData() {
      this.itemsTable = [];
      this.graphData = [];
      this.data.forEach((element) => {
        let maxPrice = Math.floor(Math.random() * 60) + 1;
        let minPrice = Math.floor(Math.random() * maxPrice);
        element.value = Number(((maxPrice + minPrice) / 2).toFixed(2));
        let amountAds = Math.floor(Math.random() * 15) + 1;
        this.graphData.push(this.buildArrayToMap(element.id, element.value));
        this.itemsTable.push(
          this.buildBodyToItemsTable(
            element.country,
            maxPrice,
            element.value,
            minPrice,
            amountAds
          )
        );
      });
      this.setMinAndMaxPrice();
    },
  },
};
</script>

<style>
</style>