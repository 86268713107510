<template>
  <div @submi.prevent="handleSubmit">
    <v-card
      class="ma-auto transparent rounded-0"
      width="400px"
      elevation="0"
    >
      <p class="font-weight-bold margin-bottom-30 login-form-title">
        RECUPERAR SENHA
      </p>

      <p
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center"
        style="color: var(--white); font-size: 14px"
      >
        Digite o email cadastrado para recuperar a senha
      </p>
      <v-row
        class="mx-0 mt-0 pa-0 align-center login-input-row"
        :class="showEmailErrorMessage ? 'mb-2' : 'margin-bottom-30'"
      >
        <v-col cols="1" class="pa-0">
          <!-- <font-awesome-icon icon="fa-solid fa-at" class="fa-icon"/> -->
          <v-icon color="var(--white)" class="fa-icon" dense>mdi-at</v-icon>
        </v-col>
        <v-col cols="11" class="pa-0">
          <input
            type="text"
            class="pa-2 ma-0 form-input"
            placeholder="E-MAIL"
            v-model="email"
            @keyup="emailToLowerCase()"
          />
        </v-col>
      </v-row>
      <p
        class="pa-1 mx-0 mt-0 margin-bottom-30 error white--text rounded-sm"
        v-if="showEmailErrorMessage"
      >
        Preencha o e-mail corretamente
      </p>
      <btn :title="'SOLICITAR'" @click.native="handleSubmit()" />
      <v-row class="mx-0 mt-0 margin-bottom-30 align-center">
        <router-link
          to="sign-in"
          style="color: var(--white); text-decoration: none"
        >
          ACESSAR MINHA CONTA
        </router-link>
        <v-spacer />
        <router-link
          to="sign-in"
          style="color: var(--white); text-decoration: none"
        >
          <v-icon class="" color="var(--white)"> mdi-chevron-right</v-icon>
        </router-link>
      </v-row>

      <v-row class="mx-0 mt-0 align-center">
        <router-link
          to="sign-up"
          style="color: var(--white); text-decoration: none"
        >
          CRIAR MINHA CONTA
        </router-link>
        <v-spacer />
        <router-link
          to="sign-up"
          style="color: var(--white); text-decoration: none"
        >
          <v-icon class="" color="var(--white)"> mdi-chevron-right</v-icon>
        </router-link>
      </v-row>
    </v-card>
  </div>
</template>


<script>
import loginFormButton from "@/components/common/loagin-form-button.vue";

export default {
  name: "passwordRecovery",
  components: {
    btn: loginFormButton,
  },
  data() {
    return {
      email: null,
      showEmailErrorMessage: false,
    };
  },
  methods: {
    isValidEmail() {
      if (!this.email) return false;
      if (!this.email.includes("@")) return false;
      if (!this.email.split("@")[1].includes(".")) return false;
      return true;
    },

    emailToLowerCase() {
      if (this.email) this.email = this.email.toLowerCase();
    },

    handleSubmit() {
      let validation = this.isValidEmail();
      if (validation) {
        console.log("olha eu aqui");
      } else {
        this.showEmailErrorMessage = true;
        setTimeout(() => {
          this.showEmailErrorMessage = false;
        }, 5000);
      }
    },
  },

  created() {
    document.title = "PREDIFY | RECUPERAR SENHA";
  },
};
</script>

<style scoped>
::selection {
  background-color: var(--bright-red);
  color: var(--white);
}
</style>