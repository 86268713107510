<template>
  <v-snackbar  timeout="-1" v-model="isOpen" :color="color">
    <div v-for="(text, index) in storeText.split('\n')" :key="index">
      {{ text }}
    </div>
  </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    isOpen: false,
    timeoutHandler: 0,
  }),
  computed: {
    storeText() {
      return this.$store.state.snackbarStore.snackbarText;
    },
    storeType() {
      return this.$store.state.snackbarStore.type;
    },
    color() {
      const type = this.storeType;
      if (type === "info") return "primary";
      else if (type === "warning") return "warning";
      else if (type === "error") return "error";
      else if (type === "success") return "success";
      else return "";
    },
  },
  watch: {
    storeText() {
      this.isOpen = true;
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = setTimeout(() => {
        this.isOpen = false;
      }, 4000);
    },
  },
};
</script>
<style lang="scss" scoped></style>
