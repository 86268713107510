<template>
  <v-container fluid>
    <v-row no-gutters align="end" justify="space-between">
      <titleBreadCrumbsComponent
        :title="'EXECUTAR CRAWLER MANUALMENTE'"
        :breadCrumbs="breadCrumbs"
        :hasTooltip="false"
        :showTooltip="false"
      />
      <router-link to="/main/predimonitor-V2">
          <prd-btn
            :icon="'mdi-arrow-left'"
            :smallIcon="true"
            :leftIcon="true"
            :title="'Voltar'"
            class="mb-2"
          />
      </router-link>
    </v-row>

    <v-card
      class="pa-4 pt-0"
      :loading="executeLoading"
      :disabled="executeLoading"
    >
      <h4 class="mb-4 pt-4">SELECIONE</h4>

      <v-radio-group v-model="selection" hide-details>
        <v-row no-gutters>
          <!-- PRODUCT -->
          <v-col cols="12" md="4" class="mr-4">
            <v-radio value="product" label="Produtos"></v-radio>
            <v-combobox
              v-model="selectedProduct"
              :items="productList"
              :loading="productListLoading"
              :disabled="
                productListLoading ||
                productList.length == 0 ||
                selection == 'group'
              "
              outlined
              dense
              hide-details
              append-icon="mdi-chevron-down"
              item-text="description"
              item-value="idMonitoringItem"
              class="mt-2"
            >
              <template v-slot:append-outer>
                <v-row>
                  <v-icon
                    :disabled="!selectedProduct"
                    :color="$prdStyles('color-success')"
                    @click.stop="selectProduct"
                    large
                    class="mt-2 mr-4 ml-2"
                  >
                    mdi-plus-box
                  </v-icon>
                </v-row>
              </template>
            </v-combobox>
          </v-col>

          <!-- GROUP -->
          <v-col cols="12" md="4">
            <v-radio value="group" label="Grupo de produtos"></v-radio>
            <v-combobox
              v-model="selectedGroup"
              :items="groupList"
              :loading="groupListLoading"
              :disabled="
                groupListLoading ||
                groupList.length == 0 ||
                selection == 'product'
              "
              @change="selectGroup"
              outlined
              dense
              hide-details
              append-icon="mdi-chevron-down"
              class="mt-2"
              item-text="description"
              item-value="idMonitoringGroup"
            >
            </v-combobox>
          </v-col>
        </v-row>
      </v-radio-group>

      <v-row v-if="groupLoading" class="mt-6" no-gutters justify="center">
        <v-progress-circular
          indeterminate
          :color="$prdStyles('color-primary')"
        ></v-progress-circular>
      </v-row>

      <div v-else class="list-item-box mt-6">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="list-item"
        >
          <span class="font-weight-bold font-size-16">{{
            product.description
          }}</span>
          <v-icon
            @click="deleteItem(index)"
            :color="$prdStyles('color-warning')"
          >
            mdi-delete-outline
          </v-icon>
        </div>
      </div>

      <v-icon
        v-if="!showAddProduct && selection == 'group'"
        :disabled="groupLoading || !selectedGroup"
        @click="showAddProduct = !showAddProduct"
        :color="$prdStyles('color-success')"
        large
      >
        mdi-plus-box
      </v-icon>

      <v-row v-if="showAddProduct" no-gutters>
        <v-col cols="12" md="5" class="mr-6">
          <label>Produto</label>
          <v-combobox
            v-model="selectedProduct"
            :items="productList"
            :loading="productListLoading"
            :disabled="productListLoading || productList.length == 0"
            outlined
            dense
            hide-details
            append-icon="mdi-chevron-down"
            item-text="description"
            item-value="idMonitoringItem"
            class="mt-2"
          >
          </v-combobox>
        </v-col>

        <prd-btn
          :title="'Cancelar'"
          :outlined="true"
          class="mr-4 mt-8"
          @click="showAddProduct = !showAddProduct"
        />

        <prd-btn @click="selectProduct" :title="'Incluir'" class="mt-8" />
      </v-row>

      <v-row no-gutters justify="end">
        <prd-btn
          @click="execute"
          :disabled="products.length == 0"
          :title="'Executar'"
        />
      </v-row>
    </v-card>

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

import service from "@/service/crawlers/crawler-execute.js";
const Service = new service();

export default {
  components: { titleBreadCrumbsComponent, PrdBtn },
  name: "crawler-execute-main",
  data() {
    return {
      breadCrumbs: [
        {
          text: "Inteligência de mercado",
        },
        {
          text: "Predimonitor",
        },
        {
          text: "Executar crawler manualmente",
        },
      ],
      products: [],
      selection: "product",
      selectedProduct: null,
      selectedGroup: null,

      productList: null,
      groupList: null,

      showAddProduct: false,
      productListLoading: false,
      groupListLoading: false,
      groupLoading: false,
      executeLoading: false,

      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },

  watch: {
    selection() {
      if (this.selection == "group") {
        this.selectedProduct = null;
      } else {
        this.selectedGroup = null;
      }
      this.products = [];
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    getProductList() {
      this.productListLoading = true;
      let request = {
        IncludeLeadInfo: false,
        MonitoringItemCreateEndDate: null,
        MonitoringItemCreateStartDate: null,
        OnlyForSearch: false,
        OnlyWithLead: false,
        idCompany: this.idCompany,
      };

      Service.getProductList(request)
        .then((res) => {
          this.productList = res.data.result;
          this.productListLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar product list", error);
          this.productListLoading = false;
        });
    },

    getProductGroupList() {
      this.groupListLoading = true;
      Service.getProductGroupList(this.idCompany)
        .then((res) => {
          this.groupList = res.data.result;
          this.groupListLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar getProductGroupList", error);
          this.groupListLoading = false;
        });
    },

    selectProduct() {
      if (!this.selectedProduct) return;

      let productObj = {
        description: this.selectedProduct.description,
        idMonitoringItem: this.selectedProduct.idMonitoringItem,
      };

      this.products.push(productObj);
      this.selectedProduct = null;
      this.showAddProduct = false;
    },

    selectGroup() {
      this.groupLoading = true;
      this.products = [];

      if (!this.selectedGroup) return;

      Service.setProductGroup(
        this.idCompany,
        this.selectedGroup.idMonitoringGroup
      )
        .then((res) => {
          this.products = res?.data?.result?.items ?? [];
          this.groupLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao buscar grupo de monitoramento", error);
          this.products = [];
          this.groupLoading = false;
        });
    },

    deleteItem(index) {
      this.products.splice(index, 1);
    },

    execute() {
      this.executeLoading = true;
      let idList = [];
      this.products.forEach((el) => {
        idList.push(el.idMonitoringItem);
      });

      let request = {
        idCompany: this.idCompany,
        listMonitoringItem: idList,
      };
      Service.executeCrawler(request)
        .then((res) => {
          console.log("resposta ao executar crawler", res);
          this.showSnackbar("Crawlers inseridos com sucesso!", "success");
          this.products = [];
          this.selectedProduct = null;
          this.selectedGroup = null;
          this.executeLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao executar crawler", error);
          this.showSnackbar("Erro ao inserir crawlers!", "error");
          this.executeLoading = false;
        });
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
  },

  created() {
    document.title = "PREDIFY | Executar crawler";

    this.getProductList();
    this.getProductGroupList();
  },
};
</script>

<style lang="scss" scoped>
.list-item-box {
  background-color: $neutral-color-high-light;
  margin-bottom: 8px;

  .list-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>