<template>
  <div>
    <span class="font-weight-bold"
      >Qual das empresas associadas ao seu perfil você deseja acessar?</span
    >
    <h4 class="my-4">FILTROS</h4>
    <v-row no-gutters class="my-4" align="center">
      <v-col cols="12" md="3">
        <prd-search
          v-model="search"
          :searchPlaceholder="'Buscar'"
          :searchLabel="'Buscar (nome ou cód. da empresa)'"
        />
      </v-col>
      <v-spacer></v-spacer>
      <prd-btn :title="'Criar empresa'" @click="$emit('showAddModal', true)" class="mt-6"/>
    </v-row>
    <!-- <v-row no-gutters>
        <v-col
          v-for="(filter, index) in filters"
          :key="index"
          :class="filter.margin"
        >
          <prd-combo-box
            v-model="filter.selected"
            :items="filter.items"
            :title="filter.label"
          />
        </v-col>
      </v-row> -->
  </div>
</template>

<script>
import PrdSearch from "@/Design_System/common/prd-search.vue";
// import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";

import service from "@/service/company-select/index.js";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const Service = new service();

export default {
  components: { PrdSearch, PrdBtn /*PrdComboBox */ },
  data() {
    return {
      search: "",
      filters: [
        {
          label: "Status da empresa",
          items: ["Ativa", "Inativa", "Bloqueada", "Free"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "País",
          items: ["Brasil", "EUA", "Canadá"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Status da empresa",
          items: ["Mais antigas", "Mais recentes"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Status da empresa",
          items: ["Ativa", "Inativa", "Bloqueada", "Free"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Status da empresa",
          items: ["Ativa", "Inativa", "Bloqueada", "Free"],
          selected: null,
          margin: "mr-0",
        },
      ],
      companyList: [],
    };
  },

  watch: {
    search(newValue) {
      let filteredList = [];
      filteredList = this.companyList.filter((el) => {
        return (
          el.nomeRazaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
          el.idEmpresa.toString().includes(newValue)
        );
      });
      this.$emit("companyList", filteredList);
    },
  },

  methods: {
    getCompanyList() {
      this.$emit("setLoading", true);
      Service.getCompanyList()
        .then((res) => {
          this.companyList = res.data;
          this.$emit("companyList", this.companyList);
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log("Erro ao buscar empresas", error);
          this.$emit("setLoading", false);
        });
    },
  },

  created() {
    this.getCompanyList();
  },
};
</script>