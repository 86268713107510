const predimonitorResults = {
  idCompany: null,
};

const predimonitorStore = {
  state: Object.assign({}, predimonitorResults),
  getters: {
  },
  mutations: {
  },
  actions: {
  },
};

export default predimonitorStore;
