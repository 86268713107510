<template>
  <div>
    <v-row no-gutters class="mt-4">
      <v-spacer></v-spacer>
      <prd-btn
        @click="isModalOpen = true"
        :outlined="true"
        :title="'Ver desconsiderados'"
        class="mr-4"
      />
      <prd-btn @click="$emit('saveProducts', 'considerar')" :title="'Salvar'" />
    </v-row>

    <v-dialog v-model="isModalOpen">
      <v-card class="pa-4" elevation="1">
        <h4 class="mb-4">PRODUTOS DESCONSIDERADOS</h4>

        <v-data-table
          :headers="headers"
          :items="disregardedProducts.priceResults"
        >
          <!-- product name -->
          <template v-slot:[`item.productName`]="{ item }">
            <td class="pa-4">
              <div class="copy-box">
                <a :href="item.productLink" target="_blank">{{
                  item.productName
                }}</a>
              </div>
            </td>
          </template>

          <!-- seller name -->
          <template v-slot:[`item.sellerName`]="{ item }">
            <td class="table-product-name">
              <div class="copy-box">
                {{ item.sellerName }}
              </div>
            </td>
          </template>

          <!-- price -->
          <template v-slot:[`item.price`]="{ item }">
            <td class="text-center">
              {{ item.price | setToIsoString }}
            </td>
          </template>

          <!-- Crawler date -->
          <template v-slot:[`item.crawlerDate`]="{ item }">
            <td class="text-center">
              {{ item.crawlerDate.replace("-", "/").replace("-", "/") }}
            </td>
          </template>

          <!-- disregarded -->
          <template v-slot:[`item.disregarded`]="{ item }">
            <td class="text-center">
              <v-simple-checkbox
                :ripple="false"
                color="#3C5CA7"
                v-model="item.disregarded"
              ></v-simple-checkbox>
            </td>
          </template>

          <!-- price_unit_type -->
          <template v-slot:[`item.price_unit_type`]="{ item }">
            {{ item.price_unit_type | unityToString }}
          </template>
        </v-data-table>

        <!-- SAVE BUTTON -->
        <v-row no-gutters class="mx-4">
          <v-spacer></v-spacer>
          <prd-btn
            @click="$emit('saveProducts', 'desconsiderar')"
            :title="$t('TXT_SAVE')"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";

export default {
  props: ["disregardedProducts", "headers"],
  components: { prdBtn },
  data() {
    return {
      predimonitorService: new PredimonitorService(),
      isModalOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}

.table-product-name {
  max-width: 350px !important;
  text-align: center !important;
}

.text-center {
  text-align: center !important;
}
</style>