<template>
  <v-card class="pa-4">
    <v-expansion-panels
      v-model="panel"
      accordion
      flat
      multiple
      :disabled="mapBrLoading || disablePanel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="ma-0 pa-0"
          expand-icon="mdi-chevron-down"
        >
          <v-row class="ma-0 pa-0" justify="space-between">
            <div style="display: flex; align-items: center">
              <label
                class="card-subtitle text-uppercase"
                v-t="'TXT_PRICE_MAP'"
              ></label>
              <v-tooltip
                max-width="550px"
                color="#F5F5F5"
                style="pointer-events: all"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span
                  style="color: #000"
                  v-html="$t('TXT_PRICE_TOOLTIP')"
                ></span>
              </v-tooltip>
            </div>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <!--  SELECIONAR PRODUTOS  -->
            <v-col cols="12" sm="12" md="4" class="ma-0 pa-0 mb-0 mt-0">
              <div v-if="mapBrLoading">
                <v-skeleton-loader
                  class="my-6 px-6"
                  type="text, divider, divider, paragraph, paragraph, paragraph, paragraph"
                ></v-skeleton-loader>
              </div>
              <label
                v-if="!mapBrLoading"
                for=""
                v-t="'TXT_SELECT_A_PRODUCT'"
              ></label>
              <v-radio-group
                v-if="!mapBrLoading"
                v-model="selectedItem"
                @change="brMap()"
              >
                <v-radio
                  active-class="active-button"
                  v-for="(item, index) in predimonitorResults"
                  :key="index"
                  :value="item"
                >
                  <template v-slot:label>
                    <span>{{ item.productName }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>

            <!--  MAPA  -->
            <v-col cols="12" sm="12" md="8">
              <div v-if="mapBrLoading">
                <v-skeleton-loader
                  class="my-6 px-6"
                  type="image, divider, table-row"
                ></v-skeleton-loader>
              </div>
              <highcharts
                v-else
                :constructor-type="'mapChart'"
                :options="mapOptions"
                class="map"
              ></highcharts>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>



<script>
import axios from "axios";

export default {
  name: "priceBrMapChart",
  props: ["mapBrLoading"],
  data() {
    return {
      panel: [],
      ming: null,
      maxg: null,
      selectedItem: null,
      graphDataFiltered: null,
      // default data to empty map
      graphData: [
        ["br-ac", null],
        ["br-al", null],
        ["br-ap", null],
        ["br-am", null],
        ["br-ba", null],
        ["br-ce", null],
        ["br-df", null],
        ["br-es", null],
        ["br-go", null],
        ["br-ma", null],
        ["br-mt", null],
        ["br-ms", null],
        ["br-mg", null],
        ["br-pa", null],
        ["br-pb", null],
        ["br-pr", null],
        ["br-pe", null],
        ["br-pi", null],
        ["br-rj", null],
        ["br-rn", null],
        ["br-rs", null],
        ["br-ro", null],
        ["br-rr", null],
        ["br-sc", null],
        ["br-sp", null],
        ["br-se", null],
        ["br-to", null],
      ],
      templateMap: null,
      mapTitle: null,
      rotateIcon: false,
      tab: null,
      disableDropdown: false,
      disablePanel: false,
      stateList: [
        { state: "Acre", idState: "ac" },
        { state: "Alagoas", idState: "al" },
        { state: "Amapá", idState: "ap" },
        { state: "Amazônia", idState: "am" },
        { state: "Bahia", idState: "ba" },
        { state: "Ceará", idState: "ce" },
        { state: "Distrito Federal", idState: "df" },
        { state: "Espírito Santo", idState: "es" },
        { state: "Goiânia", idState: "go" },
        { state: "Maranhão", idState: "ma" },
        { state: "Mato Grosso", idState: "mt" },
        { state: "Mato Grosso do Sul", idState: "ms" },
        { state: "Minas Gerais", idState: "mg" },
        { state: "Pará", idState: "pa" },
        { state: "Paraíba", idState: "pb" },
        { state: "Paraná", idState: "pr" },
        { state: "Pernambuco", idState: "pe" },
        { state: "Piauí", idState: "pi" },
        { state: "Rio de Janeiro", idState: "rj" },
        { state: "Rio Grande do Norte", idState: "rn" },
        { state: "Rio Grande do Sul", idState: "rs" },
        { state: "Rondônia", idState: "ro" },
        { state: "Roraima", idState: "rr" },
        { state: "Santa Catarina", idState: "sc" },
        { state: "São Paulo", idState: "sp" },
        { state: "Sergipe", idState: "se" },
        { state: "Tocantins", idState: "to" },
      ],
    };
  },

  methods: {
    //Carrega o mapa
    brMap() {
      axios
        .get("https://code.highcharts.com/mapdata/countries/br/br-all.geo.json")
        .then((res) => {
          this.templateMap = res.data;
          this.graphDataFiltered = this.stateList.map((serie) => [
            //Esse map cria os elementos que vão se encaixar nos estados lá no gráfico
            "br-" + serie.idState,
            this.calculateAverage(
              this.getResultsByState(this.selectedItem, serie.idState)
            ),
          ]);
          this.ming = this.stateList.map((serie) => [
            //Aqui ele seta o menor valor
            this.calculateAverage(
              this.getResultsByState(this.selectedItem, serie.idState)
            ),
          ]);
          this.maxg = this.stateList.map((serie) => [
            //Aqui ele seta o maior valor
            this.calculateAverage(
              this.getResultsByState(this.selectedItem, serie.idState)
            ),
          ]);
        });
    },

    //Método para pegar os priceResults de produtos de um estado específico
    getResultsByState(product, state) {
      if (
        product == null ||
        product.priceResults == null ||
        product.priceResults.length == 0 ||
        state == null
      )
        return [];
      return product.priceResults.filter((item) => {
        return item.state == state;
      });
    },

    //Método para calcular média de preço de priceResults (list tem que ser uma lista de priceResults)
    calculateAverage(list) {
      if (list == null || list.length == 0) return 0;
      let r = list.reduce((total, next) => total + next.price, 0) / list.length;
      return r;
    },

    debugItem(item) {
      console.log("debugItem", item);
      return item;
    },
  },
  created() {
    this.brMap();
  },

  watch: {
    predimonitorResults() {
      if (this.predimonitorResults.length == 0) {
        this.disablePanel = true;
        this.panel = [];
      } else {
        this.disablePanel = false;
      }
    },
    mapBrLoading() {
      this.graphDataFiltered = this.graphData;
    },
  },
  computed: {
    predimonitorResults: {
      get: function () {
        return this.$store.state.predimonitorResults;
      },
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },

    mapOptions() {
      return {
        plotOptions: {
          series: {
            point: {
              events: {},
            },
            cursor: "pointer",
          },
        },
        chart: {
          map: this.templateMap, // template map
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false, // hidden export buttons
            },
          },
        },
        title: {
          text: null, // country name
        },
        subtitle: {
          text: null,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            align: "right",
            alignTo: "plotBox",
            verticalAlign: "bottom",
          },
        },
        colorAxis: {
          min: Math.min(...this.ming),
          max: Math.max(...this.maxg),
        },
        series: [
          {
            name: this.$i18n.t("TXT_AVERAGE_PRICE"),
            states: {
              hover: {
                color: "#EC4C37",
              },
            },
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            allAreas: false,
            data: this.graphDataFiltered, // states and average price source
            tooltip: {
              valueDecimals: 2,
              valuePrefix: this.currency,
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.active-button {
  font-weight: bold;
}

.rotateIcon {
  transform: rotate(180deg);
}
</style>


