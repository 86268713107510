<template>
  <div>
    <div>
      <h4>FILTROS</h4>
      <v-row no-gutters class="my-4">
        <v-col cols="12" md="2">
          <prd-combo-box v-model="deliberationReferenc" :items="deliberationReferencItems"
            :disabled="deliberationReferencItems.length == 0" item-value="id" item-text="nome" :title="'Referência'"
            class="mr-4" />
        </v-col>
        <v-col cols="12" md="2">
          <prd-combo-box v-model="brand" :items="brandItems" :disabled="brandItems.length == 0" :title="'Marca (Brand)'"
            class="mr-4" />
        </v-col>
        <v-col cols="12" md="2">
          <prd-combo-box v-model="segment" :items="segmentItems" :disabled="segmentItems.length == 0"
            :title="'Segmento (Segment)'" />
        </v-col>
        <v-col cols="12" md="1">
          <prd-btn @click="relatorio" :outlined="true" :title="'Relatório'" class="mt-7 ml-5" />
        </v-col>
        <v-col cols="12" md="1">
          <prd-btn @click="relatorioFinal" :outlined="false" :title="'Relatório Final'" class="mt-7 ml-5" />
        </v-col>

      </v-row>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <prd-btn @click="applyFilters" :disabled="segmentItems.length == 0 && brandItems.length == 0"
          :title="'Aplicar Filtros'" />
      </v-row>
    </div>
    <div>
      <span v-if="this.valueFilters != null">{{ valueFilters.brand + " " + valueFilters.segment }}</span>
      <div class="scrollable-tabs mt-2">
        <v-tabs v-model="tab" color="black" :slider-color="$prdStyles('color-secundary')"
          class="group-filters scrollable-tabs" height="30px">
          <!-- PRODUCT -->
          <v-tab v-for="(table, index) in tables" :key="index" class="mr-4">{{
            table.name
          }}</v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(table, index) in tables" :key="index"><table-data :headers="table.headers"
            :items="table.items" :name="table.name" :subtitle="table.subtitle"
            @setLoading="$emit('setLoading', $event)" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import tableData from "./table-data.vue";
import UPLService from "@/service/upl";
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: { tableData, prdComboBox, PrdBtn },
  data() {
    return {
      service: new UPLService(),
      tab: 0,
      tables: [],
      isLoading: false,
      brandItems: [],
      brand: null,
      segmentItems: [],
      segment: null,
      deliberationReferenc: null,
      deliberationReferencItems: []
    };
  },
  methods: {
    async relatorio() {
      await this.service
        .getRelatorioDeliberation()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Deliberation.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async relatorioFinal() {
      if (confirm("Gerar relatório final? Essa operação nao poderá ser desfeita")) {
        await this.service
          .getRelatorioFinalDeliberation()
          .then((res) => {
            const url = window.URL.createObjectURL(
              new Blob([res.data], { type: "application/vnd.ms-excel" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Deliberation.xlsx");
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async generetaTable() {
      this.tables = [];
      await this.getInfoTable();
      await this.getLastMonthWeightedPriceListAndPerformance();
      await this.getLastMonthPriceListMain();
      await this.getDeliberationOnePage();
      await this.getDeliberationDeliberation();
      await this.getDeliberationAssumptions();
      //await this.getDeliberationDiscountApprovalHierarchys();
      await this.getDeliberationPriceListFVUS();
      await this.getDeliberationPriceListFVBRL();
      await this.getDeliberationPriceListPVBRL();
      await this.getDeliberationPPricListMarginFVUSD();
      await this.getDeliberationPricListMarginPVUSD();
      // await this.getDeliberationBlockMargin();
      // await this.getDeliberationBlockMarginUSD();
      // await this.getDeliberationBlockMarginBRL();
      // await this.getDeliberationProjecoesVolumeInTons();
      // await this.getDeliberationProjecoesNetSalesInMio();
      // await this.getDeliberationProjecoesGrossMarginInMio();
      // await this.getDeliberationProjecoesNetPrice();
      // await this.getDeliberationProjecoesCogs();
      // await this.getDeliberationVersusBudget();
      //await this.getDeliberationVersusFinReview();
    },
    async applyFilters() {
      await this.generetaTable();
    },
    async getInfoTable() {
      return await this.service.getDeliberationInfo(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        res.data.items.forEach((el) => {
          el.isEditPriceBook = false;
          el.isEditKeepOnList = false;
        });
        this.tables.push(res.data);
      });
    },
    async getLastMonthWeightedPriceListAndPerformance() {
      return await this.service
        .getLastMonthWeightedPriceListAndPerformance(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },

    async getLastMonthPriceListMain() {
      return await this.service.getLastMonthPriceListMain(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },

    async getDeliberationOnePage() {
      return await this.service.getDeliberationOnePage(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },

    async getDeliberationDeliberation() {
      return await this.service.getDeliberationDeliberation(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        res.data.items.forEach((el) => {
          el.isEditUSD = false;
          el.isEditBRL = false;
          el.isEditComments = false;
        });

        this.tables.push(res.data);
      });
    },
    async getDeliberationAssumptions() {
      return await this.service.getDeliberationAssumptions(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationDiscountApprovalHierarchys() {
      return await this.service
        .getDeliberationDiscountApprovalHierarchys(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },
    async getDeliberationPriceListFVUS() {
      return await this.service.getDeliberationPriceListFVUS(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationPriceListFVBRL() {
      return await this.service.getDeliberationPriceListFVBRL(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationPPricListMarginFVUSD() {
      return await this.service
        .getDeliberationPPricListMarginFVUSD(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },
    async getDeliberationPriceListPVBRL() {
      return await this.service.getDeliberationPriceListPVBRL(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationPricListMarginPVUSD() {
      return await this.service
        .getDeliberationPricListMarginPVUSD(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },
    async getDeliberationBlockMargin() {
      return await this.service.getDeliberationBlockMargin(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationBlockMarginUSD() {
      return await this.service.getDeliberationBlockMarginUSD(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationBlockMarginBRL() {
      return await this.service.getDeliberationBlockMarginBRL(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationProjecoesVolumeInTons() {
      return await this.service
        .getDeliberationProjecoesVolumeInTons(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },
    async getDeliberationProjecoesNetSalesInMio() {
      return await this.service
        .getDeliberationProjecoesNetSalesInMio(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },

    async getDeliberationProjecoesGrossMarginInMio() {
      return await this.service
        .getDeliberationProjecoesGrossMarginInMio(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },

    async getDeliberationProjecoesNetPrice() {
      return await this.service
        .getDeliberationProjecoesNetPrice(this.brand, this.segment)
        .then((res) => {
          res.data.headers.forEach((el) => {
            el.align = "center";
          });
          this.tables.push(res.data);
        });
    },

    async getDeliberationProjecoesCogs() {
      return await this.service.getDeliberationProjecoesCogs(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationVersusFinReview() {
      return await this.service.getDeliberationVersusFinReview(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
    async getDeliberationVersusBudget() {
      return await this.service.getDeliberationVersusBudget(this.brand, this.segment).then((res) => {
        res.data.headers.forEach((el) => {
          el.align = "center";
        });
        this.tables.push(res.data);
      });
    },
  },
  async beforeMount() {
    this.service.getFilters().then((res) => {
      this.brandItems = res.data.brands;
      this.segmentItems = res.data.segments;
    });
    this.service.getDeliberationApp().then((res) => {
      this.deliberationReferencItems = res.data;
      this.deliberationReferenc = this.deliberationReferencItems[0];
    });
    this.$emit("setLoading", true);
    await this.getInfoTable();
    await this.getLastMonthWeightedPriceListAndPerformance();
    await this.getLastMonthPriceListMain();
    await this.getDeliberationOnePage();
    await this.getDeliberationDeliberation();
    await this.getDeliberationAssumptions();
    //await this.getDeliberationDiscountApprovalHierarchys();
    await this.getDeliberationPriceListFVUS();
    await this.getDeliberationPriceListFVBRL();
    await this.getDeliberationPriceListPVBRL();
    await this.getDeliberationPPricListMarginFVUSD();
    await this.getDeliberationPricListMarginPVUSD();
    // await this.getDeliberationBlockMargin();
    // await this.getDeliberationBlockMarginUSD();
    // await this.getDeliberationBlockMarginBRL();
    // await this.getDeliberationProjecoesVolumeInTons();
    // await this.getDeliberationProjecoesNetSalesInMio();
    // await this.getDeliberationProjecoesGrossMarginInMio();
    // await this.getDeliberationProjecoesNetPrice();
    // await this.getDeliberationProjecoesCogs();
    // await this.getDeliberationVersusBudget();
    //await this.getDeliberationVersusFinReview();
    this.$emit("setLoading", false);
  },
};
</script>

<style lang="scss" scoped>
.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}

.scrollable-tabs {
  overflow-x: auto;
  padding-bottom: 5px;
  display: flex;
}
</style>