<template>
  <v-container fluid>
    <v-row no-gutters >
      <titleBreadCrumbsComponent
        :title="title"
        :breadCrumbs="breadCrumbs"
        :hasTooltip="false"
        tooltipText="tooltip1"
      />
      <v-spacer />
      <prd-btn
        class="mt-9"
        :title="$t('TXT_MANAGEMENT_CRAWLERS')"
        :icon="'mdi-robot'"
        :leftIcon="true"
        @click="callAnotherPage"
      />
    </v-row>
    <crawler-config-table />
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import crawlerConfigTable from "./components/crawler-config-table.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import router from "@/router/index.js";
export default {
  name: "crawler-config-main",
  components: {
    titleBreadCrumbsComponent,
    crawlerConfigTable,
    PrdBtn,
  },
  data() {
    return {
      title: this.$i18n.t("TXT_CONFIGURATION_CRAWLER_UPPERCASE"),
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_ADMIN"),
        },
        {
          text: "Crawlers",
        },
        {
          text: this.$i18n.t("TXT_CONFIGURATION_CRAWLER"),
        },
      ],
    };
  },

  methods: {
    callAnotherPage() {
      router.push("/main/crawler-management");
    },
  },

  created() {
    document.title = "Gerenciamento de crawlers";
  },
};
</script>