<template>
  <v-card
    :loading="isLoading"
    :disabled="isLoading"
    :loader-height="'6px'"
    class="pa-4 pt-0"
    flat
  >
    <v-row no-gutters align="center" class="pt-4 mb-6">
      <prd-search
        v-model="search"
        :searchLabel="$t('TXT_SEARCH_PRODUCT_2')"
        :searchPlaceholder="'TXT_SEARCH'"
        :disabled="products.length == 0"
      />
      <v-spacer></v-spacer>

      <!-- IMPORT BUTTON -->
      <router-link to="/main/file-import">
        <prd-btn
          :title="$t('TXT_TOIMPORT')"
          :icon="'mdi-upload'"
          :smallIcon="true"
        />
      </router-link>
    </v-row>

    <!-- PRODUCT-LIST -->
    <div>
      <h3 v-t="'TXT_PRODUCTS'" class="mb-6"></h3>
      <div
        v-for="(product, index) in products"
        :key="index"
        class="product-flex"
      >
        <div style="width: 50%">
          <!-- PRODUCT NAME -->
          <span
            v-show="!product.isEdited"
            style="display: flex; align-items: center"
          >
            <v-icon
              @click="copyProductName(product.description)"
              small
              left
              color="#3c5ca7"
              >mdi-content-copy
            </v-icon>

            <span style="font-size: 18px">{{ product.description }}</span>
            <v-icon @click="product.isEdited = true" right color="#3c5ca7"
              >mdi-pencil-box
            </v-icon>
          </span>

          <!-- EDIT PRODUCTNAME FIELD -->
          <div v-show="product.isEdited" style="width: 70%">
            <v-row no-gutters>
              <v-text-field
                v-model="product.description"
                dense
                hide-details
              ></v-text-field>
              <v-icon
                @click="editOrDeleteProduct(product, 'edit')"
                color="#56a667"
                >mdi-checkbox-marked</v-icon
              >
            </v-row>
          </div>

          <br />
          <span v-t="'TXT_CODE'" style="font-size: 12px"
            >: {{ product.code }}</span
          >
        </div>

        <div>
          <span style="font-size: 14px">Id Prod: {{ product.idProduct }}</span>
        </div>
        <v-spacer></v-spacer>

        <!-- ACTION BUTTONS -->
        <div>
          <v-icon
            v-if="!product.isMonitoring"
            @click="monitoringProduct(product)"
            color="#3c5ca7"
            class="mr-2"
            >mdi-flag-outline</v-icon
          >
          <v-icon
            v-if="product.isMonitoring"
            @click="monitoringProduct(product)"
            color="#3c5ca7"
            class="mr-2"
            >mdi-flag</v-icon
          >

          <v-icon
            @click="editOrDeleteProduct(product, 'delete', product.index)"
            color="#ec4c37"
            >mdi-delete-outline</v-icon
          >
        </div>
      </div>
    </div>

    <prd-pagination
      v-model="currentPage"
      v-show="products.length != 0"
      :totalRegisters="totalRecords"
      :length="totalPages"
      :showingRegistersLen="numberOfRecords"
      :resultLabel="$t('TXT_PAGINATION')"
      :pageLabel="$t('TXT_PAGE_LABEL')"
      class="mt-4"
    />
  </v-card>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import productManagement from "@/service/product-management/index.js";
import PrdSearch from "@/Design_System/common/prd-search.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";

export default {
  components: { PrdBtn, PrdSearch, PrdPagination },
  data() {
    return {
      service: new productManagement(),
      search: "",
      isLoading: false,
      searchLoading: false,

      products: [],
      timer: false,

      // Pagination vars
      recordsPerPage: 10,
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    currentPage() {
      this.listProductsPaginated();
    },

    search() {
      this.listProductsPaginated();
    },
  },

  methods: {
    listProductsPaginated() {
      this.isLoading = true;

      let request = {
        productNameFilter: this.search,
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage,
        idCompany: this.idCompany,
      };

      this.service
        .productList(request)
        .then((res) => {
          console.log(res);
          let response = res.data.answer;
          this.products = response.answer;
          this.totalRecords = response.totalRecords;
          this.recordsPerPage = response.recordsPerPage;
          this.currentPage = response.currentPage;
          this.totalPages = response.totalPages;
          this.numberOfRecords = response.numberOfRecords;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    monitoringProduct(product) {
      this.isLoading = true;
      this.service
        .monitoringItem(this.idCompany, product.idProduct)
        .then((res) => {
          if (res.data == true || res.data == false) {
            product.isMonitoring = !product.isMonitoring;
            this.isLoading = false;

            this.$emit("showSnackbar", "Produto salvo com sucesso!", "success");
          } else {
            this.$emit("showSnackbar", "Erro ao salvar produto!", "error");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    editOrDeleteProduct(product, type, index) {
      this.isLoading = true;

      let request = {
        idProduct: product.idProduct,
        externalId: product.externalId,
        code: product.code,
        isBenchmark: product.isComparativoMercado,
        monitoringExternalSearch: product.monitoringExternalSearch,
        monitoringInternalSearch: product.monitoringInternalSearch,
        description: product.description,
        isMonitoring: product.isMonitoring,
        tags: product.tags,
        deleted: type == "delete" ? true : false,
        idCompany: this.idCompany,
      };

      this.service
        .editOrDeleteProduct(request)
        .then((res) => {
          if (res.status == 200) {
            switch (type) {
              case "edit":
                product.isEdited = !product.isEdited;
                break;
              case "delete":
                this.products.splice(index, 1);
            }

            this.$emit(
              "showSnackbar",
              `Produto ${
                type == "edit" ? "atualizado" : "excluido"
              } com sucesso!`,
              "success"
            );
          } else {
            this.$emit(
              "showSnackbar",
              `Erro ao ${type == "edit" ? "atualizar" : "excluir"} produto`,
              "error"
            );
          }

          this.deleteDialog = false;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    copyProductName(productName) {
      var textarea = document.createElement("textarea");
      textarea.value = productName;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);

      this.$emit("showSnackbar", "Copiado!", "info");
    },
  },

  mounted() {
    this.listProductsPaginated();
  },
};
</script>

<style lang="scss" scoped>
.product-flex {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 24px;
}

.product-flex div:nth-child(2) {
  align-self: flex-end;
}
</style>