<template>
  <div>
    <prd-btn :title="$t('TXT_SAVE')" @click="openDialog" />
    <v-dialog
      v-model="isDialogOpen"
      :max-width="550"
      persistent
      v-if="localData != null"
    >
      <v-card class="pa-4" flat :disabled="isLoading">
        <h4 class="mb-4">{{ $t("TXT_TRAILED_CRAWLER") }}</h4>
        <span>{{ $t("TXT_COMPANY_CHANGES") }}</span>

        <div class="changes-main pa-4 mb-4">
          <h4 class="mb-4">
            {{ localData.idEmpresa }} -
            {{
              localData.nomeRazaoSocial
                ? localData.nomeRazaoSocial.toUpperCase()
                : ""
            }}
          </h4>
          <v-divider></v-divider>

          <div
            class="my-4 crawler-div"
            v-if="
              localData.newCrawlersLinked
                ? localData.newCrawlersLinked.length > 0
                : false
            "
          >
            <h4>
              {{ $t("TXT_NEW_CRAWLERS") }}
              <span class="green-text">{{ $t("TXT_ATTACHED") }}</span
              >:
            </h4>
            <div
              class="my-flex"
              v-for="(crawler, index) in localData.newCrawlersLinked"
              :key="index"
            >
              {{ crawler.description }} - {{ crawler.idMonitoringCrawler }}
              <span
                style="align-items: center !important"
                v-if="crawler.idCountry"
              >
                <img
                  width="25px"
                  style="border-radius: 5px"
                  :src="setToCountryFlag(crawler.idCountry)"
                />
              </span>
              |
            </div>
          </div>
          <v-divider></v-divider>

          <div
            class="my-4 crawler-div"
            v-if="
              localData.crawlersUnlinked
                ? localData.crawlersUnlinked.length > 0
                : false
            "
          >
            <h4>
              {{ $t("TXT_CRAWLERS_WILL_BE") }}
              <span class="red-text">{{ $t("TXT_UNCHARGED") }}</span
              >:
            </h4>
            <div
              class="my-flex"
              v-for="(crawler, index) in localData.crawlersUnlinked"
              :key="index"
            >
              {{ crawler.description }} - {{ crawler.idMonitoringCrawler }}
              <span v-if="crawler.idCountry">
                <img
                  class="pt-1"
                  width="25px"
                  style="border-radius: 5px"
                  :src="setToCountryFlag(crawler.idCountry)"
                />
              </span>
              |
            </div>
          </div>
        </div>

        <span class="crawler-div">{{ $t("TXT_CONFIRM_ALTERATIONS") }}?</span>

        <v-row no-gutters class="mt-4">
          <v-spacer></v-spacer>
          <prd-btn
            :disabled="isLoading"
            @click="isDialogOpen = false"
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            class="mr-4"
          />
          <prd-btn
            :disabled="isLoading"
            :loading="isLoading"
            :title="$t('TXT_CONFIRM')"
            @click="confirmChanges"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/crawlers/crawler-management.js";
const Service = new service();

export default {
  components: { PrdBtn },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      localData: null,
    };
  },
  methods: {
    openDialog() {
      this.$emit("buildBody");
      this.isDialogOpen = true;
    },
    setToCountryFlag(value) {
      switch (value) {
        case 29:
          return require("../../../../../../public/images/icons/flags/br.svg");
        case 232:
          return require("../../../../../../public/images/icons/flags/us.svg");
        case 205:
          return require("../../../../../../public/images/icons/flags/es.svg");
        default:
          return "";
      }
    },

    buildBodyToRequest(idCompany, dataCrawler) {
      return {
        idCompany_MonitoringCrawler: dataCrawler.idCompanyMonitoringCrawler
          ? dataCrawler.idCompanyMonitoringCrawler
          : 0,
        crawlerType: dataCrawler.crawlerType ? dataCrawler.crawlerType : null,
        idCompany: idCompany,
        idMonitoringCrawler: dataCrawler.idMonitoringCrawler,
        source: dataCrawler.source,
        city: dataCrawler.city ? dataCrawler.city : null,
        state: dataCrawler.state ? dataCrawler.state : null,
        networks: dataCrawler.networks ? dataCrawler.networks : null,
        cnpj: dataCrawler.cnpj ? dataCrawler.cnpj : null,
        exactVector: dataCrawler.exactVector ? dataCrawler.exactVector : null,
        isDeleted: dataCrawler.idCompanyMonitoringCrawler ? true : false,
      };
    },

    listPreparation(list) {
      const idEmpresa = this.localData.idEmpresa;
      let newList = [];
      list.forEach((element) => {
        newList.push(this.buildBodyToRequest(idEmpresa, element));
      });

      return newList;
    },
    runRequest(originalList) {
      this.isLoading = true;
      let requestList = this.listPreparation(originalList);
      Service.saveCrawlers(requestList)
        .then((res) => {
          if (res.status !== 500 && res.data.success === true) {
            this.isLoading = false;
            this.closeModal(true);
          } else {
            this.isLoading = false;
            this.closeModal(false);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.closeModal(false);
          console.log("Erro do InsertUpdateCrawler: ", error);
        });
    },
    confirmChanges() {
      let updatedCrawlersList = [];
      if (this.localData.newCrawlersLinked.length > 0) {
        this.localData.newCrawlersLinked.forEach((element) => {
          updatedCrawlersList.push(element);
        });
      }
      if (this.localData.crawlersUnlinked.length > 0) {
        this.localData.crawlersUnlinked.forEach((element) => {
          updatedCrawlersList.push(element);
        });
      }
      this.runRequest(updatedCrawlersList);
    },
    closeModal(isSuccess) {
      let text = null;
      let type = null;
      if (isSuccess) {
        text = this.$i18n.t("TXT_CHANGES_MADE");
        type = "success";
      } else {
        text = this.$i18n.t("TXT_CHANGES_PROBLEM");
        type = "error";
      }
      this.$emit("showSnackbar", {
        text: text,
        type: type,
      });
      this.localData = null;
      this.isDialogOpen = false;
      this.$emit("invertCard");
    },
  },

  watch: {
    isDialogOpen: {
      handler(value) {
        if (value) {
          this.localData = this.data;
        } else {
          this.localData = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.changes-main {
  background-color: $neutral-color-high-light;
  margin-top: 12px;
}

.green-text {
  color: $feedback-color-success-pure;
}

.red-text {
  color: $feedback-color-warning-pure;
}

.crawler-div {
  font-weight: 500;
  font-size: 14px;
}

.my-flex {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>