<template>
  <v-container fluid>
    <page-title  :title="$t('TXT_PRICING_UPPERCASE')">
      <!-- <template v-slot:tooltip> Página de Precificação </template> -->
    </page-title>
    <router-view class=" light-background"></router-view>
  </v-container>
</template>

<script>
import pageTitle from "@/Design_System/common/prd-title";

export default {
  name: "pricingView",
  components: {
    pageTitle,
  },
  data: () => ({}),
};
</script>
