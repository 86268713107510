<template>
  <div v-if="this.product != null" class="mb-4">
    <v-card class="pa-4" flat>
      <h4>SOBRE O PRODUTO</h4>

      <v-row no-gutters>
        <v-col class="pa-0 mt-4">
          <label>Segmento</label>
          <v-text-field
            :disabled="true"
            hide-details
            outlined
            dense
            filled
            class="mt-2"
            :label="
              this.product.sobre != null ? this.product.sobre.segmento : ''
            "
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="pa-0 mt-4">
          <label>Classificação</label>
          <v-text-field
            :disabled="true"
            :label="
              this.product.sobre != null ? this.product.sobre.classificacao : ''
            "
            hide-details
            outlined
            dense
            filled
            class="mt-2"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="pa-0 mt-4 mb-8">
          <label>Cultura driver</label>
          <v-text-field
            :disabled="true"
            :label="
              this.product.sobre != null ? this.product.sobre.culturaDriver : ''
            "
            hide-details
            outlined
            dense
            filled
            class="mt-2"
          ></v-text-field>
        </v-col>
      </v-row>

      <h4>MERCADO</h4>

      <v-row no-gutters>
        <v-col class="pa-0 mt-4">
          <label>Market share</label>
          <v-text-field
            hide-details
            outlined
            dense
            filled
            class="mt-2"
            :label="
              this.product.mercado == null
                ? ''
                : this.product.mercado.marketShare
            "
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="pa-0 mt-4">
          <label>Potencial de área tratada (Mio/Ha)</label>
          <v-text-field
            hide-details
            outlined
            dense
            filled
            class="mt-2"
            :label="
              this.product.mercado == null
                ? ''
                : this.product.mercado.potencialAreaTratada
            "
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {};
  },
  beforeMount() {},
};
</script>