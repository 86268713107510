<template>
  <div>
    <label class="label">
      <slot name="label">{{ title }}</slot>
      <tooltip-informations
        v-if="useTooltipInformation"
        :title="textTooltipInformation"
        :rightIcon="true"
      />
    </label>
    <v-combobox
      @input="onInput"
      v-bind="$attrs"
      append-icon="mdi-chevron-down"
      outlined
      dense
      hide-details
      return-object
      class="test"
      item-text="text"
      item-value="value"
    >
      <template v-for="slot in parentSlots" #[slot]>
        <slot :name="slot" />
      </template>

      <template v-for="(index, name) in parentScopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
import TooltipInformations from "./tooltip-informations.vue";
export default {
  name: "prdComboBox",
  components: { TooltipInformations },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
    },
    textTooltipInformation: {
      type: String,
    },
    useTooltipInformation: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput(values) {
      this.$emit("input", values);
    },
  },
  computed: {
    parentSlots() {
      if (this.$slots) return Object.keys(this.$slots);
      return [];
    },
    parentScopedSlots() {
      if (this.$scopedSlots) return Object.keys(this.$scopedSlots);
      return [];
    },
  },
};
</script>

<style scoped>
.test {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 45px;
}
</style>