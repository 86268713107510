<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadCrumbs"
        :title="$t('TXT_PRODUCT_MANAGEMENT')"
      />
    </v-row>

    <!-- product list -->
    <product-list @showSnackbar="showSnackbar" />

    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import ProductList from "./components/product-list.vue";

export default {
  name: "product-management-v2",
  components: { titleBreadCrumbsComponent, ProductList },
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("PRODUCT_LIST"),
        },
      ],

      snackbar: {
        snackbar: false,
        snackbarColor: "#3c5ca7",
        snackbarText: null,
      },
    };
  },

  methods: {
    showSnackbar(text, type) {
      console.log("texto", text, "type", type);
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
  },
};
</script>
<style scoped>
</style>