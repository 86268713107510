<template>
  <div class="my-flex">
    <h4>PREMISSAS</h4>

    <v-row no-gutters>
      <v-col>
        <label>Metodologia de precificação</label>
        <v-text-field :disabled="true" filled outlined dense class="mt-2"
          v-model="this.metodologiaPrecificacao"></v-text-field>
      </v-col>
      <v-col v-if="this.metodologiaPrecificacao == 'Custo'">
        <label>Margem Contribuição</label>
        <v-text-field :disabled="true" filled outlined dense class="mt-2 ml-4"
          v-model="this.margemContribuicao"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-if="this.metodologiaPrecificacao != 'Custo'">
        <label>Posicionamento</label>
        <v-text-field :disabled="true" filled outlined dense class="mt-2 mr-4"
          v-model="this.posicionamento"></v-text-field>
      </v-col>
      <v-col>
        <label>Corredor preços</label>
        <v-text-field :disabled="true" filled outlined dense class="mt-2 mr-4"
          v-model="this.corredorPrecos"></v-text-field>
      </v-col>
      <v-col>
        <label>Remuneração canal</label>
        <v-text-field :disabled="true" filled outlined dense class="mt-2" v-model="this.remuneracaoCanal"></v-text-field>
      </v-col>
    </v-row>

    <premissa-input-table v-bind:id="this.product.id" v-bind:product="this.product" />
  </div>
</template>

<script>
import premissaInputTable from "./premissa-input-table.vue";
export default {
  components: { premissaInputTable },
  props: ["product"],
  data() {
    return {
      metodologiaPrecificacao: "",
      posicionamento: "",
      corredorPrecos: "",
      remuneracaoCanal: "",
      margemContribuicao: "",
    };
  },
  beforeMount() {
    if (this.product != null) {
      if (this.product.tipoPrecificacao != 'Concorrente') {
        this.metodologiaPrecificacao = this.product.tipoPrecificacao;
      } else {
        this.metodologiaPrecificacao = this.product.estrategiaPrecificacao;
      }
      this.posicionamento = this.product.posicionamento;
      this.remuneracaoCanal = this.product.remuneracaoPorCanal;
      this.corredorPrecos = this.product.corredorPreco;
      this.margemContribuicao = this.product.margemContribuicao;
    }
  }
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
}
</style>