<template>
  <div id="market-intelligence" class="ma-0 pa-0 py-10 mx-auto">
    <v-row class="ma-0 pa-0 mb-4" align-content="center">
      <h1 class="page-title">Monitoramento de Produto</h1>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            style="cursor: pointer"
            v-bind="attrs"
            v-on="on"
            color="#6792F8"
            right
            >mdi-information-outline</v-icon
          >
        </template>
        <span>Pesquisa de monitoramento de produtos</span>
      </v-tooltip>
    </v-row>
    <v-row class="ma-0 pa-0" align-content="center">
      <p class=" ma-0 pa-0 mb-6">
        Inteligência de mercado > Monitoramento por lead
      </p>
    </v-row>

<v-tabs background-color="transparent" color="black" class="mx-0 mt-0">
  <v-tabs-slider color="red"></v-tabs-slider>
      <v-tab v-for="tab  in tabList" :key="tab.id" @click="changeTab(tab.id)">
        {{ tab.description }}
      </v-tab>
    </v-tabs>

    <!--- SMART PRICING ---> 
    <monitoring-product v-if="selectedTab === 0"/>
    <monitoring-lead v-if="selectedTab === 1"/>
     <tags-configure v-if="selectedTab ===3"/>
  </div>
 
</template>

<script>
import MonitoringLead from './components/monitoring-lead.vue';
import MonitoringProduct from "./components/monitoring-product.vue";
import TagsConfigure from './components/tags-configure.vue';

export default {
    name: "market-intelligence",
    components: { MonitoringProduct,MonitoringLead, TagsConfigure },
    data(){
      return{
        selectedTab: 0,
        tabList: [
        // {
        //   id: 0,
        //   description: "Monitoramento por Produto",
        //   alias: "monitoring-product",
        // },
        {
          id: 1,
          description: "Monitoramento por Lead",
          alias: "monitoring-lead",
        },
        // {
        //   id: 3,
        //   description: "Configuração de Tags",
        //   alias: "tags-configure",
        // },
        ]
      }
    },
     methods: {
    changeTab(value) {
      this.selectedTab = value;
      this.$router.push(this.tabList[value].alias);
    },
    getPath(newUrl) {
      newUrl = newUrl.split("/")[2];
      let pos = this.tabList.findIndex((e) => e.alias == newUrl);
      this.selectedTab = pos;
    },
  },
  watch: {
    "$route.path"(newUrl) {
      this.getPath(newUrl);
    },
  },
  created() {
    this.getPath(this.$route.path);
    this.selectedTab = 1
  },
};
</script>

<style scoped>
/* MY CLASSES */
#market-intelligence * {
  font-family: "Ubuntu", sans-serif;
}

#market-intelligence {
  width: 90%;
  margin-top: 2rem;
}

.page-title {
  font-size: 24px;
  font-weight: 600;
}

.page-breadcrumbs {
  font-size: 14px;
  opacity: 0.5;
}

</style>