/** This is the */
const defaultOptions = {
  /**
   * If the number's fractional part is longer thant this value, it'll be
   * cropped to this value.
   *
   * @type {number | null}
   */
  fractionLength: null,

  /** If this is true, always render the number's sign. */
  alwaysRenderSign: false,
};

/**
 * @param {string | number} value
 * @param {Partial<typeof defaultOptions>} options
 * @returns {string}
 */
export function formatNumber(value, options = {}) {
  if (typeof value === "number") {
    value = value.toString().replace(".", ",");
  } else if (value === "" || typeof value !== "string") {
    return "";
  }

  // --- Applying Default Options ---
  const fractionLength =
    options.fractionLength ?? defaultOptions.fractionLength;
  const alwaysRenderSign =
    options.alwaysRenderSign ?? defaultOptions.alwaysRenderSign;

  // Remove all non-number characters from value
  const onlyNumbers = value.replace(/[^\-0-9,]/g, "");

  let [whole, fraction] = onlyNumbers.split(",");
  fraction = fraction || "";

  // Add all thousands separators
  whole = whole.replace(/(\d)(?=(?:\d\d\d)+$)/g, "$1.");

  if (fractionLength !== null && fraction) {
    fraction = fraction
      .substring(0, fractionLength)
      .padEnd(fractionLength, "0");
  } else if (fractionLength !== null && !fraction) {
    fraction = "0".repeat(fractionLength);
  }

  if (fraction) {
    fraction = `,${fraction}`;
  }

  const plusSign = !onlyNumbers.startsWith("-") && alwaysRenderSign ? "+" : "";

  return `${plusSign}${whole}${fraction}`;
}

/**
 * Given a string generated by the above formatNumber function, return it's
 * number equivalent
 *
 * @param {string} value
 * @returns {number}
 */
export function parseNumber(value = "") {
  if (typeof value === "number") return value;
  if (value === "") return NaN;
  const onlyNumbers = value.replace(/[^\-0-9,]/g, "").replace(/,/g, ".");
  return Number(onlyNumbers);
}

/**
 * @param {string} value
 * @param {Partial<typeof defaultOptions>} options
 * @returns {string}
 */
export function formatNumberPercentage(value, options) {
  const result = formatNumber(value, options);
  if (result) {
    return `${result}%`;
  } else return "";
}

/**
 * Given a string generated by the above formatNumberPercentage function, return
 * it's number equivalent
 *
 * @param {string} value
 * @returns {number}
 */
export function parseNumberPercentage(value) {
  return parseNumber(value);
}

/**
 * @param {string} value
 * @param {Partial<Omit<typeof defaultOptions, "fractionLength">>} options
 * @returns {string}
 */
export function formatNumberMoney(value, options) {
  const result = formatNumber(value, { ...options, fractionLength: 2 });
  let currency = localStorage.getItem("currency") || 'R$'
  if (result) return `${currency} ${result}`;
  else return "";
}

/**
 * Given a string generated by the above formatNumberMoney function, return it's
 * number equivalent
 *
 * @param {string} value
 * @returns {number}
 */
export function parseNumberMoney(value) {
  return parseNumber(value);
}
