import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class newAiPricing extends ServiceBase {
    async getHeaderPricing(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/HeaderFujioka", {
            params: {
                idEnterprisePricesProjection: idEnterprisePricesProjection
            }
        })
    }
    async getHeaderInfo(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceProjection/List", request)
    }
    async adjustPrice(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/AddValueManual", request)
    }
    async sendToERP(idEnterprisePricesProjection) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/SendPriceToERP", idEnterprisePricesProjection)
    }
    async setWorkflow(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/SetActionWorkflow", request)
    }


}