<template>
  <div>
    <v-card class="pa-4">
      <h4>LISTA DE PREÇOS | VALOR PRESENTE</h4>

      <table style="width: 100%;">
        <thead>
          <tr>
            <td></td>
            <td>BRL</td>
            <td>USD</td>
          </tr>
        </thead>
        <tbody v-if="this.product.tipoPrecificacao != 'Custo'">
          <tr>
            <td>Preço unitário</td>
            <td>{{ this.precoUnitario }}</td>
            <td>{{ Math.round(this.precoUnitarioDolar * 10) / 10 }}</td>
          </tr>
          <tr>
            <td>MaCo%</td>
            <td> {{ calculateMaCo(this.precoUnitario) }}% </td>
            <td> {{ calculateMaCoDolar(this.precoUnitarioDolar) }}%</td>
          </tr>
          <tr>
            <td>Preço mínimo</td>
            <td>{{ this.precoMinimo }}</td>
            <td>{{ Math.round(this.precoMinimoDolar * 10) / 10 }}</td>
          </tr>
          <tr>
            <td>MaCO%</td>
            <td>{{ calculateMaCo(this.precoMinimo) }}%</td>
            <td>{{ calculateMaCoDolar(this.precoMinimoDolar) }}%</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>Preço unitário</td>
            <td>{{ this.product.precoCanalGeral }}</td>
            <td>{{ Math.round((this.product.precoCanalGeral / 5.20) * 10) / 10 }}</td>
          </tr>
          <tr>
            <td>MaCo%</td>
            <td> {{ calculateMaCo(this.product.precoCanalGeral) }}% </td>
            <td> {{ calculateMaCoDolar((this.product.precoCanalGeral / 5.20)) }}%</td>
          </tr>
          <tr>
            <td>Preço mínimo</td>
            <td>{{ this.product.precoMinimoCanalGeral }}</td>
            <td>{{ Math.round((this.product.precoMinimoCanalGeral / 5.20) * 10) / 10 }}</td>
          </tr>
          <tr>
            <td>MaCO%</td>
            <td>{{ calculateMaCo(this.product.precoMinimoCanalGeral) }}%</td>
            <td>{{ calculateMaCoDolar((this.product.precoMinimoCanalGeral / 5.20)) }}%</td>
          </tr>
        </tbody>

      </table>
    </v-card>

  </div>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      vizualization: null,
      precoUnitario: "",
      precoUnitarioDolar: "",
      precoMinimo: "",
      precoMinimoDolar: "",
      unitCogs: "",
      unitCogsDolar: "",
      dolar: null,
      custoReposicao: [],
    };
  },
  methods: {
    calculateMaCo(preco) {
      let imposto = (1 - this.product.imposto);
      let frete = this.product.frete;
      return Math.floor((preco - (preco * imposto) - this.unitCogs - (this.unitCogs * frete)) / (preco - (preco * imposto)) * 100);
    },
    calculateMaCoDolar(preco) {
      let imposto = (1 - this.product.imposto);
      let frete = this.product.frete;
      return Math.floor((preco - (preco * imposto) - this.unitCogsDolar - (this.unitCogsDolar * frete)) / (preco - (preco * imposto)) * 100);
    }
  },
  async beforeMount() {
    await this.service.getCustoReposicao().then((res) => {
      this.custoReposicao = res.data;
    });
    if (this.product != null) {
      this.precoUnitario = this.product.precoUnitario;
      this.precoUnitarioDolar = this.product.precoUnitarioDolar;
      this.precoMinimo = this.product.precoMinimo;
      this.precoMinimoDolar = this.product.precoMinimoDolar;

      if (this.product.unitCogs == null || this.product.unitCogs == 0) {
        let selectedCustoReposicao = this.custoReposicao.find(x => x.brand == this.product.nome);
        this.unitCogs = selectedCustoReposicao.unitCogs;
        this.unitCogsDolar = selectedCustoReposicao.unitCogs / 5.2;
      } else {
        this.unitCogs = this.product.unitCogs;
        this.unitCogsDolar = this.product.unitCogsDolar;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  td {
    text-align: center;
  }

  thead {
    tr td {
      background-color: #3c5ca7;
      color: white;
      padding: 4px 16px;
      font-weight: bold;
    }

    tr td:first-child {
      background-color: transparent;

    }
  }

  tbody {
    tr td:first-child {
      background-color: #ccc;
      font-weight: bold;
      padding: 8px 16px;
    }

  }
}
</style>