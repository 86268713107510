import axiosInstance, { API_URL_HOME_20, API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";
import store from "@/store";

export default class Home20Service extends ServiceBase {

  async getAcaoPromocional() {
    return await axiosInstance.get(API_URL_HOME_20 + "api/AcaoPromocional");
  }

  async checkAdm(store) {
    return await axiosInstance.post(API_URL_HOME_20 + "api/Admin", store)
  }

  async createAcaoPromocional(acaoPromocional) {
    return await axiosInstance.post(API_URL_HOME_20 + `api/AcaoPromocional`, acaoPromocional, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async getAlert(loja, curva, categoria) {
    return await axiosInstance.get(API_URL_HOME_20 + `api/Alert?loja=${loja}&curva=${curva}&categoria=${categoria}`);
  }

  async getRecipe(loja, curva, categoria) {
    return await axiosInstance.get(API_URL_HOME_20 + `api/Recipe?loja=${loja}&curva=${curva}&categoria=${categoria}`);
  }

  async getRecipeByPricing(idPriceGroup) {
    return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceGroups/SceneryInfo?idCompany=3026&idPriceGroup=${idPriceGroup}`);
  }

  async getPricingGroup(idPriceGroup) {
    return await axiosInstance.get(`https://dataanalyticsapi.azurewebsites.net/vem/price_group`, { params: { affiliate: idPriceGroup, max_period: 45 } });
  }

  async getGraficos(loja, curva, categoria) {
    return await axiosInstance.get(API_URL_HOME_20 + `api/Grafico?loja=${loja}&curva=${curva}&categoria=${categoria}`);
  }

  async getOportunities(loja, curva, categoria) {
    return await axiosInstance.get(API_URL_HOME_20 + `api/Oportunities?loja=${loja}&curva=${curva}&categoria=${categoria}`);
  }

  async getAffiliateFilter(idCompany) {
    return await axiosInstance.get(API_URL + `api/V2/Enterprise/PriceGroups/Filter?idCompany=${idCompany}&preSelect=${true}`);
  }

  async getFilters() {
    const auth = store.getters.userData;
    return await axiosInstance.get(API_URL_HOME_20 + `api/Filters?token=${auth.token.token}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  async getRelatorioAcaoPromocional() {
    return await axiosInstance.get(API_URL_HOME_20 + "api/AcaoPromocional/relatorio", { responseType: 'blob' });
  }

  async getLastMonthProjection(loja, curva, categoria) {
    return await axiosInstance.get(API_URL_HOME_20 + `api/Recipe/lastMonth?loja=${loja}&curva=${curva}&categoria=${categoria}`);
  }
}