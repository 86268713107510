<template>
  <div v-if="simulationResponse" class="full-width">
    <h2 class="my-6 text-body-1 font-weight-bold">RESULTADOS</h2>
    <div v-if="!hasError" class="ml-4 mb-2">
      <div
        v-for="infoMessage in messages.info"
        :key="infoMessage"
        class="color-primary"
      >
        {{ infoMessage }}
      </div>
      <div
        v-for="warningMessage in messages.warning"
        :key="warningMessage"
        class="color-warning"
      >
        {{ warningMessage }}
      </div>
    </div>
    <div v-if="hasError">
      Falha ao simular o jogo:
      <div v-for="(error, index) in messages.error" :key="index">
        {{ error }}
      </div>
    </div>
    <div v-else-if="hasZeroTickets">Nenhum resultado foi encontrado</div>
    <div v-else class="pa-4 card rounded-lg full-width mt-32">
      <div class="d-flex gap-6">
        <v-card
          elevation="4"
          class="d-flex gap-6 flex-column floating pa-4 min-width-288"
        >
          <div class="font-weight-bold">Receita Total</div>
          <div class="predify-blue text-h5">{{ receitaTotal }}</div>
        </v-card>
        <v-card
          elevation="4"
          class="d-flex gap-6 flex-column floating pa-4 min-width-288"
        >
          <div class="font-weight-bold">Ticket Médio</div>
          <div class="predify-blue text-h5">{{ averageTicket }}</div>
        </v-card>
      </div>
      <v-card elevation="4" class="mt-6 pa-4 d-flex flex-column">
        <div class="mb-9 font-weight-bold">Preço dos ingressos</div>
        <table-component
          :headers="tableHeaderData"
          :rows="tableData"
          :stickyHeight="64"
        >
          <template v-slot:default="slotProps">
            <div
              v-for="(item, index) in slotProps.items"
              :key="index"
              class="table-item"
            >
              {{ item }}
            </div>
          </template>
        </table-component>
        <prd-btn
          title="Exportar PDF"
          class="mt-6 align-self-end"
          @click="downloadPdf()"
        ></prd-btn>
      </v-card>
    </div>
  </div>
</template>
<script>
import prdBtn from "@/Design_System/common/prd-btn";
import { formatMoney } from "@/utils/format-money";
import tableComponent from "./table-component";

export default {
  props: ["simulationResponse", "publicPrediction", "tickets", "sectors"],
  components: {
    tableComponent,
    prdBtn,
  },
  methods: {
    downloadPdf() {
      const pdfData = this.simulationResponse.result.feature_table;
      if (!pdfData)
        return this.$store.commit(
          "snackbar-error",
          "Dados do PDF não foram gerados"
        );
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfData}`;
      link.download = "predifut-file.pdf";
      link.dispatchEvent(new MouseEvent("click"));
    },
  },
  computed: {
    hasZeroTickets() {
      return (
        (this.simulationResponse?.result?.tickets_result?.length || 0) === 0
      );
    },
    messages() {
      const messages = this.simulationResponse?.messages || [];
      const warningMessages = [];
      const errorMessages = [];
      const infoMessages = [];

      messages.forEach(({ type, message }) => {
        if (type === 0 && !message.startsWith("Cálculo aceito no"))
          infoMessages.push(message);
        if (type === 1) warningMessages.push(message);
        if (type === 2) errorMessages.push(message);
      });

      return {
        error: errorMessages,
        warning: warningMessages,
        info: infoMessages,
      };
    },
    hasError() {
      return this.messages.error.length > 0;
    },
    receitaTotal() {
      return formatMoney(this.simulationResponse.result.income);
    },
    averageTicket() {
      const expectedPublic = this.publicPrediction;
      if (expectedPublic === 0 || expectedPublic === null)
        return "Não pôde ser calculado";
      return formatMoney(
        this.simulationResponse.result.income / expectedPublic
      );
    },
    tableHeaderData() {
      return [
        "Tipo de ingresso",
        this.sectors.map((sector) => sector.name),
      ].flat();
    },
    tableData() {
      const resultsDict = this.simulationResponse.result.tickets_result.reduce(
        (dict, ticket) => {
          dict[ticket.idEnterpriseEventTicket] = ticket;
          return dict;
        },
        {}
      );

      return this.tickets.map((ticket) =>
        [
          ticket.name,
          this.sectors.map((sector) => {
            const result = resultsDict[ticket.idEnterpriseEventTicket];
            if (!result) return "-";
            const sectorData = result.sectors.find(
              (resultSector) =>
                resultSector.idEnterpriseEventSector ===
                sector.idEnterpriseEventSector
            );
            if (sectorData) return formatMoney(sectorData.ticket_price);
            else return "-";
          }),
        ].flat()
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background-color: $neutral-color-high-pure;
  display: flex;
  flex-direction: column;
}
.full-width {
  width: 100%;
}
.gap-6 {
  row-gap: 24px;
  column-gap: 24px;
}
.predify-blue {
  color: $brand-color-primary-pure;
}
.min-width-288 {
  min-width: 288px;
}
.table-item {
  background-color: $neutral-color-high-light;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-primary {
  color: $brand-color-primary-pure;
}
.color-primary {
  color: $feedback-color-helper-pure;
}
</style>
