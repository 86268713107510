export const ActionEnum = {
  Approve: 0,
  Reject: 1,
  Escalate: 2,
};

/** @typedef {typeof ActionEnum} ActionEnum */

/** @param {ActionEnum[keyof ActionEnum]} action */
export function resolveActionToVerb(action) {
  return [this.$i18n.t("TXT_APPROVE"), this.$i18n.t("TXT_NOT_APPROVE"), this.$i18n.t("TXT_SCALATE")][action];
}

/** @param {ActionEnum[keyof ActionEnum]} action */
export function resolveActionToPastVerb(action) {
  return [this.$i18n.t("TXT_APPROVED"), this.$i18n.t("TXT_NOT_APPROVED"), this.$i18n.t("TXT_SCALATED")][action];
}

/** @param {ActionEnum[keyof ActionEnum]} action */
export function resolveActionToIconName(action) {
  return ["mdi-thumb-up-outline", "mdi-thumb-down-outline", "mdi-trending-up"][
    action
  ];
}

/** @param {ActionEnum[keyof ActionEnum]} action */
export function resolveToColorClass(action) {
  return ["color-approve", "color-reject", "color-scale"][action];
}

export function listAllActions() {
  return [0, 1, 2];
}
