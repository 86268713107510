import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class ProductManagement extends ServiceBase {

    async productList(request) {
        return await axiosInstance.post(API_URL + 'api/V2/Product/ListPaginado', request)
    }

    async listWantedProduct(idCompany, idProduct) {
        return await axiosInstance.get(API_URL + `api/V2/Product/MudarComparativoMercado?idCompany=${idCompany}&idProduct=${idProduct}`)
    }

    async editOrDeleteProduct(request) {
        return await axiosInstance.put(API_URL + "api/V2/Product", request)
    }

    async monitoringItem(idCompany, idProduct) {
        return await axiosInstance.get(API_URL + `api/V2/Product/MudarComparativoMercado?idCompany=${idCompany}&idProduct=${idProduct}`)
    }
    

}