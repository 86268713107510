<template>
  <v-card
    class="pa-4 pt-0"
    :disabled="productListLoading"
    :loading="productListLoading"
  >
    <v-row no-gutters class="pt-4">
      <!--Pricing select-->
      <v-col cols="12" lg="4" md="3" sm="6">
        <prd-combo-box
          v-model="selectedPricing"
          @input="setPricingJob"
          :title="'Selecione uma precificação'"
          :items="pricingItems"
          :disabled="pricingGroupLoading || pricingItems.length == 0"
          :loading="pricingGroupLoading"
        />
      </v-col>

      <!-- Pricing modal -->
      <pricing-modal class="mt-6" @setSavePricingJob="getPricingGroup(true)" />

      <!-- Calculate prices -->
      <prd-btn
        :title="'Calcular Preços'"
        :disabled="pricingGroupLoading || !selectedPricing"
        @click="calculatePrices"
        class="mt-7 ml-4"
      />

      <!-- Calculate rules -->
      <prd-btn
        v-if="isPredifyAdmin"
        :disabled="pricingGroupLoading || !selectedPricing"
        :title="'Calcular Regras'"
        :outlined="true"
        :icon="'mdi-shield-account-outline'"
        :leftIcon="true"
        :smallIcon="true"
        :iconColor="$prdStyles('color-warning')"
        @click="calculateRules"
        class="mt-7 ml-4"
      />
    </v-row>

    <div>
      <div v-show="filterLoading" class="my-flex">
        <h4>Carregando filtros</h4>
        <v-progress-circular
          indeterminate
          :color="$prdStyles('color-primary')"
        ></v-progress-circular>
      </div>
      <h4 v-show="!filterLoading && filterItems.length != 0" class="mt-6">
        FILTROS
      </h4>
    </div>

    <!-- FILTERS -->
    <v-row v-show="!filterLoading" no-gutters>
      <v-col
        v-for="filter in filterItems"
        :key="filter.idEnterprisePriceProjection_DefaultFilter"
        cols="12"
        md="3"
      >
        <prd-combo-box
          v-model="filter.selected"
          :title="filter.fieldName"
          :items="filter.values"
          :disabled="filter.values.length == 0 || pricingGroupLoading"
          :hasOuterIcon="true"
          @input="getProductList"
          small-chips
          deletable-chips
          label="Selecione"
          single-line
          class="mr-4 mt-2"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PricingModal from "./pricing-modal.vue";

import searchService from "@/service/new-ai-pricing/search-filters.js";
const SearchService = new searchService();

export default {
  components: { prdComboBox, PrdBtn, PricingModal },
  props: ["recordsPerPage", "currentPage", "productListLoading"],
  data() {
    return {
      pricingGroupLoading: false,
      filterLoading: false,

      selectedPricing: null,
      pricingItems: [],
      filterItems: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },

  watch: {
    recordsPerPage() {
      this.getProductList();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    currentPage() {
      this.getProductList();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },

  methods: {
    calculatePrices() {
      this.$emit("setLoading", true);

      let cleanResponse = {
        records: [],
        currentPage: 1,
        totalRecords: null,
        totalPages: null,
        numberOfRecords: null,
      };

      this.filterItems = [];

      this.$emit("products", cleanResponse);

      SearchService.calculatePrices(this.idCompany, this.selectedPricing.value)
        .then((res) => {
          console.log("Resposta do gerar precificação", res);
          if (res.status != 500) {
            this.setPricingJob();
          }
        })
        .catch((error) => {
          console.log("Erro ao gerar precificação", error);
          this.$emit("showSnackbar", "Erro ao calcular precificação", "error");
          this.$emit("setLoading", false);
        });
    },

    calculateRules() {
      this.$emit("setLoading", true);

      let cleanResponse = {
        records: [],
        currentPage: 1,
        totalRecords: null,
        totalPages: null,
        numberOfRecords: null,
      };

      this.filterItems = [];

      this.$emit("products", cleanResponse);
      SearchService.calculateRules(this.idCompany, this.selectedPricing.value)
        .then((res) => {
          if (res.status != 500) {
            this.setPricingJob();
          }
        })
        .catch((error) => {
          console.log("Erro ao gerar regras", error);
          this.$emit("showSnackbar", "Erro ao calcular regras", "error");
        });
    },

    getPricingGroup(isnewPricing) {
      this.pricingGroupLoading = true;

      let request = {
        idCompany: this.idCompany,
        filters: [],
        simulation: false,
      };

      SearchService.getPricingGroup(request)
        .then((res) => {
          if (res.status != 500) {
            let response = res.data.result;

            response = response.map((el) => ({
              text: el.name,
              value: el.idEnterprisePriceGroups,
              idAITraining: el.idAITraining,
              filters: el.filters,
            }));

            this.pricingItems = response;

            if (isnewPricing) {
              this.selectedPricing = this.pricingItems[0];
              this.calculatePrices();
            }
          }
          this.pricingGroupLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao precificação ", error);
          this.$emit("showSnackbar", "Erro ao listar precificações", "error");

          this.pricingGroupLoading = false;
        });
    },

    getFilters() {
      this.filters = [];
      this.filterLoading = true;
      SearchService.getFilters(this.idCompany, this.selectedPricing.value)
        .then((res) => {
          let response = res.data.result.customFilters;

          response.forEach((filter) => {
            filter.values = filter.values.map((el) => ({
              text: el.description,
              value: el.value,
            }));
          });

          response = response.map((el) => ({
            ...el,
            selected: null,
          }));

          this.filterItems = response;
          this.filterLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao buscar filtros", error);
          this.$emit("showSnackbar", "Erro ao listar filtros", "error");

          this.filterLoading = false;
        });
    },

    setPricingJob() {
      this.getProductList();
      this.getFilters();
    },

    getProductList() {
      this.$emit("setLoading", true);
      let request = this.buildRequest();

      SearchService.getProductList(request)
        .then((res) => {
          let response = res?.data?.result ?? [];
          this.$emit("products", response);
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log("Erro ao buscar produtos", error);
          this.$emit("showSnackbar", "Erro ao listar produtos", "error");
          this.$emit("setLoading", false);
        });
    },

    buildRequest() {
      let request = {
        filter: [],
        idCompany: this.idCompany,
        idEnterprisePriceGroups: this.selectedPricing.value,
        isSimulation: false,
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage,
      };

      let selectedFilterList = [];
      this.filterItems.forEach((el) => {
        if (el.selected) {
          let filterModel = {
            dbField: el.dbField,
            idEnterprisePriceProjectionDefaultFilter:
              el.idEnterprisePriceProjection_DefaultFilter,
            value: el.selected.value,
          };

          selectedFilterList.push(filterModel);
        }
      });

      request.filter = selectedFilterList;

      return request;
    },
  },

  created() {
    document.title = "PREDIFY | Precificação por IA";

    this.getPricingGroup();
  },
};
</script>

<style lang="scss" scoped>
.filters-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.my-flex {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}
</style>