<template>
  <v-card v-if="this.produtoInterno != null && this.product != null">
    <br />
    <h4 class="ml-4 mr-4">DIRECIONADOR DE PREÇOS MISTURA INTERNA</h4>
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style="width: 18.75%">{{ this.produtoInterno.nome }}</th>
            <th style="width: 18.75%">{{ this.product.capturas[0].nomeCaptura }}</th>
            <th style="width: 18.75%">{{ this.product.nome }}</th>
            <th style="width: 18.75%">Δ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td style="border-top-left-radius: 10px" class="item">Dose</td>
            <td>
              <div>
                <span>{{ formatVal(this.produtoInterno.dose, 2) }}</span>
              </div>
            </td>
            <td>
              <div>
                <span>{{ formatVal(this.product.capturas[0].doseCompetidor, 2) }}</span>
              </div>
            </td>
            <td>
              <div>
                <span>{{ formatVal(this.product.dose, 2) }}</span>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">BRL</td>
            <td class="item">Preço kg/l</td>
            <td>{{ formatVal(this.produtoInterno.precoQuilo, 1) }}</td>
            <td>{{ formatVal(this.product.capturas[0].precoQuiloCompetidor, 1) }}</td>
            <td>{{ formatVal(this.product.precoQuilo, 1) }}</td>
            <td rowspan="2" :class="{ positive: number > 0, negative: number < 0 }">
              {{ calcPercentage((this.produtoInterno.precoQuilo * this.product.dose),
                this.product.capturas[0].precoCustoHectareCompetidor,
                this.product.precoCustoHectare) }} %
            </td>
          </tr>
          <tr>
            <td class="item">Custo/hectare</td>
            <td>{{ formatVal(this.produtoInterno.precoCustoHectare, 1) }}</td>
            <td>{{ formatVal(this.product.capturas[0].precoCustoHectareCompetidor, 1) }}</td>
            <td>{{ formatVal(this.product.precoCustoHectare, 1) }}</td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">USD</td>
            <td class="item">Preço kg/l</td>
            <td>{{ calcDolar(this.produtoInterno.precoQuilo) }}</td>
            <td>{{ calcDolar(this.product.capturas[0].precoQuiloCompetidor) }}</td>
            <td>{{ calcDolar(this.product.precoQuilo) }}</td>
            <td rowspan="2" :class="{ positive: number > 0, negative: number < 0 }">
              {{ calcPercentage((this.produtoInterno.precoQuilo * this.product.dose),
                this.product.capturas[0].precoCustoHectareCompetidor,
                this.product.precoCustoHectare) }} %
            </td>
          </tr>
          <tr>
            <td class="item">Custo/hectare</td>
            <td>{{ calcDolar(this.produtoInterno.precoCustoHectare) }}</td>
            <td>{{ calcDolar(this.product.capturas[0].precoCustoHectareCompetidor) }}</td>
            <td>{{ calcDolar(this.product.precoCustoHectare) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      itemsUfBrandSemCap: [],
      isEdit: true,
      number: 10,
      number2: -10,
      produtoInterno: null,
    };
  },
  methods: {
    calculateCapInt(val1, val2) {
      return ((val1 / val2) - 1)
    },
    calcDolar(val) {
      return (val / 5.2).toFixed(1).toString().replace(".", ",");
    },
    calcDolarWithoutString(val) {
      return (val / 5.2).toFixed(2);
    },
    formatVal(val, decimalPlaces) {
      return (val).toFixed(decimalPlaces).toString().replace(".", ",");
    },
    calcPercentage(val1, val2, val3) {
      return (((val3 / (val1 + val2)) - 1) * 100).toFixed(0).toString().replace(".", ",");
    },
  },
  async beforeMount() {
    await this.service.getProductById(this.product.capturas[0].idProdutoUPL).then((res) => {
      this.produtoInterno = res.data;
    });
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr th:first-child {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(2) {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(3) {
      border-top-left-radius: 10px;
    }

    tr th:nth-child(6) {
      border-top-right-radius: 10px;
    }

    tr th {
      background-color: #3c5ca7;
      color: white;
      border: 2px solid white;
      padding: 5px;
    }
  }

  td {
    padding: 8px;
    border: 2px solid white;
    text-align: center;
  }

  tbody {
    td {
      background-color: #f5f5f5;
    }

    .currency {
      writing-mode: vertical-rl;
      background-color: #3c5ca7;
      color: white;
      font-weight: bold;
      width: 8px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .item {
      background-color: #cccccc;
      font-weight: bold;
    }

    .item:first-child {
      background-color: #cccccc;
      font-weight: bold;
    }
  }
}

.hidden-cell {
  background-color: transparent;
  border: 1px solid transparent;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
</style>