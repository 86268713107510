<template>
  <div>
    <br/>
  <prd-datatable 
    ref="tabela"

    :headers="headers" 
    :items="desserts" 
    :indicators="indicators"
    :dynamic="false"
    :pagesCount="2"
    :totalRegisters="3"
    :multi-sort="false"

    resultLabel="Mostrando ${result} registros de ${total}"
    pageLabel="Página ${page} selecionada de ${total}"
    :itemsPerPageTitle="'Usuários por página:'"
    :itemsPerPage="selected"
    

    @onChange="write"
    >
    <!-- <template v-slot:[`item.first.content`]="{ item }">
      testando: {{item.name}}
    </template> -->

    <template v-slot:[`item.resourceGroups`]="{ item }">
        <v-btn @click="clicked(item)">Testando</v-btn>
        {{ item.resourceGroups }}
    </template>
  </prd-datatable>
</div>
</template>

<script>
import PredifyDataTable from "@/Design_System/common/prd-datatable.vue";

export default {
  name: "ViewParaTesteDaTabela",
  components: {
    prdDatatable: PredifyDataTable,
},
  data() {
    return {
      dataTableInfo: {},
      selected: 15,
      headers: [
        {
          text: "Nome do usuário",
          align: "center",
          value: "name",
          width: "300px",
          index: 1,
        },
        {
          text: "Grupo de recursos",
          align: "center",
          value: "resourceGroup",
          index: 2,
        },
        { text: "Carbs (g)", align: "center", value: "carbs", index: 3 },
        { text: "Protein (g)", align: "center", value: "protein", index: 4 },
        { text: "Actions", align: "center", value: "actions", index: 5 },
        { text: "Resource Groups", align: "center", value: "resourceGroups" },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          resourceGroup: 6.0,
          carbs: 24,
          protein: 4.0,
          actions: "1%",
          isAdmin: true,
          groups: ["Grupo1", "Grupo2", "Grupo3"],
        },
        {
          name: "Ice cream sandwich",
          resourceGroup: 9.0,
          carbs: 37,
          protein: 4.3,
          actions: "1%",
          isAdmin: false,
          groups: ["Grupo1", "Grupo2", "Grupo3"],
        },
        {
          name: "Paçoca predify",
          resourceGroup: 9.0,
          carbs: 25,
          protein: 4.3,
          actions: "1%",
          isAdmin: true,
          groups: ["Grupo1", "Grupo2", "Grupo3"],
        },
        {
          name: "Chocolate",
          resourceGroup: 9.0,
          carbs: 37,
          protein: 4.3,
          actions: "1%",
          isAdmin: true,
          groups: ["Grupo1", "Grupo2", "Grupo3"],
        },
        {
          name: "Churros",
          resourceGroup: 9.0,
          carbs: 37,
          protein: 4.3,
          actions: "1%",
          isAdmin: false,
          groups: ["Grupo1", "Grupo2", "Grupo3"],
        },
      ],
      indicators: [
          {
            value: "carbs",
            title: "Carboitrados com o valor de 24.",
            activators: [
              {
                active: 24,
                class: "indicator-user-admin",
              },
              {
                title:"Valor de 25",
                active: 25,
                class: "indicator-user-admin",
              }

            ]
          },
          // {
          //   value: "carbs",
          //   title: "Carboitrados com o valor de 25.",
          //   activators: [
          //     {
          //       active: 25,
          //       class: "indicator-regular",
          //     }
          //   ]
          // },
          {
            value: "carbs",
            title: "Carboitrados com o valor de 37.",
            activators: [
              {
                active: 37,
                class: "indicator-carbs-low",
              }
            ]
          },          
      ]
    };
  },
  methods:{
    write(values){
      this.dataTableInfo = values
    },
    clicked(item){
      console.log(item)
    }
  }
};
</script>