<template>
  <v-card class="pa-4" v-if="this.product != null" style="max-height: 600px;">
    <h4>ATINGIMENTO x FIN. REVIEW 23</h4>
    <span class="font-size-12">Fonte: Finance Review & Qlik</span>
    <highcharts class="hc mt-4" :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

export default {
  props: ["product"],
  data() {
    return {
      vol: null,
      sales: null,
      gp: null,
      chartData: {
        chart: {
          type: "column",
          height: 300,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: ["2021/22"],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },

        series: [
          {
            name: "Volume",
            data: [this.product.graficoAtingimentoVsBudget.vol.total],
            color: "#3c5ca7",
          },
          {
            name: "Sales",
            data: [this.product.graficoAtingimentoVsBudget.sales.total],
            color: "#3c5ca7",
          },
          {
            name: "GP",
            data: [this.product.graficoAtingimentoVsBudget.gp.total],
            color: "#3c5ca7",
          }
        ],

        // series: [
        //   {
        //     name: "Vol",
        //     data: ["EESTE", 1],
        //     color: "#3c5ca7",
        //     dataLabels: {
        //       enabled: true,
        //       rotation: 0,
        //       color: '#FFFFFF',
        //       align: 'center',
        //       format: '{point.y:.1f}', // one decimal
        //       y: 150, // 10 pixels down from the top
        //       style: {
        //         fontSize: '20px',
        //         fontFamily: 'Verdana, sans-seri'
        //       }
        //     }
        //   },
        // {
        //   name: "Sales",
        //   data: [this.product.graficoAtingimentoVsBudget.sales.total],
        //   color: "#3c5ca7",
        // },
        // {
        //   name: "GP",
        //   data: ['Tianjin', this.product.graficoAtingimentoVsBudget.gp.total],
        //   color: "#3c5ca7",
        // }
        //],
      },
    };
  },
  beforeMount() {
    if (this.product != null) {
      this.vol = this.product.graficoAtingimentoVsBudget.vol.total;
      this.sales = this.product.graficoAtingimentoVsBudget.sales.total;
      this.gp = this.product.graficoAtingimentoVsBudget.gp.total;
    }
  }
};
</script>