<template>
  <v-card
    class="pa-4 main-box"
    :class="setBorder(company.statusPagamento)"
    :disabled="company.isBloqueado"
    @click="selectCompany"
  >
    <!-- LEFT INFO -->
    <div class="flex-column-left">
      <h3>{{ company.nomeRazaoSocial }} ({{ company.idEmpresa }})</h3>
      <span class="mt-4 font-size-18 primary-color"
        >Responsável: {{ company.proprietarioNome }}
      </span>
      <span class="font-size-14"
        ><span class="font-weight-bold">E-mail:</span>
        {{ company.proprietarioEmail }}
      </span>
      <span class="font-size-14"
        ><span class="font-weight-bold">Telefone</span>:
        {{ company.proprietarioTelefone }}
      </span>
      <span class="primary-color font-size-14"
        >Data de cadastro: 24/06/2022
      </span>
    </div>

    <!-- RIGHT INFO -->
    <div class="flex-column-right">
      <div class="my-flex">
        <div
          class="company_situation"
          :class="setBackground(company.statusPagamento)"
        >
          {{ setText(company.statusPagamento) }}
        </div>
        <img src="@/assets/flags/brasil.svg" class="flag" />
      </div>

      <div v-if="company.isBloqueado" class="my-flex">
        <div class="company_situation-block">Bloqueada</div>
      </div>

      <span class="my-flex font-size-14">Ùltimo acesso: {{ date }}</span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["company"],
  data() {
    return {};
  },

  computed: {
    date() {
      if (!this.company.dataHoraUltimaAlteracao) return null;

      const dataObj = new Date(this.company.dataHoraUltimaAlteracao);

      const dia = dataObj.getDate().toString().padStart(2, "0");
      const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
      const ano = dataObj.getFullYear().toString();

      return dia + "/" + mes + "/" + ano;
    },
  },

  methods: {
    selectCompany() {
      let userStore = this.$store.getters.userData;

      let userData = {
        ...userStore,
        idCompany: this.company.idEmpresa,
      };

      this.$store.dispatch("setUser", userData);
      this.$router.push("/main/home");
    },
    
    setBorder(status) {
      if (this.company.isBloqueado) return "border-expired";
      switch (status) {
        case 0:
          return "border-expired";
        case 1:
          return "border-free";
        case 2:
          return "border-active";
      }
    },

    setBackground(status) {
      switch (status) {
        case 0:
          return "background-expired";
        case 1:
          return "background-free";
        case 2:
          return "background-active";
      }
    },

    setText(status) {
      switch (status) {
        case 0:
          return "Vencido";
        case 1:
          return "Free";
        case 2:
          return "Ativo";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  display: flex;
  justify-content: space-between;
}
.main-box:hover {
  opacity: 0.8;
}

.border-expired {
  border-left: 7px solid #b00020;
}
.border-free {
  border-left: 7px solid #3c5ca7;
}
.border-active {
  border-left: 7px solid #1d8527;
}

.background-expired {
  background: #b00020;
}
.background-free {
  background: #3c5ca7;
}
.background-active {
  background: #1d8527;
}

.my-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-column-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.flex-column-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.company_situation {
  padding: 2px 32px;
  border-radius: 4px;
  margin-right: 18px;
  color: white;
}
.company_situation-block {
  padding: 2px 32px;
  border-radius: 4px;
  color: white;
  background-color: $feedback-color-warning-pure;
  width: min-content;
}
.flag {
  width: 30px;
  height: 30px;
  border-radius: 10px;
}

.primary-color {
  color: $brand-color-primary-pure;
}
</style> 