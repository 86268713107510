var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(_vm.hasPreFilters)?_c('v-col',{staticClass:"mr-6",attrs:{"cols":"12","md":"4"}},_vm._l((_vm.preFilters),function(item){return _c('combo-box',{key:item.idEnterprisePriceGroupDefaultFilter,attrs:{"title":_vm.$t('TXT_SELECT_A') + item.fieldName,"items":item.values,"text":'description',"loading":_vm.service.getAllFilterGroups.isLoading,"disabled":_vm.service.getAllFilterGroups.isLoading ||
            _vm.service.calculateProducts.isLoading ||
            _vm.service.listPriceGroups.isLoading ||
            _vm.service.calculateProducts.isLoading ||
            item.values.length == 0,"multiple":false,"hideIcon":""},on:{"input":function($event){return _vm.selectPreFilter($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})}),1):_vm._e(),_c('v-col',{staticClass:"mr-6",attrs:{"cols":"12","md":"4"}},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_SELECT_PRICING'),expression:"'TXT_SELECT_PRICING'"}]}),_c('v-combobox',{staticClass:"mt-2",attrs:{"items":_vm.priceGroups,"loading":_vm.service.listPriceGroups.isLoading,"disabled":_vm.service.calculateProducts.isLoading || !_vm.isAllPreFiltersSelected,"item-text":"name","item-value":"filter","dense":"","outlined":"","append-icon":"mdi-chevron-down","hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-row',[(
                  _vm.isAllPreFiltersSelected &&
                  !_vm.service.listPriceGroups.isLoading
                )?_c('create-pricing-modal',{attrs:{"pre-filters":_vm.generateLockedFilters,"disabled":_vm.service.getAllFilterGroups.isLoading ||
                  _vm.service.calculateProducts.isLoading ||
                  _vm.service.listPriceGroups.isLoading ||
                  _vm.service.calculateProducts.isLoading,"teste":'teste1111'},on:{"submit":_vm.fetchPriceGroups}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.selectedPriceGroup),callback:function ($$v) {_vm.selectedPriceGroup=$$v},expression:"selectedPriceGroup"}})],1),(_vm.showCalculateToPeralta)?_c('prd-btn-loading',{staticClass:"mt-7 mr-4",attrs:{"title":_vm.$t('TXT_CALCULATE_PRICES'),"disabled":_vm.selectedPriceGroup
            ? _vm.selectedPriceGroup.archive
            : null ||
              _vm.service.getAllFilterGroups.isLoading ||
              _vm.service.calculateProductsRules.isLoading ||
              _vm.service.listPriceGroups.isLoading ||
              _vm.service.calculateProducts.isLoading,"is-loading":_vm.service.getAllFilterGroups.isLoading ||
          _vm.service.calculateProducts.isLoading ||
          _vm.service.listPriceGroups.isLoading ||
          _vm.service.calculateProducts.isLoading,"outlined":""},on:{"click":_vm.calculatePrice}}):_vm._e(),_c('prd-btn-loading',{staticClass:"mt-7",attrs:{"title":_vm.$t('TXT_CALCULATE_RULES'),"disabled":_vm.service.getAllFilterGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading ||
          _vm.service.listPriceGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading,"is-loading":_vm.service.getAllFilterGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading ||
          _vm.service.listPriceGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading,"outlined":""},on:{"click":_vm.calculateRules}})],1),(_vm.service.getAllFilterGroups.error)?_c('div',{staticClass:"color-error"},[_vm._v(" "+_vm._s(this.$i18n.t("TXT_ERROR_FIND_PRICING"))+": "+_vm._s(_vm.service.getAllFilterGroups.error)+" ")]):_vm._e(),_c('h2',{directives:[{name:"t",rawName:"v-t",value:('TXT_FILTER'),expression:"'TXT_FILTER'"}],staticClass:"font-size-16 font-weight-bold mt-6 mb-4"}),_c('filter-groups',{ref:"filterGroup",attrs:{"idPricingGroup":_vm.idEnterprisePriceGroups},on:{"input":function($event){_vm.projectionFilters = $event}}})],1),_c('products-list',{ref:"produtcsList",attrs:{"idEnterprisePriceGroups":_vm.idEnterprisePriceGroups,"filters":_vm.projectionFilters,"idPriceGroup":_vm.selectedPriceGroup && _vm.selectedPriceGroup.idEnterprisePriceGroups}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }