<template>
  <v-card class="pa-4">
    <h4 class="my-flex-center-align">
      <v-icon small left color="#B00020">mdi-alert-outline</v-icon>ALERTAS
    </h4>

    <div v-for="(card, index) in alertData" :key="index">
      <v-row no-gutters class="mt-6">
        <span>{{ card.infoMsg }}</span>
        <v-spacer></v-spacer>
        <v-icon color="#3C5CA7">mdi-arrow-right-bold-box</v-icon>
      </v-row>
      <span class="destaq">{{ card.infoValue }}</span>
      <div class="item-list-main">
        <div v-for="(item, index) in card.items" :key="index">
          <p>{{ item.text }}</p>
          <p>
            {{ item.typeOf }}: <span>{{ item.value }}</span>
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      alertData: [
        {
          infoMsg: "Nº de itens com margem negativa",
          infoValue: 29,
          items: [
            {
              text: "Fralda Cremer Mega Maxi G - 44 Un.",
              typeOf: "Margem",
              value: "+ 19,85%",
            },
            {
              text: "Absorvente Geriátrico Bigfral Moviment Miss Super 10 Un.",
              typeOf: "Margem",
              value: "+ 21,36%",
            },
            {
              text: "Toalha Pompom Camomila Leve 100 Unidades Pague 90 Unidades",
              typeOf: "Margem",
              value: "+ 14,85%",
            },
          ],
        },
        {
          infoMsg: "Nº de itens com variação de custo >10%",
          infoValue: 41,
          items: [
            {
              text: "Fralda Pompom Protek Jumbinho 12 Horas Rn - Com 18 Unidades",
              typeOf: "Variação",
              value: "- 9,44%",
            },
            {
              text: "Kit Toalhas Umedecidas Cremer Aloe Vera - 4 Unidades",
              typeOf: "Variação",
              value: "+ 8,85%",
            },
            {
              text: "Fralda Pompom Protek Jumbinho 12 Horas Rn - Com 36 Unidades",
              typeOf: "Variação",
              value: "+ 8,00%",
            },
          ],
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.destaq {
  font-size: 20px;
  color: #b00020;
}

span {
  font-size: 12px;
}

.item-list-main div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin: 8px 0;
  padding: 10px;
  border-left: 6px solid #b00020;

  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
  }

  p span {
    color: #b00020;
  }
}
</style>