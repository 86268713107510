<template>
  <div>
    <highcharts
      class="hc mt-4"
      :options="tab == 2 ? chartDataRecipe : chartData"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

import { setCurrency } from "@/utils/format-currency";
export default {
  props: ["yAxisTitle", "data", "tab"],
  data() {
    return {
      tabs: 2,
      
      chartData: {
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          title: {
            text: "Mês / Ano 2023",
          },
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
          },
        },

        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: this.data,
      },

      chartDataRecipe: {
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          title: {
            text: "Mês / Ano 2023",
          },
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
          },
        },

        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return setCurrency(this.y);
              },
            },
          },
        },
        series: this.data,
      },
    };
  },

  methods: {
    setCurrency,
  },
};
</script>