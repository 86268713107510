<template>
  <!-- DEPOIS EU ORGANIZO ESSA BAGUNÇA DE CÓDIGO TABLE É FOD -->
  <v-card>
    <br />
    <h4 class="ml-4 mr-4">DIRECIONADOR DE PREÇOS CONCORRENTE</h4>
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style="width: 18.75%">{{ this.product.capturas[0].nomeCaptura }}</th>
            <th style="width: 18.75%">{{ this.product.nome }}</th>
            <th style="width: 18.75%">Δ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td style="border-top-left-radius: 10px" class="item">Dose</td>
            <td>
              <div>
                <span>{{ formatVal(this.product.capturas[0].doseCompetidor, 2) }}</span>
              </div>
            </td>
            <td>
              <div>
                <span>{{ formatVal(this.product.dose, 2) }}</span>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">BRL</td>
            <td class="item">Preço kg/l</td>

            <td>{{ formatVal(this.product.capturas[0].precoQuiloCompetidor, 1) }}</td>
            <td>{{ formatVal(this.product.precoQuilo, 1) }}</td>
            <td rowspan="2" :class="{ positive: number > 0, negative: number < 0 }">
              {{ calcPercentage(this.product.capturas[0].precoQuiloCompetidor, this.product.precoQuilo) }} %
            </td>
          </tr>
          <tr>
            <td class="item">Custo/hectare</td>
            <td>{{ formatVal(this.product.capturas[0].precoCustoHectareCompetidor, 1) }}</td>
            <td>{{ formatVal(this.product.precoCustoHectare, 1) }}</td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">USD</td>
            <td class="item">Preço kg/l</td>
            <td>{{ calcDolar(this.product.capturas[0].precoQuiloCompetidor) }}</td>
            <td>{{ calcDolar(this.product.precoQuilo) }}</td>
            <td rowspan="2" :class="{ positive: number > 0, negative: number < 0 }">
              {{
                calcPercentage(calcDolarWithoutString(this.product.capturas[0].precoCustoHectareCompetidor),
                  calcDolarWithoutString(this.product.precoCustoHectare))
              }} %
            </td>
          </tr>
          <tr>
            <td class="item">Custo/hectare</td>
            <td>{{ calcDolar(this.product.capturas[0].precoCustoHectareCompetidor) }}</td>
            <td>{{ calcDolar(this.product.precoCustoHectare) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      itemsUfBrandSemCap: [],
      isEdit: true,
      number: 10,
      number2: -10,
    };
  },
  methods: {
    calculateCapInt(val1, val2) {
      return ((val1 / val2) - 1)
    },
    calcDolar(val) {
      return (val / 5.2).toFixed(1).toString().replace(".", ",");
    },
    calcDolarWithoutString(val) {
      return (val / 5.2).toFixed(2);
    },
    formatVal(val, decimalPlaces) {
      return (val).toFixed(decimalPlaces).toString().replace(".", ",");
    },
    calcPercentage(val, val2) {
      return (((val / val2) - 1) * -100).toFixed(0).toString().replace(".", ",");
    },
  },
  beforeMount() {
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr th:first-child {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(2) {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(3) {
      border-top-left-radius: 10px;
    }

    tr th:nth-child(6) {
      border-top-right-radius: 10px;
    }

    tr th {
      background-color: #3c5ca7;
      color: white;
      border: 2px solid white;
      padding: 5px;
    }
  }

  td {
    padding: 8px;
    border: 2px solid white;
    text-align: center;
  }

  tbody {
    td {
      background-color: #f5f5f5;
    }

    .currency {
      writing-mode: vertical-rl;
      background-color: #3c5ca7;
      color: white;
      font-weight: bold;
      width: 8px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .item {
      background-color: #cccccc;
      font-weight: bold;
    }

    .item:first-child {
      background-color: #cccccc;
      font-weight: bold;
    }
  }
}

.hidden-cell {
  background-color: transparent;
  border: 1px solid transparent;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
</style>