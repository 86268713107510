<template>
  <div>
    <v-card v-for="(card, index) in data" :key="index" class="pa-4 mb-4">
      <h4 class="mb-8 my-flex-center-align">
        <v-icon small left color="#3c5ca7">{{ card.icon }}</v-icon
        >{{ card.title }}
      </h4>

      <v-row no-gutters align="center">
        <v-icon color="#37B252" left small>mdi-arrow-down-bold-outline</v-icon>
        <span>{{ card.enterMsg }}</span>
        <v-spacer></v-spacer>
        <v-icon color="#3C5CA7">mdi-arrow-right-bold-box</v-icon>
      </v-row>
      <span class="price-destaq upper">{{ card.enterValue }}</span>

      <v-row no-gutters align="center" class="mt-8">
        <v-icon color="#B00020" left small>mdi-arrow-up-bold-outline</v-icon>
        <span>{{ card.outterMsg }}</span>
        <v-spacer></v-spacer>
        <v-icon color="#3C5CA7">mdi-arrow-right-bold-box</v-icon>
      </v-row>
      <span class="price-destaq down">{{ card.outterValue }}</span>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          title: "MUDANÇAS DE PREÇO",
          enterMsg: "Nº de itens com preço entrando hoje",
          outterMsg: "Nº de itens com preço saindo hoje",
          icon: "mdi-chart-timeline-variant-shimmer",
          enterValue: 32,
          outterValue: 56,
        },
        {
          title: "OPORTUNIDADES",
          enterMsg: "Nº de itens com oportunidade de aumento de LUCRO",
          outterMsg: "Nº de itens com oportunidade de aumento de MARGEM",
          icon: "mdi-currency-usd",
          enterValue: 43,
          outterValue: 17,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
span {
  font-size: 12px;
  font-weight: 500;
}

.price-destaq {
  font-size: 20px;
}

.upper {
  color: #1d8527;
}

.down {
  color: #b00020;
}
</style>