<template>
  <v-row class="ma-0">
    <v-col class="pa-0" cols="12" sm="12" md="5" style="background: white">
      <!-- <router-view class="py-15"/> -->
      <div class="login-content">
        <router-view />
      </div>
    </v-col>

    <v-col cols="12" sm="12" md="7" class="login-background hidden-sm-and-down">
      <div
        height="100vh"
        class="d-flex fill-height flex-column justify-space-between"
      >
        <div class="pa-16">
          <h1 class="slogan">
            {{ $t("TXT_FRASE1") }}
          </h1>
        </div>
        <div class="d-flex justify-end">
          <div>
            <span>{{ $t("TXT_FRASE2") }}</span>
            <a href="https://neogrid.com/br/solucoes/predify" target="_blank">
              <v-img
                src="@/assets/logo_neogrid_white.png"
                width="192px"
                height="77px"
              />
            </a>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "loginArea",
  created() {
    if (this.$route.path == "/login") this.$router.push("/login/sign-in");
  },
};
</script>

<style lang="scss" scoped>
.login-content {
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.login-background {
  background: url("@/assets/arrows_predify.png") no-repeat;
  background-color: $brand-color-primary-dark;
  background-position-y: bottom;
  background-position-x: left;
  background-size: 70%;
  color: $brand-color-primary-light;

  .slogan {
    text-align: center;
    font-size: $font-size-md;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
  }

  span {
    text-align: right;
  }
}
</style>