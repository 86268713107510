import axios from "axios";
import store from "@/store";

function GetURL() {
  if (process.env.NODE_ENV === "development")
    return "http://localhost:5162/"
  else if (window.location.host.includes("appv3.predify.me"))
    return "https://hom-service.predify.me/"
  else if (window.location.host.includes("monitor-vue.predify.me"))
    return "https://mon-service.predify.me/"
  else if (window.location.host.includes("pre-prod-vue.predify.me"))
    return "https://pre-prod-service.predify.me/"
  return "https://web-service.predify.me/"
}

const API_URL = GetURL()
//     process.env.NODE_ENV === "development"
// ? "http://localhost:5162/"
// : (window.location.host.includes("appv3.predify.me") ? "https://hom-service.predify.me/"
// : (window.location.host.includes("monitor-vue.predify.me") ? "https://mon-service.predify.me/"
// : "https://web-service.predify.me/"));

const API_URL_UPL = "https://apiuplpredify.azurewebsites.net/";
const API_URL_HOME_20 = "https://predifyhom20.azurewebsites.net/";

process.env.NODE_ENV === "development"
  ? "https://apppredifydevapi.azurewebsites.net/"
  : (window.location.host.includes("appv3.predify.me") ? "https://apppredifydevapi.azurewebsites.net/"
    : "https://web-service.predify.me/");
// const API_URL = "https://pricego-service.predify.me/";

const api_v1 = "api/v1/";

var axiosInstance = axios.create({
  baseURL: API_URL,
});

var axiosRaw = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const auth = store.getters.userData;
  if (auth) config.headers["Authorization"] = "Bearer " + auth.token;
  return config;
});

//cria axios personalizado
export { axiosRaw, api_v1, API_URL, API_URL_UPL, API_URL_HOME_20 };

export default axiosInstance;
