<template>
  <v-card class="pa-4 pt-0" :loading="isLoading" :disabled="isLoading">
    <v-row no-gutters class="pt-4 mb-4">
      <!-- DATA PICKER -->
      <v-col cols="12" sm="4" md="3" class="mr-6">
        <span>
          <label v-t="'TXT_REFERENCE_PERIOD'"></label>
        </span>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          ref="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              v-bind="attrs"
              v-on="on"
              :disabled="searchLoading && productGroupLoading"
              :label="$t('TXT_SELECT_PERIOD')"
              :placeholder="$t('TXT_SELECT_PERIOD')"
              hide-details
              outlined
              single-line
              dense
              readonly
              class="input-datapicker mt-1"
            >
              <template v-slot:append>
                <v-icon class="append-calendar" color="white" small>
                  mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <!-- TODO - properly use user's locale -->
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            range
            :min="minDay"
            :max="maxDay"
          >
            <v-spacer></v-spacer>

            <v-btn
              text
              color="error"
              @click="menu = false && dateRangeText == null"
              v-t="'TXT_CANCEL'"
            ></v-btn>
            <v-btn outlined color="primary" @click="setDate">Reset</v-btn>

            <v-btn
              color="primary"
              @click="$refs.menu.save(date)"
              v-t="'TXT_OK'"
            ></v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <label>Grupo de produtos</label>
        <v-combobox
          v-model="selectedPricingGroup"
          :items="productGroups"
          @change="products = []"
          :disabled="productGroupLoading"
          :loading="productGroupLoading"
          item-text="description"
          item-value="idMonitoringGroup"
          append-icon="mdi-chevron-down"
          class="mt-1"
          dense
          outlined
          hide-details
        />
      </v-col>
    </v-row>

    <v-row no-gutters align="center">
      <v-col>
        <label v-t="'TXT_PRODUCTS'"></label>
        <v-autocomplete
          v-model="products"
          :items="items"
          :search-input.sync="search"
          :loading="searchLoading"
          :disabled="searchLoading"
          :label="$t('TXT_SEARCH_2')"
          @change="selectedPricingGroup = null"
          item-text="getDescription"
          append-icon="mdi-chevron-down"
          single-line
          hide-details
          multiple
          return-object
          outlined
          dense
          deletable-chips
          small-chips
          cache-items
          class="mt-2 mr-4"
        >
          <!--selected item -->
          <template v-slot:selection="{ item }">
            <v-chip
              @click:close="removeProduct(item)"
              close
              small-chips
              dark
              color="#E0E0E0"
            >
              <span style="color: black">
                {{ item.getDescription }}
              </span>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <prd-btn
        @click="searchProducts"
        :disabled="
          (searchLoading && productGroupLoading) ||
          (!selectedPricingGroup && products.length == 0)
        "
        :title="'Ver resultados'"
        class="mt-7"
      />
    </v-row>
  </v-card>
</template>

<script>
import service from "@/service/predimonitor-V2/index.js";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const Service = new service();
export default {
  components: { PrdBtn },
  data() {
    return {
      searchLoading: false,
      productGroupLoading: false,
      isLoading: false,
      // data picker vars
      menu: false,
      date: [],
      minDay: null,
      maxDay: null,

      products: [],
      productGroups: [],
      items: [],
      search: "",
      selectedPricingGroup: null,
    };
  },

  computed: {
    dateRangeText() {
      return this.date.join("  ~  ");
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    search() {
      if (!this.searchLoading) {
        var startTimer = (scope) => {
          scope.searchProductTimer = setTimeout(function () {
            var searching = (scope) => {
              var searchExp = scope.search;
              scope.searchLoading = true;

              let data = {
                idCompany: scope.idCompany,
                page: 1,
                recordsPerPage: 100000,
                filters: {
                  description: scope.search,
                },
              };

              Service.getProductList(data)
                .then((res) => {
                  scope.items = res.data.result.records;
                  scope.searchLoading = false;
                  if (searchExp != scope.search) searching(scope);
                })
                .catch(() => {
                  scope.searchLoading = false;
                });
            };
            searching(scope);
            scope.searchProductTimer = null;
          }, 1000);
        };

        if (this.searchProductTimer == null) {
          startTimer(this);
        } else {
          clearTimeout(this.searchProductTimer);
          startTimer(this);
        }
      }
      return;
    },
    date() {
      // Set the resource range limit
      let dateResource = this.$store.getters.resources;
      let maxRange;

      let filteredDateResource = dateResource.filter((item) =>
        item.startsWith("predimonitor.days_range_")
      );

      if (filteredDateResource.length != 0) {
        maxRange = filteredDateResource.sort((a, b) => {
          const numA = parseInt(a.split("_")[2]);
          const numB = parseInt(b.split("_")[2]);
          return numB - numA;
        });
        maxRange = parseInt(maxRange[0].split("_").pop());
      } else {
        maxRange = 30;
      }
      this.minDay = this.date[0];

      var baseDay = new Date(this.date[0]);
      var actualDay = new Date();

      baseDay.setDate(baseDay.getDate() + maxRange);

      if (
        baseDay.setDate(baseDay.getDate()) >
        actualDay.setDate(actualDay.getDate())
      ) {
        this.maxDay = actualDay.toISOString().slice(0, 10);
      } else {
        this.maxDay = baseDay.toISOString().slice(0, 10);
      }
    },
  },

  methods: {
    setDate() {
      this.minDay = null;
      this.maxDay = null;
      var startDay = new Date();
      startDay.setDate(startDay.getDate() - 7);
      this.minRange = startDay.toISOString().slice(0, 10);
      this.maxDay = new Date().toISOString().slice(0, 10);

      this.date[0] = this.minRange;
      this.date[1] = new Date().toISOString().slice(0, 10);
    },

    removeProduct(item) {
      const index = this.products.indexOf(item);
      if (index >= 0) this.products.splice(index, 1);
    },

    async searchProducts() {
      this.isLoading = true;
      this.$emit("setLoading", true);
      let productsSearched = [];

      if (!this.selectedPricingGroup) {
        for (const el of this.products) {
          let request = {
            end_date: this.date[1],
            idCompany: this.idCompany,
            idMonitoringGroup: 0,
            idMonitoringItem: el.idMonitoringItem,
            includeIgnored: true,
            search_type: 1, //1- produto 3- grupo
            start_date: this.date[0],
          };

          const res = await Service.searchV3(request);
          productsSearched.push(res.data.answer.products[0]);
        }

        this.$emit("searchProducts", productsSearched);
        this.isLoading = false;
        this.$emit("setLoading", false);
      } else {
        let request = {
          end_date: this.date[1],
          idCompany: this.idCompany,
          idMonitoringGroup: this.selectedPricingGroup.idMonitoringGroup,
          idMonitoringItem: 0,
          includeIgnored: true,
          search_type: 3, //1- produto 3- grupo
          start_date: this.date[0],
        };

        const res = await Service.searchV3(request);

        this.$emit("searchProducts", res.data.answer.products);

        this.isLoading = false;
        this.$emit("setLoading", false);
      }
    },

    getProductGroups() {
      this.productGroupLoading = true;
      Service.getProductGroups(this.idCompany)
        .then((res) => {
          console.log("Resposta de buscar grupo de precificação", res);
          this.productGroups = res.data.result;
          this.productGroupLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.productGroupLoading = false;
        });
    },

    cleanProducts() {
      console.log("aqui");
      this.products = [];
    },
  },
  created() {
    this.setDate();
    this.getProductGroups();
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>