<template>
  <v-card class="pa-4 mt-4 mb-6">
    <v-expansion-panels :disabled="isLoading" accordion flat multiple>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="ma-0 pa-0"
          style="pointer-events: none"
          hide-actions
        >
          <v-row class="ma-0 pa-0" justify="space-between" align="center">
            <div style="display: flex; align-items: center">
              <label class="card-subtitle">PRODUTOS</label>
              <v-tooltip right color="#F5F5F5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: #000">Alguma informação</span>
              </v-tooltip>
            </div>
            <div class="expansion-panel-actions">
              <v-icon
                :class="{ rotateIcon: rotateIcon }"
                :disabled="isLoading"
                @click="rotateIcon = !rotateIcon"
                style="pointer-events: all"
                large
                >mdi-chevron-down</v-icon
              >
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <!-- SKELETON LOADER -->
          <div v-show="isLoading">
            <v-skeleton-loader
              class="mx-auto mt-2"
              type="card"
              height="50px"
              v-for="(item, i) in 5"
              :key="i"
            ></v-skeleton-loader>
          </div>

          <v-expansion-panels
            v-show="!isLoading"
            class="results-card mt-n2"
            flat
          >
            <v-expansion-panel
              class="mb-2"
              v-for="(product, IdProduct) in products"
              :key="IdProduct"
            >
              <v-expansion-panel-header
                color="#f5f5f5"
                class="ma-0 pa-0 px-3"
                hide-actions
                style="pointer-events: none"
              >
                <div class="product-results">
                  <v-col style="max-height: 60px; overflow: hidden">
                    <a
                      :href="product.productLink"
                      target="_blank"
                      style="pointer-events: all; color: black"
                      class="ma-0 pa-0 font-weight-bold"
                    >
                      {{ product.productName }}
                    </a>
                  </v-col>

                  <div class="pr-5" style="display: flex; gap: 0.5rem">
                    <p class="ma-0 pa-0 font-weight-bold" style="display: flex">
                      <v-icon class="mr-1" color="#37B352" small
                        >mdi-arrow-up-bold-outline</v-icon
                      >{{ product.maxPrice.toFixed(2) }}
                    </p>
                    <p class="ma-0 pa-0 font-weight-bold" style="display: flex">
                      <v-icon class="mr-1" color="#3C5CA7" small
                        >mdi-minus-circle-outline</v-icon
                      >{{ product.avgPrice.toFixed(2) }}
                    </p>
                    <p class="ma-0 pa-0 font-weight-bold" style="display: flex">
                      <v-icon class="mr-1" color="#B00020" small
                        >mdi-arrow-down-bold-outline</v-icon
                      >{{ product.minPrice.toFixed(2) }}
                    </p>
                  </div>

                  <div style="display: flex; gap: 1rem">
                    <p
                      v-if="product.ean"
                      class="ma-0 pa-0"
                      style="opacity: 0.6"
                    >
                      GTIN: {{ product.ean }}
                    </p>
                    <p
                      v-if="product.section"
                      class="ma-0 pa-0"
                      style="opacity: 0.6"
                    >
                      {{ $t("TXT_SECTION") }}: {{ product.section }}
                    </p>
                  </div>

                  <div style="display: flex; opacity: 0.6">
                    <p v-if="product.category" class="ma-0 pa-0 pl-5">
                      {{ $t("TXT_CATEGORIES") }}:
                      <span
                        v-for="(categorys, index) in product.category"
                        :key="index"
                        >{{ categorys }},&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import DashboardBamaqService from "@/service/predimonitor/dashboard-bamaq-service";

export default {
  name: "results-card",
  props: ["requestData"],
  data() {
    return {
      rotateIcon: false,
      products: [],
      isLoading: true,
    };
  },

  methods: {
    showResults() {
      this.isLoading = true;
      let data = this.requestData;

      let dashboardBamaqService = new DashboardBamaqService();
      this.$emit("onLoadingStarted");

      dashboardBamaqService.AvgInfoByProduct(data).then((res) => {
        this.products = res.data.answer;
        this.isLoading = false;
      }).finally(() => {
          this.$emit("onLoadingFinished");
        });
    },

  },

  watch: {
    requestData() {
      this.showResults();
    },
  },

  created() {
    this.showResults();
  },
};
</script>

<style lang="scss" scoped>
.results-card {
  max-height: 250px !important;
  overflow: scroll;
}

.rotateIcon {
  transform: rotate(180deg);
}

.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.product-results {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-info {
  align-items: center;
  opacity: 0.6;
  font-size: 14px;
}
</style>

  