<template>
  <v-container fluid>
    <home-vem v-if="idCompany == 3026" />
    <home-main v-else />
  </v-container>
</template>

<script>
import HomeMain from "./home-main/home-main.vue";
import HomeVem from "./home-vem/home-vem.vue";
export default {
  name: "home-container",
  components: { HomeMain, HomeVem },
  data() {
    return {};
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
};
</script>