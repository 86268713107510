<template>
  <v-card class="pa-4" id="external-var">
    <v-row no-gutters>
      <h4 style="font-size: 14px">VARIÁVEIS EXTERNAS - FINANCEIRAS</h4>
      <v-spacer></v-spacer>

      <v-icon color="#3C5CA7">mdi-dots-vertical</v-icon>
    </v-row>

    <v-row no-gutters class="my-6">
      <v-col md="4">
        <label> Filtrar por data</label>
        <v-menu
          ref="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="input-datapicker mt-1"
              hide-details
              outlined
              single-line
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:append>
                <v-icon class="append-calendar" color="white" small>
                  mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <!-- TODO - properly use user's locale -->
          <v-date-picker v-model="date" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="error" v-t="'TXT_CANCEL'"></v-btn>
            <v-btn outlined color="primary">Reset</v-btn>
            <v-btn
              color="primary"
              @click="$refs.menu.save(date)"
              v-t="'TXT_OK'"
            ></v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div style="display: flex">
      <highcharts class="mt-6" :options="chartData"></highcharts>
      <highcharts class="mt-6" :options="chartData"></highcharts>
    </div>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
export default {
  data() {
    return {
      chartData: {
        chart: {
          zoomType: "x",
          height: 250,
        },
        title: {
          text: "",
        },

        xAxis: {
          type: "datetime",
          title: {
            text: "Período",
          },
        },
        yAxis: {
          title: {
            text: "Valor (R$)",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        series: [
          {
            type: "area",
            name: "USD to EUR",
            data: [
              [1262304000000, 0.7537],
              [1262563200000, 0.6951],
              [1262649600000, 0.6925],
              [1262736000000, 0.697],
              [1262822400000, 0.6992],
              [1262908800000, 0.7007],
              [1263168000000, 0.6884],
              [1263254400000, 0.6907],
              [1263340800000, 0.6868],
              [1263427200000, 0.6904],
              [1263513600000, 0.6958],
              [1263772800000, 0.696],
              [1263859200000, 0.7004],
              [1263945600000, 0.7077],
              [1264032000000, 0.7111],
              [1264118400000, 0.7076],
              [1264377600000, 0.7068],
              [1264464000000, 0.7101],
              [1264550400000, 0.7107],
              [1264636800000, 0.7144],
              [1264723200000, 0.7161],
              [1264982400000, 0.7189],
              [1265068800000, 0.7176],
              [1265155200000, 0.7152],
              [1265241600000, 0.7223],
              [1265328000000, 0.7305],
              [1265587200000, 0.7314],
              [1265673600000, 0.7268],
              [1265760000000, 0.7279],
              [1265846400000, 0.7291],
            ],
          },
        ],
      },

      data: [],
      date: null,
    };
  },
  methods: {
    async getChartData() {
      const req = await fetch(
        "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v10.3.3/samples/data/usdeur.json"
      );
      this.data = req.json();
    },
  },
};
</script>

<style lang="scss">
#external-var {
  .append-pencil {
    background: #3c5ca7;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .append-minus {
    background: red;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .input-datapicker .v-input__app end-inner {
    margin-top: 0px !important;
  }

  .append-calendar {
    height: 40px;
    width: 40px;
    background: #3c5ca7;
    margin-right: -11px;
    border-radius: 0px 4px 4px 0px;
    color: white;
  }
}
</style>