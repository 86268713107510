<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadCrumbs"
        :title="$t('TXT_PRODUCTS_IMPORT_UPPERCASE')"
      />
      <v-spacer></v-spacer>
      <prd-btn
        v-if="fileResultsComponent"
        @click.native="backFunction"
        :title="$t('TXT_BACK')"
        :icon="'mdi-arrow-left'"
        :smallIcon="true"
      />
    </v-row>

    <import-file
      v-if="!fileResultsComponent"
      @uploadFile="sendFileResults"
      @showSnackbar="showSnackbar"
    />

    <file-results
      v-show="fileResultsComponent"
      :fileResults="fileResults"
      :fileName="fileName"
      :archiveType="archiveType"
      :delimiterType="delimiterType"
      @showSnackbar="showSnackbar"
      @cleanData="backFunction"
    />

    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import ImportFile from "./components/import-file.vue";
import FileResults from "./components/file-results.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { titleBreadCrumbsComponent, ImportFile, FileResults, PrdBtn },
  name: "file-import-main",
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_FILE_IMPORT"),
        },
      ],

      fileResultsComponent: false,
      fileResults: [],

      archiveType: null,
      delimiterType: null,
      fileName: null,
      cleanData: false,

      snackbar: {
        snackbar: false,
        snackbarColor: "#3c5ca7",
        snackbarText: null,
      },
    };
  },

  methods: {
    // Send the result of import file to component file-results
    sendFileResults(data, fileName, archiveType, delimiterType) {
      this.archiveType = archiveType;
      this.delimiterType = delimiterType
      this.fileName = fileName;
      this.fileResults = data;
      this.fileResultsComponent = true;
    },

    backFunction() {
      this.fileResults = [];
      this.archiveType = null;
      this.fileName = null;
      this.fileResultsComponent = false;
      this.cleanData = true;
    },

    showSnackbar(snackbar) {
      this.snackbar = snackbar;
    },
  },

  watch: {
    // Only change the breadcrumbs
    fileResultsComponent() {
      if (!this.fileResultsComponent) {
        this.breadCrumbs = [
          {
            text: this.$i18n.t("TXT_FILE_IMPORT"),
          },
        ];
      } else {
        this.breadCrumbs = [
          {
            text: this.$i18n.t("TXT_FILE_IMPORT"),
          },
          {
            text: this.$i18n.t("TXT_RESULT"),
          },
        ];
      }
    },
  },
};
</script>