<template>
  <div>
    <highcharts class="hc mt-4" :options="chartData"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";

// Import the "xrange" module
import xrangeInit from "highcharts/modules/xrange";
xrangeInit(Highcharts);

// Export data in PNG, JPEG, PDF, SVG, print, and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);
import ServiceUPL from "@/service/upl/index";
// Export data as CSV, XLS, and change visualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

export default {
  props: ["product"],
  data() {
    return {
      dados: [],
      service: new ServiceUPL(),
      chartData: {
        chart: {
          type: "xrange",
        },
        title: {
          text: "Peso Variáveis",
        },
        xAxis: {
          type: "int",
        },
        yAxis: {
          title: {
            text: "",
          },
          categories: ["Interação 2 dolar", "Forex dollar", "Custo(Cogs)"],
          reversed: true,
        },
        series: [
          {
            name: "Pesos",
            // pointPadding: 0,
            // groupPadding: 0,
            borderColor: "gray",
            pointWidth: 20,
            data: [
            ],
            dataLabels: {
              enabled: true,
            },
          },
        ],
      },
    };
  },
  async beforeMount() {
    if (this.product != null) {
      await this.service.getGraficoPesoVariavel(this.product.nome).then((res) => {
        this.dados = res.data;
        console.log(this.dados);
        this.chartData.yAxis.categories = this.dados.categories;
        this.chartData.series[0].data = this.dados.data;
      });
    }
  }
};
</script>