<template>
  <div>
    <v-card
      class="ma-auto transparent rounded-0 login-predify"
      width="400px"
      elevation="0"
    >
      <p class="font-weight-bold margin-bottom-30 login-form-title">
        QUE BOM QUE VOCÊ CHEGOU!
      </p>
      <p
        class="font-weight-normal margin-bottom-30 mx-0 mt-0"
        style="font-size: 14px; color: var(--white)"
      >
        Complete seu cadastro para que possamos te auxiliar ainda mais nesse seu
        processo de precificação inteligente
      </p>

      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <input
          type="text"
          class="py-2 ma-0 form-input"
          placeholder="CARGO/FUNÇÃO NA EMPRESA"
          v-model="position"
        />
      </v-row>

      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <input
          type="text"
          class="py-2 ma-0 form-input"
          placeholder="SEGMENTO DA EMPRESA"
          v-model="companySegment"
        />
      </v-row>

      <v-row
        class="mx-0 mt-0 margin-bottom-30 pa-0 align-center login-input-row"
      >
        <input
          type="text"
          class="py-2 ma-0 form-input"
          placeholder="LOCAL DA EMPRESA"
          v-model="companyLocal"
        />
      </v-row>

      <v-row
        class="mx-0 margin-bottom-30"
        :class="qtyEmployee ? 'margin-top-30' : 'mt-0'"
      >
        <v-select
          :items="qtyEmployeeList"
          label="NÚMERO DE FUNCIONÁRIOS DA EMPRESA"
          placeholder="NÚMERO DE FUNCIONÁRIOS DA EMPRESA"
          v-model="qtyEmployee"
          class="ma-0 pa-0 rounded-sm"
          hide-details
          dense
        >
          <template v-slot:append>
            <v-icon small color="white">$arrowDown</v-icon>
          </template>
        </v-select>
      </v-row>

      <v-row
        class="mx-0 margin-bottom-30"
        :class="howDidYouFindUs ? 'margin-top-30' : 'mt-0'"
      >
        <v-select
          :items="howDidYouFindUsList"
          v-model="howDidYouFindUs"
          label="COMO NOS CONHECEU?"
          placeholder="COMO NOS CONHECEU?"
          class="ma-0 pa-0 rounded-sm"
          hide-details
          dense
        >
          <template v-slot:append>
            <v-icon small color="white">$arrowDown</v-icon>
          </template>
        </v-select>
      </v-row>

      <v-row
        class="mx-0 margin-bottom-30"
        :class="companyObjective ? 'margin-top-30' : 'mt-0'"
      >
        <v-select
          :items="companyObjectiveList"
          v-model="companyObjective"
          label="OBJETIVO AO UTILIZAR O PREDIFY"
          placeholder="OBJETIVO AO UTILIZAR O PREDIFY"
          class="ma-0 pa-0 rounded-sm"
          hide-details
          dense
          multiple
        >
          <template v-slot:append>
            <v-icon small color="white">$arrowDown</v-icon>
          </template>
        </v-select>
      </v-row>

      <v-row
        class="mx-0 margin-bottom-30"
        :class="couldWeContactTheCompany ? 'margin-top-30' : 'mt-0'"
      >
        <v-select
          :items="couldWeContactTheCompanyList"
          v-model="couldWeContactTheCompany"
          label="GOSTARIA QUE ENTRÁSSEMOS EM CONTATO?"
          placeholder="GOSTARIA QUE ENTRÁSSEMOS EM CONTATO?"
          class="ma-0 pa-0 rounded-sm"
          hide-details
          dense
        >
          <template v-slot:append>
            <v-icon small color="white">$arrowDown</v-icon>
          </template>
        </v-select>
      </v-row>

      <btn :title="'CONCLUIR'" @click.native="finisheSignUp()" />

      <v-row class="mx-0 mt-0 align-center">
        <router-link
          to="/main"
          style="color: var(--white); text-decoration: none"
        >
          PREENCHER MAIS TARDE
        </router-link>
        <v-spacer />
        <router-link
          to="/main"
          style="color: var(--white); text-decoration: none"
        >
          <v-icon class="" color="var(--white)"> mdi-chevron-right</v-icon>
        </router-link>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import loginFormButton from "@/components/common/loagin-form-button.vue";

export default {
  name: "companyInformation",
  components: {
    btn: loginFormButton,
  },
  data() {
    return {
      position: null,
      companySegment: null,
      companyLocal: null,
      qtyEmployee: null,
      howDidYouFindUs: null,
      companyObjective: null,
      couldWeContactTheCompany: null,
      qtyEmployeeList: [
        "ATÉ 9",
        "10 À 49",
        "50 À 99",
        "100 À 500",
        "ACIMA DE 500",
      ],
      howDidYouFindUsList: [
        "BUSCA NO GOOGLE",
        "MÍDIAS SOCIAIS",
        "NOTÍCIAS/NEWSLETTER",
        "INDICAÇÃO",
        "OUTROS",
      ],
      companyObjectiveList: [
        "Precificar meus produtos com um preço saudável e competitivo no mercado de maneira simples",
        "Entender melhor meus custos e margens",
        "Monitorar o preço dos meus concorrentes",
        "Precificar meus produtos utilizando inteligência artificial",
      ],
      couldWeContactTheCompanyList: ["SIM", "NÃO"],
    };
  },
  methods: {
    finisheSignUp() {
      console.log("teste");
      this.$router.push("/main");
    },
  },
  created() {
    document.title = "Predify | Informações adicionais da empresa";
  },
};
</script>

<style>
.login-predify .v-select__slot label,
.login-predify .v-label {
  color: #ccc !important;
  font-size: 16px !important;
  font-weight: 500;
}

.login-predify .v-input__icon--append i,
.login-predify .v-select__selection--comma {
  color: #fff !important;
}

.login-predify .v-input__append-inner,
.login-predify .v-select__selections input {
  margin: auto !important;
  color: #fff !important;
}

.login-predify .v-input__slot {
  border-bottom: 2px solid #fff !important;
  padding: 8px 0;
}
</style>

<style scoped>
::selection {
  background-color: var(--bright-red);
  color: var(--white);
}
</style>