import { render, staticRenderFns } from "./premissa-input-table.vue?vue&type=template&id=024e3975&scoped=true&"
import script from "./premissa-input-table.vue?vue&type=script&lang=js&"
export * from "./premissa-input-table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024e3975",
  null
  
)

export default component.exports