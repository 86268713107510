<template>
  <div>
    <v-icon @click="toggleDialog" :color="$prdStyles('color-warning')">
      mdi-delete-outline
    </v-icon>
    <v-dialog v-model="showDialog" persistent max-width="650px">
      <v-card class="pa-4">
        <h4 class="mb-4">{{ this.$i18n.t("TXT_DELETE_CRAWLER") }}</h4>
        <span>
          {{ this.$i18n.t("TXT_REALLY_EXCLUDE_CRAWLER_O") }}
          <strong
            ><span class="red-color">
              {{ this.$i18n.t("TXT_EXCLUDE_UPPERCASE") }}
            </span></strong
          >
          {{ this.$i18n.t("TXT_REALLY_EXCLUDE_CRAWLER_T") }}
        </span>
        <div class="very-light-gray-color mt-4">
          <h4 class="mb-2">
            {{ this.item.description }}
          </h4>
          ID: {{ this.item.idMonitoringCrawler }} |

          {{ this.$i18n.t("TXT_PLAIN_TYPE") }}:
          {{ this.item.crawlerType | setNumberToText(typeList) }} |

          {{ this.$i18n.t("TXT_SOURCE") }}:
          {{ this.item.source | setNumberToText(sourceList) }} |

          {{ this.$i18n.t("TXT_COUNTRY") }}: {{ countryName }} |

          {{ this.$i18n.t("TXT_LANGUAGE") }}:
          {{ this.item.language | setLanguage }} |

          {{ this.$i18n.t("TXT_STATUS") }}:
          {{ this.status }}
        </div>
        <small class="red-color">{{ this.$i18n.t("TXT_UNDO_ACTION") }}</small>
        <v-row no-gutters justify="end">
          <prd-btn
            class="me-4"
            :title="this.$i18n.t('TXT_CANCEL')"
            :outlined="true"
            @click="toggleDialog"
          />
          <prd-btn
            :title="this.$i18n.t('TXT_EXCLUDE')"
            @click="deleteCrawler"
            :color="$prdStyles('color-warning')"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import CrawlerManagementService from "@/service/crawlers/crawler-config.js";
export default {
  components: { PrdBtn },
  props: ["item", "countryList", "sourceList", "typeList"],
  data() {
    return {
      service: new CrawlerManagementService(),
      showDialog: false,
      status: null,
    };
  },
  computed: {
    countryName() {
      if (!this.item.idCountry) return "-";
      return this.item.idCountry.text;
    },
  },

  methods: {
    buildRequest(item) {
      let request = {
        idMonitoringCrawler: item.idMonitoringCrawler,
        description: item.description,
        crawlerType: item.crawlerType || null,
        isOffline: item.isOffline,
        source: item.source?.value || null,
        language: item.language,
        idCountry: item.idCountry?.value || null,
        isDeleted: true,
      };
      return request;
    },
    async deleteCrawler() {
      try {
        let request = this.buildRequest(this.item);
        let response = await this.service.InsertUpdateDeleteCrawler(request);
        if (response.status != 500) {
          this.$emit("ActualizarTable");
          this.$emit(
            "showSnackbar",
            `${this.$i18n.t("TXT_SUCCESS_DELETE")} Crawler !`,
            "success"
          );
          this.toggleDialog();
        } else {
          this.$emit(
            "showSnackbar",
            `${this.$i18n.t("TXT_ERROR_DELETE")} Crawler !`,
            "error"
          );
          this.toggleDialog();
        }
      } catch (error) {
        console.log(
          this.$i18n.t("TXT_ERROR_METHOD"),
          " 'deleteCrawler':",
          error
        );
      }
    },
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
  },
  filters: {
    setNumberToText(value, list) {
      if (value == null) return "-";
      let localValue = null;
      if (value.value) {
        localValue = value.value;
      } else {
        localValue = value;
      }
      const text = list.find((el) => el.value == localValue);
      return text ? text.text : "-";
    },
    setLanguage(value) {
      return value ? value : "-";
    },
  },

  mounted() {
    if (this.item.isOffline) {
      this.status = this.$i18n.t("TXT_ONLINE");
    } else {
      this.status = this.$i18n.t("TXT_OFFLINE");
    }
  },
};
</script>

<style lang="scss" scoped>
.red-color {
  color: $feedback-color-warning-pure;
}
.very-light-gray-color {
  background-color: $neutral-color-high-light;
  padding: 16px;
}
</style>