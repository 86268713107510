<template>
  <v-container fluid>
    <titleBreadCrumbsComponent
      :title="`${$t('TXT_PRODUCT_MIX_UPPERCASE')} - APRIORI`"
      :breadCrumbs="breadCrumbs"
      :hasTooltip="false"
      :tooltipText="'tooltip tela: Mix de Produtos'"
    />
    <simulation-card
      @showSnackbar="showSnackbar"
      @simulationData="simulationDataFunction"
      :showButtonSaveSimulation="showButtonSaveSimulationBoolean"
      :idEnterpriseAprioriResultGroup="idEnterpriseAprioriResultGroupLocal"
      :isLoading="isLoading"
    />
    <table-card
      @showSnackbar="showSnackbar"
      @setLoading="isLoading = $event"
      :dataSimulation="simulationData"
      @showButtonSaveSimulation="showButtonSaveSimulation"
      @getIdEnterpriseAprioriResultGroup="getIdEnterpriseAprioriResultGroup"
    />
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import simulationCard from "./components/simulation-card.vue";
import tableCard from "./components/tables/table-card.vue";
export default {
  name: "mix-products-apriori",
  components: { titleBreadCrumbsComponent, simulationCard, tableCard },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Apriori",
        },
      ],
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
      simulationData: null,
      showButtonSaveSimulationBoolean: false,
      isLoading: false,
      idEnterpriseAprioriResultGroupLocal: null,
    };
  },
  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };
      snackbar.snackbarText = text;
      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    simulationDataFunction(type, data) {
      this.simulationData = data;
      this.simulationData.type = type;
    },

    showButtonSaveSimulation(data) {
      this.showButtonSaveSimulationBoolean = data;
    },
    getIdEnterpriseAprioriResultGroup(data) {
      this.idEnterpriseAprioriResultGroupLocal = data;
    },
  },

  created(){
    document.title = 'Mix - Apriori'
  }
};
</script>

<style>
</style>