<template>
  <div style="display: flex; flex-direction: row" class="my-flex mt-6">
    <table style="width: 20%">
      <thead>
        <tr>
          &nbsp;
        </tr>
        <tr>
          &nbsp;
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="
              text-align: center;
              padding: 8px;
              font-weight: bold;
              background-color: #ccc;
            ">
            Captura externa
          </td>
        </tr>
        <tr>
          <td style="
              text-align: center;
              padding: 8px;
              font-weight: bold;
              background-color: #ccc;
            ">
            Captura interna
          </td>
        </tr>
        <tr>
          <td style="
              text-align: center;
              padding: 8px;
              font-weight: bold;
              background-color: #ccc;
            ">
            Δ Cap. Ext. vs Int.
          </td>
        </tr>
      </tbody>
    </table>

    <table v-if="this.product.tipoPrecificacao == 'Custo' || this.product.tipoPrecificacao == 'Concorrente'"
      style="width: 80%">
      <thead>
        <tr style="background-color: #3c5ca7; color: white; text-align: center">
          <td style="padding: 4px" colspan="3">
            {{
              this.product.tipoPrecificacao != 'Custo' ? this.product.capturas[0].nomeCaptura : this.product.nome
            }}
          </td>
        </tr>
        <tr>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            BRL
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            USD
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            PESO
          </td>
        </tr>
      </thead>
      <tbody v-if="this.product.tipoPrecificacao != 'Custo'">
        <tr>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].mediaCapturaExterna }}</td>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaExterna /
            5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaExterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].mediaCapturaInterna }}</td>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaInterna /
            5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaInterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getConcorrentePercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"> {{ getConcorrentePercantage() + "%" }}</td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td style="text-align: center; padding: 8px">{{ 0 }}</td>
          <td style="text-align: center; padding: 8px">{{ (0).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ 0 }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">{{ 0 }}</td>
          <td style="text-align: center; padding: 8px">{{ (0).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ 0 }}%</td>
        </tr>
      </tbody>
    </table>

    <table v-else style="width: 80%">
      <thead>
        <tr style="background-color: #3c5ca7; color: white; text-align: center">
          <td v-if="this.product.capturas[1] != null" style="padding: 4px" colspan="3">
            {{
              this.product.capturas[0].nomeCaptura + " + " + this.product.capturas[1].nomeCaptura
            }}
          </td>
          <td v-else-if="this.produtoInterno != null" style="padding: 4px" colspan="3">
            {{
              this.product.capturas[0].nomeCaptura + " + " + this.produtoInterno.nome
            }}
          </td>
          <td v-else style="padding: 4px" colspan="3">
            {{
              this.product.capturas[0].nomeCaptura
            }}
          </td>
        </tr>
        <tr>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            BRL
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            USD
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            PESO
          </td>
        </tr>
      </thead>
      <tbody v-if="this.product.capturas[1] != null">
        <tr>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaExterna +
            this.product.capturas[1].mediaCapturaExterna).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ ((this.product.capturas[0].mediaCapturaExterna +
            this.product.capturas[1].mediaCapturaExterna) / 5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaExterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaInterna +
            this.product.capturas[1].mediaCapturaInterna).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ ((this.product.capturas[0].mediaCapturaInterna +
            this.product.capturas[1].mediaCapturaInterna) / 5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaInterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"> {{ getMisturaPercantage() + "%" }}</td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>

      <tbody
        v-if="this.product.capturas[1] != null && this.product.capturas[0].idProdutoUPL != null && this.produtoInterno != null">
        <tr>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaExterna +
            this.produtoInterno.precoQuilo).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ ((this.product.capturas[0].mediaCapturaExterna +
            this.product.capturas[1].mediaCapturaExterna) / 5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaExterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaInterna +
            this.product.capturas[1].mediaCapturaInterna).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ ((this.product.capturas[0].mediaCapturaInterna +
            this.product.capturas[1].mediaCapturaInterna) / 5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaInterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"> {{ getMisturaPercantage() + "%" }}</td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaExterna).toFixed(2) }}
          </td>
          <td style="text-align: center; padding: 8px">{{ ((this.product.capturas[0].mediaCapturaExterna) /
            5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaExterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">{{ (this.product.capturas[0].mediaCapturaInterna).toFixed(2) }}
          </td>
          <td style="text-align: center; padding: 8px">{{ ((this.product.capturas[0].mediaCapturaInterna) /
            5.20).toFixed(2) }}</td>
          <td style="text-align: center; padding: 8px">{{ this.product.capturas[0].pesoCapturaInterna }}%</td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"> {{ getMisturaPercantage() + "%" }}</td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      itemsUfBrandSemCap: [],
      produtoInterno: null,
    };
  },
  methods: {
    calculateCapInt(val1, val2) {
      return ((val1 / val2) - 1)
    },
    getMisturaPercantage() {
      if (this.product.capturas[1] != null) {
        let val1 = (this.product.capturas[0].mediaCapturaExterna + this.product.capturas[1].mediaCapturaExterna).toFixed(2)
        let val2 = (this.product.capturas[0].mediaCapturaInterna + this.product.capturas[1].mediaCapturaInterna).toFixed(2)

        return ((val1 / val2) - 1).toFixed(2) * 100;
      } else {
        let val1 = (this.product.capturas[0].mediaCapturaExterna).toFixed(2)
        let val2 = (this.product.capturas[0].mediaCapturaInterna).toFixed(2)

        return ((val1 / val2) - 1).toFixed(2) * 100;
      }
    },
    getConcorrentePercantage() {
      let val1 = (this.product.capturas[0].mediaCapturaExterna).toFixed(2)
      let val2 = (this.product.capturas[0].mediaCapturaInterna).toFixed(2)

      return ((val1 / val2) - 1).toFixed(2) * 100;
    }
  },
  async beforeMount() {
    if (this.product.capturas[0].idProdutoUPL != null) {
      await this.service.getProductById(this.product.capturas[0].idProdutoUPL).then((res) => {
        this.produtoInterno = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>