<template>
  <div class="my-flex">
    <v-card class="pa-4 left-card">
      <premissas-input v-if="this.product != null" v-bind:key="this.product.id" v-bind:product="this.product" />
    </v-card>
    <div class="rigth-card">
      <direcionadorPrecos style="height: 50%" v-bind:product="product"></direcionadorPrecos>
      <div style="display: flex; gap: 16px; height: 50%">
        <price-list style="width: 60%; height: 100%" v-bind:key="this.product.id" v-bind:product="this.product" />
        <card-info style="width: 40%; " :title="'COMENTÁRIOS'" :text="this.product.comentario" :cardType="'comentario'"
          :productId="this.product.id" />
      </div>
    </div>
  </div>
</template>

<script>
import CardInfo from "../card-info.vue";
import premissasInput from "./components/premissas-input.vue";
import PriceList from "./components/price-list.vue";
import DirecionadorPrecos from "./components/direcionador-precos.vue";
export default {
  components: { premissasInput, PriceList, CardInfo, DirecionadorPrecos },
  props: ["product"],
  data() {
    return {};
  },
  beforeMount() { },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.left-card {
  width: 50%;
}

.rigth-card {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>