var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.actions != undefined)?_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('history-button',_vm._g(_vm._b({},'history-button',attrs,false),on))]}}],null,false,2036911709),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"d-flex flex-column gap-6 pa-4"},[_c('div',{staticClass:"font-size-20 font-weight-medium"},[_vm._v(_vm._s(this.$i18n.t("TXT_ACTION_HISTORY"))+":")]),(_vm.actions.length === 0)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(this.$i18n.t("TXT_NONE_HISTORY_FOUND"))+": ")]):_c('div',{staticClass:"grid flex-column gap-2"},[_vm._l(([
            'Ação',
            'Pronto para ERP',
            'Cargo',
            'Usuário',
            'Data' ]),function(header){return [_c('span',{key:header,staticClass:"table-header pa-2"},[_vm._v(_vm._s(header))])]}),_vm._l((_vm.actions),function(ref,index){
          var action = ref.action;
          var toSend = ref.toSend;
          var companyRoleName = ref.companyRoleName;
          var userName = ref.userName;
          var date = ref.date;
return [_c('span',{key:action.toString() + index,staticClass:"pa-2"},[_c('v-icon',{class:['mr-1', _vm.resolveToColorClass(action)],attrs:{"size":16}},[_vm._v(" "+_vm._s(_vm.resolveActionToIconName(action))+" ")]),_vm._v(" "+_vm._s(_vm.resolveActionToPastVerb(action))+" ")],1),_c('div',{key:toSend.toString() + index,staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":toSend,"disabled":"","hide-details":true}})],1),_c('span',{key:companyRoleName + index,staticClass:"pa-2"},[_vm._v(_vm._s(companyRoleName))]),_c('span',{key:userName + index,staticClass:"pa-2"},[_vm._v(_vm._s(userName))]),_c('span',{key:date + index,staticClass:"pa-2"},[_vm._v(_vm._s(_vm.formatDate(new Date(date), "dd/MM/yyyy - hh:mm:ss")))])]})],2),_c('prd-btn',{staticClass:"align-self-end",attrs:{"title":"Ok"},on:{"click":function($event){_vm.dialog = false}}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }