<template>
  <div>
    <v-row no-gutters justify="center" class="mt-4">
      <v-col>
        <h4>{{ name }}</h4>
        <label>{{ subtitle }}</label>
        <v-data-table v-if="subtitle == 'FUTURE VALUE | CANAL GERAL' && name == 'DELIBERATION'
          " :headers="headers" :items="items" class="mt-4">
          <template v-slot:[`item.unitPriceListUSD`]="{ item }">
            <div v-if="!item.isEditUSD">
              {{ item.unitPriceListUSD }}
              <v-icon @click="item.isEditUSD = true" :color="$prdStyles('color-primary')" right>
                mdi-pencil-box</v-icon>
            </div>

            <div v-else class="my-flex">
              <v-text-field v-model="item.unitPriceListUSD" outlined dense hide-details style="max-width: 120px">
              </v-text-field>
              <v-icon @click="editUnitPriceDeliberation(item)" color="#1D8527">
                mdi-checkbox-outline
              </v-icon>
              <v-icon @click="item.isEditUSD = false" color="#B00020">
                mdi-close-box
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.unitPriceListBRL`]="{ item }">
            <div v-if="!item.isEditBRL">
              {{ item.unitPriceListBRL }}
              <v-icon @click="item.isEditBRL = true" :color="$prdStyles('color-primary')" right>
                mdi-pencil-box</v-icon>
            </div>

            <div v-else class="my-flex">
              <v-text-field v-model="item.unitPriceListBRL" outlined dense hide-details style="max-width: 120px">
              </v-text-field>
              <v-icon @click="editUnitPriceDeliberation(item)" color="#1D8527">
                mdi-checkbox-outline
              </v-icon>
              <v-icon @click="item.isEditBRL = false" color="#B00020">
                mdi-close-box
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.coments`]="{ item }">
            <div v-if="!item.isEditComments">
              {{ item.coments }}
              <v-icon @click="item.isEditComments = true" :color="$prdStyles('color-primary')" right>
                mdi-pencil-box</v-icon>
            </div>

            <div v-else class="my-flex">
              <v-text-field v-model="item.coments" outlined dense hide-details>
              </v-text-field>
              <v-icon @click="editUnitPriceDeliberation(item)" color="#1D8527">
                mdi-checkbox-outline
              </v-icon>
              <v-icon @click="item.isEditComments = false" color="#B00020">
                mdi-close-box
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-data-table v-else-if="subtitle == '' && name == 'INFO'
          " :headers="headers" :items="items" class="mt-4">
          <template v-slot:[`item.priceBook`]="{ item }">
            <div v-if="!item.isEditPriceBook">
              {{ item.priceBook }}
              <v-icon @click="item.isEditPriceBook = true" :color="$prdStyles('color-primary')" right>
                mdi-pencil-box</v-icon>
            </div>

            <div v-else class="my-flex">
              <v-text-field v-model="item.priceBook" outlined dense hide-details style="max-width: 120px">
              </v-text-field>
              <v-icon @click="editUnitPrice(item)" color="#1D8527">
                mdi-checkbox-outline
              </v-icon>
              <v-icon @click="item.isEditPriceBook = false" color="#B00020">
                mdi-close-box
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.keepOnList`]="{ item }">
            <div v-if="!item.isEditKeepOnList">
              {{ item.keepOnList }}
              <v-icon @click="item.isEditKeepOnList = true" :color="$prdStyles('color-primary')" right>
                mdi-pencil-box</v-icon>
            </div>

            <div v-else class="my-flex">
              <v-text-field v-model="item.keepOnList" outlined dense hide-details style="max-width: 120px">
              </v-text-field>
              <v-icon @click="editUnitPrice(item)" color="#1D8527">
                mdi-checkbox-outline
              </v-icon>
              <v-icon @click="item.isEditKeepOnList = false" color="#B00020">
                mdi-close-box
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-data-table v-else :headers="headers" :items="items" class="mt-4">
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UPLService from "@/service/upl";
export default {
  props: ["headers", "items", "name", "subtitle"],
  data() {
    return {
      service: new UPLService(),
    };
  },

  methods: {
    async editUnitPrice(item) {
      this.$emit("setLoading", true);
      await this.service.updateDeliberationInfo(item);
      this.$emit("setLoading", false);
    },
    async editUnitPriceDeliberation(item) {
      this.$emit("setLoading", true);
      await this.service.updateDeliberationDeliberation(item).then((res) => {
        this.item = res.data;
      });
      this.$emit("setLoading", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
</style>