<template>
  <v-container>
    <v-btn small class="white--text pa-3 rounded-sm margin-y-5 mt-4" color="warning"
      @click="$router.go('main/upl-main')">Voltar</v-btn>
    <new-premissas-product></new-premissas-product>
  </v-container>
</template>

<script>
import NewPremissasProduct from './componentes/novo/new-premissas-product.vue'

export default {
  components: { NewPremissasProduct },
  name: "new-product",
  data() {
    return {
      externalProducts: [],
      typeItems: [
        {
          text: "Concorente",
        },
        {
          text: "Custo",
        },
      ],

      isLoading: false,

      name: null,
      type: null,

      showInputs: false,
    };
  },

  methods: {
    addTarget() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.showInputs = true;
        this.breadCrumbs.push({ text: "outro input" });
      }, 1000);
    },

    closeInputs() {
      this.name = null;
      this.type = null;
      this.showInputs = false;
      this.breadCrumbs.pop();
    },

    backOnePage() {
    }
  },

  mounted() {
    document.title = 'PREDIFY | Inputs'
  },
  beforeMount() {
  }
};
</script>