<template>
  <v-card class="pa-4 d-flex flex-column gap-2 font-size-12" flat>
    <div class="d-flex flex-column gap-2">
      <div class="d-flex align-center gap-x-2">
        <strong v-t="'TXT_READY_TO_GO'"></strong>
      </div>
      <div class="d-flex align-center gap-2">
        <v-icon
          small
          :color="wasSentToERP ? 'success' : 'error'"
          class="bg-gray rounded-sm"
        >
          {{ wasSentToERP ? "mdi-check" : "mdi-close" }}
        </v-icon>
        {{ wasSentToERP ? $t('TXT_SENT'): $t('TXT_NOT_SENT') }}
      </div>
      <div class="color-gray-2 font-size-10" v-if="wasSentToERP"></div>
    </div>
    <div class="mt-8 d-flex flex-column gap-2">
      <div class="d-flex align-center gap-x-2">
        <strong v-t="'TXT_ACTIONS'"></strong>
        <actions-history :actions="workflow.history" />
        <progress-component
          :size="20"
          :width="3"
          v-if="service.sendPricingAction.isLoading"
        />
      </div>
      <v-radio-group
        :hide-details="true"
        class="ma-0"
        style="padding-top: 0px"
        @change="handleActionChange"
        :disabled="
          service.sendPricingAction.isLoading || service.sendPricingAction.error
        "
        :value="actionValue"
      >
        <div
          class="d-flex align-center"
          v-for="action in listAllActions()"
          :key="action"
        >
          <v-radio
            style="transform: scale(0.6)"
            class="ma-0 pa-0"
            :value="action"
            :disabled="!canUserDoAction(action)"
          />
          <v-icon
            :class="[
              'mr-1',
              canUserDoAction(action) && resolveToColorClass(action),
            ]"
            :size="16"
          >
            {{ resolveActionToIconName(action) }}
          </v-icon>
          <div>{{ resolveActionToVerb(action) }}</div>
        </div>
      </v-radio-group>
    </div>
  </v-card>
</template>
<script>
import actionsHistory from "../actions-history";
import {
  resolveActionToVerb,
  resolveToColorClass,
  resolveActionToIconName,
  listAllActions,
  ActionEnum,
} from "../../../../../actions-enum";
import AiPricingService from "@/service/ai-pricing";
import progressComponent from "@/Design_System/common/progressComponent";

export default {
  props: ["workflow", "idEnterprisePricesProjection", "productName"],
  components: { actionsHistory, progressComponent },
  data: () => ({
    service: new AiPricingService(),
    actionValue: null,
  }),
  mounted() {
    if (this.workflow?.actions?.length === 0) {
      this.actionValue = this.workflow?.lastAction?.action ?? null;
    }
  },
  methods: {
    async handleActionChange(action) {
      try {
        const result = await this.service.sendPricingAction.call(
          this.idCompany,
          this.idEnterprisePricesProjection,
          action
        );

        this.actionValue = action;
        this.$emit("workflow-override", result);
        if (action === ActionEnum.Approve) {
          this.$store.commit("approveProductRegion", {
            idEnterprisePricesProjection: this.idEnterprisePricesProjection,
            productName: this.productName,
          });
        }
      } catch (e) {
        console.error(e);
        this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_FAIL_SEND_ACTIONS")
        );
        this.actionValue = null;
      }
    },
    canUserDoAction(newAction) {
      return this.workflow?.actions?.some(({ action }) => action === newAction);
    },
    listAllActions,
    resolveActionToVerb,
    resolveActionToIconName,
    resolveToColorClass,
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    wasSentToERP() {
      return this.workflow?.lastAction?.toSend ?? null;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.color-approve {
  color: $feedback-color-success-pure;
}
.color-reject {
  color: $brand-color-secondary-pure;
}
.color-scale {
  color: $feedback-color-helper-pure;
}
.bg-gray {
  background-color: $neutral-color-high-light;
}
.color-gray-2 {
  color: $neutral-color-low-light;
}
</style>
