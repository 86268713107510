<template>
  <div>
    <div style="display: flex; flex-direction: column; gap: 8px">
      <!-- AJUSTED PRICE-->
      <elasticity-card-price
      v-if="false"
        :price="ajustedPrice"
        :demand="ajustedDemand"
        :renevue="ajustedRenevue"
        :title="$t('TXT_ADJUSTED_PRICE')"
        :tooltipText="$t('TXT_ADJUSTED_PRICE_TOOLTIP')"
        :haveRadio="false"
        cardType="elasticity"
      />

      <!-- AVG PRICE-->
      <elasticity-card-price
        :price="avgPrice"
        :demand="avgDemand"
        :renevue="avgRenevue"
        :title="$t('TXT_AVERAGE_PRICE')"
        :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_AVERAGE_PRICE')"
        :haveRadio="false"
        cardType="price"
      />

      <!-- IA PRICE Inferior limit-->
      <elasticity-card-price
        :price="iaPriceInferior"
        :demand="iaDemandInferior"
        :renevue="iaRenevueInferior"
        :title="$t('TXT_TOOLTIP_ELASTICITY_IA_INFERIOR_LIMIT_PRICE_TITLE')"
        :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_IA_LIMIT_PRICE')"
        :haveRadio="false"
        cardType="elasticity"
      />

      <!-- IA PRICE-->
      <elasticity-card-price
        :price="iaPrice"
        :demand="iaDemand"
        :renevue="iaRenevue"
        :title="$t('TXT_IA_PRICE_TITLE')"
        :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_IA_PRICE')"
        :haveRadio="false"
        cardType="elasticity"
      />

      <!-- IA PRICE Superior limit-->
      <elasticity-card-price
        :price="iaPriceSuperior"
        :demand="iaDemandSuperior"
        :renevue="iaRenevueSuperior"
        :title="$t('TXT_TOOLTIP_ELASTICITY_IA_UPPER_LIMIT_PRICE_TITLE')"
        :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_IA_LIMIT_PRICE')"
        :haveRadio="false"
        cardType="elasticity"
      />
    </div>
  </div>
</template>

<script>
import elasticityCardPrice from "@/views/main/pricing/components/products-list/product-item/region-item/subregions/elasticity-card-price.vue";
export default {
  props: ["elasticity"],
  components: { elasticityCardPrice },
  data() {
    return {};
  },

  computed: {
    ajustedPrice() {
      return this.elasticity?.ajustedPrice ?? null;
    },
    ajustedDemand() {
      return this.elasticity?.ajusted_Price_Demand ?? null;
    },
    ajustedRenevue() {
      return this.ajustedPrice * this.ajustedDemand ?? null;
    },
    // AVG CARD
    avgPrice() {
      return this.elasticity?.averagePrice ?? null;
    },
    avgDemand() {
      return this.elasticity?.averageDemand ?? null;
    },
    avgRenevue() {
      return this.avgPrice * this.avgDemand ?? null;
    },

    //IA CARD inferior limit
    iaPriceInferior() {
      return this.elasticity?.salePriceInferiorLimit ?? null;
    },
    iaDemandInferior() {
      return this.elasticity?.salePriceInferiorLimit_Demand ?? null;
    },
    iaRenevueInferior() {
      return this.iaPriceInferior * this.iaDemandInferior ?? null;
    },

    // IA CARD
    iaPrice() {
      return this.elasticity?.salePrice ?? null;
    },
    iaDemand() {
      return this.elasticity?.salePrice_Demand ?? null;
    },
    iaRenevue() {
      return this.iaPrice * this.iaDemand ?? null;
    },

    // IA CARD superior limit
    iaPriceSuperior() {
      return this.elasticity?.salePriceSuperiorLimit ?? null;
    },
    iaDemandSuperior() {
      return this.elasticity?.salePriceSuperiorLimit_Demand ?? null;
    },
    iaRenevueSuperior() {
      return this.iaPriceSuperior * this.iaDemandSuperior ?? null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>