<template>
  <v-card
    class="pa-4 font-size-12 d-flex flex-column gap-4"
    flat
    :disabled="disabled"
  >
    <div class="d-flex align-center">
      <div class="mr-2 d-flex align-center pointer" @click="$emit('select')">
        <v-radio style="transform: scale(0.6)" class="ma-0" value="MANUAL" />
        <strong v-t="'TXT_MANUAL_PRICE'"></strong>
      </div>
      <prd-action-button
        :icons="'$edit'"
        :small="true"
        @click="isEditing = true"
        v-if="!isEditing && isSelected"
      />
    </div>

    <text-field
      :title="$t('TXT_PRICE')"
      class="text gap-2-important"
      v-model="price"
      @blur="handlePriceBlur"
      :disabled="!isEditing"
      :formatOnBlur="formatNumberMoney"
    />
    <text-field
      class="text gap-2-important"
      v-model="margin"
      :formatOnBlur="formatNumberPercentage"
      :disabled="!isEditing"
    >
      {{ this.$i18n.t("TXT_MARGIN") }}
      <v-tooltip top v-if="isEditing && margin && price">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="ml-1 pointer"
            style="position: relative; bottom: 4px"
            color="warning"
            v-bind="attrs"
            v-on="on"
          >
            mdi-alert
          </v-icon>
        </template>
        <div>
          {{ this.$i18n.t("TXT_CAUTION_MANUAL_PRICE") }}
        </div>
      </v-tooltip>
    </text-field>
    <text-field
      :title="$t('TXT_COMPETITIVE_INDEX')"
      class="text gap-2-important"
      v-model="competitivityIndex"
      @blur="handleCompetitivityIndexBlur"
      :formatOnBlur="formatNumberPercentage"
      :disabled="!averagePrice || !isEditing"
    />
    <div
      class="d-flex justify-end align-center font-size-16 gap-x-4"
      v-if="isEditing"
    >
      <prd-btn
        :title="$t('TXT_CANCEL')"
        outlined
        class="px-6"
        @click="handleCancel"
      />
      <prd-btn-loading
        :title="$t('TXT_CONFIRM')"
        @click="savePriceEdit"
        :isLoading="service.overrideManualPrice.isLoading"
      />
    </div>
  </v-card>
</template>
<script>
import PrdActionButton from "@/Design_System/common/prd-action-button.vue";
import prdBtn from "@/Design_System/common/prd-btn";
import prdBtnLoading from "@/Design_System/common/prd-btn-loading";
import textField from "@/Design_System/common/text-field";
import AiPricingService from "@/service/ai-pricing";
import {
  calculateIC,
  calculatePriceFromIC,
} from "../../../../../calculate-competitivity-index";
import {
  formatNumberPercentage,
  parseNumberPercentage,
  formatNumberMoney,
  parseNumberMoney,
} from "@/utils/format-numbers";

export default {
  props: ["productRegion", "productName", "isSelected", "disabled"],
  components: {
    prdBtnLoading,
    prdBtn,
    PrdActionButton,
    textField,
  },
  data: () => ({
    price: "",
    margin: "",
    competitivityIndex: "",
    isEditing: false,

    service: new AiPricingService(),
  }),
  mounted() {
    this.resetValues();
  },
  methods: {
    formatNumberMoney,
    formatNumberPercentage(value) {
      return formatNumberPercentage(value, { fractionLength: 2 });
    },

    async savePriceEdit() {
      let manualPrice = null;
      let manualContributionMargin = null;

      if (this.price !== "") manualPrice = parseNumberMoney(this.price);
      if (this.margin !== "")
        manualContributionMargin = parseNumberPercentage(this.margin);

      if (!this.price && !this.margin) {
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_PROVIDE_PRICE")
        );
      }

      try {
        const response = await this.service.overrideManualPrice.call(
          this.idCompany,
          this.idEnterprisePricesProjection,
          manualPrice,
          manualContributionMargin
        );

        console.log("enviou do manual price", response);

        this.$emit("override-price-update", response);
        this.resetValues(response);
        this.isEditing = false;
      } catch (e) {
        console.error(e);
        return this.$store.commit("snackbar-error", e.message);
      }
    },

    resetValues(newOverride) {
      const override =
        newOverride?.price?.override || this.productRegion?.price?.override;

      if (!override?.isManual) {
        this.price = "";
        this.margin = "";
        this.competitivityIndex = "";
        return;
      }

      this.price = this.formatNumberMoney(override.manual_Price);
      this.margin = this.formatNumberPercentage(override.manual_Price_Margin);
      this.competitivityIndex = this.formatNumberPercentage(
        calculateIC(override.manual_Price, this.averagePrice)
      );
    },
    handlePriceBlur() {
      const priceNumber = parseNumberMoney(this.price);
      if (this.averagePrice) {
        this.competitivityIndex = this.formatNumberPercentage(
          calculateIC(priceNumber, this.averagePrice)
        );
      }
    },
    handleCompetitivityIndexBlur() {
      if (typeof this.averagePrice !== "number") return null;
      const competitivityIndex = parseNumberPercentage(this.competitivityIndex);
      this.price = this.formatNumberMoney(
        calculatePriceFromIC(competitivityIndex, this.averagePrice)
      );
    },
    handleCancel() {
      this.resetValues();
      this.isEditing = false;
      if (!this.productRegion?.price?.override?.isManual) {
        this.$emit("cancel");
      }
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    idEnterprisePricesProjection() {
      return this.productRegion?.price?.idEnterprisePricesProjection ?? null;
    },
    averagePrice() {
      return this.productRegion?.price.getAvgPriceMarketResult ?? null;
    },
    isSomethingLoading() {
      return (
        this.service.overrideManualPrice.isLoading ||
        this.service.overrideCancelManualPrice.isLoading
      );
    },
  },
  watch: {
    isSelected() {
      if (this.isSelected) {
        this.isEditing = true;
      } else {
        this.isEditing = false;
        this.resetValues();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.label-text {
  font-weight: bold;
}
.gap-2-important {
  column-gap: 8px !important;
  row-gap: 8px !important;
}
.pointer {
  cursor: pointer;
}
</style>