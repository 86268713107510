<template>
  <div class="mt-4">
    <v-row no-gutters align="center">
      <h4>RECEITA TOTAL</h4>
      <tooltip-informations :title="'Tooltip'" :rightIcon="true" />
      <v-spacer></v-spacer>
      <div class="group-box">
        <p class="pa-0 ma-0 teste mr-4">Vizualização:</p>
        <v-tabs v-model="tab" class="group-filters ml-n3" height="25px">
          <v-tab>Semana</v-tab>
          <v-tab>Dia</v-tab>
        </v-tabs>
      </div>
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <highcharts class="mt-6" :options="chartData"></highcharts>
      </v-tab-item>

      <v-tab-item>
        <highcharts class="mt-6" :options="chartData"></highcharts>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
exportData(Highcharts);

export default {
  components: { tooltipInformations },
  data() {
    return {
      tab: 0,
      chartData: {
        chart: {
          type: "column",
          height: 236,
        },

        title: {
          text: "",
        },

        credits: {
          enabled: false,
        },

        xAxis: {
          title: {
            text: "Periodo",
          },
          categories: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
        },
        yAxis: {
          title: {
            text: "Mil reais (R$)",
          },
        },
        legend: {
          enabled: false,
        },

        series: [
          {
            color: "#3c5ca7",
            data: [33, 25, 98, 94, 40, 29, 33],
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.group-filters {
  border-bottom: 1px solid #00000048;
}

.group-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}
</style>