<template>
  <v-expansion-panel v-if="product.tableData.length != 0">
    <product-header :product="product.tableData" :productName="product" />
    <product-body
      :product="product.tableData"
      @avgPrices="avgPrices = $event"
    />
  </v-expansion-panel>
</template>

<script>
import ProductBody from "./product-body.vue";
import ProductHeader from "./product-header.vue";
export default {
  props: ["product"],
  components: { ProductHeader, ProductBody },
  data() {
    return {
      avgPrices: null,
    };
  },
};
</script>
