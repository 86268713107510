import Vue from 'vue'

Vue.filter('setToIsoString', function (value) {
    let currency = localStorage.getItem("currency");
    if (value === null || value === undefined) return '-'
    if (value == '-') return '-'

    let rounded = (Math.trunc(value * 100) / 100).toFixed(2);
    let formated = rounded.replace(".", ",");

    return currency + ' ' + formated;
})

Vue.filter('unityToString', function (value) {
    switch (value) {
        case 0:
            return (value = "Unidade");

        case 1:
            return (value = "Unidade na caixa");

        case null:
            return (value = "-");
    }
})

