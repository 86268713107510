<template>
  <div>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent :breadCrumbs="breadCrumbs" :title="'HOME'" />
    </v-row>
    <v-row no-gutters align="center" class="mb-6">
      <v-row no-gutters align="center" class="warning-box">
        <v-icon left color="#B00020">mdi-alert-outline</v-icon>
        <span v-show="pendingApprovals" class="warning-text"
          >Existem {{ pendingApprovals }} preços pendentes de aprovação</span
        >
        <v-spacer></v-spacer>
        <prd-btn @click="showDetails" :title="'Ver detalhes'" />
      </v-row>
    </v-row>
    <suzano-infos class="mb-6" />
    <last-simulation class="mb-6" />
    <h3>VISÃO GERAL</h3>
    <general-vision-main />
  </div>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import prdBtn from "@/Design_System/common/prd-btn.vue";
import lastSimulation from "./components/last-simulation.vue";
import GeneralVisionMain from "./components/general-vision/general-vision-main.vue";
import SuzanoInfos from "./components/general-vision/components/suzano-infos.vue";

export default {
  name: "home-main",
  components: {
    titleBreadCrumbsComponent,
    prdBtn,
    lastSimulation,
    GeneralVisionMain,
    SuzanoInfos,
  },

  data() {
    return {
      breadCrumbs: [
        {
          text: "Dashboard",
        },
      ],

      pendingApprovals: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    async seekApprovals() {
      const req = await fetch("http://localhost:3000/pendingApprovation");
      const data = await req.json();

      this.pendingApprovals = data.pendingApprovation;
    },

    showDetails() {},
  },

  created() {
    this.seekApprovals();
  },
};
</script>

<style lang="scss">
.warning-text {
  color: #b00020;
  font-size: 14px;
}

.warning-box {
  border: 1px solid #b00020 !important;
  padding: 10px;
  border-radius: 5px;
}

.my-flex-center-align {
  display: flex;
  align-items: center;
}
</style>