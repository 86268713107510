<template>
  <div>
    <highcharts class="hc mt-4" :options="chartData"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
import ServiceUPL from "@/service/upl/index";

exportData(Highcharts);
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      dados: [],
      series: [],
      chartData: {
        title: {
          text: "<b>CUSTO POR HECTARE | CAPTURA EXTERNA | BRL</b>",
          align: "left",
        },

        subtitle: {
          text: "Fonte: Mq Stat |",
          align: "left",
        },

        yAxis: {
          title: {
            text: "",
          },
        },

        xAxis: {
          categories: [
          ],
        },

        legend: {
          enabled: false,
        },

        series: [

        ],
      },
    };
  },
  beforeMount() {
    if (this.product != null) {
      if (this.product.tipoPrecificacao == "Custo") {
        this.service.getGraficoCustoParaPrecificacao(this.product.nome).then((res) => {
          this.dados = res.data;
          this.series.push({
            name: this.product.nome,
            data: this.dados.values,
            color: "#3C5CA7",
          });
          this.chartData.series = this.series;
          this.chartData.xAxis.categories = this.dados.meses;
          this.chartData.subtitle.text = "Fonte: Mq Stat | Atualizado em " + this.dados.dataInsert;
        });
      } else if (this.product.tipoPrecificacao == "Concorrente" || this.product.tipoPrecificacao == "Mistura Interna") {
        this.service.getGraficoCustoParaPrecificacao(this.product.nome).then((res) => {
          this.dados = res.data;
          this.series.push({
            name: this.product.nome,
            data: this.dados.values,
            color: "#3C5CA7",
          });
          this.chartData.xAxis.categories = this.dados.meses;
          this.chartData.subtitle.text = "Fonte: Mq Stat | Atualizado em " + this.dados.dataInsert;
        });

        if (this.product.capturas[0] != null) {
          this.service.getGraficoCustoParaPrecificacao(this.product.capturas[0].nomeCaptura).then((res) => {
            this.dados = res.data;
            this.series.push({
              name: this.product.capturas[0].nomeCaptura,
              data: this.dados.values,
              color: "#EC4C37",
            });
          });
        }
        this.chartData.series = this.series;
      }
      else if (this.product.tipoPrecificacao == "Mistura Externa") {
        this.service.getGraficoCustoParaPrecificacao(this.product.nome).then((res) => {
          this.dados = res.data;
          this.series.push({
            name: this.product.nome,
            data: this.dados.values,
            color: "#3C5CA7",
          });
          this.chartData.xAxis.categories = this.dados.meses;
          this.chartData.subtitle.text = "Fonte: Mq Stat | Atualizado em " + this.dados.dataInsert;
        });

        if (this.product.capturas[0] != null) {
          this.service.getGraficoCustoParaPrecificacao(this.product.capturas[0].nomeCaptura).then((res) => {
            this.dados = res.data;
            this.series.push({
              name: this.product.capturas[0].nomeCaptura,
              data: this.dados.values,
              color: "#EC4C37",
            });
          });
        }

        if (this.product.capturas[1] != null) {
          this.service.getGraficoCustoParaPrecificacao(this.product.capturas[1].nomeCaptura).then((res) => {
            this.dados = res.data;
            this.series.push({
              name: this.product.capturas[1].nomeCaptura,
              data: this.dados.values,
              color: "green",
            });
          });
        }

        this.chartData.series = this.series;
      }
    }
  }
};
</script>