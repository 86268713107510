<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadcrumbs"
        :title="$t('TXT_TAG_CONFIGURATION_UPPERCASE')"
      />
      <v-spacer></v-spacer>
      <prd-btn
        @click="backPredimonitor"
        :icon="'mdi-arrow-left'"
        :leftIcon="true"
        :title="$t('TXT_BACK')"
        class="mt-14"
      />
    </v-row>

    <v-card class="pa-4 pt-0" :loading="isLoading" :disabled="isLoading">
      <!-- SEARCH AND COMBOBOX -->
      <v-row align="center" class="mt-2 mb-6">
        <v-col cols="12" md="4">
          <prd-search
            v-model="search"
            :searchLabel="$t('TXT_SEARCH_TAGS')"
            :searchPlaceholder="'Buscar'"
            :disabled="products.length == 0"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <prd-combo-box
            v-model="recordsPerPage"
            :title="$t('TXT_RESULTS_BY_PAGE')"
            :disabled="products.length == 0"
            :placeholder="$t('TXT_SELECT')"
            :items="itemsPerPage"
          />
        </v-col>
      </v-row>

      <product-card
        v-for="(product, index) in products"
        :key="index"
        :product="product"
        @callGet="getMonitoringTags"
        @setLoading="isLoading = $event"
        @showSnackbar="showSnackbar"
      />

      <!-- PAGINATION -->
      <prd-pagination
        v-model="currentPage"
        v-show="products.length != 0"
        :totalRegisters="totalRecords"
        :length="totalPages"
        :showingRegistersLen="numberOfRecords"
        :resultLabel="$t('TXT_PAGINATION')"
        :pageLabel="$t('TXT_PAGE_LABEL')"
        class="mt-4"
      />

      <!-- SNACKBAR -->
      <v-snackbar
        v-model="snackbar.snackbar"
        :color="snackbar.snackbarColor"
        :timeout="2000"
      >
        {{ snackbar.snackbarText }}
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdSearch from "@/Design_System/common/prd-search.vue";
import ProductCard from "./components/product-card.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import service from "@/service/tags-config/index.js";
import PrdBtn from "../../../Design_System/common/prd-btn.vue";

export default {
  name: "tags-config",
  components: {
    titleBreadCrumbsComponent,
    PrdSearch,
    ProductCard,
    PrdComboBox,
    PrdPagination,
    PrdBtn,
  },

  data() {
    return {
      service: new service(),
      breadcrumbs: [
        {
          text: "Predimonitor",
        },
        {
          text: this.$i18n.t("TXT_TAG_CONFIGURATION"),
        },
      ],
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      itemsPerPage: [5, 10, 15, 20],

      isLoading: false,
      search: "",

      // Pagination vars
      recordsPerPage: 10,
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,

      products: [
        // {
        //   getCode: "28",
        //   productDescription: "NEXTBASE DASH CAM 422GW",
        //   lowerPriceRange: null,
        //   upperPriceRange: null,
        //   configs: [
        //     {
        //       description: "NEXTBASE DASH CAM 422GW",
        //       isExternalSearch: false
        //     },
        //     {
        //       description: "NextBaseDashcam",
        //       isExternalSearch: true
        //     },
        //   ],
        // },
      ],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    currentPage() {
      this.getMonitoringTags();
    },

    search() {
      this.getMonitoringTags();
    },

    recordsPerPage() {
      this.getMonitoringTags();
    },
  },

  methods: {
    getMonitoringTags() {
      this.isLoading = true;

      let request = {
        includeLeadInfo: true,
        includeConfigInfo: true,
        monitoringItemCreateStartDate: null,
        monitoringItemCreateEndDate: null,
        onlyForSearch: false,
        onlyWithLead: false,
        filters: {
          description: this.search,
        },
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage,
        idCompany: this.idCompany,
      };

      this.service
        .getMonitoringItems(request)
        .then((res) => {
          console.log(res);
          if (res.status != 500) {
            let response = res.data.result;
            this.products = response.records;

            this.totalRecords = response.totalRecords;
            this.recordsPerPage = response.recordsPerPage;
            this.currentPage = response.currentPage;
            this.totalPages = response.totalPages;
            this.numberOfRecords = response.numberOfRecords;
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          } else {
            this.showSnackbar("Erro ao buscar tags", "error");
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    backPredimonitor() {
      this.$router.push("/main/predimonitor");
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
  },

  mounted() {
    this.getMonitoringTags();
    document.title = `PREDIFY | ${this.$i18n.t("TXT_TAG_CONFIGURATION")}`;
  },
};
</script>