<template>
  <div>
    <highcharts class="hc mt-4" :options="chartData"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);
import ServiceUPL from "@/service/upl/index";

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      dados: [],
      series: [],
      chartData: {
        title: {
          text: "<b>HISTÓRICO DESEMPENHO x LISTA DE PREÇOS</b>",
          align: "left",
        },
        subtitle: {
          text: "Fonte:Win room",
          align: "left",
        },

        xAxis: {
          categories: [
          ],
          title: {
            text: "Data",
          },
        },
        yAxis: {
          title: {
            text: "Custo",
          },
        },

        series: [
          {
            type: "column",
            name: "2020",
            color: "#6792F8",
            data: [59, 83, 65, 228, 184],
          },
          {
            type: "column",
            name: "2021",
            color: "#3C5CA7",
            data: [24, 79, 72, 240, 167],
          },

          {
            type: "spline",
            name: "Average",
            color: "#EC4C37",
            data: [47, 83.33, 70.66, 239.33, 175.66],
            marker: {
              lineWidth: 2,
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    if (this.product != null) {
      this.service.getGraficoHistoricoDesempenho(this.product.nome).then((res) => {
        this.dados = res.data;
        this.series.push({
          type: "column",
          name: "NetOrder",
          data: this.dados.netOrder,
          color: "#6792F8",
        });
        this.series.push({
          type: "column",
          name: "NetPrice",
          data: this.dados.netPrice,
          color: "#3C5CA7",
        });
        this.series.push({
          type: "spline",
          name: "Volume",
          data: this.dados.volume,
          color: "#EC4C37",
          marker: {
            lineWidth: 2,
          },
        });
        this.chartData.series = this.series;
        this.chartData.xAxis.categories = this.dados.meses;
        this.chartData.subtitle.text = "Fonte: Win room | Atualizado em " + this.dados.dataInsert;
      });
    }
  }
};
</script>