<template>
  <v-dialog max-width="600px" v-model="showInfoQueryDialog">
    <v-card class="pa-4">
      <v-expansion-panels>
        <v-expansion-panel v-for="(query, index) in filteredQuery" :key="index">
          <v-expansion-panel-header expand-icon="mdi-chevron-down">
            <b>{{ query.filters.products[0] }}</b>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div class="flex-ul">
              <pre>{{ query }}</pre>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["filteredQuery"],
  data() {
    return {
      showInfoQueryDialog: false,
    };
  },

  methods: {
    openDialog() {
      this.showInfoQueryDialog = true;
    },
  },
};
</script>