<template>
  <v-card id="similarity-card" class="pa-4">
    <v-row no-gutters align="center" class="mb-4">
      <h4>SIMILARIDADE</h4>
      <v-spacer></v-spacer>
      <similiarity-modal-items
        :idEnterprisePricesProjection="idEnterprisePricesProjection"
        :tableSimilarity="tableSimilarity"
        @callSimiliarityTable="$emit('callSimiliarityTable')"
      />
    </v-row>
    <v-row
      v-if="tableSimilarityLoading"
      class="mt-10"
      no-gutters
      justify="center"
    >
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <v-data-table
      v-if="tableSimilarity.length != 0"
      :headers="headers"
      :items="tableSimilarity"
      :items-per-page="5"
      :hide-default-footer="tableSimilarity.length <= 5"
    >
      <!-- Price -->
      <template v-slot:[`item.price`]="{ item }">
        {{ setCurrency(item.price) }}
      </template>
    </v-data-table>

    <v-row v-else no-gutters justify="center" class="mt-6">
      <h4>Nenhum produto encontrado</h4>
    </v-row>
  </v-card>
</template>
<script>
import { setCurrency } from "@/utils/format-currency";
import similiarityModalItems from "./similiarity-modal-items.vue";

export default {
  components: { similiarityModalItems },
  props: [
    "tableSimilarity",
    "tableSimilarityLoading",
    "idEnterprisePricesProjection",
  ],
  data() {
    return {
      headers: [
        {
          text: "Artigo",
          align: "center",
          sortable: false,
          value: "idProduct",
        },
        {
          text: "Produto",
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: "Preço",
          align: "center",
          sortable: false,
          value: "price",
          width: "120px",
        },
        {
          text: "Similiaridade",
          align: "center",
          sortable: false,
          value: "typeSimilarity",
        },
      ],
    };
  },

  methods: {
    setCurrency,
  },
};
</script>
<style lang="scss">
#similarity-card {
  margin-top: 8px;
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 11px !important;
    height: 40px !important;
  }
}
</style>