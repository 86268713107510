<template>
  <v-card :disabled="disableElasticity" flat class="pa-4">
    <div>
      <v-row no-gutters>
        <v-row no-gutters align="center">
          <label
            v-t="'TXT_ELASTICITY_UPPERCASE'"
            class="font-weight-bold font-size-12"
          ></label>
          <tooltip-informations
            :title="this.$i18n.t('TXT_TOOLTIP_ELASTICITY')"
            :rightIcon="true"
          />
        </v-row>
        <v-spacer></v-spacer>
        <div class="font-size-12">
          <span v-show="elasticityType" class="mr-6"
            >{{ $t("TXT_TOOLTIP_ELASTICITY_TYPE_TITLE") }}:
            <span class="font-weight-bold">{{ elasticityType }}</span
            ><tooltip-informations
              :rightIcon="true"
              :title="$t('TXT_TOOLTIP_ELASTICITY_TYPE')"
          /></span>
          <span v-show="elasticityIndex">
            {{ $t("TXT_INDEX") }}:
            <span class="font-weight-bold">{{ elasticityIndex }}</span>
            <tooltip-informations
              :rightIcon="true"
              :title="$t('TXT_TOOLTIP_ELASTICITY_INDEX')"
            />
          </span>
        </div>
      </v-row>

      <v-card flat class="my-flex mt-4">
        <!-- AJUSTED PRICE -->
        <elasticity-card-price
          v-if="hasAjustedPrice"
          :title="$t('TXT_ADJUSTED_PRICE')"
          :price="ajustedPrice"
          :demand="ajustedPriceDemand"
          :renevue="ajustedPriceRenevue"
          :haveRadio="false"
          :tooltipText="$t('TXT_ADJUSTED_PRICE_TOOLTIP')"
          :disableElasticity="disableElasticity"
          :marketPrice="marketPrice"
          cardType="elasticity"
          class="card-elasticity"
        />

        <!-- AVG PRICE -->
        <elasticity-card-price
          :title="$t('TXT_AVERAGE_PRICE')"
          :price="avgPrice"
          :demand="avgPriceDemand"
          :renevue="avgPriceRevenue"
          :haveRadio="false"
          :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_AVERAGE_PRICE')"
          :disableElasticity="disableElasticity"
          cardType="price"
          class="card-elasticity"
        />

        <!-- IA PRICE (limite inferior) -->
        <elasticity-card-price
          :title="$t('TXT_TOOLTIP_ELASTICITY_IA_INFERIOR_LIMIT_PRICE_TITLE')"
          :price="iaPriceInferior"
          :demand="iaPriceDemandInferior"
          :renevue="iaPriceRenevueInferior"
          :marketPrice="marketPrice"
          :haveRadio="false"
          :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_IA_LIMIT_PRICE')"
          :disableElasticity="disableElasticity"
          cardType="elasticity"
          class="card-elasticity"
        />

        <!-- IA PRICE -->
        <elasticity-card-price
          :title="$t('TXT_IA_PRICE_TITLE')"
          :price="iaPrice"
          :demand="iaPriceDemand"
          :renevue="iaPriceRenevue"
          :marketPrice="marketPrice"
          :haveRadio="false"
          :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_IA_PRICE')"
          :disableElasticity="disableElasticity"
          cardType="elasticity"
          class="card-elasticity"
        />

        <!-- IA PRICE superior limit -->
        <elasticity-card-price
          :title="$t('TXT_TOOLTIP_ELASTICITY_IA_UPPER_LIMIT_PRICE_TITLE')"
          :price="iaPriceSuperior"
          :demand="iaPriceDemandSuperior"
          :renevue="iaPriceRenevueSuperior"
          :marketPrice="marketPrice"
          :haveRadio="false"
          :tooltipText="$t('TXT_TOOLTIP_ELASTICITY_IA_LIMIT_PRICE')"
          :disableElasticity="disableElasticity"
          cardType="elasticity"
          class="card-elasticity"
        />
      </v-card>
    </div>
  </v-card>
</template>

<script>
import tooltipInformations from "../../../../../../../../Design_System/common/tooltip-informations.vue";
import elasticityCardPrice from "./elasticity-card-price.vue";
export default {
  props: ["productRegion"],
  components: { tooltipInformations, elasticityCardPrice },
  data() {
    return {};
  },

  computed: {
    marketPrice() {
      return this.productRegion?.price?.productCost ?? null;
    },
    disableElasticity() {
      if (
        !this.productRegion?.price?.elasticity &&
        !this.productRegion?.elasticity
      )
        return true;

      return false;
    },
    elasticityIndex() {
      if (!this.productRegion) return null;
      if (
        this.productRegion?.price?.elasticity?.elasticity !== null &&
        this.productRegion?.price?.elasticity?.elasticity !== undefined
      ) {
        return this.productRegion.price.elasticity.elasticity.toFixed(2);
      } else if (
        this.productRegion?.elasticity?.elasticity !== null &&
        this.productRegion?.elasticity?.elasticity !== undefined
      ) {
        return this.productRegion.elasticity.elasticity.toFixed(2);
      } else {
        return null;
      }
    },
    elasticityType() {
      if (!this.productRegion) return null;
      let elasticity = null;

      if (
        this.productRegion?.price?.elasticity?.elasticity !== null &&
        this.productRegion?.price?.elasticity?.elasticity !== undefined
      ) {
        elasticity = this.productRegion.price.elasticity.elasticity.toFixed(2);
      } else if (
        this.productRegion?.elasticity?.elasticity !== null &&
        this.productRegion?.elasticity?.elasticity !== undefined
      ) {
        elasticity = this.productRegion.elasticity.elasticity.toFixed(2);
      } else {
        elasticity = null;
      }

      if (elasticity) {
        if (elasticity < 1 && elasticity > -1)
          return this.$i18n.t("TXT_INELASTIC");
        else return this.$i18n.t("TXT_ELASTIC");
      } else {
        return null;
      }
    },
    hasAjustedPrice() {
      if (!this.productRegion) return null;

      return this.productRegion?.price?.override?.price ?? null;
    },

    //Ajusted price
    ajustedPrice() {
      if (!this.productRegion) return null;
      return this.productRegion?.price?.suggestedPrice ?? null;
    },
    ajustedPriceDemand() {
      if (!this.productRegion) return null;
      return this.productRegion?.price?.suggestedPrice_Demand ?? null;
    },
    ajustedPriceRenevue() {
      if (!this.productRegion) return null;
      return (
        this.productRegion?.price?.suggestedPrice *
          this.productRegion?.price?.suggestedPrice_Demand ?? null
      );
    },

    //avg price
    avgPrice() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.priceDemand || null;
      } else {
        return this.productRegion.price.elasticity.meanPrice;
      }
    },
    avgPriceDemand() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.averageDemand || null;
      } else {
        return this.productRegion.price.elasticity.meanQuantity;
      }
    },
    avgPriceRevenue() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.revenueApprovedPrice || null;
      } else {
        return (
          this.productRegion.price.elasticity.meanPrice *
          this.productRegion.price.elasticity.meanQuantity
        );
      }
    },

    //IA price
    iaPrice() {
      if (!this.productRegion) return null;

      return this.productRegion.price.salePrice;
    },
    iaPriceDemand() {
      if (!this.productRegion) return null;

      if (!this.productRegion?.price?.elasticity) {
        return this.productRegion.elasticity?.approvedPriceDemand || null;
      } else {
        return this.productRegion.price.salePrice_Demand;
      }
    },
    iaPriceRenevue() {
      if (!this.productRegion) return null;

      if (!this.productRegion?.price?.elasticity) {
        return this.productRegion.elasticity?.revenueIAPrice || null;
      } else {
        return (
          this.productRegion.price.salePrice *
          this.productRegion.price.salePrice_Demand
        );
      }
    },

    //IA price inferior limit
    iaPriceInferior() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.priceInferiorLimit || null;
      } else {
        return this.productRegion.price.salePriceInferiorLimit;
      }
    },
    iaPriceDemandInferior() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.inferiorLimitDemand || null;
      } else {
        return this.productRegion.price.salePriceInferiorLimit_Demand;
      }
    },
    iaPriceRenevueInferior() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.revenueInferiorLimit || null;
      } else {
        return (
          this.productRegion.price.salePriceInferiorLimit_Demand *
          this.productRegion.price.salePriceInferiorLimit
        );
      }
    },

    // IA price superior limit
    iaPriceSuperior() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.priceSuperiorLimit || null;
      } else {
        return this.productRegion.price.salePriceSuperiorLimit;
      }
    },
    iaPriceDemandSuperior() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.superiorLimitDemand || null;
      } else {
        return this.productRegion.price.salePriceSuperiorLimit_Demand;
      }
    },
    iaPriceRenevueSuperior() {
      if (!this.productRegion) return null;

      if (!this.productRegion.price.elasticity) {
        return this.productRegion.elasticity?.revenueSuperiorLimit || null;
      } else {
        return (
          this.productRegion.price.salePriceSuperiorLimit *
          this.productRegion.price.salePriceSuperiorLimit_Demand
        );
      }
    },
  },

  methods: {
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },
  },

  created() {
    console.log(this.productRegion);
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  .card-elasticity {
    flex-grow: 1;
  }
}
.blue-text {
  color: #6792f8;
}
</style>