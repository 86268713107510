<template>
  <v-expansion-panels :readonly="readOnly">
    <v-expansion-panel
      :class="[
        'mb-2',
        'left-border',
        'rounded-lg',
        'color-square',
        isGray ? 'show-bg-gray' : '',
        `show-${borderColor}`,
      ]"
    >
      <v-expansion-panel-header :expand-icon="'mdi-chevron-down'">
        <v-container fluid class="ma-0 pa-0 mr-3">
          <v-row justify="space-between" class="ma-0 pa-0 font-weight-bold" align="center">
            <v-col cols="auto" class="mt-0 pa-0 grow header-left ml-n2">
              <slot name="header-left"></slot>
            </v-col>
             <v-col cols="auto" class="mt-0 pa-0 grow header-left ml-n2">
              <slot name="header-center"></slot>
            </v-col>
            <v-col cols="auto" class="shrink mt-0 pa-0 header-right">
              <slot name="header-right"></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-header>
      <v-expansion-panel-content style="background-color:#f5f5f5;" class="pa-2">
        <slot name="content" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data(){
    return{
      isGray: false,
      }
  },
  props: {
    /** The color of the left border */
    borderColor: {
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    icon: {
      type: String,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
<style lang="scss" scoped>
.left-border {
  border-left-width: 8px;
  border-left-style: solid;
}

.header-right {
  font-size: 12px;
}
.show-blue {
  border-color: $brand-color-primary-pure;
}
.show-green {
  border-color: $feedback-color-success-pure;
}
.show-yellow {
  border-color: $feedback-color-helper-pure;
}
.show-red {
  border-color: $feedback-color-warning-pure;
}
.show-bg-gray {
  background-color: $neutral-color-high-light !important;
}
</style>
