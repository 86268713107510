<template>
  <div>
    <div class="my-flex">
      <hectar-cost style="width: 50%" v-bind:key="this.product.id" v-bind:product="this.product" />
      <pricing-cost style="width: 50%" v-bind:key="this.product.id" v-bind:product="this.product" />

    </div>

    <div class="my-flex">
      <performance-history style="width: 50%" v-bind:key="this.product.id" v-bind:product="this.product" />
      <peso-variaveis-ia style="width: 50%" v-bind:key="this.product.id" v-bind:product="this.product" />
    </div>
  </div>
</template>

<script>
import hectarCost from "./components/hectar-cost.vue";
import PerformanceHistory from "./components/performance-history.vue";
import PricingCost from "./components/pricing-cost.vue";
import PesoVariaveisIa from "./components/peso-variaveis-ia.vue";
export default {
  components: { hectarCost, PerformanceHistory, PricingCost, PesoVariaveisIa },
  props: ["product"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 16px;
}
</style>