import axiosInstance, { API_URL } from "@/configs/axiosConfig";
import { makeServiceMethod } from "../smart-service";
import ServiceBase from "../service-base";

export default class AiPricingService extends ServiceBase {
  listPriceGroups = makeServiceMethod(
    async (idCompany, filters) => {
      const response = await axiosInstance.post(
        API_URL + `api/v2/Enterprise/PriceGroups/List`,
        { idCompany, filters, simulation: false }
      );
      return response.data.result;
    }
  );

  getAllPriceProjectionFilters = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idPriceGroup
     * @returns {Promise<{
     *   result: {
     *     affilliateFilter: {
     *       key: string;
     *       value: string;
     *     }[];
     *     categoryFilter: {
     *       key: string;
     *       value: string;
     *     }[];
     *     productFilter: {
     *       key: string;
     *       value: string;
     *     }[];
     *     customerFilter: {
     *       key: string;
     *       value: string;
     *     }[];
     *   };
     *   success: boolean;
     *   messages: string[] | null;
     * }>}
     */
    async (idCompany, idPriceGroup) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/v2/Enterprise/PriceProjection/PriceProjectionFilter?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}&isSimulation=false`
      );

      return response.data.result;
    }
  );

  listProducts = makeServiceMethod(
    async (
      request
    ) => {
      const response = await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceProjection/List", {
        page: request.page,
        recordsPerPage: request.recordsPerPage,
        isSimulation: false,
        idEnterprisePriceGroups: request.idEnterprisePriceGroups,
        idCompany: request.idCompany,
        filter: request.filter
      }
      );

      return response.data.result;
    }
  );

  overrideManualPrice = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterprisePricesProjection
     * @param {number} manualPrice
     * @param {number} manualContributionMargin
     */
    async (
      idCompany,
      idEnterprisePricesProjection,
      manualPrice,
      manualContributionMargin
    ) => {
      const response = await axiosInstance.post(
        API_URL + "api/v2/Enterprise/PriceProjection/ValueOverride",
        {
          idCompany,
          idEnterprisePricesProjection,
          manualPrice,
          manualContributionMargin,
          isManual: true,
          isSimulation: false,
        }
      );
      return response.data.result;
    }
  );

  overrideCancelManualPrice = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterprisePricesProjection
     */
    async (idCompany, idEnterprisePricesProjection) => {
      const response = await axiosInstance.post(
        API_URL + "api/v2/Enterprise/PriceProjection/ValueOverride",
        {
          idCompany,
          idEnterprisePricesProjection,
          manualPrice: 0,
          manualContributionMargin: 0,
          isManual: false,
          isSimulation: false,
        }
      );
      return response.data.result;
    }
  );

  calculateProducts = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterprisePriceGroups
     */
    async (idCompany, idEnterprisePriceGroups) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/v2/Enterprise/PriceProjection/CalculatePrices?idCompany=${idCompany}&idEnterprisePriceGroups=${idEnterprisePriceGroups}&isManual=false`
      );
      return response.data;
    }
  );

  calculateProductsRules = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterprisePriceGroups
     */
    async (idCompany, idEnterprisePriceGroups) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/v2/Enterprise/PriceProjection/CalculateRules?idCompany=${idCompany}&idEnterprisePriceGroups=${idEnterprisePriceGroups}&isManual=false`
      );
      return response.data;
    }
  );

  getAllFilterGroups = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @returns {Promise<{
     *   result: {
     *     idEnterprisePriceGroupDefaulFilter: number;
     *     fieldName: string;
     *     order: number;
     *     values: {
     *       values: string;
     *     }[];
     *   }[];
     *   success: boolean;
     *   messages: string[] | null;
     * }>}
     */
    async (idCompany, preSelect) => {
      const response = await axiosInstance.get(
        API_URL + `api/V2/Enterprise/PriceGroups/Filter?idCompany=${idCompany}&preSelect=${preSelect}`
      );
      return response.data.result;
    }
  );

  previewProducts = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {{
     *   idEnterprisePriceGroupDefaulFilter: number;
     *   value: string;
     * }[]} filters
     * @param {number} page
     * @param {number} recordsPerPage
     * @returns {Promise<{
     *   result: {
     *     currentPage: number;
     *     totalPages: number;
     *     recordsPerPage: number;
     *     numberOfRecords: number;
     *     totalRecords: number;
     *     records: {
     *       product: string;
     *       description: string;
     *     }[];
     *   };
     *   success: boolean;
     *   messages: string[] | null;
     * }>}
     */
    async (idCompany, filters, page, recordsPerPage) => {
      const response = await axiosInstance.post(
        API_URL + "api/V2/Enterprise/PriceGroups/Product",
        {
          idCompany,
          filters,
          page,
          recordsPerPage,
        }
      );
      return response.data.result;
    }
  );

  getPreviewTotalProducts = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {{
     *   idEnterprisePriceGroupDefaulFilter: number;
     *   value: string;
     * }[]} filters
     * @returns {Promise<number>}
     */
    async (idCompany, filters) => {
      const response = await axiosInstance.post(
        API_URL + "api/V2/Enterprise/PriceGroups/Product",
        {
          idCompany,
          filters,
          page: 1,
          recordsPerPage: 0,
        }
      );
      return response.data.result.totalRecords;
    }
  );

  savePrecification = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {string} idCompany
     * @param {{
     *   idEnterprisePriceGroupDefaulFilter: number;
     *   value: string;
     * }[]} filters
     * @returns {Promise<number>}
     */
    async (idCompany, precificationName, filters) => {
      const response = await axiosInstance.post(
        API_URL + "api/V2/Enterprise/PriceGroups",
        {
          idCompany,
          idEnterprisePriceGroup: 0,
          isSimulation: false,
          name: precificationName,
          filters,
        }
      );
      return response.data.result.totalRecords;
    }
  );

  sendPricingAction = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterprisePriceProjection
     * @param {number} action
     */
    async (idCompany, idEnterprisePriceProjection, action) => {
      const response = await axiosInstance.post(
        API_URL + "api/v2/Enterprise/WorkflowApproval/SetActionPriceProjection",
        { idCompany, idEnterprisePriceProjection, action }
      );
      return response.data.result;
    }
  );

  getErpPreview = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idPriceGroup
     */
    async (idCompany, idPriceGroup) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/v2/Enterprise/PriceGroups/SceneryInfo?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}`,
        { idCompany, idPriceGroup }
      );
      return response.data.result;
    }
  );

  async extractReport(data) {
    return await axiosInstance.get(API_URL + "api/V2/Enterprise/PriceProjection/ListExport", { params: data });
  }

  async extractReportSAP(data) {
    return await axiosInstance.get(API_URL + "api/V2/Enterprise/PriceProjection/ListExportSAP", { params: data });
  }

  async listMapViewInfo(idCompany) {
    return await axiosInstance.get(API_URL + `api/V2/Enterprise/PriceProjection/ListMapViewInfo?idCompany=${idCompany}`);
  }

  ManualMarketResult = makeServiceMethod(
    async (
      data
    ) => {
      const response = await axiosInstance.post(
        API_URL + "api/v2/Enterprise/PriceProjection/ManualMarketResult", data);
      return response.data.result;
    }
  );
  
  async listMonitoringMarketResultItem(idCompany, idMonitoringProductMarketResult) {
    return await axiosInstance.get(API_URL + `/api/V2/Monitoring/GetListAllMonitoringMarketResult?idCompany=${idCompany}&idMonitoring_ProductMarketResult=${idMonitoringProductMarketResult}`);
  }

  async tableResultsDisregarded(data) {
    return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/SaveIgnoredMonitoringItems", data );
  }

  async predimonitorTable(request) {
    return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/AvgInfoByProduct", request );
  }
}
