export function formatDecimals(value, decimals) {
    if (typeof value !== "number" && !Number.isNaN(value)) return '-';

    if (!decimals) decimals = 2

    const formated = value.toLocaleString('pt-BR', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    });

    return formated


}