<template>
  <v-card
    :loading="isLoading"
    :disabled="isLoading"
    class="pa-4 pt-0"
    id="new-search"
  >
    <v-row no-gutters align="center" class="pt-4">
      <!-- DATA PICKER -->
      <v-col cols="12" sm="4" md="3" class="mr-6">
        <span>
          <label v-t="'TXT_REFERENCE_PERIOD'"></label>
          <tooltip-informations :title="'data picker info'" :rightIcon="true" />
        </span>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          ref="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              v-bind="attrs"
              v-on="on"
              :label="$t('TXT_SELECT_PERIOD')"
              :placeholder="$t('TXT_SELECT_PERIOD')"
              :disabled="searchLoading"
              hide-details
              outlined
              single-line
              dense
              readonly
              class="input-datapicker mt-2"
            >
              <template v-slot:append>
                <v-icon class="append-calendar" color="white" small>
                  mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <!-- TODO - properly use user's locale -->
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            range
            :min="minDay"
            :max="maxDay"
          >
            <v-spacer></v-spacer>

            <v-btn
              text
              color="error"
              @click="menu = false && dateRangeText == null"
              v-t="'TXT_CANCEL'"
            ></v-btn>
            <v-btn outlined color="primary" @click="setDate">Reset</v-btn>

            <v-btn
              color="primary"
              @click="$refs.menu.save(date)"
              v-t="'TXT_OK'"
            ></v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <!-- SAVED FILTERS -->
      <v-col cols="12" sm="4" md="4">
        <label v-t="'TXT_SAVED_FILTERS'"></label>
        <v-combobox
          v-model="selectedFilter"
          :items="savedFilters"
          :disabled="
            searchLoading || savedFilterLoading || comboboxFilterloading
          "
          :loading="savedFilterLoading"
          :label="$t('TXT_SELECT')"
          @change="fillSelectedFilter"
          item-text="name"
          item-value="IdMonitoringPredimonitorFilter"
          append-icon="mdi-chevron-down"
          dense
          outlined
          hide-details
          single-line
          class="mt-2"
        >
          <template v-slot:append-outer>
            <!-- <v-icon :color="$prdStyles('color-primary')">mdi-pencil-box</v-icon> -->
          </template>
        </v-combobox>
      </v-col>

      <v-col v-if="false">
        <router-link to="/main/predimonitor-V2">
          <prd-btn
            :title="'Predimonitor V2'"
            class="mt-7 ml-4"
            v-if="isPredifyAdmin"
          />
        </router-link>
      </v-col>
    </v-row>

    <h4 v-t="$t('TXT_FILTERS')" class="my-6"></h4>

    <div class="filter-label">
      <span>{{ this.$i18n.t("TXT_PRODUCT_FILTERS") }}</span>
    </div>
    <!-- SEARCH PRODUCTS -->
    <v-row no-gutters>
      <v-col>
        <label v-t="'TXT_PRODUCTS'"></label>
        <v-autocomplete
          v-model="products"
          :items="items"
          :search-input.sync="search"
          :loading="searchLoading"
          :disabled="searchLoading"
          :label="$t('TXT_SEARCH_2')"
          :placeholder="$t('TXT_SELECT_ONE_OR_MORE_PRODUCTS')"
          item-text="getDescription"
          append-icon="mdi-chevron-down"
          single-line
          hide-details
          multiple
          return-object
          outlined
          dense
          deletable-chips
          small-chips
          cache-items
          class="mt-2"
        >
          <!--selected item -->
          <template v-slot:selection="{ item }">
            <v-chip
              @click:close="removeProduct(item)"
              close
              small-chips
              dark
              color="#E0E0E0"
            >
              <span style="color: black">
                {{ item.getDescription }}
              </span>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <!-- FILTERS -->
    <v-row no-gutters class="mt-6">
      <v-col
        v-for="(filter, index) in filters"
        :key="index"
        :class="filter.margin"
      >
        <label>{{ filter.label }}</label>
        <v-combobox
          v-model="filter.selected"
          :items="filter.items"
          :disabled="
            searchLoading || comboboxFilterloading || savedFilterLoading
          "
          :loading="comboboxFilterloading"
          item-text="description"
          item-value="id"
          multiple
          deletable-chips
          small-chips
          dense
          outlined
          append-icon="mdi-chevron-down"
          class="mt-2"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <!-- MACHINERY FILTERS -->
    <v-row v-if="machineryResource" no-gutters class="mt-6">
      <!-- YEARS -->
      <v-col cols="3">
        <label v-t="$t('TXT_YEARS')"></label>
        <v-combobox
          v-model="yearsFilterSelected"
          :items="yearsFilterItems"
          :disabled="
            searchLoading || comboboxFilterloading || savedFilterLoading
          "
          :loading="comboboxFilterloading"
          item-text="description"
          item-value="id"
          multiple
          deletable-chips
          small-chips
          dense
          outlined
          append-icon="mdi-chevron-down"
          class="mt-2 mr-4"
        >
        </v-combobox>
      </v-col>

      <!-- REGION -->
      <v-col cols="3">
        <label v-t="$t('TXT_REGION')"></label>
        <v-combobox
          v-model="regionFilterSelected"
          :items="regionFilterItems"
          :disabled="
            searchLoading || comboboxFilterloading || savedFilterLoading
          "
          :loading="comboboxFilterloading"
          item-text="description"
          item-value="id"
          multiple
          deletable-chips
          small-chips
          dense
          outlined
          append-icon="mdi-chevron-down"
          class="mt-2 mr-4"
        >
        </v-combobox>
      </v-col>

      <!-- HORIMETRO -->
      <v-col cols="3">
        <label v-t="$t('TXT_WORKED_HOURS')"></label>
        <v-range-slider
          :disabled="
            searchLoading || comboboxFilterloading || savedFilterLoading
          "
          v-model="range"
          :max="max"
          :min="min"
          hide-details
          thumb-label
          class="align-center mt-2"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="range[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 0px"
              @change="$set(range, 0, $event)"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              :value="range[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 0px"
              @change="$set(range, 1, $event)"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>

    <!-- LOCATION FILTER -->
    <div v-if="idCompany == 2699">
      <div class="filter-label">
        <span>{{ $t("TXT_LOCATION_FILTER") }}</span>
      </div>
      <v-row no-gutters class="mt-6">
        <v-col
          md="3"
          v-for="(filter, index) in locationFilters"
          :key="index"
          :class="filter.margin"
        >
          <v-row no-gutters
            ><label>{{ filter.label }}</label>
          </v-row>
          <v-combobox
            v-model="filter.selected"
            :items="filter.items"
            :disabled="filter.isDisable"
            :loading="filter.isLoading"
            :item-text="filter.itemText"
            :multiple="filter.isMultiple"
            item-value="id"
            dense
            outlined
            append-icon="mdi-chevron-down"
            class="mt-2"
            @change="onChangeAnyLocationFilter(filter)"
          >
          </v-combobox>
        </v-col>
      </v-row>
    </div>

    <!-- SEARCH FILTERS -->
    <!-- <div class="filter-label">
      <span>Filtros de pesquisa</span>
    </div>
    <v-row no-gutters class="mt-6">
      <v-col
        md="3"
        v-for="(filter, index) in searchFilters"
        :key="index"
        :class="filter.margin"
      >
        <v-row no-gutters
          ><label>{{ filter.label }}</label>
          <tooltip-informations :title="filter.tooltipText" :rightIcon="true" />
        </v-row>
        <v-combobox
          v-model="filter.selected"
          :items="filter.items"
          :disabled="true"
          item-text="description"
          item-value="id"
          multiple
          deletable-chips
          small-chips
          dense
          outlined
          append-icon="mdi-chevron-down"
          class="mt-2"
        >
        </v-combobox>
      </v-col>
    </v-row> -->

    <!-- FILTER ACTIONS -->
    <v-row no-gutters class="mb-8">
      <!-- DELETE FILTER-->
      <v-btn
        v-show="selectedFilter != null"
        @click="$refs.deleteFilterDialog.openDialog()"
        depressed
      >
        <v-icon left :color="$prdStyles('color-error')" small
          >mdi-delete-outline
        </v-icon>

        <span style="color: #ec4c37">Excluir filtro</span>
      </v-btn>

      <v-spacer></v-spacer>
      <!-- CLEAN FILTER -->
      <v-btn @click="cleanSelectedFilters" depressed class="mr-4">
        <v-icon left :color="$prdStyles('color-primary')" small
          >mdi-close
        </v-icon>

        <span style="color: #3c5ca7">{{
          this.$i18n.t("TXT_CLEAR_FILTERS")
        }}</span>
      </v-btn>

      <!-- SAVE FILTER -->
      <v-btn
        @click="$refs.newSearch.openDialog()"
        outlined
        :color="$prdStyles('color-primary')"
      >
        {{ this.$i18n.t("TXT_SAVE_FILTERS") }}
      </v-btn>
    </v-row>

    <v-row no-gutters align="center">
      <v-spacer></v-spacer>
      <!-- QUERY MODAL -->
      <v-btn
        v-if="isPredifyAdmin"
        @click="$refs.infoQuery.openDialog()"
        :disabled="isLoading || items.length == 0 || products.length == 0"
        :color="$prdStyles('color-primary')"
        class="white--text mr-4"
      >
        <v-icon v-show="isPredifyAdmin" left color="red">
          mdi-shield-account-outline
        </v-icon>
        Querys
      </v-btn>

      <!-- SEARCH V3 -->
      <v-btn
        v-if="isPredifyAdmin"
        @click="searchProducts('v2')"
        :disabled="isLoading || items.length == 0 || products.length == 0"
        :color="$prdStyles('color-primary')"
        class="white--text mr-4"
      >
        <v-icon color="red" left> mdi-shield-account-outline </v-icon>
        Search V3</v-btn
      >

      <!-- SEARCH -->
      <v-btn
        @click="searchProducts"
        :disabled="products.length == 0 || isLoading"
        :color="$prdStyles('color-primary')"
        class="white--text"
        ><span> {{ this.$i18n.t("TXT_SEE_RESULTS") }}</span></v-btn
      >
    </v-row>

    <!-- SAVE FILTER DIALOG -->
    <save-filter-dialog
      :idCompany="idCompany"
      :products="products"
      :filters="filters"
      :locationFilters="locationFilters"
      :range="range"
      :yearsFilterSelected="yearsFilterSelected"
      :regionFilterSelected="regionFilterSelected"
      :machineryResource="machineryResource"
      @saveFilterSuccess="setSaveFilterJob"
      ref="newSearch"
    />
    <!-- INFO QUERY DIALOG -->
    <info-query-dialog ref="infoQuery" :filteredQuery="filteredQuery" />

    <!-- DELETE FILTER DIALOG -->
    <delete-filter-dialog
      @deleteFilter="deleteFilter"
      :selectedFilter="selectedFilter"
      :deleteFilterLoading="deleteFilterLoading"
      ref="deleteFilterDialog"
    />
  </v-card>
</template>

<script>
import Service from "@/service/predimonitor/filtros-predmonitor-service";
import saveFilterDialog from "../new-search/components/save-filter-dialog.vue";
import InfoQueryDialog from "../new-search/components/info-query-dialog.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import DeleteFilterDialog from "./components/delete-filter-dialog.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const service = new Service();

export default {
  props: ["updateData", "isLoading", "infoQuery"],
  components: {
    saveFilterDialog,
    InfoQueryDialog,
    TooltipInformations,
    DeleteFilterDialog,
    PrdBtn,
  },
  data() {
    return {
      // Loaders
      searchLoading: false,
      searchProductTimer: null,
      comboboxFilterloading: false,
      savedFilterLoading: false,
      deleteFilterLoading: false,
      locationFiltersLoading: false,

      // data picker vars
      menu: false,
      date: [],
      minDay: null,
      maxDay: null,

      // Autocomplete varsd
      search: "",
      products: [],
      items: [],

      filteredQuery: [],

      // Filters
      filters: [
        {
          label: this.$i18n.t("TXT_CATEGORY"),
          value: "categories",
          valueType: 1,
          items: [],
          margin: "mr-4",
          selected: null,
        },
        {
          label: this.$i18n.t("TXT_NETWORK"),
          value: "networks",
          valueType: 2,
          items: [],
          margin: "mr-4",
          selected: null,
        },
        {
          label: this.$i18n.t("TXT_PRICE_TYPE"),
          value: "priceType",
          valueType: 6,
          items: [],
          margin: "mr-4",
          selected: null,
        },
        {
          label: this.$i18n.t("TXT_MANUFACTURER"),
          value: "brands",
          valueType: 3,
          items: [],
          margin: "mr-4",
          selected: null,
        },
        {
          label: this.$i18n.t("TXT_SECTION"),
          value: "sections",
          valueType: 0,
          items: [],
          margin: "mr-0",
          selected: null,
        },
      ],

      // Machinery
      yearsFilterSelected: [],
      yearsFilterItems: [],
      regionFilterSelected: [],
      regionFilterItems: [],
      range: [20000, 60000],
      min: 20000,
      max: 60000,

      searchFilters: [
        {
          label: "Tipo de pesquisa",
          tooltipText: "teste",
          value: "searchType",
          items: [],
          margin: "mr-4",
          selected: null,
        },
        {
          label: "Sensibilidade da pesquisa",
          tooltipText: "teste",
          value: "searchType",
          items: [],
          margin: "mr-4",
          selected: null,
        },
        {
          label: "Origem da nomenclatura",
          tooltipText: "teste",
          value: "searchType",
          items: [],
          margin: "mr-4",
          selected: null,
        },
      ],

      locationFilters: [
        {
          label: this.$i18n.t("TXT_STATE"),
          value: "localeUf",
          valueType: 9,
          items: [],
          margin: "mr-4",
          selected: [],
          isDisable: true,
          isLoading: false,
          itemText: "nome",
          isMultiple: false,
        },
        {
          label: this.$i18n.t("TXT_CITY"),
          value: "localeCity",
          valueType: 10,
          items: [],
          margin: "mr-4",
          selected: [],
          isDisable: true,
          isLoading: false,
          itemText: "municipio",
          isMultiple: false,
        },
        {
          label: this.$i18n.t("TXT_NEIGHBORHOOD"),
          value: "localeNeighborhood",
          valueType: 11,
          items: [],
          margin: "mr-4",
          selected: [],
          isDisable: true,
          isLoading: false,
          itemText: "neighborhood",
          isMultiple: true,
        },
      ],
      savedFilters: [],
      unlockLocationFilter: null,
      selectedFilter: null,
    };
  },

  computed: {
    dateRangeText() {
      return this.date.join("  ~  ");
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },

    machineryResource() {
      if (this.hasResource("marketplace.machinery")) return true;
      else return false;
    },
  },

  watch: {
    search() {
      if (!this.searchLoading) {
        var startTimer = (scope) => {
          scope.searchProductTimer = setTimeout(function () {
            var searching = (scope) => {
              var searchExp = scope.search;
              scope.searchLoading = true;

              let data = {
                idCompany: scope.idCompany,
                page: 1,
                recordsPerPage: 100000,
                filters: {
                  description: scope.search,
                },
              };

              service
                .postProductList(data)
                .then((res) => {
                  scope.items = res.data.result.records;
                  scope.searchLoading = false;
                  if (searchExp != scope.search) searching(scope);
                })
                .catch(() => {
                  scope.searchLoading = false;
                });
            };
            searching(scope);
            scope.searchProductTimer = null;
          }, 1000);
        };

        if (this.searchProductTimer == null) {
          startTimer(this);
        } else {
          clearTimeout(this.searchProductTimer);
          startTimer(this);
        }
      }
      return;
    },

    date() {
      // Set the resource range limit
      let dateResource = this.$store.getters.resources;
      let maxRange;

      let filteredDateResource = dateResource.filter((item) =>
        item.startsWith("predimonitor.days_range_")
      );

      if (filteredDateResource.length != 0) {
        maxRange = filteredDateResource.sort((a, b) => {
          const numA = parseInt(a.split("_")[2]);
          const numB = parseInt(b.split("_")[2]);
          return numB - numA;
        });
        maxRange = parseInt(maxRange[0].split("_").pop());
      } else {
        maxRange = 30;
      }
      this.minDay = this.date[0];

      var baseDay = new Date(this.date[0]);
      var actualDay = new Date();

      baseDay.setDate(baseDay.getDate() + maxRange);

      if (
        baseDay.setDate(baseDay.getDate()) >
        actualDay.setDate(actualDay.getDate())
      ) {
        this.maxDay = actualDay.toISOString().slice(0, 10);
      } else {
        this.maxDay = baseDay.toISOString().slice(0, 10);
      }
    },

    products() {
      if (this.products.length > 10) {
        this.products.length = 10;
      }
    },

    updateData() {
      this.searchProducts();
    },

    infoQuery() {
      if (!this.infoQuery) return [];

      this.filteredQuery = this.parseQuery(this.infoQuery);
    },
  },

  methods: {
    searchProducts(search) {
      this.$emit("setLoading", true);
      this.date.sort();

      let request = {
        groupBy: 1,
        startDate: this.date[0],
        endDate: this.date[1],
        filters: {
          idProducts: [],
          eans: [],
          Networks: [],
          Categories: [],
          PriceType: [],
          Brands: [],
          Sections: [],
          localStates: [],
          workedHours: [],
          manufactureYears: [],
          postalCode: [],
        },
        idCompany: this.idCompany,
      };

      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter.selected !== null) {
          let selectedIds = filter.selected.map((item) => item.id);
          request.filters[filter.value] = selectedIds;
        }
      }

      if (
        this.locationFilters[0].selected != null &&
        this.locationFilters[1].selected != null
      ) {
        request.filters.localStates.push(
          this.locationFilters[0]?.selected?.uf ?? null
        );
        if (this.locationFilters[2].selected.length != 0) {
          this.locationFilters[2].selected.forEach((element) => {
            request.filters.postalCode.push(element.postalCode);
          });
        } else {
          this.locationFilters[2].items.forEach((element) => {
            request.filters.postalCode.push(element.postalCode);
          });
        }
      }

      // has resource machinery
      if (this.machineryResource) {
        let range = {
          min: this.range[0],
          max: this.range[1],
        };

        request.filters.workedHours = range ?? [];
        request.filters.manufactureYears = this.yearsFilterSelected ?? [];

        this.regionFilterSelected.forEach((el) => {
          request.filters.localStates.push(el.id);
        });
      }

      //Monta filtro de produto
      this.products.forEach((x) => {
        if (x.idMonitoringItem != null && x.idMonitoringItem != 0) {
          request.filters.idProducts.push(x.idMonitoringItem);
        }
        if (x.productEan != null && x.productEan != "") {
          request.filters.eans.push({
            ean: x.productEan,
            description: x.description,
          });
        }
      });

      this.$emit("search", request, search);

      this.$store.commit("searchProductPredimonitor", []);
    },

    removeProduct(item) {
      const index = this.products.indexOf(item);
      if (index >= 0) this.products.splice(index, 1);
    },

    setDate() {
      this.minDay = null;
      this.maxDay = null;
      var startDay = new Date();
      startDay.setDate(startDay.getDate() - 7);
      this.minRange = startDay.toISOString().slice(0, 10);
      this.maxDay = new Date().toISOString().slice(0, 10);

      this.date[0] = this.minRange;
      this.date[1] = new Date().toISOString().slice(0, 10);
    },

    parseQuery(query) {
      const substrings = query.split(
        "======================================\r\n\r\n"
      );
      const result = [];

      substrings.forEach((substring) => {
        if (substring) {
          const obj = JSON.parse(substring.trim());
          result.push(obj);
        }
      });

      return result;
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    // FILTER AREA
    getComboboxFilters() {
      this.comboboxFilterloading = true;
      service
        .getFilters({ idCompany: this.idCompany })
        .then((res) => {
          if (res.status != 500) {
            const data = res.data;

            this.filters.forEach((filter) => {
              filter.items = data[filter.value];
            });

            if (this.machineryResource) this.fillMachineryFilters(res.data);
          } else {
            this.$emit("showSnackbar", "Erro ao buscar filtros", "error");
          }
          this.comboboxFilterloading = false;
        })
        .catch((error) => {
          console.log(error);
          this.comboboxFilterloading = false;
        });
    },

    getSavedFilters() {
      this.savedFilterLoading = true;
      service
        .ListSavedFilters({ idCompany: this.idCompany })
        .then((res) => {
          if (res.status != 500) {
            this.savedFilters = res.data.answer;
          } else {
            this.$emit("showSnackbar", "Erro ao carregar filtros salvos");
          }

          this.savedFilterLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.savedFilterLoading = false;
        });
    },

    deleteFilter() {
      this.deleteFilterLoading = true;
      if (!this.selectedFilter) return;

      this.selectedFilter.delete = true;
      this.selectedFilter.values?.forEach((el) => {
        el.delete = true;
      });

      this.selectedFilter.monitoringItems?.forEach((el) => {
        el.isDeleted = true;
      });

      service
        .CUDFilters(this.selectedFilter)
        .then((res) => {
          if (res.status != 500) {
            this.cleanSelectedFilters();
            this.getSavedFilters();
            this.selectedFilter = null;
            this.$emit(
              "showSnackbar",
              "Filtro excluido com sucesso!",
              "success"
            );
            this.$refs["deleteFilterDialog"].closeDialog();
            this.deleteFilterLoading = false;
          } else {
            this.$emit("showSnackbar", "Falha ao excluir filtro!", "error");
            this.deleteFilterLoading = false;
          }
        })
        .catch((error) => {
          console.log("Erro no deletar filtro", error);
          this.deleteFilterLoading = false;
        });
    },

    fillSelectedFilter() {
      this.cleanFilters();
      this.selectedFilter.monitoringItems?.forEach((el) => {
        function hasId(id) {
          return id.idMonitoringItem === el.monitoringId;
        }

        let equalProduct = this.items.find(hasId);
        this.products.push(equalProduct);
        equalProduct = null;
      });

      this.filters.forEach((filter) => {
        const savedValues = this.selectedFilter.values.filter(
          (value) => value.valueType === filter.valueType
        );

        if (savedValues.length > 0) {
          const selectedItems = [];

          savedValues.forEach((savedValue) => {
            const values = savedValue.value.split(",");
            const items = filter.items.filter((item) =>
              values.includes(item.id)
            );

            items.forEach((item) => {
              selectedItems.push({
                id: item.id,
                description: item.description,
              });
            });
          });

          filter.selected = selectedItems.length > 0 ? selectedItems : null;
        }
      });

      this.selectedFilter.values.forEach((filter) => {
        if (filter.valueType == 8) {
          let numberFilter = parseFloat(filter.value);
          this.yearsFilterSelected.push(numberFilter);
        }

        if (filter.valueType == 7) {
          this.regionFilterSelected.push(filter.value);
        }

        if (filter.valueType == 9) {
          let itemSelected = this.locationFilters[0].items.find(
            (element) => element.idEstado == filter.value
          );
          this.locationFilters[0].selected = itemSelected;
          this.getCity(
            itemSelected.idEstado,
            itemSelected.uf,
            itemSelected.nome
          );
        }
      });
    },

    cleanFilters() {
      this.products = [];
      this.filters.forEach((el) => {
        el.selected = null;
      });
      this.locationFilters.forEach((el) => {
        el.selected = [];
        if (el.value == "localeCity" || el.value == "localeNeighborhood") {
          el.items = [];
          el.isDisable = true;
        }
      });
    },

    cleanSelectedFilters() {
      this.products = [];
      this.selectedFilter = null;
      this.filters.forEach((el) => {
        el.selected = null;
      });
      this.locationFilters.forEach((el) => {
        el.selected = [];
        if (el.value == "localeCity" || el.value == "localeNeighborhood") {
          el.items = [];
          el.isDisable = true;
        }
      });
      this.yearsFilterSelected = [];
      this.regionFilterSelected = [];
      this.range = [20000, 60000];
      this.min = 20000;
      this.max = 60000;
    },

    setSaveFilterJob(event) {
      this.getSavedFilters();
      this.selectedFilter = event;
      this.$emit("showSnackbar", "Filtro salvo com sucesso!", "success");
    },

    //Fill filter machinery
    fillMachineryFilters(filters) {
      for (var i = 1980; i <= 2023; i++) {
        this.yearsFilterItems.push(i);
      }

      this.regionFilterItems = filters.localStates;
    },

    onChangeAnyLocationFilter(filter) {
      if (this.selectedFilter != null) {
        this.selectedFilter = null;
        this.locationFilters.forEach((element, index) => {
          if (index != 0) {
            element.selected = [];
          }
        });
      }
      if (filter.value == "localeUf") {
        const id = filter.selected.idEstado;
        const uf = filter.selected.uf;
        const name = filter.selected.nome;
        this.getCity(id, uf, name);
      }

      if (filter.value == "localeCity") {
        const id = filter.selected.idMunicipio;
        this.getNeighboghood(id);
      }
    },

    // Location Filter
    getStates() {
      this.locationFilters[0].isLoading = true;
      service
        .getState(this.idCompany)
        .then((res) => {
          if (res.status !== 500) {
            this.locationFilters[0].items = res.data.answer.estados;
            this.locationFilters[0].items.forEach((element) => {
              element.nome = element.nome.toUpperCase();
            });
            this.locationFilters[0].isDisable = false;
            this.locationFilters[0].isLoading = false;
          }
        })
        .catch((error) => {
          this.locationFilters[0].isLoading = false;
          console.log("Erro do getState: ", error);
        });
    },

    getCity(id, uf, name) {
      this.locationFilters[1].isLoading = true;
      service
        .getCity(id, uf, name, this.idCompany)
        .then((res) => {
          if (res.status !== 500) {
            this.locationFilters[1].items = res.data.answer.municipios;
            this.locationFilters[1].isDisable = false;
            this.locationFilters[1].isLoading = false;
            if (this.selectedFilter !== null) {
              this.selectedFilter.values.forEach((filter) => {
                if (filter.valueType == 10) {
                  let itemSelected = this.locationFilters[1].items.find(
                    (element) => element.idMunicipio == filter.value
                  );
                  this.locationFilters[1].selected = itemSelected;
                  this.getNeighboghood(itemSelected.idMunicipio);
                }
              });
            }
          }
        })
        .catch((error) => {
          this.locationFilters[1].isLoading = false;
          console.log("Erro do getCity ", error);
        });
    },

    getNeighboghood(id) {
      let bodyRequest = {
        idCity: [id],
        idCompany: this.idCompany,
      };
      this.locationFilters[2].isLoading = true;
      service
        .getNeighborhood(bodyRequest)
        .then((res) => {
          if (res.status !== 500) {
            this.locationFilters[2].items = res.data.answer.neighborhood;
            this.locationFilters[2].items.forEach((element) => {
              element.neighborhood = element.neighborhood.toUpperCase();
            });
            this.locationFilters[2].isDisable = false;
            this.locationFilters[2].isLoading = false;
            if (this.selectedFilter !== null) {
              this.selectedFilter.values.forEach((filter) => {
                if (filter.valueType == 11) {
                  let itemSelected = this.locationFilters[2].items.find(
                    (element) =>
                      Number(element.postalCode) == Number(filter.value)
                  );
                  this.locationFilters[2].selected.push(itemSelected);
                }
              });
            }
          }
        })
        .catch((error) => {
          this.locationFilters[2].isLoading = false;
          console.log("Erro do getNeighboghood ", error);
        });
    },
  },

  mounted() {
    if (this.idCompany == 2699) {
      this.getStates();
    }

    this.getComboboxFilters();
    this.getSavedFilters();
  },

  created() {
    this.setDate();
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}

.selection-chips {
  background: var(--dark-moderate-blue) !important;
  color: white !important;
  padding: 0px 10px !important;
}

.selection-chips-span {
  font-size: 14px !important;
  max-width: 50ch !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.filter-label {
  padding: 4px 0px;
  margin-bottom: 16px;
  border-bottom: 0.5px solid #ccc;
  box-sizing: border-box;
  font-family: "Ubuntu";
  font-weight: bold !important;
  font-size: 14px !important;
}

.filter-label span {
  width: max-content;
  border-bottom: 3px solid $brand-color-secondary-pure;
  padding-bottom: 4px;
}
</style>