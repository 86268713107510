<template>
  <v-card id="similarity-card" class="pa-4">
    <v-row no-gutters align="center" class="mb-4">
      <h4>REPLICAR PRECIFICAÇÃO PARA</h4>
      <v-spacer></v-spacer>
      <reply-pricing-modal
        :idPriceGroup="idPriceGroup"
        :tableReply="tableReply"
        :headerPricing="headerPricing"
        :idPriceProjection="idPriceProjection"
        @callReplyTable="$emit('callReplyTable')"
      />
    </v-row>

    <v-row v-if="tableReplyLoading" class="mt-10" no-gutters justify="center">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <v-data-table
      v-if="!tableReplyLoading && tableReply.length != 0"
      :headers="headers"
      :items="tableReply"
      :hide-default-footer="tableReply.length <= 5"
      :items-per-page="5"
    ></v-data-table>

    <v-row
      v-if="!tableReplyLoading && tableReply.length == 0"
      no-gutters
      justify="center"
      class="mt-6"
    >
      <h4>Nenhum produto encontrado</h4>
    </v-row>
  </v-card>
</template>
<script>
import replyPricingModal from "./reply-pricing-modal.vue";
export default {
  props: [
    "tableReplyLoading",
    "tableReply",
    "idPriceGroup",
    "headerPricing",
    "idPriceProjection",
  ],
  components: { replyPricingModal },
  data() {
    return {
      headers: [
        {
          text: "Artigo",
          align: "center",
          sortable: false,
          value: "idProduct",
        },
        {
          text: "Produto",
          align: "center",
          sortable: false,
          value: "description",
        },
      ],
      items: [],
    };
  },
};
</script>
<style lang="scss">
#similarity-card {
  margin-top: 8px;
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 11px !important;
    height: 40px !important;
  }
}
</style>