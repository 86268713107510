<template>
  <v-container id="dashboard-bamaq" class="padding-all-30">
    <!-- TITLE --->
    <div>
      <v-row class="ma-0 pa-0 mb-2" align-items="center">
        <div style="display: flex; align-items: center">
          <h1 class="page-title">DASHBOARD</h1>
          <v-tooltip right color="#F5F5F5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="cursor: pointer; pointer-events: all"
                v-bind="attrs"
                v-on="on"
                color="#6792F8"
                right
              >
                mdi-information-outline</v-icon
              >
            </template>
            <span style="color: #000">Alguma informação</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-row class="ma-0 pa-0 page-breadcrumbs">Dashboard</v-row>
    </div>

    <!-- CARD FILTES --->
    <Filters-card @searchProducts="search" ref="searchProductRef" />

    <label class="card-title" v-t="'TXT_RESULTS_UPPERCASE'"></label>
    <v-card flat>
      <!--- RESULTS -->
      <Results-card
        :requestData="requestData"
        @onLoadingFinished="(resultsCardLoading = false), setLoading()"
        @onLoadingStarted="(resultsCardLoading = true), setLoading()"
      />

      <!-- HISTORY CHART --->
      <History-price-chart
        :requestData="requestData"
        @onLoadingFinished="(historyPriceChartLoading = false), setLoading()"
        @onLoadingStarted="(historyPriceChartLoading = true), setLoading()"
      />
      </v-card>
  </v-container>
</template>

<script>
import FiltersCard from "./components/filters-card.vue";
import HistoryPriceChart from "./components/history-price-chart.vue";
import ResultsCard from "./components/results-card.vue";
export default {
  components: { FiltersCard, ResultsCard, HistoryPriceChart },
  name: "dashboard-bamaq",
  data() {
    return {
      requestData: null,

      resultsCardLoading: false,
      historyPriceChartLoading: false,
      announcementMapLoading: false,
    };
  },

  methods: {
    setLoading() {
      this.$refs.searchProductRef.setLoading(
        this.resultsCardLoading ||
          this.historyPriceChartLoading ||
          this.announcementMapLoading
      );
    },

    search(data) {
      this.requestData = data;
    },
  },

  created() {
    document.title = "PREDIFY | Predimonitor";
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 24px;
  font-weight: 500;
}

.page-breadcrumbs {
  font-size: 14px;
  opacity: 0.5;
}

.card-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
}
</style>