<template>
  <div>
    <label>{{ searchLabel }}</label>
    <tooltip-informations v-if="hasTooltip" :title="tooltipText" :rightIcon="true" />
    <v-text-field
      :value="value"
      :label="$t('TXT_SEARCH')"
      @input="searching"
      append-icon="mdi-magnify"
      single-line
      hide-details
      outlined
      dense
      rounded
      class="mt-1"
    ></v-text-field>
  </div>
</template>

<script>
import prdDelay from "@/Design_System/modulos/prd-delay";
import TooltipInformations from "./tooltip-informations.vue";
let myDelay = null;

export default {
  name: "PredifySearch",
  components: {
    TooltipInformations,
  },
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: String,
    useDelay: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 500,
    },
    delayCharLen: {
      type: Number,
      default: 0,
    },
    searchLabel: {
      type: String,
      default: "Digite o termo a ser buscado:",
    },
    searchPlaceholder: {
      type: String,
      default: "Busca",
    },
    tooltipText: {
      type: String,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async searching(value) {
      if (!this.useDelay) {
        this.$emit("input", value);
      } else {
        await myDelay.Process(value, async (v) => {
          this.$emit("input", v);
          return value;
        });
      }
    },
  },
  watch: {
    delay(newValue) {
      if (myDelay.delay != newValue) myDelay.delay = newValue;
    },
    delayCharLen(newValue) {
      if (myDelay.minLength != newValue) myDelay.minLength = newValue;
    },
  },
  created() {
    myDelay = new prdDelay(this.delay, this.delayCharLen);
  },
};
</script>