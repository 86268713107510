<template>
  <div>
    <v-row v-if="elasticityLoading" class="mt-10" no-gutters justify="center">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <div v-else class="data-main">
      <div class="elasticity-data">
        <div>
          <span>Preço médio</span>
          <div>
            {{ setCurrency(avgPrice) }}
          </div>
        </div>
        <div>
          <span>Preço da demanda</span>
          <div>
            {{ setCurrency(demandPrice) }}
          </div>
        </div>
        <div>
          <span>Variação da demanda</span>
          <div :class="setMarginColor(demandVariation)">
            {{ formatDecimals(demandVariation) }}
          </div>
        </div>
      </div>
      <div class="elasticity-data">
        <div>
          <span>Demanda média</span>
          <div>
            {{ formatDecimals(avgDemand) }}
          </div>
        </div>
        <div>
          <span>Demanda calculada</span>
          <div>
            {{ formatDecimals(calculateDemand) }}
          </div>
        </div>
        <div>
          <span>Variação de venda</span>
          <div :class="setMarginColor(salesVariation)">
            {{ setCurrency(salesVariation) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setCurrency } from "@/utils/format-currency.js";
import { formatDecimals } from "@/utils/format-decimals.js";
import { setMarginColor } from "@/utils/set-margin-color.js";
export default {
  props: ["elasticity", "elasticityLoading"],
  data() {
    return {};
  },

  computed: {
    avgPrice() {
      return this.elasticity?.averagePrice ?? null;
    },

    avgDemand() {
      return this.elasticity?.averageDemand ?? null;
    },

    demandPrice() {
      if (!this.elasticity) return null;

      let demandPrice = null;

      if (this.elasticity.ajustedPrice)
        demandPrice = this.elasticity.ajustedPrice;
      else demandPrice = this.elasticity.salePrice;

      return demandPrice;
    },

    calculateDemand() {
      if (!this.elasticity) return null;

      let calculateDemand = null;

      if (this.elasticity.ajusted_Price_Demand)
        calculateDemand = this.elasticity.ajusted_Price_Demand;
      else calculateDemand = this.elasticity.salePrice_Demand;

      return calculateDemand;
    },

    demandVariation() {
      if (!this.elasticity) return null;

      let demandVariation = (this.calculateDemand / this.avgDemand - 1) * 100;
      
      if (!demandVariation) return null;

      return demandVariation;
    },

    salesVariation() {
      if (!this.elasticity) return null;

      let salesVariation =
        this.demandPrice * this.calculateDemand -
          this.avgPrice * this.avgDemand ?? null;

      if (!salesVariation) return null;

      return salesVariation;
    },
  },

  methods: {
    setCurrency,
    formatDecimals,
    setMarginColor,
  },
};
</script>

<style lang="scss" scoped>
.data-main {
  display: flex;
  margin-top: 12px;
  gap: 1rem;

  .elasticity-data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    > div > div {
      border: 1px solid rgb(121, 119, 119);
      border-radius: 5px;
      padding: 6px;
      margin-top: 4px;
    }
  }

  span {
    font-size: 12px;
  }

  .negative-value {
    color: $feedback-color-warning-pure;
  }
  .positive-value {
    color: $feedback-color-success-pure;
  }
}
</style>