import axiosInstance, { API_URL_UPL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class UPLService extends ServiceBase {

  async getVariaveisTributacao() {
    return await axiosInstance.get(API_URL_UPL + "api/VariaveisTributacao/");
  }

  async updatetVariaveisTributacao(variaveisTributacao) {
    return await axiosInstance.put(API_URL_UPL + `api/VariaveisTributacao`, variaveisTributacao, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async getFilters() {
    return await axiosInstance.get(API_URL_UPL + "api/Deliberation/Filters");
  }

  async getDeliberationVersusBudget(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/VersusBudget?brand=${brand}&segment=${segment}`);
  }
  async getDeliberationVersusFinReview(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/VersusFin?brand=${brand}&segment=${segment}`);
  }
  async getDeliberationProjecoesCogs(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/ProjecoesCogs?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationProjecoesNetPrice(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/ProjecoesNetPrice?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationProjecoesGrossMarginInMio(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/ProjecoesGrossMarginInMio?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationProjecoesVolumeInTons(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/ProjecoesNetSalesInMio?brand=${brand}&segment=${segment}`);
  }


  async getDeliberationProjecoesNetSalesInMio(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/ProjecoesVolumeInTons?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationInfo(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/info?brand=${brand}&segment=${segment}`);
  }


  async getDeliberationPriceListFVUS(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/PriceListFVUS?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationPriceListFVBRL(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/PriceListFVBRL?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationPriceListPVBRL(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/PriceListPVBRL?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationPPricListMarginFVUSD(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/PricListMarginFVUSD?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationBlockMargin(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/BlockMargin?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationBlockMarginUSD(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/BlockMarginUSD?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationBlockMarginBRL(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/BlockMarginBRL?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationPricListMarginPVUSD(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/PricListMarginPVUSD?brand=${brand}&segment=${segment}`);
  }

  async getLastMonthWeightedPriceListAndPerformance(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/LastMonthWeightedPriceListAndPerformance?brand=${brand}&segment=${segment}`);
  }

  async getLastMonthPriceListMain(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/LastMonthPriceListMain?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationOnePage(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/OnePage?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationAssumptions(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/Assumptions?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationDiscountApprovalHierarchys(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/DiscountApprovalHierarchys?brand=${brand}&segment=${segment}`);
  }

  async getDeliberationDeliberation(brand, segment) {
    return await axiosInstance.get(API_URL_UPL + `api/Deliberation/Deliberation?brand=${brand}&segment=${segment}`);
  }

  async getProducts() {
    return await axiosInstance.get(API_URL_UPL + "api/produtos");
  }

  async updateDeliberationInfo(deliberation) {
    return await axiosInstance.put(API_URL_UPL + `api/Deliberation/info`, deliberation, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async updateDeliberationDeliberation(deliberation) {
    return await axiosInstance.put(API_URL_UPL + `api/Deliberation/deliberation`, deliberation, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async getRelatorio() {
    return await axiosInstance.get(API_URL_UPL + "api/relatorio", { responseType: 'blob' });
  }

  async getRelatorioDeliberation() {
    return await axiosInstance.get(API_URL_UPL + "api/relatorio/deliberation", { responseType: 'blob' });
  }

  async getRelatorioFinalDeliberation() {
    return await axiosInstance.get(API_URL_UPL + "api/relatorio/deliberation/final", { responseType: 'blob' });
  }

  async getEmptyProduct() {
    return await axiosInstance.get(API_URL_UPL + "api/produtos/empty");
  }

  async getProductById(id) {
    return await axiosInstance.get(API_URL_UPL + `api/produtos/${id}`);
  }

  async getCustoReposicao() {
    return await axiosInstance.get(API_URL_UPL + "api/CustoReposicaoFiles");
  }

  async getExternalFiles() {
    return await axiosInstance.get(API_URL_UPL + "api/ExternalFiles");
  }

  async getDeliberationApp() {
    return await axiosInstance.get(API_URL_UPL + "api/DeliberationApplication");
  }

  async getInternalFiles() {
    return await axiosInstance.get(API_URL_UPL + "api/InternalFiles");
  }

  async getGraficoPesoVariavel(name) {
    return await axiosInstance.get(API_URL_UPL + `api/PesoVariaveis/${name}`);
  }

  async getGraficoCustoParaPrecificacao(name) {
    return await axiosInstance.get(API_URL_UPL + `api/GraficoCustoParaPrecificacao/${name}`);
  }

  async getGraficoCustoParaPrecificacaoById(id) {
    return await axiosInstance.get(API_URL_UPL + `api/GraficoCustoParaPrecificacao/id/${id}`);
  }

  async getGraficoHistoricoDesempenho(name) {
    return await axiosInstance.get(API_URL_UPL + `api/GraficoHistoricoDesempenho/${name}`);
  }

  async getCustoReposicaoCompletoFiles(name) {
    return await axiosInstance.get(API_URL_UPL + `api/CustoReposicaoCompletoFiles/${name}`);
  }

  async updateProduct(product) {
    return await axiosInstance.put(API_URL_UPL + `api/produtos/${product.id}`, product, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async UpdateConcorrente(product) {
    return await axiosInstance.put(API_URL_UPL + `api/produtos/concorrente/${product.id}`, product, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async deleteProduct(id) {
    return await axiosInstance.delete(API_URL_UPL + `api/produtos/${id}`);
  }

  async createProduct(product) {
    return await axiosInstance.post(API_URL_UPL + `api/produtos`, product, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async addHistoricoIaVariavel(variavel) {
    return await axiosInstance.post(API_URL_UPL + `api/HistoricoIA`, variavel, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async getHistoricoIaVariavel() {
    return await axiosInstance.get(API_URL_UPL + "api/HistoricoIA");
  }

  async recalculateProduct() {
    return await axiosInstance.post(API_URL_UPL + `api/produtos/recalcularProdutos`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


  async updateSobreProduct(id, sobre) {
    let data = {
      segmento: sobre.segmento,
      classificacao: sobre.classificacao,
      culturaDriver: sobre.culturaDriver
    }

    return await axiosInstance.patch(API_URL_UPL + `api/produtos/sobre/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async updatMercadoProduct(id, mercado) {
    let data = {
      marketShare: mercado.marketShare,
      potencialAreaTratada: mercado.potencialAreaTratada,
    }

    return await axiosInstance.patch(API_URL_UPL + `api/produtos/mercado/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async updateInfo(id, cardType, newMessage) {
    return await axiosInstance.patch(API_URL_UPL + `api/produtos/${cardType}/${id}?newMessage=${newMessage}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}