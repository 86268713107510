import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class ImportFileService extends ServiceBase {

    async importFile(request) {
        return await axiosInstance.post(API_URL + "api/V2/Company/UploadFile", request)
    }

    async listRegion() {
        return await axiosInstance.get(API_URL + "api/V2/Region/ListRegions")
    }

    async listCompanyByRegion(accessLevel) {
        return await axiosInstance.get(API_URL + "api/Empresa/GetEmpresaNome", {params: { accessLevel }})
    }

    async importFileDialog(request) {
        return await axiosInstance.post(API_URL + "api/v2/company/ProcessFile", request)
    }
}