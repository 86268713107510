<template>
  <div class="mt-6">
    <!-- CALENDAR BUTTON -->
    <div class="my-flex">
      <v-tabs
        v-model="tab"
        color="black"
        slider-color="#3c5ca7"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <h4>VISÃO GERAL</h4>
        </v-tab>
      </v-tabs>
    </div>
    <div>
      <total-projection
        :geralVisionData="geralVisionData"
        :totalProjectionData="totalProjectionData"
        :isLoading="isLoading"
        :loadingProjection="loadingProjection"
        :chartIALoading="chartIALoading"
      />
    </div>
  </div>
</template>

<script>
import totalProjection from "./components/total-projection.vue";
export default {
  props: [
    "geralVisionData",
    "totalProjectionData",
    "isLoading",
    "loadingProjection",
    "chartIALoading",
  ],
  components: { totalProjection },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>