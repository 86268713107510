<template>
  <v-card class="pa-4">
    <v-row no-gutters align="center">
      <h4>Adicionar empresa</h4>
      <v-spacer></v-spacer>
      <prd-btn
        :title="'Voltar'"
        :icon="'mdi-arrow-left'"
        :smallIcon="true"
        :leftIcon="true"
        @click="$emit('showAddModal', false)"
      />
    </v-row>
  </v-card>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { prdBtn },
  data() {
    return {};
  },
};
</script>