<template>
  <v-btn
    block
    class="mx-0 mt-0 rounded-sm white--text margin-bottom-30"
    color="var(--bright-red)"
    >{{ title }}<v-icon>mdi-chevron-right</v-icon></v-btn
  >
</template>

<script>
export default {
  name: "loginFormButton",
  props: {
    title: {
      required: true,
      type: String,
    },
  },
};
</script>