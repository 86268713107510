<template>
  <div>
    <v-card
      :disabled="isLoading"
      class="pa-4 d-flex flex-column gap-4 font-size-12"
      flat
    >
      <div class="d-flex align-center" align="center">
        <label v-t="'TXT_MARKET_ANALYSIS'" class="font-weight-bold"></label>
        <predimonitor-table
          v-if="
            !productRegion.price.marketResult &&
            productRegion.price.idMonitoringItem != 0
          "
          :idMonitoringItem="productRegion.price.idMonitoringItem"
          class="ml-4"
        />
        <div
          v-show="
            this.productRegion.price.marketResult != null &&
            this.productRegion.price.marketResult
              .monitoring_ProductMarketResultItem > 0
          "
        >
          <main-modal :productRegion="productRegion" />
        </div>
      </div>
      <div class="d-flex align-end gap-6">
        <!-- <div class="flex-1 max-width-150">
          <label v-t="'TXT_LAST_ENTRY_COST'"></label>
          <div class="bordered pa-2 mt-2 nowrap rounded">
            <span v-if="lastEntranceCost !== null">{{
              formatMoney(lastEntranceCost)
            }}</span>
            <span v-else>R$ 0</span>
          </div>
        </div> -->
        <div class="flex-1 max-width-150">
          <label
            v-t="'TXT_COMPETITORS_PRICES'"
            v-if="this.idCompany == 2857"
          ></label>
          <label v-t="'TXT_PRODUCT_COST'" v-else></label>
          <div class="bordered pa-2 mt-2 nowrap rounded">
            <span v-if="productCost !== null">
              {{ productCost | setToIsoString }}
            </span>
            <span v-else>&nbsp;</span>
          </div>
        </div>
        <div class="flex-1 max-width-150">
          <label v-t="'TXT_CURRENT_PRICE'">Preço atual</label>
          <div class="bordered pa-2 mt-2 rounded nowrap">
            <span v-if="currentPrice !== null">
              {{ currentPrice | setToIsoString }}
            </span>
            <span v-else>&nbsp;</span>
          </div>
        </div>
        <div class="d-flex flex-1 flex-column gap-2">
          <div>
            <label v-t="'TXT_MARKET_MONITORING_IA'"></label>
            &nbsp;
            <v-icon
              color="#3C5CA7"
              small
              v-show="
                !isEditing && hasResource('enterprise.marketresult.manual.edit')
              "
              style="max-width: 25px"
              @click="startEditing()"
            >
              mdi-pencil-outline
            </v-icon>
            &nbsp;
            <v-icon
              color="#37B352"
              small
              v-show="isEditing"
              style="max-width: 25px"
              @click="updateManualPrice()"
            >
              mdi-check-outline
            </v-icon>
            &nbsp;
            <v-icon
              color="#B00020"
              small
              v-show="isEditing"
              style="max-width: 25px"
              @click="clearManualPrice()"
            >
              mdi-close-circle-outline
            </v-icon>
            &nbsp;
          </div>
          <div
            class="d-flex gap-2 bordered rounded pa-2 align-center justify-space-between"
          >
            <div class="d-flex align-center nowrap">
              <v-icon left color="#37B352" small>
                mdi-arrow-up-bold-outline
              </v-icon>
              <span v-if="!isEditing">
                {{ maxPrice | setToIsoString }}
              </span>
              <span v-else>
                <input
                  type="number"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding-left: 5px;
                    max-width: 70px;
                  "
                  v-model="manualMaxPrice"
                />
              </span>
            </div>
            <!-- <div v-else>&nbsp;</div> -->
            <div class="d-flex align-center nowrap">
              <v-icon left color="#3C5CA7" small>
                mdi-minus-circle-outline
              </v-icon>
              <span v-if="!isEditing">
                {{ avgPrice | setToIsoString }}
              </span>
              <span v-else>
                <input
                  type="number"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding-left: 5px;
                    max-width: 70px;
                  "
                  v-model="manualAvgPrice"
                />
              </span>
            </div>
            <!-- <div v-else>&nbsp;</div> -->
            <div class="d-flex align-center nowrap">
              <v-icon left color="#B00020" small>
                mdi-arrow-down-bold-outline
              </v-icon>
              <span v-if="!isEditing">
                {{ minPrice | setToIsoString }}
              </span>
              <span v-else>
                <input
                  type="number"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding-left: 5px;
                    max-width: 70px;
                  "
                  v-model="manualMinPrice"
                />
              </span>
            </div>
            <!-- <div v-else>&nbsp;</div> -->
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import MainModal from "../../../../monitorig-item-modal/main-modal.vue";
import AiPricingService from "@/service/ai-pricing";
import PredimonitorTable from "./predimonitor-table.vue";

export default {
  props: ["productRegion", "productName"],
  components: {
    MainModal,
    PredimonitorTable,
  },

  data: () => ({
    service: new AiPricingService(),
    isEditing: false,
    isLoading: false,
    manualMaxPrice: null,
    manualAvgPrice: null,
    manualMinPrice: null,

    backManualMaxPrice: null,
    backManualAvgPrice: null,
    backManualMinPrice: null,
  }),
  methods: {
    setToIsoString(value) {
      let currency = localStorage.getItem("currency");
      if (!value) return;
      if (value == "-") return;

      let rounded = (Math.trunc(value * 100) / 100).toFixed(2);
      let formated = rounded.replace(".", ",");

      return currency + " " + formated;
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    startEditing() {
      this.backManualMaxPrice = this.manualMaxPrice;
      this.backManualAvgPrice = this.manualAvgPrice;
      this.backManualMinPrice = this.manualMinPrice;
      this.isEditing = true;
    },

    async updateManualPrice() {
      this.isLoading = true;
      this.isEditing = false;

      this.backManualMaxPrice = this.manualMaxPrice;
      this.backManualAvgPrice = this.manualAvgPrice;
      this.backManualMinPrice = this.manualMinPrice;

      let data = {
        idCompany: this.idCompany,
        idEnterprisePricesProjection:
          this.productRegion.price.idEnterprisePricesProjection,
        idEnterprisePriceGroups:
          this.productRegion.price.idEnterprisePriceGroups,
        manualMaxPrice: this.manualMaxPrice,
        manualAvgPrice: this.manualAvgPrice,
        manualMinPrice: this.manualMinPrice,
      };

      let response = await this.service.ManualMarketResult.call(data);

      this.clearManualPrice();

      this.$emit("updated-market-result", response);

      this.isLoading = false;
    },

    clearManualPrice() {
      this.isEditing = false;
      this.manualMaxPrice = this.backManualMaxPrice;
      this.manualAvgPrice = this.backManualAvgPrice;
      this.manualMinPrice = this.backManualMinPrice;
    },

    searchPredimonitor() {
      let data = [
        { idMonitoringItem: this.productRegion.price.idProductPredify },
        { productName: this.productName },
      ];

      this.$store.commit("searchProductPredimonitor", data);
      this.$router.push("../predimonitor");
    },

    init() {
      this.manualMaxPrice = this.productRegion.price.manualMaxPriceMarketResult;
      this.manualAvgPrice = this.productRegion.price.manualAvgPriceMarketResult;
      this.manualMinPrice = this.productRegion.price.manualMinPriceMarketResult;
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    productCost() {
      return this.productRegion?.price?.productCost ?? null;
    },
    currentPrice() {
      return this.productRegion?.price?.lastSalePrice ?? null;
    },
    lastEntranceCost() {
      // TODO - Implement this
      return null;
    },
    maxPrice() {
      if (this.productRegion?.price?.manualMaxPriceMarketResult != null)
        return this.productRegion.price.manualMaxPriceMarketResult;

      return this.productRegion?.price?.marketResult?.maxPrice ?? null;
    },
    minPrice() {
      if (this.productRegion?.price?.manualMinPriceMarketResult != null)
        return this.productRegion.price.manualMinPriceMarketResult;

      return this.productRegion?.price?.marketResult?.minPrice ?? null;
    },
    avgPrice() {
      if (this.productRegion?.price?.manualAvgPriceMarketResult != null)
        return this.productRegion.price.manualAvgPriceMarketResult;
      return this.productRegion?.price?.marketResult?.avgPrice ?? null;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.bordered {
  border: 1px solid $neutral-color-low-light;
}
.max-width-150 {
  max-width: 150px;
}
</style>
