<template>
    <v-card class="pa-4 pt-0 mt-2">
        <v-row no-gutters class="mb-4">
            <v-col cols="12" md="4">
                <h4>MERCADO</h4>
            </v-col>
            <v-col cols="12" class="pa-1" offset="7" align="right" md="1">
                <v-icon @click="saveMarket()" title="Salvar" :color="$prdStyles('color-success')">mdi-content-save</v-icon>
            </v-col>
        </v-row>
        <v-row no-gutters class="pb-2">
            <v-col class="pa-0 mt-3">
                <label>Market share</label>
                <v-text-field hide-details outlined dense class="mt-2" v-model="marketShare"></v-text-field>
            </v-col>
            <v-col class="pl-1 mt-3">
                <span><label>Potencial de área tratada (Mio/Ha)</label></span>
                <v-text-field hide-details outlined dense class="mt-2" v-model="potencialAreaTratada"></v-text-field>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import ServiceUPL from "@/service/upl/index";
export default {
    props: ["product"],
    data() {
        return {
            marketShare: " ",
            potencialAreaTratada: " ",
            service: new ServiceUPL(),
        };
    },
    methods: {
        async saveMarket() {
            let mercado = {
                marketShare: this.marketShare,
                potencialAreaTratada: this.potencialAreaTratada,
            };
            await this.service.updatMercadoProduct(this.product.id, mercado).then(() => {
                alert("Salvo com sucesso");
            });
        }
    },
    beforeMount() {
        if (this.product.mercado != null) {
            this.marketShare = this.product.mercado.marketshare;
            this.potencialAreaTratada = this.product.mercado.potencialareatratada;
        }
    }
};
</script>