/**
 * @template {(...any) => Promise<any>} MethodFunction
 * @typedef {"NOT_CALLED" | "LOADING" | "ERROR" | "DATA_READY"} RequestState
 *
 * @typedef {{
 *   isLoading: boolean;
 *   error: Error | null;
 *   data: ReturnType<MethodFunction> | null;
 *   wasCalled: boolean;
 *   state: () => RequestState;
 *   call: MethodFunction;
 *   safeCall: MethodFunction;
 * }} ServiceMethod
 */

/**
 * This is a helper function to wrap your method in a bit of data.
 *
 * @template MethodFunction
 * @param {MethodFunction} methodFunction The actual function that'll make the
 *   request.
 * @returns {ServiceMethod<MethodFunction>}
 */
export function makeServiceMethod(methodFunction) {
  const method = {
    isLoading: false,
    error: null,
    data: null,
    wasCalled: false,
    async call(...args) {
      method.wasCalled = true;
      method.isLoading = true;
      method.error = null;
      method.data = null;
      try {
        method.data = await methodFunction(...args);
        return method.data;
      } catch (e) {
        method.error = e;
        throw e;
      } finally {
        method.isLoading = false;
      }
    },

    async safeCall(...args) {
      return method.call(...args).catch((e) => console.error(e));
    },

    /**
     * This function is just to make it easier to check in what state the
     * request is in.
     *
     * @returns {RequestState}
     */
    state() {
      if (!method.wasCalled) return "NOT_CALLED";
      if (method.isLoading) return "LOADING";
      if (!method.isLoading && method.error) return "ERROR";
      if (!method.isLoading && !method.error && method.data)
        return "DATA_READY";
      // This should be unreachable. If you ever see this state, it is a bug.
      return "UNKNOWN_STATE";
    },
  };

  return method;
}
