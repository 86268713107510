<template>
  <div>saindo</div>
</template>

<script>
export default {
  mounted() {
    let userData = {
      email: null,
      hasUser: false,
      rememberLogin: null,
      token: null,
      idCompany: 0,
    };

    this.$store.dispatch("setUser", userData);
    this.$router.push("/login/sign-in");
  },
};
</script>