<template>
  <v-card class="pa-4" id="suzano-infos" flat>
    <div style="width: 25% !important">
      <v-row no-gutters>
        <v-col class="pa-0">
          <v-row no-gutters
            ><label> Periodo de referência</label>
            <tooltip-informations :title="'tooltip'" :rightIcon="true"
          /></v-row>
          <v-menu
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="input-datapicker mt-1"
                hide-details
                outlined
                single-line
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-icon class="append-calendar" color="white" small>
                    mdi-calendar</v-icon
                  >
                </template>
              </v-text-field>
            </template>
            <!-- TODO - properly use user's locale -->
            <v-date-picker v-model="date" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="error" v-t="'TXT_CANCEL'"></v-btn>
              <v-btn outlined color="primary">Reset</v-btn>
              <v-btn
                color="primary"
                @click="$refs.menu.save(date)"
                v-t="'TXT_OK'"
              ></v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>

    <div class="my-flex" style="width: 75%">
      <v-card class="pa-4" style="width: 50%">
        <h4 class="mb-7">Datas importantes</h4>
        <div
          v-for="(date, index) in importantDates"
          :key="index"
          :class="[
            'date_info',
            date.isImportant ? 'border-green' : 'border-red',
          ]"
        >
          <span>{{ date.date }}</span>
          <v-icon v-show="date.hasAction" right :color="$prdStyles('color-primary')"
            >mdi-arrow-right-bold-box-outline</v-icon
          >
        </div>
      </v-card>
      <v-card class="pa-4" style="width: 50%">
        <v-row no-gutters class="mb-4">
          <h4>Ações correntes</h4>
          <v-spacer></v-spacer>
          <prd-btn :title="'Ver todos'" />
        </v-row>
        <div class="date_info border-blue">
          <span>18/08/2022 - Regular Price review begin</span>
          <v-icon right :color="$prdStyles('color-primary')"
            >mdi-arrow-right-bold-box-outline</v-icon
          >
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { prdBtn, TooltipInformations },
  data() {
    return {
      importantDates: [
        {
          date: "18/08/2022 - Regular Price review begin",
          isImportant: true,
          hasAction: true
        },
        {
          date: "19/08/2022 - Regular Price review begin",
          isImportant: false,
          hasAction: false
        },
        {
          date: "20/08/2022 - Regular Price review begin",
          isImportant: true,
          hasAction: true
        },
      ],
      data: [],
      date: null,
    };
  },
};
</script>

<style lang="scss" >
#suzano-infos {
  display: flex;
  gap: 1rem;

  .my-flex {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  .append-pencil {
    background: #3c5ca7;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .append-minus {
    background: red;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .input-datapicker .v-input__app end-inner {
    margin-top: 0px !important;
  }

  .append-calendar {
    height: 40px;
    width: 40px;
    background: #3c5ca7;
    margin-right: -11px;
    border-radius: 0px 4px 4px 0px;
    color: white;
  }

  .date_info {
    padding: 8px;
    background-color: $neutral-color-high-light;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .border-blue {
    border-left: 6px solid $brand-color-primary-pure;
    border-top-left-radius: 5px;
  }

  .border-green {
    border-left: 6px solid $feedback-color-success-medium;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .border-red {
    border-left: 6px solid $feedback-color-warning-pure;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
</style>