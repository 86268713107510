<template>
  <div v-if="actions != undefined" class="text-center">
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <history-button v-bind="attrs" v-on="on" />
      </template>

      <v-card class="d-flex flex-column gap-6 pa-4">
        <div class="font-size-20 font-weight-medium">{{ this.$i18n.t("TXT_ACTION_HISTORY") }}:</div>

        <div v-if="actions.length === 0" class="font-weight-medium">
         {{ this.$i18n.t("TXT_NONE_HISTORY_FOUND") }}:
        </div>
        <div class="grid flex-column gap-2" v-else>
          <template
            v-for="header in [
              'Ação',
              'Pronto para ERP',
              'Cargo',
              'Usuário',
              'Data',
            ]"
          >
            <span :key="header" class="table-header pa-2">{{ header }}</span>
          </template>
          <template
            v-for="(
              { action, toSend, companyRoleName, userName, date }, index
            ) in actions"
          >
            <span class="pa-2" :key="action.toString() + index">
              <v-icon :class="['mr-1', resolveToColorClass(action)]" :size="16">
                {{ resolveActionToIconName(action) }}
              </v-icon>

              {{ resolveActionToPastVerb(action) }}
            </span>

            <div
              class="d-flex align-center justify-center"
              :key="toSend.toString() + index"
            >
              <v-checkbox
                :input-value="toSend"
                disabled
                class="ma-0 pa-0"
                :hide-details="true"
              />
            </div>

            <span :key="companyRoleName + index" class="pa-2">{{
              companyRoleName
            }}</span>
            <span :key="userName + index" class="pa-2">{{ userName }}</span>
            <span :key="date + index" class="pa-2">{{
              formatDate(new Date(date), "dd/MM/yyyy - hh:mm:ss")
            }}</span>
          </template>
        </div>

        <prd-btn @click="dialog = false" title="Ok" class="align-self-end" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import historyButton from "../../../history-button";
import prdBtn from "@/Design_System/common/prd-btn";
import { formatDate } from "@/utils/format-date";
import {
  resolveActionToIconName,
  resolveToColorClass,
  resolveActionToPastVerb,
} from "../../../../actions-enum";

export default {
  props: ["actions"],
  components: { historyButton, prdBtn },
  data: () => ({
    dialog: false,
  }),
  methods: {
    formatDate,
    resolveActionToIconName,
    resolveActionToPastVerb,
    resolveToColorClass,
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.color-approve {
  color: $feedback-color-success-pure;
}
.color-reject {
  color: $brand-color-secondary-pure;
}
.color-scale {
  color: $feedback-color-helper-pure;
}
.table-header {
  background-color: $neutral-color-low-light;
}
.grid {
  display: grid;
  grid-template-columns: max-content max-content max-content 1fr max-content;
  grid-template-rows: max-content;
}
</style>
