<template>
  <v-data-table
    class="mt-3"
    style="width: 50%"
    :headers="headers"
    :items="data"
    :footer-props="footerProps"
  >
    <template v-slot:[`item.maxPrice`]="{ item }">
      {{ currency + item.maxPrice.toFixed(2).replace(".", ",") }}
    </template>
    <template v-slot:[`item.averagePrice`]="{ item }">
      {{ currency + item.averagePrice.toFixed(2).replace(".", ",") }}
    </template>
    <template v-slot:[`item.minPrice`]="{ item }">
      {{ currency + item.minPrice.toFixed(2).replace(".", ",") }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["data", "currency"],
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      headers: [
        {
          text: this.$i18n.t("TXT_COUNTRY"),
          value: "country",
          align: "center",
          width: "1rem",
        },
        {
          text: this.$i18n.t("TXT_MAXIMUM_PRICE"),
          value: "maxPrice",
          align: "center",
          width: "1rem",
        },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "averagePrice",
          align: "center",
          width: "1rem",
        },
        {
          text: this.$i18n.t("TXT_MIN_PRICE"),
          value: "minPrice",
          align: "center",
          width: "1rem",
        },
        {
          text: this.$i18n.t("TXT_AMOUNT_ADS"),
          value: "amountAds",
          align: "center",
          width: "1rem",
        },
      ],
    };
  },
};
</script>

<style>
</style>