import { render, staticRenderFns } from "./elasticity-card-price.vue?vue&type=template&id=0eaf95f7&scoped=true&"
import script from "./elasticity-card-price.vue?vue&type=script&lang=js&"
export * from "./elasticity-card-price.vue?vue&type=script&lang=js&"
import style0 from "./elasticity-card-price.vue?vue&type=style&index=0&id=0eaf95f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eaf95f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VRadio,VRow})
