<template>
  <div>
    <h3 v-if="dataTable == null || dataTable == []" class="text-center">
      {{this.$i18n.t("TXT_NO_DATA_FOUND_DISPLAY")}}
    </h3>
    <v-data-table
      v-if="dataTable"
      :headers="headers"
      :items="dataTable"
      hide-default-header
    >
      <template v-slot:header>
        <td
          v-for="(header, index) in headers"
          :key="index"
          class="original-header-table text-center"
        >
          {{ header.text }}
          <tooltip-informations :title="header.tooltipText" />
        </td>
      </template>

      <template v-slot:[`item.frequency`]="{ item }">
        <span>{{ item.frequency | roundUp }}</span>
      </template>

      <template v-slot:[`item.support`]="{ item }">
        <span>{{ item.support | supportFilter }}</span>
      </template>
    </v-data-table>
  </div>
</template> 

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { TooltipInformations },
  props: ["dataTable"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          value: "mix",
          align: "center",
          tooltipText: "tooltip Produto",
        },
        {
          text: this.$i18n.t("TXT_FREQUENCY"),
          value: "frequency",
          align: "center",
          tooltipText: "tooltip Frequência",
        },

        {
          text: this.$i18n.t("TXT_SUPPORT"),
          value: "support",
          align: "center",
          tooltipText: "tooltip Suporte",
        },
      ],
    };
  },
  filters: {
    roundUp(value) {
      return Math.ceil(value);
    },
    supportFilter(value) {
      if (!value) return "-";
      return (value * 100).toFixed(4) + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
.original-header-table {
  background-color: rgba(224, 224, 224, 255);
  padding: 12px;
  font-weight: bold;
  font-size: 13px;
  border-right: 2px solid white;
}
</style>