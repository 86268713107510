<template>
  <div>
    <slot v-if="!isLoading && !error" />
    <slot v-else-if="!isLoading && error" name="error" :error="error">
      <span class="text-error">
        {{ errorMessage }}
      </span>
    </slot>
    <progress-component v-else />
  </div>
</template>
<script>
import progressComponent from "@/components/common/progressComponent";

export default {
  props: ["isLoading", "spinnerSize", "error"],
  components: {
    progressComponent,
  },
  computed: {
    errorMessage() {
      if (!this.error) return "";
      if (this.error.message) return this.error.message;
      return this.error;
    },
  },
};
</script>
<style lang="scss" scopeed>
.text-error {
  color: $brand-color-secondary-pure;
}
</style>
