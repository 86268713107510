<template>
  <div>
    <v-card class="pa-4">
      <v-row no-gutters align="center">
        <!-- PRÉ FILTERS -->
        <v-col cols="12" md="4" class="mr-6" v-if="hasPreFilters">
          <combo-box
            v-model="item.selected"
            :key="item.idEnterprisePriceGroupDefaultFilter"
            :title="$t('TXT_SELECT_A') + item.fieldName"
            :items="item.values"
            :text="'description'"
            :loading="service.getAllFilterGroups.isLoading"
            :disabled="
              service.getAllFilterGroups.isLoading ||
              service.calculateProducts.isLoading ||
              service.listPriceGroups.isLoading ||
              service.calculateProducts.isLoading ||
              item.values.length == 0
            "
            :multiple="false"
            @input="selectPreFilter($event, item)"
            v-for="item in preFilters"
            hideIcon
          >
          </combo-box>
        </v-col>

        <!-- PRECIFICAÇÃO FILTER -->
        <v-col cols="12" md="4" class="mr-6">
          <label v-t="'TXT_SELECT_PRICING'"></label>
          <v-combobox
            v-model="selectedPriceGroup"
            :items="priceGroups"
            :loading="service.listPriceGroups.isLoading"
            :disabled="
              service.calculateProducts.isLoading || !isAllPreFiltersSelected
            "
            item-text="name"
            item-value="filter"
            dense
            outlined
            append-icon="mdi-chevron-down"
            hide-details
            class="mt-2"
          >
            <template v-slot:append-outer>
              <v-row>
                <create-pricing-modal
                  v-if="
                    isAllPreFiltersSelected &&
                    !service.listPriceGroups.isLoading
                  "
                  :pre-filters="generateLockedFilters"
                  :disabled="
                    service.getAllFilterGroups.isLoading ||
                    service.calculateProducts.isLoading ||
                    service.listPriceGroups.isLoading ||
                    service.calculateProducts.isLoading
                  "
                  :teste="'teste1111'"
                  @submit="fetchPriceGroups"
                />
              </v-row>
            </template>
          </v-combobox>
        </v-col>
        <!-- CALCULATE PRICES BTN -->
        <prd-btn-loading
          v-if="showCalculateToPeralta"
          :title="$t('TXT_CALCULATE_PRICES')"
          :disabled="
            selectedPriceGroup
              ? selectedPriceGroup.archive
              : null ||
                service.getAllFilterGroups.isLoading ||
                service.calculateProductsRules.isLoading ||
                service.listPriceGroups.isLoading ||
                service.calculateProducts.isLoading
          "
          :is-loading="
            service.getAllFilterGroups.isLoading ||
            service.calculateProducts.isLoading ||
            service.listPriceGroups.isLoading ||
            service.calculateProducts.isLoading
          "
          @click="calculatePrice"
          outlined
          class="mt-7 mr-4"
        />

        <!-- CALCULATE RULES BTN -->
        <prd-btn-loading
          :title="$t('TXT_CALCULATE_RULES')"
          :disabled="
            service.getAllFilterGroups.isLoading ||
            service.calculateProductsRules.isLoading ||
            service.listPriceGroups.isLoading ||
            service.calculateProductsRules.isLoading
          "
          :is-loading="
            service.getAllFilterGroups.isLoading ||
            service.calculateProductsRules.isLoading ||
            service.listPriceGroups.isLoading ||
            service.calculateProductsRules.isLoading
          "
          @click="calculateRules"
          outlined
          class="mt-7"
        />
      </v-row>

      <div class="color-error" v-if="service.getAllFilterGroups.error">
        {{ this.$i18n.t("TXT_ERROR_FIND_PRICING") }}:
        {{ service.getAllFilterGroups.error }}
      </div>
      <h2
        v-t="'TXT_FILTER'"
        class="font-size-16 font-weight-bold mt-6 mb-4"
      ></h2>
      <filter-groups
        ref="filterGroup"
        @input="projectionFilters = $event"
        :idPricingGroup="idEnterprisePriceGroups"
      />
    </v-card>
    <products-list
      ref="produtcsList"
      :idEnterprisePriceGroups="idEnterprisePriceGroups"
      :filters="projectionFilters"
      :idPriceGroup="
        selectedPriceGroup && selectedPriceGroup.idEnterprisePriceGroups
      "
    />
  </div>
</template>
<script>
import comboBox from "@/Design_System/common/comboBox";
import prdBtnLoading from "@/Design_System/common/prd-btn-loading";
import AiPricingService from "@/service/ai-pricing";
import createPricingModal from "./components/create-pricing-modal/main-modal";
import filterGroups from "./components/filter-groups";
import productsList from "./components/products-list/products-list";

import companyService from "@/service/company-service.js";
const Service = new companyService();

export default {
  components: {
    comboBox,
    filterGroups,
    createPricingModal,
    prdBtnLoading,
    productsList,
  },
  data: () => ({
    service: new AiPricingService(),

    // variaveis de pre filtro
    preFilters: [],
    selectedPreFilters: [],

    // variaveis relacionada a pricegroup
    priceGroups: [],
    selectedPriceGroup: null,

    projectionFilters: [],

    // idEnterprisePriceGroups: null,
  }),

  computed: {
    idEnterprisePriceGroups() {
      if (this.selectedPriceGroup == null) return null;

      return this.selectedPriceGroup.idEnterprisePriceGroups;
    },
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
    hasPreFilters() {
      return this.preFilters.length > 0;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    generateLockedFilters() {
      return this.selectedPreFilters; //.map((e) => {
      //   return {
      //     idEnterprisePriceGroupDefaultFilter:
      //       e.idEnterprisePriceGroupDefaultFilter,
      //     dbField: e.dbField,
      //     values: [e.value],
      //   };
      // });
    },

    showCalculateToPeralta() {
      if (this.isPredifyAdmin) {
        return true;
      }

      if (this.idCompany == 2941) {
        return false;
      }

      return true;
    },

    isAllPreFiltersSelected() {
      for (let i = 0; i < this.preFilters.length; i++) {
        const filter = this.selectedPreFilters.find(
          (e) =>
            e.idEnterprisePriceGroupDefaultFilter ===
            this.preFilters[i].idEnterprisePriceGroupDefaultFilter
        );

        if (filter == null) {
          return false; // Objeto pareado não foi encontrado na lista2
        }
      }
      return true; // Todos os objetos foram pareados
    },

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },
  watch: {
    async selectedPreFilters() {
      if (!this.isAllPreFiltersSelected) return;

      await this.fetchPriceGroups();
    },

    selectedPriceGroup() {
      this.$refs.filterGroup.selectedFilters = [];
      this.$refs.produtcsList.currentPage = 1;
      this.projectionFilters = [];
    },
  },

  methods: {
    // calcula o price groups
    async calculatePrice() {
      let selectedPricing = this.selectedPriceGroup;
      if (!this.idEnterprisePriceGroups) {
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_SELECT_A_PRICING")
        );
      }

      try {
        await this.service.calculateProducts.call(
          this.idCompany,
          this.idEnterprisePriceGroups
        );
        this.$refs.produtcsList.fetchProducts();
        this.$refs.filterGroup.setFilters();
        this.fetchPriceGroups();
        this.selectedPriceGroup = selectedPricing;
      } catch (e) {
        console.error(e);
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_CALCULATE_PRODUCTS_ERROR")
        );
      }
    },

    // calcula o price groups
    async calculateRules() {
      let selectedPricing = this.selectedPriceGroup;
      if (!this.idEnterprisePriceGroups) {
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_SELECT_A_PRICING")
        );
      }

      try {
        await this.service.calculateProductsRules.call(
          this.idCompany,
          this.idEnterprisePriceGroups
        );
        this.$refs.produtcsList.fetchProducts();
        this.$refs.filterGroup.setFilters();
        this.fetchPriceGroups();
        this.selectedPriceGroup = selectedPricing;
      } catch (e) {
        console.error(e);
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_CALCULATE_PRODUCTS_ERROR")
        );
      }
    },

    // função que é ativada na seleção de combobox de pre filtro
    selectPreFilter(event, item) {
      // remove os values já existente de tal filtro
      this.selectedPreFilters = this.selectedPreFilters.filter(
        (x) =>
          x.idEnterprisePriceGroupDefaultFilter !=
          item.idEnterprisePriceGroupDefaultFilter
      );

      // adiciona o novo valor
      this.selectedPreFilters.push({
        idEnterprisePriceGroupDefaultFilter:
          item.idEnterprisePriceGroupDefaultFilter,
        dbField: item.dbField,

        idEnterprisePriceGroupDefaultFilterValue:
          event.idEnterprisePriceGroupDefaultFilterValue,
        idParent: event.idParent,
        description: event.description,
        value: event.value,
      });
    },

    // filtros de pré seleção obrigatorio para selecionar o filter group
    async fetchPreFilterGroups() {
      try {
        this.preFilters = await this.service.getAllFilterGroups.call(
          this.idCompany,
          true
        );

        if (this.preFilters.length == 0) this.fetchPriceGroups();
      } catch (e) {
        console.error(e);
        this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_ERROR_SEARCHING_PRICING_FILTERS")
        );
      }
    },

    // busca os pricegroups de acordo com os filtros caso haja
    async fetchPriceGroups() {
      try {
        this.selectedPriceGroup = null;
        this.priceGroups = await this.service.listPriceGroups.call(
          this.idCompany,
          this.selectedPreFilters
        );
      } catch (e) {
        console.error(e);
        this.$store.commit("snackbar-error", "Falha ao buscar as price groups");
      }
    },

    // função de execução inicial
    async init() {
      this.fetchPreFilterGroups();
    },

    setCurrency() {
      Service.getCurrency(this.idCompany)
        .then((res) => {
          // console.log("resposta do buscar moeda", res);
          if (res.status != 500) {
            let currency = res.data.currencySymbol;

            if (!currency) {
              localStorage.setItem("currency", "R$");
            } else {
              localStorage.setItem("currency", currency);
            }
          }
        })
        .catch((error) => {
          console.log("Erro no buscar currency", error);
          localStorage.setItem("currency", "R$");
        });
    },
  },

  created() {
    this.setCurrency();
  },

  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}
.flex-3 {
  flex: 3;
}
.flex-2 {
  flex: 2;
}
.color-error {
  color: $brand-color-secondary-pure;
}
.max-content {
  width: max-content;
}
</style>
