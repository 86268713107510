const company = {
  idCompany: null,
  resources: []
};

const companyStore = {
  state: Object.assign({}, company),
  getters: {
    idCompany: (state) => state.idCompany,
    resources: (state) => state.resources,
    hasResource: (state) => (res) => {
      return state.resources.some(s => s == res);
    }
  },
  mutations: {
    setCompanyId(state, data) {
      state.idCompany = data.idCompany;
    },
    setResources(state, data) {
      state.resources.splice(0,state.resources.length)
      data.forEach(e => {
        state.resources.push(e)
      })      
    },    
    clearCompany(state) {
      Object.assign(state, company);
    },
  },
  actions: {
    setCompanyId(context, data) {
      context.commit("setCompanyId", data);
    },
    setResources(context, data){
      context.commit("setResources",data);
    },
    clearCompany(context) {
      context.commit("clearCompany");
    },
  },
};

export default companyStore;
