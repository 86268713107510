<template>
  <div class="d-flex">
    <div
      :class="['color-square', 'mt-1', colorClass ? `show-${colorClass}` : '']"
    />
    <div class="mx-1">
      {{ text }}
      <tooltip-informations v-if="tooltipText" :title="tooltipText" />
    </div>
  </div>
</template>
<script>
import tooltipInformations from "@/Design_System/common/tooltip-informations";

export default {
  props: ["text", "tooltipText", "colorClass"],
  components: {
    tooltipInformations,
  },
  data: () => ({}),
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.color-square {
  width: 8px;
  height: 16px;
  border-radius: 2px;
}
.show-blue {
  background-color: $brand-color-primary-pure;
}
.show-green {
  background-color: $feedback-color-success-pure;
}
.show-yellow {
  background-color: $feedback-color-helper-pure;
}
.show-red {
  background-color: $feedback-color-warning-pure;
}
</style>
