<template>
    <v-card class="pa-4 pt-0 mt-2">
        <v-row no-gutters class="mb-4">
            <v-col cols="12" md="4">
                <h4>PREMISSAS </h4>
            </v-col>
            <v-col cols="12" class="pa-1" offset="7" align="right" md="1">
                <v-icon @click="saveChanges()" title="Salvar" :color="$prdStyles('color-success')">mdi-content-save</v-icon>
            </v-col>
        </v-row>

        <v-card :loading-height="6" class="pa-4 pt-0">
            <v-row no-gutters align="center" class="pt-4">
                <v-col>
                    <label class="font-weight-bold">Tipo</label>
                    <v-combobox dense outlined :disabled="true" filled hide-details v-model="tipo"
                        append-icon="mdi-chevron-down" class="mt-2 mr-4"></v-combobox>
                </v-col>
                <v-col>
                    <label class="font-weight-bold">Brand</label>
                    <v-combobox dense outlined append-icon="mdi-chevron-down" hide-details class="mt-2 mr-4"
                        :items="custoReposicao" item-value="id" item-text="brand" v-on:change="setCustoReposicao($event.id)"
                        v-model="selectedCustoReposicao">
                    </v-combobox>
                </v-col>
                <v-col>
                    <label class="font-weight-bold">Descrição Unificada</label>
                    <v-text-field :disabled="true" v-model="descricaoUnificada" filled dense outlined hide-details
                        class="mt-2 mr-4"></v-text-field>
                </v-col>
                <v-col>
                    <label class="font-weight-bold">Unit Cogs</label>
                    <v-text-field :disabled="true" v-model="unitCogs" filled dense outlined hide-details
                        class="mt-2 mr-4"></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters align="center" class="pt-4">
                <v-col>
                    <label class="font-weight-bold">Margem de Contribuição</label>
                    <v-text-field type="number" v-model="margemContribuicao" dense outlined hide-details
                        class="mt-2 mr-4"></v-text-field>
                </v-col>
                <v-col>
                    <label class="font-weight-bold">Remuneração por canal</label>
                    <v-text-field type="number" v-model="remuneracaoCanal" dense outlined hide-details
                        class="mt-2 mr-4"></v-text-field>
                </v-col>
                <v-col>
                    <label class="font-weight-bold">Corredor Preço</label>
                    <v-text-field type="number" v-model="corredorPreco" dense outlined hide-details
                        class="mt-2 mr-4"></v-text-field>
                </v-col>

            </v-row>
        </v-card>
    </v-card>
</template>
<script>
import ServiceUPL from "@/service/upl/index";
export default {
    props: ["product"],
    data() {
        return {
            service: new ServiceUPL(),
            tipo: "",
            descricaoUnificada: "",
            unitCogs: "",
            margemContribuicao: "",
            remuneracaoCanal: "",
            corredorPreco: "",
            custoReposicao: [],
            selectedCustoReposicao: null,
            newProduct: null,
            externalProducts: [],
        };
    },
    methods: {
        getCustoReposicao() {
            this.service.getCustoReposicao().then((res) => {
                this.custoReposicao = res.data;
                this.selectedCustoReposicao = this.custoReposicao.find(x => x.brand == this.product.nome);
                this.descricaoUnificada = this.selectedCustoReposicao.descricaoUnificada;
                this.unitCogs = this.selectedCustoReposicao.unitCogs;
            })
        },
        setCustoReposicao(id) {
            this.selectedCustoReposicao = this.custoReposicao.find(x => x.id == id);
            this.descricaoUnificada = this.selectedCustoReposicao.descricaoUnificada;
            this.unitCogs = this.selectedCustoReposicao.unitCogs;
        },
        async saveChanges() {
            this.newProduct.margemContribuicao = this.margemContribuicao
            this.newProduct.remuneracaoPorCanal = this.remuneracaoCanal;
            this.newProduct.corredorPreco = this.corredorPreco;
            this.newProduct.custoReposicao = this.selectedCustoReposicao.id;
            await this.service.updateProduct(this.newProduct).then(() => {
                alert("Salvo com sucesso");
            });
        }
    },
    beforeMount() {
        if (this.product != null) {
            this.newProduct = this.product;
            this.tipo = this.product.tipoPrecificacao;
            this.margemContribuicao = this.product.margemContribuicao;
            this.remuneracaoCanal = this.product.remuneracaoPorCanal;
            this.corredorPreco = this.product.corredorPreco;
        }
        this.getCustoReposicao();
    }
};
</script>