<template>
  <v-card class="pa-4 mt-4 mb-6">
    <v-expansion-panels
      v-model="panel"
      :disabled="isLoading"
      accordion
      flat
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="ma-0 pa-0"
          expand-icon="mdi-chevron-down"
        >
          <v-row class="ma-0 pa-0" justify="space-between" align="center">
            <div style="display: flex; align-items: center">
              <label class="card-subtitle">MAPA DE ANÚNCIOS</label>
              <v-tooltip right color="#F5F5F5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: #000">Alguma informação</span>
              </v-tooltip>
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div v-show="isLoading">
            <v-skeleton-loader
              class="my-6"
              type="image, table-row, text"
            ></v-skeleton-loader>
          </div>
          <!-- -------------------------------------------- -->
          <v-row v-show="!isLoading" no-gutters align="center">
            <v-col cols="12" sm="12" md="6">
              <highcharts
                :constructor-type="'mapChart'"
                :options="mapOptions"
                class="map"
              ></highcharts>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-data-table
                :headers="headers"
                :items="states"
                :items-per-page="5"
                class="elevation-1"
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import axios from "axios";
import DashboardBamaqService from "@/service/predimonitor/dashboard-bamaq-service";

const mapBrazil =
  "https://code.highcharts.com/mapdata/countries/br/br-all.geo.json";

export default {
  name: "announcement-map",
  props: ["requestData"],
  data() {
    return {
      rotateIcon: false,
      isLoading: true,
      templateMap: null,
      priceData: null,
      annoucements: null,
      panel: [],
      series: [],

      headers: [
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: "state",
          width: "200px",
        },
        {
          text: "Preço máximo",
          sortable: true,
          align: "center",
          value: "maxPrice",
        },
        {
          text: "Preço médio",
          sortable: true,
          align: "center",
          value: "avgPrice",
        },
        {
          text: "Preço mínimo",
          sortable: true,
          align: "center",
          value: "minPrice",
        },
        {
          text: "Anúncios",
          sortable: true,
          align: "center",
          value: "avgCount",
        },
      ],
      states: [],
    };
  },

  methods: {
    getMap() {
      this.isLoading = true;
      this.$emit("onLoadingStarted");
      let data = this.requestData;

      let dashboardBamaqService = new DashboardBamaqService();
      // this.$emit("onLoadingStarted");

      dashboardBamaqService
        .annnoucementMap(data)
        .then((res) => {
          if (res.data.answer.filterMap[0].regions.length != 0) {
            // Montagem do gráfico
            axios.get(mapBrazil).then((res) => {
              this.templateMap = res.data;
            });

            res.data.answer.filterMap.forEach((el) => {
              let model = {
                country: el.country,
                price: [],
              };

              el.regions.forEach((x) => {
                if (x.state == null) {
                  x.state = "others";
                }

                model.price.push([
                  model.country + "-" + x.state.toLowerCase(),
                  x.avgCount,
                ]);
              });

              this.priceData = model.price;
            });

            // Montagem da tabela
            this.states = res.data.answer.filterMap[0].regions.map((serie) => {
              return {
                state: serie.state.toUpperCase(),
                maxPrice: this.$options.filters.setToIsoString(serie.maxPrice),
                minPrice: this.$options.filters.setToIsoString(serie.minPrice),
                avgPrice: this.$options.filters.setToIsoString(serie.avgPrice),
                avgCount: serie.avgCount,
              };
            });

            this.isLoading = false;
          } else {
            this.panel = [];
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$emit("onLoadingFinished");
        });
    },

    //carrega o mapa de acordo com filtros da pesquisa
    // filteredMap() {
    //   // this.$emit("onLoadingStarted");
    //   this.isLoading = true;

    //   axios.get("http://localhost:3000/bamaqAnnoucementMap").then((res) => {
    //     this.priceData = res.data[0].price;
    //     this.annoucements = res.data[0].annoucements;

    //     axios.get(mapBrazil).then((res) => {
    //       this.templateMap = res.data;
    //     });
    //     this.isLoading = false;
    //   });
    //   // .finally(() => {
    //   //     this.$emit("onLoadingFinished");
    //   //   });
    // },
  },

  computed: {
    mapOptions() {
      let option = {
        chart: {
          map: this.templateMap, //template do mapa
        },

        title: {
          text: "",
        },

        colorAxis: {
          min: 0,
        },

        tooltip: {
          shared: true,
          valueDecimals: 0,
          valuePrefix: "",
        },

        series: [
          {
            name: "Anúncios",

            states: {
              hover: {
                color: "#BADA55",
              },
            },
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            data: this.priceData, // array com estados e preço médio
          },
        ],
      };

      return option;
    },
  },

  watch: {
    requestData() {
      this.getMap();
    },
  },

  created() {
    // this.filteredMap();
  },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.rotateIcon {
  transform: rotate(180deg);
}
</style>