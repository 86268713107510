import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CrawlerExecuteService extends ServiceBase {
    async getProductList(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItems", request);
    }

    async executeCrawler(request) {
        return await axiosInstance.post(API_URL + "api/V2/MonitoringBatch/Insert", request);
    }

    async getProductGroupList(idCompany) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/ListMonitoringGroup", { params: { idCompany: idCompany } });
    }

    async setProductGroup(idCompany, idMonitoringGroup) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/MonitoringGroup?idCompany=2652&idMonitoringGroup=6758", { params: { idCompany: idCompany, idMonitoringGroup: idMonitoringGroup } });
    }
}
