<template>
  <div id="main-div">
    <div class="product_info">
      <span class="font-weight-bold">{{
        monitoringTag.productDescription
      }}</span>

      <div>
        <span class="font-size-12 mr-6">
          {{ this.$i18n.t("TXT_CODE") }}: {{ monitoringTag.getCode }}</span
        >
        <v-icon
          @click="isEdit = true"
          v-show="!isEdit"
          :color="$prdStyles('color-primary')"
          >mdi-pencil-box</v-icon
        >

        <v-icon
          @click="isEditClose"
          v-show="isEdit"
          :color="$prdStyles('color-warning')"
          >mdi-close-box-outline</v-icon
        >
        <v-icon @click="confirmEditItems" v-show="isEdit" color="#1D8527"
          >mdi-checkbox-marked</v-icon
        >
      </div>
    </div>

    <div class="product_cards">
      <!-- EXTERNAL SEARCH -->
      <div class="pa-4 card_info">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_NAME_AND_CHAR") }}</h5>
          <tooltip-informations :title="'teste'" />
        </v-row>

        <div v-for="(tag, index) in monitoringTag.configs" :key="index">
          <div
            v-if="!tag.isExternalSearch && !isEdit && !tag.isDeleted"
            class="flex"
          >
            <div class="product_description">
              {{ tag.description }}
            </div>
            <v-icon v-show="isEdit" :color="$prdStyles('color-warning')" small
              >mdi-delete-outline</v-icon
            >
          </div>

          <div
            v-if="!tag.isExternalSearch && isEdit && !tag.isDeleted"
            class="flex mt-2"
          >
            <v-text-field
              v-model="tag.description"
              dense
              outlined
              hide-details
            ></v-text-field>

            <v-icon
              @click="deleteMonitoringTag(monitoringTag.configs, tag, index)"
              v-show="isEdit"
              :color="$prdStyles('color-warning')"
              small
              >mdi-delete-outline</v-icon
            >
          </div>
        </div>

        <div v-show="isEdit" class="ma-2 ml-0">
          <v-icon @click="addMonitoringTag(false)" color="#1D8527"
            >mdi-plus-box</v-icon
          >
        </div>
      </div>

      <!-- INTERNAL SEARCH -->
      <div class="pa-4 card_info">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_NAME_AND_MARKETPLACE") }}</h5>
          <tooltip-informations :title="'teste'" />
        </v-row>

        <div v-for="(tag, index) in monitoringTag.configs" :key="index">
          <div
            v-if="tag.isExternalSearch && !isEdit && !tag.isDeleted"
            class="flex"
          >
            <div class="product_description">
              {{ tag.description }}
            </div>
            <v-icon v-show="isEdit" :color="$prdStyles('color-warning')" small
              >mdi-delete-outline</v-icon
            >
          </div>

          <div
            v-if="tag.isExternalSearch && isEdit && !tag.isDeleted"
            class="flex mt-2"
          >
            <v-text-field
              v-model="tag.description"
              dense
              outlined
              hide-details
            ></v-text-field>

            <v-icon
              @click="deleteMonitoringTag(monitoringTag.configs, tag, index)"
              v-show="isEdit"
              :color="$prdStyles('color-warning')"
              small
              >mdi-delete-outline</v-icon
            >
          </div>
        </div>
        <div class="ma-2 ml-0">
          <v-icon
            @click="addMonitoringTag(true)"
            v-show="isEdit"
            color="#1D8527"
            >mdi-plus-box</v-icon
          >
        </div>
      </div>

      <!-- PRICE RANGE -->
      <div class="pa-4">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_PRICE_RANGE") }}</h5>
          <tooltip-informations :title="'teste'" />
        </v-row>

        <v-row no-gutters align="center" class="mt-4">
          <p class="pa-0 mr-3">{{ this.$i18n.t("TXT_ACTIVE_DESACTIVE") }}</p>
          <v-switch
            v-model="monitoringTag.usePriceRange"
            :color="$prdStyles('color-primary')"
            :disabled="!isEdit"
            dense
            inset
            flat
            class="mt-0"
          ></v-switch>
        </v-row>

        <div class="flex">
          <div>
            <label>{{ this.$i18n.t("TXT_MAX_PRICE") }}</label>
            <v-text-field
              v-model="monitoringTag.lowerPriceRange"
              :disabled="!isEdit"
              type="number"
              outlined
              dense
              class="mr-4"
            ></v-text-field>
          </div>
          <div>
            <label>{{ this.$i18n.t("TXT_MIN_PRICE") }}</label>
            <v-text-field
              v-model="monitoringTag.upperPriceRange"
              :disabled="!isEdit"
              type="number"
              outlined
              dense
            ></v-text-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import service from "@/service/tags-config/index.js";

export default {
  components: { tooltipInformations },
  props: ["product"],
  data() {
    return {
      service: new service(),
      isEdit: false,
      productCopy: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    monitoringTag() {
      if (!this.product) return [];

      return this.product;
    },
  },

  methods: {
    isEditClose() {
      this.isEdit = false;
      this.$emit("callGet");
    },

    confirmEditItems() {
      this.$emit("setLoading", true);

      let request = this.buildRequest();

      this.service
        .editMonitoringItems(request)
        .then((res) => {
          if (res.status != 500) {
            this.isEdit = false;
            this.$emit(
              "showSnackbar",
              this.$i18n.t("TXT_TAGS_ATT_SUCCESS"),
              "success"
            );
          } else {
            this.$emit(
              "showSnackbar",
              this.$i18n.t("TXT_TAGS_ATT_FAIL"),
              "error"
            );
          }
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$emit("setLoading", false);
        });
    },

    addMonitoringTag(externalType) {
      this.monitoringTag.configs.push({
        isExternalSearch: externalType,
        idMonitoringItem_Config: 0,
        isDeleted: false,
        description: "",
        ignoreNull: true,
        lifeTimeMonitoring: 0,
        crawlers: [
          {
            idCrawler: 0,
          },
        ],
      });
    },

    deleteMonitoringTag(monitoringArray, tag, index) {
      console.log(monitoringArray, tag, index);
      if (tag.idMonitoringItem_Config == 0) {
        monitoringArray.splice(index, 1);
      } else {
        tag.isDeleted = true;
      }
    },

    buildRequest() {
      let request = {
        idCompany: this.idCompany,
        monitoringItems: [this.monitoringTag],
        ignoreNull: true,
      };

      let upperPrice = request.monitoringItems[0].upperPriceRange;
      let lowerPrice = request.monitoringItems[0].lowerPriceRange;

      if (
        typeof upperPrice === "string" &&
        (upperPrice.includes(",") || upperPrice.includes("."))
      ) {
        upperPrice = upperPrice.replace(",", ".");
      }

      if (
        typeof lowerPrice === "string" &&
        (lowerPrice.includes(",") || lowerPrice.includes("."))
      ) {
        lowerPrice = lowerPrice.replace(",", ".");
      }

      request.monitoringItems[0].upperPriceRange = parseFloat(upperPrice);
      request.monitoringItems[0].lowerPriceRange = parseFloat(lowerPrice);

      request.monitoringItems[0].configs =
        request.monitoringItems[0].configs.filter((el) => {
          return el.description != "";
        });

      return request;
    },
  },
};
</script>

<style lang="scss" scoped>
#main-div {
  .product_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $neutral-color-high-medium;
    padding: 8px;
    border-radius: 4px 4px 0px 0px;
    margin-top: 16px;
  }

  .product_cards {
    display: flex;
    gap: 16px;
    background-color: $neutral-color-high-light;
    padding: 16px;

    div {
      background-color: $neutral-color-high-pure;

      .product_description {
        background-color: $neutral-color-high-light;
        padding: 8px;
        margin: 8px;
        border-radius: 4px;
        width: 100%;
      }
    }
  }

  .card_info {
    width: 55% !important;
  }
}
</style>