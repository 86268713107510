<template>
  <expandable-card
    :isGray="true"
    :borderColor="semaphoreColor"
    class="ma-0 pa-0"
  >
    <template v-slot:header-left>
      <div>
        <strong>{{ showClusterName(group) }}</strong>
        <v-tooltip
          v-if="idCompany == 2565"
          color="#f2f2f2"
          class="tootip"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="#3c5ca7" class="icon" right v-bind="attrs" v-on="on">
              $info
            </v-icon>
          </template>
          <template v-slot:default>
            <div
              class="label"
              slot="tooltip"
              v-html="showTooltipByCluster(currentRegion.price.affiliateName)"
            ></div>
          </template>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:header-center>
      <v-row align="center">
        <tooltip-informations
          v-if="region.price.lastSalePrice != null"
          title="Preço atual"
          color="#f2f2f2"
          class="tootip"
          left
        >
          <tbody style="color: black">
            <tr>
              {{
                $t("TXT_ACTUAL")
              }}
              -
              {{
                $t("TXT_ACTUAL_INFO")
              }}
            </tr>
            <tr>
              {{
                $t("TXT_AI")
              }}
              -
              {{
                $t("TXT_AI_INFO")
              }}
            </tr>
            <tr>
              {{
                $t("TXT_ADJUSTED")
              }}
              -
              {{
                $t("TXT_ADJUSTED_INFO")
              }}
            </tr>
          </tbody>
        </tooltip-informations>

        <span style="font-size: 14px" class="mr-5 ml-2">
          {{ $t("TXT_PRICES") }} |
          <!-- Preço atual -->
          <span v-if="region.price.lastSalePrice != null">
            {{ $t("TXT_ACTUAL") }}:
            {{ region.price.lastSalePrice | setToIsoString }} |
          </span>

          <!-- Preço mercado
          <span v-if="region.price.marketResult != null">
            {{ $t("TXT_MARKET") }}:
            {{ region.price.getAvgPriceMarketResult | setToIsoString }} |
          </span> -->

          <!-- IA -->
          <span v-if="region.price.salePrice != null"
            >{{ $t("TXT_AI") }}: {{ region.price.salePrice | setToIsoString }} |
          </span>

          <!-- Ajustad -->
          <span v-if="region.price.override != null"
            >{{ $t("TXT_ADJUSTED") }}:
            {{ region.price.override.price | setToIsoString }} |
          </span>

          <!-- Margem -->
          <span
            v-if="region.price.salePrice_Margin != null && idCompany != 2857"
            >{{ $t("TXT_MARGIN") }}:
            <span :class="makeCardValueClasses(margin)"
              >{{ formatNumberPercentage(margin) }}
            </span>
            |
          </span>

          <!-- IC -->
          <span v-if="ic && idCompany != 2857">
            IC:
            <span :class="makeCardValueClasses(ic)">{{ ic }}</span>
          </span>

          <!-- Variação de venda
          <span v-if="salesVariation && idCompany != 2857">
            {{ $t("TXT_SALLES_VAR") }}
            <span :class="makeCardValueClasses(salesVariation)">{{
              salesVariation | setToIsoString
            }}</span>
          </span> -->
        </span>
        <v-spacer></v-spacer>
        <div class="d-flex align-center gap-x-2">
          <v-icon :class="`white show-${semaphoreColor}`">
            mdi-traffic-light-outline
          </v-icon>
          <tooltip-informations
            v-if="showTooltipSemaphore.length != 0"
            title="Historico de aplicação das regras"
            color="#f2f2f2"
            class="tootip"
            right
          >
            <table>
              <thead>
                <tr class="label">
                  <th>{{ $t("TXT_ORDER") }}</th>
                  <th>{{ $t("TXT_APPLIED_RULE") }}</th>
                  <th>{{ $t("TXT_PRE_PRICE") }}</th>
                  <th>{{ $t("TXT_POST_PRICE") }}</th>
                  <th>{{ $t("TXT_PRE_MARGIN") }}</th>
                  <th>{{ $t("TXT_POST_MARGIN") }}</th>
                  <th>{{ "Status" }}</th>
                  <th>{{ $t("TXT_REASON") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in showTooltipSemaphore"
                  :key="item.id"
                  class="label"
                >
                  <td>
                    {{ item.order }}
                  </td>
                  <td>
                    {{ $t(item.nameCondition) }}
                  </td>
                  <td>
                    {{ item.oldPrice | setToIsoString }}
                  </td>
                  <td>
                    {{ item.currentPrice | setToIsoString }}
                  </td>
                  <td>
                    {{ Math.trunc(item.oldMargin) + "%" }}
                  </td>
                  <td>
                    {{ Math.trunc(item.currentMargin) + "%" }}
                  </td>
                  <td>
                    {{ $t(item.isBlockedMessage) }}
                  </td>
                  <td>
                    {{ $t(item.messageBlockedRuleCondition) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </tooltip-informations>
        </div>
      </v-row>
    </template>
    <template v-slot:header-right></template>

    <template v-slot:content>
      <v-radio-group :value="priceStatus" class="pa-0 ma-0">
        <div class="gap-2 grid">
          <product-info
            class="span-row-2"
            :class="idCompany == 2857 ? 'little-width' : 'small-width'"
            :productRegion="currentRegion"
            :productName="productName"
            :listMapViewInfo="listMapViewInfo"
          />
          <market-analysis
            class="span-col-3"
            :productRegion="currentRegion"
            :productName="productName"
            @updated-market-result="overridenRegion = $event"
          />
          <new-elasticity
            v-if="hasResource('enterprise.new.elasticity')"
            :productRegion="currentRegion"
            :productName="productName"
            class="span-col-3"
          />
          <elasticity-info
            v-else
            class="span-col-3"
            :productRegion="currentRegion"
            :productName="productName"
          />
          <pricing-info
            v-if="idCompany != 2857"
            class="small-width"
            :productRegion="currentRegion"
            :productName="productName"
          />
          <div class="span-col-3" style="display: flex; gap: 8px">
            <competitive-price
              style="width: 40%"
              :productRegion="currentRegion"
              :productName="productName"
              :isSelected="priceStatus === 'COMPETITIVE'"
              @select="selectCompetitivePrice"
              :isLoadingResetOverride="
                service.overrideCancelManualPrice.isLoading
              "
              :disabled="
                currentWorkflow.lastAction && currentWorkflow.lastAction.toSend
              "
            />
            <manual-price
              style="width: 40%"
              :productRegion="currentRegion"
              :productName="productName"
              :isSelected="priceStatus === 'MANUAL'"
              :disabled="
                currentWorkflow.lastAction && currentWorkflow.lastAction.toSend
              "
              @select="seleectManualPrice"
              @cancel="selectCompetitivePrice"
              @override-price-update="overridenRegion = $event"
            />
            <product-actions
              style="width: 20%"
              @workflow-override="workflowOverride"
              :workflow="currentWorkflow"
              :idEnterprisePricesProjection="
                currentRegion.price.idEnterprisePricesProjection
              "
              :productName="productName"
            />
          </div>
        </div>
      </v-radio-group>
    </template>
  </expandable-card>
</template>
<script>
import expandableCard from "@/Design_System/common/expandable-card";
import AiPricingService from "@/service/ai-pricing";
import tooltipInformations from "@/Design_System/common/tooltip-informations";
import productInfo from "./subregions/product-info";
import pricingInfo from "./subregions/pricing-info";
import marketAnalysis from "./subregions/market-analysis";
import elasticityInfo from "./subregions/elasticity-info";
import competitivePrice from "./subregions/competitive-price";
import manualPrice from "./subregions/manual-price";
import productActions from "./subregions/product-actions";
import NewElasticity from "./subregions/new-elasticity.vue";
import { formatNumberPercentage } from "@/utils/format-numbers";

export default {
  props: ["region", "productName", "listMapViewInfo"],
  components: {
    expandableCard,
    productInfo,
    pricingInfo,
    marketAnalysis,
    elasticityInfo,
    competitivePrice,
    manualPrice,
    productActions,
    tooltipInformations,
    NewElasticity,
  },
  data: () => ({
    isManualPriceDisabled: true,
    competitivePrice: false,
    overridenRegion: null,
    overridenWorkflow: null,
    CLUSTER_NAMES: {
      1: "Nordeste - 1",
      2: "Nordeste - 2",
      3: "Sudeste",
      4: "Praça de Salvador",
      5: "Norte",
    },

    /** @type {"COMPETITIVE" | "MANUAL"} */
    priceStatus: "COMPETITIVE",

    service: new AiPricingService(),
  }),
  methods: {
    formatNumberPercentage(value) {
      return formatNumberPercentage(value, {
        fractionLength: 0,
        alwaysRenderSign: true,
      });
    },
    makeCardValueClasses(value) {
      return [
        value === undefined
          ? "green-value"
          : value < 0
          ? "red-value"
          : "green-value",
      ];
    },
    workflowOverride(newWorkflow) {
      this.overridenWorkflow = newWorkflow;
      this.priceStatus === "MANUAL";
    },
    seleectManualPrice() {
      this.priceStatus = "MANUAL";
    },
    async selectCompetitivePrice() {
      if (this.currentRegion?.price?.override?.isManual) {
        try {
          this.overridenRegion =
            await this.service.overrideCancelManualPrice.call(
              this.idCompany,
              this.idEnterprisePricesProjection
            );
        } catch (e) {
          console.error(e);
          this.$store.commit(
            "snackbar-error",
            this.$i18n.t("TXT_CHANGE_COMPETITIVE_ERROR")
          );
          return;
        }
      }

      this.priceStatus = "COMPETITIVE";
    },
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    // Shows the list of regions depending on the cluster
    showTooltipByCluster(cluster) {
      if (cluster == 1) {
        return `
    <table>
      <tbody>
        <tr>
          <td>ALAGOINHAS</td>
          <td>ARAPIRACA</td>
          <td>CABO DE SANTO AGOSTINHO</td>
          <td>CAMARAGIBE</td>
          <td>CAMPINA GRANDE</td>
        </tr>

        <tr>
          <td>CARUARU</td>
          <td>ITABAIANA</td>
          <td>ITABUNA</td>
          <td>JABOATAO DOS GUARARAPES</td>
          <td>MACEIO</td>
        </tr>

        <tr>
          <td>MOSSORO</td>
          <td>NATAL</td>
          <td>OLINDA</td>
          <td>PAULISTA</td>
          <td>PAULO AFONSO</td>
        </tr>

        <tr>
          <td>PETROLINA</td>
          <td>RECIFE</td>
          <td>SANTA INES</td>
          <td>VITORIA DA CONQUISTA</td>
          <td>VITORIA DE SANTO ANTO</td>
        </tr>
      </tbody>
    </table>
  `;
      }
      if (cluster == 2) {
        return `<table>
  <tbody>
    <tr>
      <td>ARACAJU</td>
      <td>BARREIRAS</td>
      <td>CABEDELO</td>
      <td>CRUZ DAS ALMAS</td>
      <td>EUNAPOLIS</td>
    </tr>

    <tr>
      <td>FEIRA DE SANTANA</td>
      <td>FORTALEZA</td>
      <td>IMPERATRIZ</td>
      <td>JEQUIE</td>
      <td>JOAO PESSOA</td>
    </tr>

    <tr>
      <td>JUAZEIRO</td>
      <td>LUIS EDUARDO</td>
      <td>MARACANAU</td>
      <td>NOSSA SENHORA DO SOCORRO</td>
      <td>PATOS</td>
    </tr>

    <tr>
      <td>SANTO ANTONIO DE JESUS</td>
      <td>SAO LUIS</td>
      <td>SAO JOSE DE RIBAMAR</td>
      <td>SOBRAL</td>
      <td>TEIXEIRA DE FREITAS</td>
    </tr>

    <tr>
      <td>teresina</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>`;
      }
      if (cluster == 3) {
        return `<table>
  <tbody>
    <tr>
      <td>BARUERI</td>
      <td>BAURU</td>
      <td>BELO HORIZONTE</td>
      <td>BETIM</td>
      <td>CAMPINAS</td>
    </tr>
    <tr>
      <td>CONTAGEM</td>
      <td>GUARULHOS</td>
      <td>MAUA</td>
      <td>PICOS</td>
      <td>PRAIA GRANDE</td>
    </tr>
    <tr>
      <td>SAO BERNARDO DO CAMPO</td>
      <td>SOROCABA</td>
      <td>UBERLANDIA</td>
      <td>SAO PAULO</td>
      <td>GOIANIA</td>
    </tr>
    <tr>
      <td>VILA VELHA</td>
      <td>VITORIA</td>
      <td>SERRA</td>
      <td>CARIACICA</td>
      <td></td>
    </tr>
  </tbody>
</table>`;
      }
      if (cluster == 4) {
        return `<table>
  <tbody>
    <tr>
      <td>SALVADOR</td>
      <td>CAMACARI</td>
      <td>LAURO DE FREITAS</td>
      <td>SIMOES FILHO</td>
    </tr>
  </tbody>
</table>`;
      }
      if (cluster == 5) {
        return `<table>
  <tbody>
    <tr>
      <td>BELEM</td>
      <td>PARAUAPEBAS</td>
      <td>SANTAREM</td>
    </tr>
    <tr>
      <td>ANANINDEUA</td>
      <td>CASTANHAL</td>
      <td>MARABA</td>
    </tr>
  </tbody>
</table>`;
      }
    },

    showClusterName(cluster) {
      if (this.idCompany == 2565) {
        return this.CLUSTER_NAMES[cluster] || "";
      } else {
        return cluster;
      }
    },
  },
  mounted() {
    if (this.currentRegion?.price?.override?.isManual) {
      this.priceStatus = "MANUAL";
    }
  },
  computed: {
    group() {
      if (this.idCompany == 2858) return this.currentRegion.price.store;

      if (this.idCompany == 3026) return this.currentRegion.price.affiliate;

      if (this.idCompany == 2941) return "";

      if (this.idCompany == 2652 || this.idCompany == 2806)
        return this.currentRegion.price.affiliateState;

      if (
        this.idCompany == 2550 ||
        this.idCompany == 2575 ||
        this.idCompany == 2582 ||
        this.idCompany == 2583 ||
        this.idCompany == 2588 ||
        this.idCompany == 2589 ||
        this.idCompany == 2590 ||
        this.idCompany == 2581 ||
        this.idCompany == 2572 ||
        this.idCompany == 2573 ||
        this.idCompany == 2853 ||
        this.idCompany == 2844 ||
        this.idCompany == 2584 ||
        this.idCompany == 2585 ||
        this.idCompany == 2854 ||
        this.idCompany == 2866 ||
        this.idCompany == 2867 ||
        this.idCompany == 2868 ||
        this.idCompany == 2869 ||
        this.idCompany == 2870
      )
        return (
          this.currentRegion.price.economicGroup +
          "-" +
          this.currentRegion.price.nameSegment
        );

      return this.currentRegion.price.affiliateName;
    },
    margin() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      if (!this.currentRegion) return null;

      if (this.currentRegion?.price?.override != null) {
        if (this.currentRegion?.price?.override?.isManual)
          return this.currentRegion?.price?.override.manual_Price_Margin;
        else return this.currentRegion?.price?.override.price_Margin;
      } else return this.currentRegion?.price?.salePrice_Margin;
    },
    ic() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      if (typeof this.currentRegion?.price.getAvgPriceMarketResult !== "number")
        return "";
      // const override = this.currentRegion?.price?.override;
      // const isManual = override?.isManual || null;
      // const isBlocked = override?.isBlocked || null;
      // const price =
      //   (isManual && override?.manual_Price) ??
      //   (isBlocked && override?.price) ??
      //   this.currentRegion?.price?.salePrice ??
      //   null;
      // if (price === null) return "";

      const price = this.currentRegion?.price?.suggestedPrice;

      let calculated =
        (price * 100) / this.currentRegion.price.getAvgPriceMarketResult;

      return Math.trunc(calculated) + "%";
    },

    salesVariation() {
      //Feito temporariamente para a Suzano
      if (this.idCompany == 2857) return null;

      let meanPrice = null;
      let meanQuantity = null;
      let calculatedDemand = null;

      if (this.currentRegion?.price?.elasticity) {
        calculatedDemand = this.currentRegion?.price?.salePrice_Demand;
        meanPrice = this.currentRegion?.price?.elasticity.meanPrice;
        meanQuantity = this.currentRegion?.price?.elasticity.meanQuantity;
      } else if (this.currentRegion?.elasticity) {
        calculatedDemand = this.currentRegion?.elasticity?.calculatedDemand;
        meanPrice = this.currentRegion?.elasticity?.priceDemand;
        meanQuantity = this.currentRegion?.elasticity?.averageDemand;
      }

      // if (
      //   typeof this.productRegion?.price?.lastSalePrice !== "number" ||
      //   typeof this.productRegion?.elasticity?.averageDemand !== "number" ||
      //   typeof this.productRegion?.elasticity?.calculatedDemand !== "number" ||
      //   typeof this.productRegion?.price?.suggestedPrice !== "number"
      // )
      //   return null;
      // else {
      return (
        this.currentRegion.price.suggestedPrice * calculatedDemand -
        meanPrice * meanQuantity
      );
      // }
    },
    currentWorkflow() {
      return (
        this.overridenWorkflow ?? this.currentRegion?.workflowApproval ?? false
      );
    },
    currentRegion() {
      return this.overridenRegion ?? this.region ?? null;
    },
    semaphoreColor() {
      return ["blue", "green", "yellow", "red"][
        this.currentRegion?.semaphore ?? Infinity
      ];
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    idEnterprisePricesProjection() {
      return this.currentRegion?.price?.idEnterprisePricesProjection ?? null;
    },

    showTooltipSemaphore() {
      if (!this.currentRegion.price?.override?.listRulePath) return [];

      return this.currentRegion.price.override.listRulePath;
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.gap-2 {
  column-gap: 8px;
  row-gap: 8px;
}

.grid {
  display: grid;
  grid-template-columns: max-content 1fr 1fr max-content;
}
.span-row-2 {
  grid-row: span 2;
}
.span-col-3 {
  grid-column: span 3;
}
.small-width {
  width: 280px !important;
}
.little-width {
  width: 180px !important;
}
.flex {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.show-green.v-icon {
  color: $feedback-color-success-pure;
}
.show-red.v-icon {
  color: $brand-color-secondary-pure;
}
.green-value {
  color: #1d8527;
}
.red-value {
  color: $feedback-color-warning-pure;
}
.show-yellow.v-icon {
  color: $feedback-color-helper-pure;
}
.show-blue.v-icon {
  color: $brand-color-primary-pure;
}
.btn-icon {
  background-color: #3c5ca7;
  border-radius: 8px;
}

.card-subtitle {
  font-size: 16px;
  font-weight: bolder;
}
.bg-gray {
  background-color: $neutral-color-high-light;
}
.color-gray-2 {
  color: $neutral-color-low-light;
}

.label table {
  border-collapse: collapse;
  width: 100%;
}
.label th,
.label td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  color: black;
}
.label th {
  background-color: #f2f2f2;
}
.label tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
