<template>
  <v-card class="pa-4 pt-0" :disabled="isLoading" :loading="isLoading">
    <h4 class="pt-4">FILTROS</h4>
    <v-row no-gutters class="mt-6">
      <v-col v-for="(filter, index) in filters" :key="index">
        <prd-combo-box v-model="filter.selected" :title="filter.label" :items="filter.items" :class="filter.margin"
          :disabled="filterLoading || filter.items.length == 0" :loading="filterLoading" placeholder="Selecione" />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-spacer></v-spacer>
      <prd-btn :title="'Aplicar filtros'" :disabled="!hasFilters" @click="setFilters" class="mr-4" />
      <prd-btn :title="'Limpar filtros'" @click="cleanFilters" :outlined="true" v-if="hasFilters" />
    </v-row>
  </v-card>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceHome20 from "@/service/home20/index";

// import service from "@/service/home-vem/index.js";
// const Service = new service();
export default {
  props: ["isLoading"],
  components: { prdComboBox, PrdBtn },
  data() {
    return {
      service: new ServiceHome20(),
      filters: [
        {
          label: "Loja",
          items: [],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Curva",
          items: [],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Categoria",
          items: [],
          selected: null,
          margin: "mr-0",
        },
      ],
      filterLoading: false,
      lastMonthProjection: null,
    };
  },

  computed: {
    hasFilters() {
      let hasFilter = null;
      hasFilter = this.filters.find((el) => {
        return el.selected != null;
      });

      if (hasFilter) return true;
      return false;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    init(filters) {
      this.$emit("setLoading", true);

      Promise.all([
        this.getPromotionalCalendar(),
        this.getGeralVision(filters),
        this.getOportunities(filters),
        this.getAlerts(filters),
      ])
        .then(() => {
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("setLoading", false);
        });
    },

    getGeralVision(filters) {
      this.$emit("chartIALoading", true);
      let response = {
        dayAdoption: [],
      };

      // ENDPOINT ANDRÉ
      // this.service
      //   .getRecipe(
      //     filters?.loja ?? null,
      //     filters?.curva ?? null,
      //     filters?.categoria ?? null
      //   )
      //   .then((res) => {
      //     response.totalRecipe = res.data.totalRecipe;
      //     response.totalMargin = res.data.totalMargin;
      //   });

      this.service
        .getGraficos(
          filters?.loja ?? null,
          filters?.curva ?? null,
          filters?.categoria ?? null
        )
        .then((res) => {
          response.dayAdoption = res.data;
          this.$emit("chartIALoading", false);
        })
        .catch((error) => {
          console.log("Erro no getGeralVision", error);
          this.$emit("chartIALoading", false);
        });

      this.$emit("geralVisionDataEvent", response);
    },

    getPromotionalCalendar() {
      this.$emit("promotionalCalendarLoading", true);
      this.service
        .getAcaoPromocional()
        .then((res) => {
          this.$emit("promotionalCalendarEvent", res.data);
          this.$emit("promotionalCalendarLoading", false);
        })
        .catch((error) => {
          console.log("Error no getPromotionalCalendar", error);
          this.$emit("promotionalCalendarLoading", false);
        });
    },

    async getTotalProjection(idPriceGroup, filters) {
      let response = {
        totalRecipe: {},
        totalMargin: {},
        lastMonthProjection: null,
      };

      if (idPriceGroup) {
        await this.getLastMonthProjection(filters);
        try {
          const res = await this.service.getRecipeByPricing(idPriceGroup);

          let resData = res?.data?.result ?? null;

          // resData = {
          //   changes_ChangesApproved: 430,
          //   changes_ShopMoreChanges: "304910 - P JD PARANAGUA",
          //   changes_ChangesShopMoreChanges: 430,
          //   saleVariation_SaleCurrentPrice: 1977.827444,
          //   saleVariation_SaleApprovedPrice: 6148.073149725,
          //   saleVariation_SaleVariation: 4170.245705725,
          //   profitVariation_SaleCurrentProfit: 812.809703,
          //   profitVariation_SaleApprovedProfit: 2694.961816,
          //   profitVariation_ProfitVariation: 1882.152113,
          //   marginVariation_SaleMargin: 40.78612596708032608131263305,
          //   marginVariation_SaleMarginApproved: 43.98936056999688865044298444,
          //   marginVariation_MarginVariation: 3.20323460291656256913035139,
          // };

          if (resData) {
            response.lastMonthProjection = this.lastMonthProjection;

            response.totalRecipe = {
              lastWeek: resData.saleVariation_SaleCurrentPrice,
              weekProjection: resData.saleVariation_SaleCurrentPrice * 7,
              monthProjection: resData.saleVariation_SaleCurrentPrice * 30,

              lastWeekIA: resData.saleVariation_SaleApprovedPrice,
              weekProjectionIA: resData.saleVariation_SaleApprovedPrice * 7,
              monthProjectionIA: resData.saleVariation_SaleApprovedPrice * 30,
            };

            response.totalMargin = {
              lastWeek: resData.marginVariation_SaleMargin,
              weekProjection: resData.marginVariation_SaleMarginApproved,
              monthProjection: resData.marginVariation_SaleMarginApproved,
            };
          }

          this.$emit("loadingProjection", false);
          this.$emit("totalProjection", response);
        } catch (error) {
          console.log("buscar total projection", error);
          this.$emit("loadingProjection", false);
        }
      }
      this.$emit("totalProjection", response);
    },

    getOportunities(filters) {
      let response = {
        increaseProfit: {},
        increaseMargin: {},
      };

      this.service
        .getOportunities(
          filters?.loja ?? null,
          filters?.curva ?? null,
          filters?.categoria ?? null
        )
        .then((res) => {
          response.increaseProfit = res.data.increaseProfit;
          response.increaseMargin = res.data.increaseMargin;
        });

      this.$emit("oportunitiesDataEvent", response);
    },

    getAlerts(filters) {
      let response = {
        marginCard: null,
        icCard: null,
        psicologyPriceCard: null,
        competitorPriceCard: null,
      };

      this.service
        .getAlert(
          filters?.loja ?? null,
          filters?.curva ?? null,
          filters?.categoria ?? null
        )
        .then((res) => {
          response.marginCard = res.data.marginCard;
          response.icCard = res.data.icCard;
          response.psicologyPriceCard = res.data.psicologyPriceCard;
          response.competitorPriceCard = res.data.competitorPriceCard;
        });

      this.$emit("alertsDataEvent", response);
    },

    getIdProduct(affiliateValue, filters) {
      this.$emit("loadingProjection", true);
      this.service
        .getPricingGroup(affiliateValue)
        .then((res) => {
          if (res.data) {
            this.getTotalProjection(res.data, filters);
          } else {
            this.$emit("loadingProjection", false);
          }
        })
        .catch((error) => {
          console.log("Erro ao pegar id do produto", error);
          this.$emit("loadingProjection", false);
        });
    },

    async getLastMonthProjection(filters) {
      try {
        const res = await this.service.getLastMonthProjection(
          filters?.loja ?? null,
          filters?.curva ?? null,
          filters?.categoria ?? null
        );
        console.log(res);
        this.lastMonthProjection = res.data;
      } catch (error) {
        console.log("Erro ao pegar projeção do ultimo mes", error);
      }
    },

    //Filter area
    getAllFilters() {
      this.filterLoading = true;
      Promise.all([this.getAffiliateFilter(), this.getFilters()])
        .then(() => {
          this.filterLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.filterLoading = false;
        });
    },

    getAffiliateFilter() {
      this.service
        .getAffiliateFilter(3026)
        .then((res) => {
          let response = res.data.result[0]?.values ?? [];
          let filtervalues = [];
          response.forEach((el) => {
            let filterObj = {
              text: el.description,
              value: el.value,
            };

            filtervalues.push(filterObj);
          });

          this.filters[0].items = filtervalues;
        })
        .catch((error) => {
          console.log("Erro ao filtro de affiliate", error);
          // Para testes
          // let response = [
          //   {
          //     idEnterprisePriceGroupDefaultFilterValue: 7435,
          //     idParent: null,
          //     value: "307219",
          //     description: "VARANDA DO HORTO COM.BEBS.P.AL.EIRE",
          //   },
          //   {
          //     idEnterprisePriceGroupDefaultFilterValue: 7436,
          //     idParent: null,
          //     value: "308212",
          //     description: "PARADA OESTE I CONVENIENCIAS LTDA",
          //   },
          //   {
          //     idEnterprisePriceGroupDefaultFilterValue: 7437,
          //     idParent: null,
          //     value: "304910",
          //     description: "POSTO JARDIM PARANAGUA LTDA",
          //   },
          //   {
          //     idEnterprisePriceGroupDefaultFilterValue: 7438,
          //     idParent: null,
          //     value: "307194",
          //     description: "PLUS CENTER AUTO POSTO LTDA",
          //   },
          // ];

          // let filtervalues = [];
          // response.forEach((el) => {
          //   let filterObj = {
          //     text: el.description,
          //     value: el.value,
          //   };

          //   filtervalues.push(filterObj);
          // });

          // this.filters[0].items = filtervalues;
        });
    },

    getFilters() {
      this.service
        .getFilters()
        .then((res) => {
          this.filters[1].items = res.data.curva.items;
          this.filters[2].items = res.data.categoria.items;
        })
        .catch((error) => {
          console.log("Erro ao buscar filtros", error);
        });
    },

    setFilters() {
      let request = {
        loja: "",
        curva: "",
        categoria: "",
      };

      this.filters.forEach((el) => {
        if (el.selected) {
          let filterObj = {
            key: el.selected ? el.label : null,
            value: el.selected?.value ?? null,
          };

          if (filterObj.key != null && filterObj.key.toUpperCase() == "LOJA") {
            request.loja = filterObj.value;
          }
          if (filterObj.key != null && filterObj.key.toUpperCase() == "CURVA") {
            request.curva = filterObj.value;
          }
          if (
            filterObj.key != null &&
            filterObj.key.toUpperCase() == "CATEGORIA"
          ) {
            request.categoria = filterObj.value;
          }
        }
      });

      if (this.filters[0].selected?.value) {
        this.getIdProduct(this.filters[0].selected.value, request);
      } else {
        this.$emit("loadingProjection", false);
      }

      this.init(request);
    },
    cleanFilters() {
      this.filters.forEach((filter) => {
        filter.selected = null;
      });
      this.setFilters();
    },
  },
  created() {
    this.init();
    this.getAllFilters();
  },
};
</script> 