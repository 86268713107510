<template>
  <v-app>
    <sidebar
      @showOrHideMenu="showOrHideMenu"
      v-if="this.$route.path.startsWith('/main')"
      :drawerMobile="drawerMobile"
    />
    <headerComponent
      @showOrHideMenu="showOrHideMenu"
      v-if="this.$route.path.startsWith('/main')"
    />
    <snackbar-watcher></snackbar-watcher>
    <v-main class="light-background">
      <v-container class="pl-8 pr-4 pa-0" style="height: 100%" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import headerComponent from "@/components/common/header.vue";
import sidebar from "@/components/common/sidebar.vue";
import snackbarWatcher from "@/utils/snackbar-watcher";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    headerComponent,
    sidebar,
    snackbarWatcher,
  },
  data() {
    return {
      drawerMobile: false,
      miniMobile: false,
    };
  },
  methods: {
    showOrHideMenu() {
      this.drawerMobile = !this.drawerMobile;
    },
  },
  computed: {
    //comment the code below, in case you want to test without log into the system using a real user
    ...mapGetters(["hasUser", "userData"]),

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  created() {
    console.log(process.env.NODE_ENV);
    //comment the code below, in case you want to test without log into the system using a real user
    if (this.$route.path.startsWith("/login") && this.hasUser) {
      this.$router.push("/main");
    } else {
      if (this.$route.path.startsWith("/main") && !this.hasUser) {
        this.$router.push("/login");
      }
    }
  },
};
</script>

<style lang="scss">
.prd-container {
  margin-left: 50px;
  margin-right: 20px;
}

* {
  font-family: $font-family-base;
}

a {
  text-decoration: none;
}

.gap-1 {
  column-gap: 4px;
  row-gap: 4px;
}

.gap-2 {
  column-gap: 8px;
  row-gap: 8px;
}

.gap-3 {
  column-gap: 12px;
  row-gap: 12px;
}

.gap-4 {
  column-gap: 16px;
  row-gap: 16px;
}

.gap-5 {
  column-gap: 20px;
  row-gap: 20px;
}

.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}

.gap-7 {
  column-gap: 28px;
  row-gap: 28px;
}

.gap-8 {
  column-gap: 32px;
  row-gap: 32px;
}

.gap-x-1 {
  column-gap: 8px;
}

.gap-x-2 {
  column-gap: 8px;
}

.gap-x-3 {
  column-gap: 12px;
}

.gap-x-4 {
  column-gap: 16px;
}

.gap-x-5 {
  column-gap: 20px;
}

.gap-x-6 {
  column-gap: 24px;
}

.gap-x-7 {
  column-gap: 28px;
}

.gap-x-8 {
  column-gap: 32px;
}

.gap-y-1 {
  row-gap: 8px;
}

.gap-y-2 {
  row-gap: 8px;
}

.gap-y-3 {
  row-gap: 12px;
}

.gap-y-4 {
  row-gap: 16px;
}

.gap-y-5 {
  row-gap: 20px;
}

.gap-y-6 {
  row-gap: 24px;
}

.gap-y-7 {
  row-gap: 28px;
}

.gap-y-8 {
  row-gap: 32px;
}

.nowrap {
  white-space: nowrap;
}

.flex-1 {
  flex: 1;
}

.span-row-2 {
  grid-row: span 2;
}

.span-row-3 {
  grid-row: span 3;
}

.span-row-4 {
  grid-row: span 4;
}

.span-row-5 {
  grid-row: span 5;
}

.span-row-6 {
  grid-row: span 6;
}

.span-column-2 {
  grid-column: span 2;
}

.span-column-3 {
  grid-column: span 3;
}

.span-column-4 {
  grid-column: span 4;
}

.span-column-5 {
  grid-column: span 5;
}

.span-column-6 {
  grid-column: span 6;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

:root {
  --bright-red: #ec4c37;
  --black: #000;
  --dark: #424242;
  --dark-moderate-blue: #3c5ca7;
  --dark-moderate-blue-2: #233053;
  --dark-text: #414141;
  --green: #56a667;
  --red: #ff0000;
  --very-dark-desaturated-blue: #353059;
  --very-light-gray: #f6f6f6;
  --white: #fff;
  --yellow: #ffdd00;
  --background-gray: #ededed;
  --msg-error: #b00020;
  --msg-success: #1d8527;
}

.image-logo {
  background-color: #353059;
}

.light-background {
  background-color: var(--background-gray);
}

.v-icon.far.fa-square {
  color: #3c5ca7 !important;
}

.dark-text-color {
  color: var(--dark-text);
}

.white-text-color {
  color: var(--white);
}

.content {
  width: 100%;
  height: 100%;
  align-content: center;
}

.padding-all-30 {
  padding: 30px !important;
}

.margin-bottom-45 {
  margin-bottom: 45px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-top-45 {
  margin-top: 45px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-y-45 {
  margin: 45px 0 !important;
}

.margin-y-15 {
  margin: 15px 0 !important;
}

.margin-y-5 {
  margin: 5px 0 !important;
}

#table-result {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}

// .v-data-table-header th.sortable {
//   background-color: #d9d9d9 !important;
//   border: 1px solid white !important;
// }

// É O TEXTO DOS HEADERS DA TABELA
.v-data-table-header th span {
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 13px !important;
}

// .v-data-table-header th{
//     border-right-width: 3px !important;
// }

// .v-data-table__wrapper > table > thead > tr > th{
//     border-right-width: 3px;
// }

// .v-data-table tbody tr td {
//   background-color: #f5f5f5 !important;
//   border: 1px solid white !important;
// }

.v-btn {
  text-transform: unset;
}

.input-datapicker .v-input__append-inner {
  margin-top: 0px !important;
}

#carousel-item {
  .v-window__prev {
    background-color: transparent !important;
    transition: 0.2s;
  }

  .v-window__prev:hover {
    background-color: #ccc !important;
  }

  .v-window__next {
    background-color: transparent !important;
    transition: 0.2s;
  }

  .v-window__next:hover {
    background-color: #ccc !important;
  }
}
</style>
