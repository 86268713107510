import Vue from "vue";
import Vuex from "vuex";
import userStore from "./user-store";
import companyStore from "./company-store";
import predimonitorStore from "./predimonitor-store";
import createPersistedState from "vuex-persistedstate";
import predifutStore from "./predifut-store";
import aiPricingStore from "./ai-pricing-store";
import snackbarStore from "./snackbar-store.js";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        "userStore",
        "companyStore",
        "predimonitorStore",
        "predifutStore",
      ],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    predimonitorResults: [],
    resultsIA: []
  },
  getters: {},
  mutations: {
    setPredimonitorResults(state, data) {
      state.predimonitorResults = data;
    },
    clearPredimonitorResults(state) {
      Object.assign(state, null);
    },
    searchProductPredimonitor(state,data){
      state.resultsIA = data
    }
  },
  actions: {},
  modules: {
    userStore,
    companyStore,
    predimonitorStore,
    predifutStore,
    snackbarStore,
    aiPricingStore,
  },
});
