<template>
  <v-expansion-panel-content style="width: 100%; overflow: scroll">
    <v-data-table :headers="headers" :items="product" :items-per-page="5">
      <!-- product name -->
      <template v-slot:[`item.product_name`]="{ item }">
        <td class="table-product-name">
          <div class="copy-box">
            <v-icon
              @click.stop="copyFunction(item.product_name)"
              :color="$prdStyles('color-primary')"
              left
              small
              class="copy-button"
              >mdi-content-copy</v-icon
            >
            <a :href="item.product_link" target="_blank">
              {{ item.product_name }}</a
            >
          </div>
        </td>
      </template>

      <!-- Price -->
      <template v-slot:[`item.price`]="{ item }">
        {{ setCurrency(item.price) }}
      </template>

      <!-- Installsment -->
      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.quantity ? item.quantity + "x" : "-" }}
      </template>

      <!-- Indice deflator -->
      <template v-slot:[`item.deflated_value`]="{ item }">
        {{ setCurrency(item.deflated_value) }}
      </template>

      <!-- Crawler date -->
      <template v-slot:[`item.crawler_date`]="{ item }">
        <td class="text-center">
          {{
            item.crawler_date
              ? item.crawler_date
                  .slice(0, 10)
                  .replace("-", "/")
                  .replace("-", "/")
              : null
          }}
        </td>
      </template>

      <!-- tax ST -->
      <template v-slot:[`item.price_tax_st`]="{ item }">
        <td class="text-center">
          {{ item.price_tax_st ? item.price_tax_st : "-" }}
        </td>
      </template>
    </v-data-table>
  </v-expansion-panel-content>
</template>

<script>
import { setCurrency } from "@/utils/format-currency";
export default {
  props: ["product"],
  data() {
    return {
      headers: [
        {
          text: "Produto",
          align: "center",
          sortable: true,
          value: "product_name",
          width: "300px",
        },
        {
          text: "Loja",
          align: "center",
          sortable: true,
          value: "crawler_name",
        },
        {
          text: "Vendedor",
          align: "center",
          sortable: true,
          value: "seller_name",
        },
        {
          text: "Preço",
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: "Parcelas",
          align: "center",
          sortable: true,
          value: "quantity",
        },
        {
          text: "Valor ST",
          align: "center",
          sortable: true,
          value: "price_tax_st",
        },
        {
          text: "Indice deflator",
          align: "center",
          sortable: true,
          value: "deflated_value",
          width: "120px",
        },
        {
          text: "Pagamento",
          align: "center",
          sortable: true,
          value: "price_payment_type",
        },
        {
          text: "Data da coleta",
          align: "center",
          sortable: true,
          value: "crawler_date",
        },
      ],
    };
  },

  methods: {
    setCurrency,

    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>