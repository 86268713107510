import axiosInstance, { API_URL } from "@/configs/axiosConfig";
import ServiceBase from "@/service/service-base";

export default class MarketIntelligenceService extends ServiceBase {


    async GetProductsToSearch(idCompany) {

        return await axiosInstance.get(API_URL + "api/precificacao/GetProdutosConfigurados", {
            params: { idEmpresa: idCompany },
        });
    }

    async PostSearchByLeadPaginated(data){
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/SearchByLeadPaginated",data)
    }

     async PostExport(data){
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/Export_SearchByLead", data)
     }
}
