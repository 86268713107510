<template>
  <v-expansion-panel-header>
    <div class="flex-space-between">
      <div class="my-flex">
        <v-icon
          @click.stop="copyFunction(productName.productName)"
          :color="$prdStyles('color-primary')"
          left
          small
          class="copy-button"
          >mdi-content-copy</v-icon
        >
        <h4>{{ productName.productName }}</h4>
      </div>

      <!-- HEADER ACTIONS -->
      <div class="my-flex font-weight-bold">
        <div class="my-flex mr-4">
          <v-icon color="#37B352" left small>mdi-arrow-up-bold-outline</v-icon>
          {{ setCurrency(maxPrice) }}
        </div>
        <div class="my-flex mr-4">
          <v-icon :color="$prdStyles('color-primary')" left small
            >mdi-minus-circle-outline</v-icon
          >
          {{ setCurrency(avgPrice) }}
        </div>
        <div class="my-flex mr-4">
          <v-icon color="#B00020" left small
            >mdi-arrow-down-bold-outline</v-icon
          >
          {{ setCurrency(minPrice) }}
        </div>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { setCurrency } from "@/utils/format-currency";

export default {
  props: ["product", "productName"],
  data() {
    return {};
  },

  computed: {
    maxPrice() {
      let maxPrice =
        Math.max(...this.product.map((item) => item.price)) ?? null;

      if (
        !maxPrice ||
        maxPrice == undefined ||
        maxPrice == Infinity ||
        maxPrice == -Infinity
      )
        return null;

      return maxPrice;
    },
    minPrice() {
      let minPrice =
        Math.min(...this.product.map((item) => item.price)) ?? null;
      if (
        !minPrice ||
        minPrice == undefined ||
        minPrice == Infinity ||
        minPrice == -Infinity
      )
        return null;

      return minPrice;
    },

    avgPrice() {
      const sum =
        this.product.reduce((total, item) => total + item.price, 0) ?? null;
      const averagePrice = sum / this.product.length ?? null;
      if (
        !averagePrice ||
        averagePrice == undefined ||
        averagePrice == Infinity ||
        averagePrice == -Infinity
      )
        return null;

      return averagePrice;
    },
  },
  methods: {
    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);
    },
    setCurrency,
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex !important;
  align-items: center !important;
}

.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
</style>