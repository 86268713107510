<template>
  <loading-div
    :is-loading="service.getAllFilterGroups.isLoading"
    :error="service.getAllFilterGroups.error"
    class="root-grid gap-6 mt-6"
  >
    <div class="d-flex gap-4">
      <div
        class="d-flex flex-column gap-6"
        v-for="(displaySide, index1) in filtersDisplay"
        :key="'displaySide-' + index1"
      >
        <div
          class="d-flex flex-column"
          v-for="(group, index2) in displaySide"
          :key="'group-' + group.name + index2"
        >
          <h3 v-if="!!group.name" class="filter-type-title pb-2 font-size-14">
            {{ group.name }}
          </h3>
          <v-checkbox
            v-for="(filter, index3) in group.list"
            :key="'filter-' + filter.fieldName + index3"
            @change="selectDefaultFilter($event, filter.idEnterprisePriceGroupDefaultFilter)"
            :label="filter.fieldName"
            :disabled="filters[filter.index].isDisabled || filter.preSelect"
            v-model="filters[filter.index].isSelected"
            class="pa-0"
            hide-details
          />
        </div>
      </div>
    </div>

    <div>
      <v-col cols="12" md="5">
        <combo-box
          v-for="(e, index) in selectedFilters"
          :key="'selectedDefault' + index + selectedFilters[index].refresh"
          :label="e.fieldName"
          chips
          :items="selectedFilters[index].values"
          :text="'description'"
          multiple
          cache-items
          small-chips
          :disabled="selectedFilters[index].preSelect || selectedFilters[index].values.length == 0"
          v-model="selectedFilters[index].selectedValues"
          @input="selectFilterValue(selectedFilters[index].idEnterprisePriceGroupDefaultFilter)"
          hideIcon
        />
      </v-col>
    </div>
  </loading-div>
</template>
<script>
import loadingDiv from "@/Design_System/common/loading-div";
import comboBox from "@/Design_System/common/comboBox";
import AiPricingService from "@/service/ai-pricing";

export default {
  props: ["preFilters"],
  components: {
    loadingDiv,
    comboBox,
  },
  data: () => ({
    service: new AiPricingService(),
    filters: [],
    filtersDisplay: [],
    selectedFilters: [],
  }),
  methods: {
    // dev1: A partir daqui jaz um código estremamente confuso e provavelmente não performatico... mas com a pretenção de funcionar

    // faz a requisição para coletar os default filter e fazer os pos processamentos
    async fetchFilterGroups() {
      try {
        this.filters = await this.service.getAllFilterGroups.call(
          this.idCompany
        );
        
        // adiciona propriedades de gerencia do front
        // e trata pre selecionado
        this.filters.forEach((x, index) => {
          x.isSelected = x.preSelect;
          x.isDisabled = x.preSelect;
          x.index = index;
        })

        this.filters.filter(filter => filter.idParent == null).forEach(x => {
          this.updateDefaultFilterSelection(this.filters,x.idEnterprisePriceGroupDefaultFilter)
          // this.updateFilterValueSelection(x.idEnterprisePriceGroupDefaultFilter)
        })

        this.generateGroupedFilters();

        //gerando locked filters
        this.lockedFilters.forEach(x => {
          this.selectDefaultFilter(true, x.idEnterprisePriceGroupDefaultFilter, false)

          const selectedIndex = this.selectedFilters.findIndex(y => y.idEnterprisePriceGroupDefaultFilter = x.idEnterprisePriceGroupDefaultFilter)

          this.selectedFilters[selectedIndex].selectedValues.push({
            idEnterprisePriceGroupDefaultFilterValue: x.idEnterprisePriceGroupDefaultFilterValue,
            idParent: x.idParent,
            value: x.value,
            description: x.description
          })
        })

        this.updateParent()

      } catch (e) {
        console.error(e);
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_ERROR_FETCHING_FILTERS")
        );
      }
    },

    // função para criar agrupamento do default filter para mostrar na tela
    generateGroupedFilters() {
      let group = [];

      this.filters.forEach((e, e_index) => {
        let nameToFind = e["groupTag"];
        let index = group.findIndex((x) => x.name == nameToFind);

        if (index == -1) {
          group.push({
            name: nameToFind,
            order: Number.MAX_VALUE,
            list: [],
          });
        }

        index = group.findIndex((x) => x.name == nameToFind);

        group[index].list.push({
          idEnterprisePriceGroupDefaultFilter: e.idEnterprisePriceGroupDefaultFilter,
          idParent: e.idParent,
          fieldName: e.fieldName,
          order: e.order,
          // dbField: e.dbField,
          // groupTag: e.groupTag,
          preSelect: e.preSelect,
          // isSelected: e.isSelected,
          index: e_index,
        });

        group[index].list.forEach((x) => {
          if (group[index].order > x["order"]) group[index].order = x["order"];
        });
      });

      let half = Math.ceil(group.length / 2);

      this.filtersDisplay = [];

      this.filtersDisplay.push(group.slice(0, half));

      if (group.length > 1)
        this.filtersDisplay.push(group.slice(half));
    },

    // função recursiva para atualizar o status de seleção e disable dos checkbox do default filters
    updateDefaultFilterSelection(filters, idDefaultFilter){
      const index = filters.findIndex(x => x.idEnterprisePriceGroupDefaultFilter === idDefaultFilter)

      if(!filters[index].idParent){
        filters[index].isDisabled = false;
      }else{
        const indexParent = filters.findIndex(x => x.idEnterprisePriceGroupDefaultFilter === filters[index].idParent)

        if(filters[indexParent].isSelected){
          filters[index].isDisabled = false;
        }
        else{
          filters[index].isDisabled = true;
          filters[index].isSelected = false;
          this.selectDefaultFilter(false, idDefaultFilter, false)
        }
      }

      const filtersChild = filters.filter(filter => filter.idParent === idDefaultFilter);

      filtersChild.forEach(x => {
        this.updateDefaultFilterSelection(filters, x.idEnterprisePriceGroupDefaultFilter)
      })
    },

    // função para gerenciar a seleção dos checkbox dos deafults filters
    selectDefaultFilter(seleted, idDefaultFilter, update = true){

      let indexFilter = this.filters.findIndex(x => x.idEnterprisePriceGroupDefaultFilter == idDefaultFilter);
      let index = this.selectedFilters.findIndex(x => x.idEnterprisePriceGroupDefaultFilter == idDefaultFilter)

      if (seleted) {
        if(index >= 0)
          return

        this.selectedFilters.push({
          idEnterprisePriceGroupDefaultFilter: this.filters[indexFilter].idEnterprisePriceGroupDefaultFilter,
          dbField: this.filters[indexFilter].dbField,
          index: indexFilter,
          idParent: this.filters[indexFilter].idParent,
          preSelect: this.filters[indexFilter].preSelect,
          values: [],
          selectedValues: [],
          refresh: false, // variavel apra dar refresh no componente
        })
      } else {
        if(index == -1)
          return

        this.selectedFilters.splice(index, 1)
      }
      if(update)
        this.updateDefaultFilterSelection(this.filters, idDefaultFilter)

      if (seleted)
        this.updateFilterValueSelection(idDefaultFilter)
    },  

    //função recursiva que atualiza os valores dos items dos combobox de acordo com as seleções de valores
    updateFilterValueSelection(idDefaultFilter, onlyChild = false){
      const filter = this.filters.find(x => x.idEnterprisePriceGroupDefaultFilter == idDefaultFilter)
      const indexSelected = this.selectedFilters.findIndex(x => x.idEnterprisePriceGroupDefaultFilter == idDefaultFilter)
      
      if(!onlyChild){
        if(this.selectedFilters[indexSelected].idParent){
          let parentValues = this.selectedFilters.find(x => x.idEnterprisePriceGroupDefaultFilter == this.selectedFilters[indexSelected].idParent).selectedValues.map(x => x.idEnterprisePriceGroupDefaultFilterValue)
          
          this.selectedFilters[indexSelected].values = filter.values.filter(x => parentValues.includes(x.idParent))
          this.selectedFilters[indexSelected].selectedValues = this.selectedFilters[indexSelected].selectedValues.filter(x => parentValues.includes(x.idParent))
          this.selectedFilters[indexSelected].refresh = !this.selectedFilters[indexSelected].refresh 
        }else{
          this.selectedFilters[indexSelected].values = filter.values
          this.selectedFilters[indexSelected].refresh = !this.selectedFilters[indexSelected].refresh
        }
      }
        
      const selectedFiltersChild = this.selectedFilters.filter(filter => filter.idParent === idDefaultFilter);
      // console.log(idDefaultFilter,this.selectedFilters[indexSelected].selectedValues)
      selectedFiltersChild.forEach(x => {
        this.updateFilterValueSelection(x.idEnterprisePriceGroupDefaultFilter)
      })
    },

    // função para gerenciar a seleção dos checkbox dos deafults filters
    selectFilterValue(idDefaultFilter){
      this.updateFilterValueSelection(idDefaultFilter, true)
      this.updateParent();
    },

    // função para atualizar o componente pai sobre os items selecionado
    updateParent() {
      let response = [];

      this.selectedFilters.forEach(x => {
        x.selectedValues.forEach(y => {
          response.push({
            idEnterprisePriceGroup_Filter: 0,
            idEnterprisePriceGroup_DefaultFilter: x.idEnterprisePriceGroupDefaultFilter, 
            value: y.value,
            isDeleted: false,
          })
        })
      })
      this.$emit("input", response);
    },

    async init() {
      await this.fetchFilterGroups();
    },
  },

  async mounted() {
    this.init();
  },

  computed: {
    
    lockedFilters() {
      return this.preFilters;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
};
</script>
<style lang="scss">
.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}
.filter-type-title {
  border-bottom: 1px solid $brand-color-secondary-pure;
}
.root-grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;
}
.filters-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.full-width {
  width: 100%;
}
.v-label {
  font-size: inherit;
}
</style>
