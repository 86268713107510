<template>
  <v-app>
    <v-main class="light-background">
      <v-container class="pa-0" style="height: 100%" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      drawerMobile: false,
      miniMobile: false,
    };
  },
  computed: {
    //comment the code below, in case you want to test without log into the system using a real user
    ...mapGetters(["hasUser", "userData"]),
  },
  created() {
    //comment the code below, in case you want to test without log into the system using a real user
    // if (this.$route.path.startsWith("/login") && this.hasUser) {
    //   this.$router.push("/main");
    // } else {
    //   if (this.$route.path.startsWith("/main") && !this.hasUser) {
    //     this.$router.push("/login");
    //   }
    // }
  },
};
</script>

<style lang="scss">
* {
  font-family: $font-family-base;
}

a {
  text-decoration: none;
}

:root {
  --bright-red: #ec4c37;
  --black: #000;
  --dark: #424242;
  --dark-moderate-blue: #3c5ca7;
  --dark-moderate-blue-2: #233053;
  --dark-text: #414141;
  --green: #56a667;
  --red: #ff0000;
  --very-dark-desaturated-blue: #353059;
  --very-light-gray: #f6f6f6;
  --white: #fff;
  --yellow: #ffdd00;
  --background-gray: #ededed;
  --msg-error: #b00020;
  --msg-success: #1d8527;
}
.image-logo {
  background-color: #353059;
}
.light-background {
  background-color: var(--background-gray);
}
.dark-text-color {
  color: var(--dark-text);
}
.white-text-color {
  color: var(--white);
}
.content {
  width: 100%;
  height: 100%;
  align-content: center;
}
.padding-all-30 {
  padding: 30px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-y-45 {
  margin: 45px 0 !important;
}
.margin-y-15 {
  margin: 15px 0 !important;
}
.margin-y-5 {
  margin: 5px 0 !important;
}
.v-data-table-header {
  background-color: #e0e0e0;
}

.v-data-table-header th {
  font-size: 14px;
  border: 1px solid white;
}

tbody {
  background-color: #f5f5f5;
  overflow-x: scroll;
}

tbody td {
  border: 1px solid white;
}
</style>
