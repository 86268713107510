/**
 * Given an array of numbers, return thir minimum, maximum and average value.
 *
 * @param {number[]} arr An array of numbers
 * @return {{ avg: number, min: number, max: number }}
 */
export function calculateMinMaxAvg (arr) {
  // If the array is empty, do nothing and return a default value
  if (arr?.length == 0) return { min: 0, max: 0, avg: 0 };

  let min = Infinity;
  let max = -Infinity;
  let sum = 0;

  arr.forEach(num => {
    if (num > max) max = num;
    if (num < min) min = num;
    sum += num;
  });

  return {
    min,
    max,
    avg: sum / arr.length,
  }
}
