<template>
  <div>
    <h3 v-if="dataTable == null || dataTable == []" class="text-center">
      {{this.$i18n.t("TXT_NO_DATA_FOUND_DISPLAY")}}
    </h3>
    <v-data-table
      v-if="dataTable"
      :headers="headers"
      :items="dataTable"
      hide-default-header
    >
      <template v-slot:header>
        <td
          v-for="(header, index) in headers"
          :key="index"
          class="original-header-table text-center"
        >
          {{ header.text }}
          <tooltip-informations :title="header.tooltipText" />
        </td>
      </template>

      <template v-slot:[`item.trust`]="{ item }">
        <span>{{ item.trust | trustFilter }}</span>
      </template>
      <template v-slot:[`item.lift`]="{ item }">
        <span>{{ item.lift | liftFilter }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { tooltipInformations },
  props: ["dataTable"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_ANTECEDENT_MIX"),
          value: "backgroudMix",
          align: "center",
          tooltipText: "tooltip Mix Antecedente",
        },
        {
          text: this.$i18n.t("TXT_CONSEQUENT_MIX"),
          value: "consequentMix",
          align: "center",
          tooltipText: "tooltip Mix Consequente",
        },

        {
          text: this.$i18n.t("TXT_TRUST"),
          value: "trust",
          align: "center",
          tooltipText: "tooltip Confiança",
        },

        {
          text: this.$i18n.t("TXT_INCREASE"),
          value: "lift",
          align: "center",
          tooltipText: "tooltip Aumento",
        },
      ],
    };
  },
  filters: {
    trustFilter(value) {
      if (!value) return "-";
      return (value * 100).toFixed(4) + "%";
    },

    liftFilter(value) {
      if (!value) return "-";
      return value.toFixed(4) + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
.original-header-table {
  background-color: rgba(224, 224, 224, 255);
  padding: 12px;
  font-weight: bold;
  font-size: 13px;
  border-right: 2px solid white;
}
</style>