<template>
  <div>
    <direcionadorPrecosConcorrente v-if="this.product.tipoPrecificacao == 'Concorrente'" v-bind:product="this.product">
    </direcionadorPrecosConcorrente>
    <direcionadorPrecosCusto v-else-if="this.product.tipoPrecificacao == 'Custo'" v-bind:product="this.product">
    </direcionadorPrecosCusto>
    <direcionadorPrecosMisturaInterna v-else-if="this.product.tipoPrecificacao == 'Mistura Interna'"
      v-bind:product="this.product">
    </direcionadorPrecosMisturaInterna>
    <direcionadorPrecosMisturaExterna v-else-if="this.product.tipoPrecificacao == 'Mistura Externa'"
      v-bind:product="this.product">
    </direcionadorPrecosMisturaExterna>
  </div>
</template>

<script>
import DirecionadorPrecosConcorrente from "./direcionador-precos-concorrente.vue";
import DirecionadorPrecosCusto from "./direcionador-precos-custo.vue";
import DirecionadorPrecosMisturaInterna from "./direcionador-precos-mistura-interna.vue";
import DirecionadorPrecosMisturaExterna from "./direcionador-precos-mistura-externa.vue";

export default {
  components: {
    DirecionadorPrecosConcorrente,
    DirecionadorPrecosCusto,
    DirecionadorPrecosMisturaInterna,
    DirecionadorPrecosMisturaExterna,
  },
  props: ["product"],
  data() {
    return {
      itemsUfBrandSemCap: [],
      isEdit: true,
      number: 10,
      number2: -10,
    };
  },
  methods: {
    calculateCapInt(val1, val2) {
      return val1 / val2 - 1;
    },
    calcDolar(val) {
      return (val / 5.2).toFixed(1).toString().replace(".", ",");
    },
    calcDolarWithoutString(val) {
      return (val / 5.2).toFixed(2);
    },
    formatVal(val, decimalPlaces) {
      return val.toFixed(decimalPlaces).toString().replace(".", ",");
    },
    calcPercentage(val, val2) {
      return ((val / val2 - 1) * -100).toFixed(0).toString().replace(".", ",");
    },
  },
  beforeMount() { },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr th:first-child {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(2) {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(3) {
      border-top-left-radius: 10px;
    }

    tr th:nth-child(6) {
      border-top-right-radius: 10px;
    }

    tr th {
      background-color: #3c5ca7;
      color: white;
      border: 2px solid white;
      padding: 5px;
    }
  }

  td {
    padding: 8px;
    border: 2px solid white;
    text-align: center;
  }

  tbody {
    td {
      background-color: #f5f5f5;
    }

    .currency {
      writing-mode: vertical-rl;
      background-color: #3c5ca7;
      color: white;
      font-weight: bold;
      width: 8px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .item {
      background-color: #cccccc;
      font-weight: bold;
    }

    .item:first-child {
      background-color: #cccccc;
      font-weight: bold;
    }
  }
}

.hidden-cell {
  background-color: transparent;
  border: 1px solid transparent;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
</style>