import Vue from "vue";
import VueI18n from "vue-i18n";
// Importing translations files
import en from "./assets/i18n/en.json";
import pt from "./assets/i18n/pt.json";
import es from "./assets/i18n/es.json";

Vue.use(VueI18n);

// user browser language local
const userLocale = navigator.language;
const fallbackLocale = userLocale || "en-US";
const locale = localStorage.getItem("lang") || fallbackLocale;

export default new VueI18n({
  locale,
  fallbackLocale,
  messages: {
    "en-US": en,
    "es-ES": es,
    "pt-BR": pt,
  },
});
