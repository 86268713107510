<template>
  <v-card class="pa-4">
    <v-expansion-panels
      v-model="panel"
      accordion
      flat
      multiple
      :disabled="isDisable || tabLoading"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          expand-icon="mdi-chevron-down"
          class="ma-0 pa-0"
        >
          <v-row class="ma-0 pa-0" justify="space-between" align="center">
            <div style="display: flex; align-items: center">
              <label class="card-subtitle" v-t="'TXT_PRICE_TRENDS'"></label>
              <v-tooltip
                max-width="470px"
                color="#F5F5F5"
                style="pointer-events: all"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span
                  style="
                    color: #000;
                    font-size: 14px !important;
                    line-height: 1.4;
                  "
                  v-html="$t('TXT_PRICE_TRENDS_TOOLTIP')"
                ></span>
              </v-tooltip>

              <div v-show="disableTabs == true">
                <v-progress-circular
                  class="mx-3"
                  size="20"
                  width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <span style="opacity: 0.5" class="font-weight-bold">{{
                  $t("TXT_CALCULATE_PROJECTION")
                }}</span>
              </div>
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <!-- SKELETON LOADER -->
          <div v-show="isLoading">
            <v-skeleton-loader
              class="my-6"
              type="image, table-row, text"
            ></v-skeleton-loader>
          </div>

          <div v-show="!isLoading">
            <v-row
              class="ma-0 pa-0 mt-n6"
              justify="space-between"
              align-content="start"
            >
              <div class="group-box mt-6">
                <p class="pa-0 ma-0 mr-5" v-t="'TXT_GROUP_BY'"></p>
                <!-- GROUP BY -->
                <v-tabs
                  color="black"
                  :slider-color="$prdStyles('color-secundary')"
                  class="group-filters ml-n3"
                  height="25px"
                >
                  <!-- PRODUCT -->
                  <v-tab
                    :disabled="disableTabs"
                    class="mr-4"
                    @click="
                      (selectedTab = 1), getTemporalEvolution(priceTrendsData)
                    "
                    >{{ $t("TXT_PRODUCT") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_TEMPORAL_TOOLTIP_PRODUCT')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                  <!-- RETAILER -->
                  <v-tab
                    :disabled="disableTabs"
                    class="mr-4"
                    @click="
                      (selectedTab = 2), getTemporalEvolution(priceTrendsData)
                    "
                  >
                    {{ $t("TXT_RETAILER") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_TEMPORAL_TOOLTIP_RETAILER')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                  <!-- MANUFACTURE -->
                  <v-tab
                    :disabled="disableTabs"
                    class="mr-4"
                    @click="
                      (selectedTab = 3), getTemporalEvolution(priceTrendsData)
                    "
                  >
                    {{ $t("TXT_MANUFACTURER") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_TEMPORAL_TOOLTIP_BRAND')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                  <!-- CONSOLIDATED -->
                  <v-tab
                    :disabled="disableTabs"
                    @click="
                      (selectedTab = 0), getTemporalEvolution(priceTrendsData)
                    "
                  >
                    {{ $t("TXT_CONSOLIDATED") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_TEMPORAL_TOOLTIP_CONSOLIDATED')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                </v-tabs>
              </div>
            </v-row>

            <div v-show="tabLoading">
              <v-skeleton-loader
                class="my-6"
                type="image, table-row, text"
              ></v-skeleton-loader>
            </div>

            <!-- CHARTS -->
            <div v-show="!tabLoading && !isLoading">
              {{ chartData.series.data }}
              <v-tabs-items justify="center" v-model="selectedTab">
                <v-tab-item>
                  <!-- Historical Price Chart -->
                  <highcharts class="hc mt-4" :options="chartData"></highcharts>
                </v-tab-item>
                <v-tab-item>
                  <!-- Historical Price Chart -->
                  <highcharts class="hc mt-4" :options="chartData"></highcharts>
                </v-tab-item>
                <v-tab-item>
                  <!-- Historical Price Chart -->
                  <highcharts class="hc mt-4" :options="chartData"></highcharts>
                </v-tab-item>
                <v-tab-item>
                  <!-- Historical Price Chart -->
                  <highcharts class="hc mt-4" :options="chartData"></highcharts>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
          <v-spacer></v-spacer>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

import PredimonitorService from "@/service/predimonitor/predimonitor-service.js";

export default {
  name: "temporalEvolutionChart",
  props: [
    "priceTrendsData",
    "dateCategories",
    "showY2",
    "isLoading",
    "isDisable",
    "closePanel",
  ],
  data() {
    return {
      predimonitorService: new PredimonitorService(),
      rotateIcon: false,
      selectedTab: 1,
      priceCurrency: null,
      panel: [],
      tabLoading: false,
      showProjectionLoading: false,

      chartProducts: [],
      chartMarketplace: [],
      chartbrand: [],
      chartConsolidated: [],

      // Gambiarra pra funcionar a data
      categorieProduct: [],
      categorieMarketplace: [],
      categorieBrand: [],
      categorieConsolidated: [],

      isProductMounted: false,
      isMarketplaceMounted: false,
      isBrandMounted: false,
      isConsolidatedMounted: false,

      disableTabs: false,
      // expansion panel header menu items (not implemented yet)
      // items: [
      //   { id: 1, name: this.$i18n.t("TXT_DAILY") },
      //   { id: 2, name: this.$i18n.t("TXT_WEEKLY") },
      //   { id: 3, name: this.$i18n.t("TXT_MONTHLY") },
      // ],

      chartData: {
        chart: {
          type: "spline", // Chart template
        },
        legend: {
          symbolWidth: 10, // Symbol width of inferior legend
        },
        lang: {
          // variables to rename the data exportation actions
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          // Msg when have no data
        },
        title: {
          text: "", // Chart title
        },
        // Yaxis information
        yAxis: [
          // Left side information
          {
            title: {
              text: this.$i18n.t("TXT_AVERAGE_PRICE"), // Texto lateral esquerda
            },

            labels: {
              formatter: function () {
                let currency = localStorage.getItem("currency");
                let value = this.value;
                if (!value) return "-";
                let rounded = (Math.trunc(value * 100) / 100).toFixed(2);
                let formated = rounded.replace(".", ",");
                return currency + " " + formated;
              },
              decimals: 2,
            },
            crosshair: true,
            allowDecimals: false,
          },

          // Rigth side information (only for hasResource(marketplace.machinery))
          {
            title: {
              text: this.$i18n.t("TXT_ANNOUCEMENT"), // Texto lateral esquerda
            },

            labels: {
              format: "", // Símbolo da moeda e separador vem do banco de dados?
            },
            opposite: true,
            allowDecimals: false,
          },
        ],

        xAxis: {
          title: {
            text: this.$i18n.t("TXT_TIME_DAYS"), // inferior data legend
          },
          // Xaxis data
          categories: [],
          // add a line when hover the chart points
          crosshair: true,
        },

        tooltip: {
          formatter: function () {
            var formatStr = "";

            // Função para retirar o item de anúncios do array príncipal de dados
            var pointsSemAnuncios = this.points.filter(
              (item) => item.series.name !== "Anuncios"
            );

            //Mostra a data do dado no tooltip
            formatStr += "<b>" + this.points[0].x + "</b>" + "<br/><br/>";

            // Loop de repetição para montar os dados do array principal no tooltip
            for (var i = 0; i < pointsSemAnuncios.length; i++) {
              var point = pointsSemAnuncios[i];

              let currency = localStorage.getItem("currency");
              let rounded = (Math.trunc(point.y * 100) / 100).toFixed(2);
              let formated = rounded.replace(".", ",");

              //Isso é o que vai ser mostrado na tela
              formatStr +=
                '<span style="color: #3C5CA7; font-weight: 400;">' +
                '<span style="color:' +
                point.color +
                '">● </span>' +
                point.series.name.toUpperCase() +
                ": </span>" +
                '<b><span style="color: #3C5CA7" >' + currency  + ' '+ 
                formated +
                "</span></b>  <br/>";
            }

            // Função que verifica se o item é anúncio, se for, ele adiciona no tooltip, mas com uma formatação diferente do array principal
            this.points.forEach((el) => {
              if (el.series.name == "Anuncios") {
                formatStr +=
                  '</br><span style="color: #3C5CA7; font-weight: 400;">' +
                  el.series.name +
                  " : </span> " +
                  '<b><span style="color: #3C5CA7; font-weight: bold;">' +
                  el.y +
                  "</b>";
              }
            });

            return formatStr;
          },

          shared: true,
          borderColor: "transparent",
        },

        plotOptions: {
          series: {
            marker: {
              lineWidth: 1,
              lineColor: null, // inherit from series,
              symbol: "circle",
            },
            animation: {
              duration: 1000, // tempo de duração da animação
            },
            cursor: "pointer",
            connectNulls: true,
          },
        },

        series: [],
      },

      consolidated: {
        name: "All",
      },
    };
  },
  methods: {
    getTemporalEvolution(chartData) {
      this.chartData.xAxis.categories = this.dateCategories;

      this.chartData.series = [];

      switch (this.selectedTab) {
        case 1:
          this.groupByProduct(chartData);
          break;
        case 2:
          this.groupByMarketplace(chartData);
          break;
        case 3:
          this.groupByBrand(chartData);
          break;
        case 0:
          this.groupByAll(chartData);
      }

      if (!this.showY2) {
        this.chartData.yAxis[1].title.text = "";
      }

      // if (this.showY2) {
      //   this.chartData.series.push(
      //     ...this.temporalEvolutionChartData.series2.map((serie) => ({
      //       zIndex: -1,
      //       name: serie.name,
      //       data: serie.data,
      //       type: serie.type,
      //       yAxis: 1,
      //       color: serie.color,
      //       maxPointWidth: 50,
      //       zoneAxis: serie.zoneAxis,
      //       zones: serie.zones,
      //     }))
      //   );
      // }
    },

    // GROUP BY PRODUCT
    async groupByProduct(chartData) {
      this.tabLoading = true;

      // Do the function if the array product has never mounted
      this.disableTabs = true;
      let priceProjection = [];
      this.chartProducts = [];

      this.chartProducts = this.groupProductByCrawlerDate(chartData);
      this.chartData.series = this.chartProducts;

      this.tabLoading = false;
      this.$emit("setLoading", false);

      // GetProjection
      let request = this.getProjection(chartData);
      request.groupBy = 1;

      await this.predimonitorService
        .getPriceProjection(request)
        .then((res) => {
          if (res.data?.answer?.series?.length != 0) {
            priceProjection = res.data.answer.series;
            let newDateProjection = [];

            this.dateCategories.forEach((el) => {
              newDateProjection.push(el);
            });

            res.data.answer.categories.forEach((el) => {
              newDateProjection.push(el);
            });

            this.chartData.xAxis.categories = newDateProjection;

            priceProjection.map((product) => {
              let matchingSeries = this.chartData.series.find(
                (s) => s.productName == product.productName
              );
              if (matchingSeries) {
                product.prices.forEach((el) => {
                  matchingSeries.data.push(el);
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log("erro ao fazer projeção", error);
          this.tabLoading = false;
          this.$emit("setLoading", false);
        });

      this.disableTabs = false;

      this.tabLoading = false;
      this.$emit("setLoading", false);
    },
    groupProductByCrawlerDate(products) {
      let newProducts = [];
      products.forEach((product) => {
        let agroupedPrice = [];

        product.priceResults
          .filter((priceResult) => !priceResult.disregarded) // Filtra os objetos com disregarded: false
          .forEach((priceResult) => {
            const crawlerDate = priceResult.crawlerDate.split("T")[0];

            // Verifica se já existe um objeto com a mesma crawlerDate no novo array
            const existingDate = agroupedPrice.find(
              (item) => item.crawlerDate === crawlerDate
            );

            // Se existir, adiciona o preço no array existente, caso contrário, cria um novo objeto
            if (existingDate) {
              existingDate.prices.push(priceResult.price);
            } else {
              agroupedPrice.push({
                crawlerDate: crawlerDate,
                prices: [priceResult.price],
              });
            }
          });

        // Cria um novo objeto produto com os preços agrupados
        const newProduct = {
          type: "spline",
          productName: product.productName,
          name: product.productName,
          data: [],
          zoneAxis: "x",
          zones: [
            { value: this.dateCategories.length - 1 },
            { dashStyle: "dot" },
          ],
          idMonitoringItem: product.idMonitoringItem,
          price: agroupedPrice,
        };

        newProduct.price.forEach((priceData) => {
          priceData.prices = this.calculateAverage(priceData.prices);
        });

        newProducts.push(newProduct);
      });

      for (const date of this.dateCategories) {
        for (const product of newProducts) {
          const price = this.getPriceForDate(product.price, date);
          product.data.push(price);
        }
      }

      newProducts.forEach((el) => {
        el.data = this.processArray(el.data);
      });

      return newProducts;
    },

    // GROUP BY MARKETPLACE
    async groupByMarketplace(chartData) {
      this.tabLoading = true;

      this.disableTabs = true;

      let priceProjection = [];
      this.chartMarketplace = [];

      let groupedData = this.groupPricesByMarketplace(chartData);

      this.chartData.series = groupedData;

      this.$emit("setLoading", false);
      this.tabLoading = false;

      // GetProjection
      let request = this.getProjection(chartData);
      request.groupBy = 2;

      await this.predimonitorService
        .getPriceProjection(request)
        .then((res) => {
          if (res.data?.answer?.series?.length != 0) {
            priceProjection = res.data.answer.series;
            let newDateProjection = [];

            this.dateCategories.forEach((el) => {
              newDateProjection.push(el);
            });

            res.data.answer.categories.forEach((el) => {
              newDateProjection.push(el);
            });

            this.chartData.xAxis.categories = newDateProjection;

            priceProjection.map((product) => {
              let matchingSeries = this.chartData.series.find(
                (s) => s.name == product.productName
              );
              if (matchingSeries) {
                product.prices.forEach((el) => {
                  matchingSeries.data.push(el);
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log("erro ao fazer projeção", error);
          this.tabLoading = false;
          this.$emit("setLoading", false);
        });

      this.disableTabs = false;

      this.$emit("setLoading", false);
      this.tabLoading = false;
    },
    groupPricesByMarketplace(products) {
      let groupedData = [];

      products.forEach((product) => {
        product.priceResults.forEach((priceResult) => {
          if (!priceResult.disregarded) {
            let marketplaceData = groupedData.find(
              (data) => data.name === priceResult.marketplace
            );

            if (marketplaceData) {
              let existingDate = marketplaceData.prices.find(
                (price) =>
                  price.crawlerDate === priceResult.crawlerDate.substr(0, 10)
              );

              if (existingDate) {
                existingDate.prices.push(priceResult.price);
              } else {
                marketplaceData.prices.push({
                  crawlerDate: priceResult.crawlerDate.substr(0, 10),
                  prices: [priceResult.price],
                });
              }
            } else {
              groupedData.push({
                type: "spline",
                name: priceResult.marketplace,
                prices: [
                  {
                    crawlerDate: priceResult.crawlerDate.substr(0, 10),
                    prices: [priceResult.price],
                  },
                ],
                data: [],
                zoneAxis: "x",
                zones: [
                  { value: this.dateCategories.length - 1 },
                  { dashStyle: "dot" },
                ],
              });
            }
          }
        });
      });

      groupedData.forEach((marketplaceData) => {
        marketplaceData.prices.forEach((datePrices) => {
          datePrices.prices = this.calculateAverage(datePrices.prices);
        });
      });

      for (const date of this.dateCategories) {
        for (const marketPlace of groupedData) {
          const price = this.getPriceForDate(marketPlace.prices, date);
          marketPlace.data.push(price);
        }
      }

      groupedData.forEach((el) => {
        el.data = this.processArray(el.data);
      });

      return groupedData;
    },

    // GROUP BY BRAND
    async groupByBrand(chartdata) {
      this.tabLoading = true;

      this.disableTabs = true;

      let priceProjection = [];
      this.chartBrand = [];

      let groupedData = this.groupPricesByBrand(chartdata);

      this.chartData.series = groupedData;
      this.chartBrand = groupedData;

      this.$emit("setLoading", false);
      this.tabLoading = false;

      // GetProjection
      let request = this.getProjection(chartdata);
      request.groupBy = 3;

      await this.predimonitorService
        .getPriceProjection(request)
        .then((res) => {
          if (res.data?.answer?.series?.length != 0) {
            priceProjection = res.data.answer.series;
            let newDateProjection = [];

            this.dateCategories.forEach((el) => {
              newDateProjection.push(el);
            });

            res.data.answer.categories.forEach((el) => {
              newDateProjection.push(el);
            });

            this.chartData.xAxis.categories = newDateProjection;

            priceProjection.map((product) => {
              let matchingSeries = this.chartData.series.find(
                (s) => s.name == product.productName
              );
              if (matchingSeries) {
                product.prices.forEach((el) => {
                  matchingSeries.data.push(el);
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log("erro ao fazer projeção", error);
          this.tabLoading = false;
          this.$emit("setLoading", false);
        });

      this.disableTabs = false;

      this.$emit("setLoading", false);
      this.tabLoading = false;
    },
    groupPricesByBrand(products) {
      let groupedData = [];

      products.forEach((product) => {
        product.priceResults.forEach((priceResult) => {
          if (!priceResult.disregarded) {
            let brandData = groupedData.find(
              (data) => data.name === priceResult.productBrand
            );

            if (brandData) {
              let existingDate = brandData.prices.find(
                (price) =>
                  price.crawlerDate === priceResult.crawlerDate.substr(0, 10)
              );

              if (existingDate) {
                existingDate.prices.push(priceResult.price);
              } else {
                brandData.prices.push({
                  crawlerDate: priceResult.crawlerDate.substr(0, 10),
                  prices: [priceResult.price],
                });
              }
            } else {
              groupedData.push({
                type: "spline",
                name: priceResult.productBrand,
                prices: [
                  {
                    crawlerDate: priceResult.crawlerDate.substr(0, 10),
                    prices: [priceResult.price],
                  },
                ],

                data: [],
                zoneAxis: "x",
                zones: [
                  { value: this.dateCategories.length - 1 },
                  { dashStyle: "dot" },
                ],
              });
            }
          }
        });
      });

      // Calculate the average for each set of prices
      groupedData.forEach((brandData) => {
        brandData.prices.forEach((datePrices) => {
          datePrices.prices = this.calculateAverage(datePrices.prices);
        });
      });

      for (const date of this.dateCategories) {
        for (const productBrand of groupedData) {
          const price = this.getPriceForDate(productBrand.prices, date);
          productBrand.data.push(price);
        }
      }

      groupedData.forEach((el) => {
        el.data = this.processArray(el.data);
      });

      return groupedData;
    },

    // GROUP BY ALL
    async groupByAll(chartdata) {
      this.tabLoading = true;
      this.consolidated = {
        type: "spline",
        name: "All",
        data: [],
        zoneAxis: "x",
        zones: [
          { value: this.dateCategories.length - 1 },
          { dashStyle: "dot" },
        ],
      };
      if (!this.isConsolidatedMounted) {
        this.disableTabs = true;

        let priceProjection = [];
        let dateProjection = [];

        let series = [];

        // Separe by all
        chartdata.forEach((product) => {
          product.priceResults.forEach((priceResult) => {
            let date = new Date(priceResult.crawlerDate);
            date = date.toISOString().slice(0, 10);
            let seriesIndex = series.findIndex((s) => s.date === date);
            if (seriesIndex === -1) {
              series.push({ date, prices: [priceResult.price] });
            } else {
              series[seriesIndex].prices.push(priceResult.price);
            }
          });
        });

        series.forEach((s) => {
          let sum = s.prices.reduce((a, b) => a + b);
          s.avgPrice = sum / s.prices.length;
        });
        let lastValidValue = null;

        this.dateCategories.forEach((dateCategory) => {
          let seriesIndex = series.findIndex((s) => s.date === dateCategory);
          if (seriesIndex === -1) {
            this.consolidated.data.push({
              y: lastValidValue,
              marker: { fillColor: "#ffff" },
            });
          } else {
            lastValidValue = series[seriesIndex].avgPrice;
            this.consolidated.data.push(lastValidValue);
          }
        });

        this.chartConsolidated = [this.consolidated];
        this.chartData.series = [this.consolidated];

        this.$emit("setLoading", false);
        this.tabLoading = false;

        // GetProjection
        let request = this.getProjection(chartdata);
        request.groupBy = 99;

        await this.predimonitorService
          .getPriceProjection(request)
          .then((res) => {
            if (
              res.data != "Erro na projeção!" &&
              res.data.categories &&
              res.data.categories.length != 0 &&
              res.data.series
            ) {
              dateProjection = res.data.categories;
              priceProjection = res.data.series;

              let newDateProjection = [];
              this.chartData.xAxis.categories.forEach((el) => {
                newDateProjection.push(el);
              });

              dateProjection.forEach((el) => {
                newDateProjection.push(el);
              });

              this.chartData.xAxis.categories = newDateProjection;
              this.categorieConsolidated = newDateProjection;

              priceProjection.map((product) => {
                let matchingSeries = this.chartData.series.find(
                  (s) => s.name === product.productName
                );
                if (matchingSeries) {
                  product.prices.forEach((el) => {
                    matchingSeries.data.push(el);
                  });
                }
              });
            }
          });

        this.isConsolidatedMounted = true;
        this.disableTabs = false;

        this.$emit("setLoading", false);
        this.tabLoading = false;
      } else {
        this.chartData.series = this.chartConsolidated;

        if (this.categorieConsolidated.length == 0) {
          this.chartData.xAxis.categories = this.dateCategories;
        } else {
          this.chartData.xAxis.categories = this.categorieConsolidated;
        }
      }

      this.$emit("setLoading", false);
      this.tabLoading = false;
    },

    calculateAverage(prices) {
      if (!prices || prices.length === 0) return 0;

      const sum = prices.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum / prices.length;
    },
    getPriceForDate(prices, date) {
      for (const priceObj of prices) {
        if (priceObj.crawlerDate === date) {
          return priceObj.prices;
        }
      }
      return null;
    },
    processArray(data) {
      let processedArray = [];
      let lastNonNullValue = null;

      for (let i = 0; i < data.length; i++) {
        if (data[i] !== null) {
          lastNonNullValue = data[i];
          processedArray.push(data[i]);
        } else if (lastNonNullValue !== null) {
          processedArray.push({
            y: lastNonNullValue,
            marker: { fillColor: "#ffff" },
          });
        } else {
          processedArray.push(null);
        }
      }
      return processedArray;
    },

    getProjection(products) {
      let updatedProducts = products.map((product) => {
        let newProduct = { ...product };
        newProduct.crawlerDate = newProduct.avgInfo_DateTime;
        delete newProduct.avgInfo_DateTime;
        return newProduct;
      });

      updatedProducts.forEach((el) => {
        el.productSearched = el.productName;
      });

      let request = {
        startDate: this.dateCategories[0] + "T00:00:00",
        endDate:
          this.dateCategories[this.dateCategories.length - 1] + "T00:00:00",
        products: updatedProducts,
        idCompany: this.idCompany,
      };

      return request;
    },

    resetMountedData() {
      this.isProductMounted = false;
      this.isMarketplaceMounted = false;
      this.isBrandMounted = false;
      this.isConsolidatedMounted = false;
    },
  },

  watch: {
    priceTrendsData() {
      this.resetMountedData();
      this.getTemporalEvolution(this.consideredProducts);
    },

    closePanel() {
      if (this.closePanel) {
        this.panel = [];
      }
    },

    disableTabs() {
      if (this.disableTabs) {
        this.showProjectionLoading = true;
      } else {
        this.showProjectionLoading = false;
      }
    },
  },

  computed: {
    consideredProducts: function () {
      let products = [];

      this.priceTrendsData.forEach((el) => {
        let newProducts = Object.assign({}, el);
        newProducts.priceResults = [];

        el.priceResults.forEach((item) => {
          if (!item.disregarded) {
            newProducts.priceResults.push(Object.assign({}, item));
          }
        });

        products.push(newProducts);
      });

      return products;
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.group-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}

.isActive {
  border-bottom: 4px solid red;
}

.rotateIcon {
  transform: rotate(180deg);
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}
</style>
