<template>
  <v-card class="pa-4">
    <v-row no-gutters align="center" class="mb-6">
      <h4>ÚLTIMAS SIMULAÇÕES</h4>
      <v-spacer></v-spacer>
      <prd-btn @click="showSimulator" :title="'Ver simulador'" />
    </v-row>

    <v-expansion-panels flat>
      <v-expansion-panel
        v-for="(simulation, index) in simulations"
        :key="index"
        class="mb-2"
      >
        <v-expansion-panel-header
          color="#f5f5f5"
          expand-icon="mdi-chevron-right"
        >
          <div class="main-simulation">
            <span>{{ simulation.name }}</span>
            <span class="simulation-date"
              >Vigência: {{ simulation.startDate }} -
              {{ simulation.endDate }}</span
            >
          </div>
        </v-expansion-panel-header>

      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { prdBtn },
  data() {
    return {
      simulations: [],
    };
  },

  methods: {
    async getSimulations() {
      const req = await fetch("http://localhost:3000/simulations");
      const data = await req.json();
      this.simulations = data;
    },

    showSimulator(){}
  },

  mounted() {
    this.getSimulations();
  },
};
</script>

<style lang="scss" scoped>
.main-simulation {
  display: flex;
  justify-content: space-between;

  span {
    font-size: 14px;
  }

  .simulation-date {
    font-size: 14px;
    opacity: 0.6;
  }
}
</style>