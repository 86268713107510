<template>
  <v-container fluid>
    <v-row no-gutters align="end" justify="space-between">
      <titleBreadCrumbs :title="'PREDIMONITOR'" :hasTooltip="false" :showTooltip="false" />
      <div class="mb-4">
        <router-link to="/main/crawler-execute">
          <prd-btn :title="'Executar crawler'" :outlined="true" class="mr-4" />
        </router-link>

        <router-link to="/main/tags-config">
          <prd-btn :title="'Configuração de tags'" :outlined="true" />
        </router-link>
      </div>
    </v-row>

    <search-main
      @searchProducts="formatDataToTable($event)"
      @setLoading="isLoading = $event"
    />

    <table-result :products="products" :isLoading="isLoading" class="mt-4" />
  </v-container>
</template>

<script>
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import SearchMain from "./components/search-main.vue";
import TableResult from "./components/table-result.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  name: "predimonitor-V2",
  components: { titleBreadCrumbs, SearchMain, TableResult, PrdBtn },
  data() {
    return {
      products: [],

      isLoading: false,
    };
  },

  watch: {
    products: {
      handler(values) {
        if (!values || values.length == 0) return;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    formatDataToTable(values) {
      let newItems = [];

      values.forEach((el) => {
        let formatedTable = this.setDataToTable(el);

        let formatedProduct = {
          tableData: formatedTable,
          productName: el.predify_product_description,
        };

        newItems.push(formatedProduct);
      });

      this.products = newItems;
    },
    setDataToTable(products) {
      let filteredData = [];

      products.results.forEach((item) => {
        const {
          crawler_date,
          crawler_name,
          product_link,
          product_name,
          sellers,
        } = item;

        sellers.forEach((seller) => {
          const { seller_name, prices } = seller;

          prices.forEach((priceItem) => {
            const { price, price_payment_type, installments, price_tax_st } =
              priceItem;

            if (installments && installments.length > 0) {
              installments.forEach((installment) => {
                const {
                  quantity,
                  deflated_value,
                  price: installmentPrice,
                  price_currency,
                } = installment;

                filteredData.push({
                  crawler_date,
                  crawler_name,
                  product_link,
                  product_name,
                  seller_name,
                  price: price,
                  price_payment_type: price_payment_type,
                  quantity,
                  deflated_value,
                  installmentPrice,
                  price_currency,
                  price_tax_st,
                });
              });
            } else {
              filteredData.push({
                crawler_date,
                crawler_name,
                product_link,
                product_name,
                seller_name,
                price: price || 0,
                price_payment_type: price_payment_type,
              });
            }
          });
        });
      });
      return filteredData;
    },
  },

  created() {
    document.title = "PREDIFY | PREDIMONITOR";
  },
};
</script>