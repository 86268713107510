<template>
  <v-container fluid id="predi-monitor">
    <v-row class="ma-0 pa-0 mb-2" align="center">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadCrumbs"
        :title="'PREDIMONITOR'"
        :hasTooltip="false"
      />
      <v-spacer></v-spacer>
    </v-row>

    <!--SEARCH -->
    <searchProducts
      :updateData="updateData"
      :isLoading="isLoading"
      :infoQuery="infoQuery"
      @setLoading="setLoading"
      @search="getResults"
      @showSnackbar="showSnackbar"
      ref="search-products"
      class="mb-6"
    />

    <v-row no-gutters
      ><label class="font-weight-bold" v-t="'TXT_RESULTS_UPPERCASE'"></label>
      <v-spacer></v-spacer>
      <router-link to="/main/tags-config">
        <prd-btn :title="$t('TXT_TAG_CONFIGURATION')" :outlined="true" />
      </router-link>
    </v-row>

    <!-- TABLE RESULTS -->
    <table-results
      :tableResultData="tableResultData"
      :isDisable="isDisable"
      :closePanel="closePanel"
      :idCompraAgora="idCompraAgora"
      :resourceGroup="resourceGroup"
      :userList="userList"
      :priceAlertList="priceAlertList"
      :priceCardLoading="priceCardLoading"
      @updateGraphics="updateGraphics"
      @showSnackbar="showSnackbar"
      @seila="seila"
      @showSnackBarItemsSave="showSnackBarItemsSave"
      @attData="attData"
      ref="table-result"
    />

    <!-- HITORICAL PRICE CHART-->
    <temporalEvolutionChart
      :priceTrendsData="priceTrendsData"
      :dateCategories="dateCategories"
      :isLoading="isLoading"
      :isDisable="isDisable"
      :closePanel="closePanel"
      @setLoading="setLoading"
      :showY2="showY2"
      class="mb-6"
    />

    <!-- PRODUCT COMPARISON --->
    <productComparison
      v-if="!idBamaq"
      class="mb-6"
      :boxplotData="boxplotData"
      :search="search"
    />

    <!-- BAMAQ ANNOUCEMENT MAP -->
    <announcementMap
      v-if="hasResource('predimonitor') && hasResource('marketplace.machinery')"
      :requestData="announcementMapData"
    />

    <!-- PRICE MAP BR --->
    <priceBrMapChart
      v-if="
        hasResource('predimonitor') &&
        hasResource('predimonitor.map.br') &&
        !hasResource('marketplace.machinery')
      "
      :mapBrLoading="mapBrLoading"
    />

    <!-- PRICE MAP USA --->
    <priceUsaMapChart
      v-if="hasResource('predimonitor.map.usa')"
      :mapUsaLoading="mapUsaLoading"
      class="mb-6"
    />

    <!-- Europe Map -->
    <priceEuMapChart
      v-if="idCompany == 2961"
      :mapEuLoading="mapEuLoading"
      :predimonitorResults="priceTrendsData"
      @showSnackbar="showSnackbar"
    />

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
// import resultTable from "./components/table-results/table-results.vue";

import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import searchProducts from "./components/new-search/new-search.vue";
import tableResults from "./components/table-results/table-results-main.vue";
import temporalEvolutionChart from "./components/charts/temporal-evolution-chart.vue";
import productComparison from "./components/product-comparison.vue";

import priceUsaMapChart from "./components/charts/priceUsa-map-chart.vue";
import priceBrMapChart from "./components/charts/priceBr-map-chart.vue";
import priceEuMapChart from "./components/charts/europe-map/priceEu-map-main.vue";

// Bamaq
import announcementMap from "./components/charts/announcement-map";

// Service
import PredimonitorService from "@/service/predimonitor/predimonitor-service";

import PriceAlertService from "@/service/predimonitor/price-alert-service";

// Lib to set the data range
import moment from "moment";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

import companyService from "@/service/company-service.js";
const Service = new companyService();

export default {
  components: {
    productComparison,
    temporalEvolutionChart,
    announcementMap,
    priceUsaMapChart,
    priceBrMapChart,
    tableResults,
    PrdBtn,
    searchProducts,
    titleBreadCrumbsComponent,
    priceEuMapChart,
  },
  data() {
    return {
      predimonitorService: new PredimonitorService(),
      priceAlertService: new PriceAlertService(),
      tableResultData: [],
      priceTrendsData: [],
      boxplotData: [],
      announcementMapData: [],

      dateCategories: [],

      breadCrumbs: [
        {
          text: "Predimonitor",
        },
      ],

      isLoading: false,
      isDisable: true,
      closePanel: false,
      mapUsaLoading: true,
      mapBrLoading: true,
      mapEuLoading: true,

      infoQuery: null,
      search: null,
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      // BAMAQ vars
      idBamaq: false,
      showY2: false, // Add a annoucement axis for bamaq

      // COMPRA AGORA vars
      idCompraAgora: false,

      // Props that are used in all components
      requestData: null,
      updateData: null,
      resourceGroup: null,
      userList: null,

      priceAlertList: [],
      osIds: [],
      priceCardLoading: false,
    };
  },
  methods: {
    showSnackBarItemsSave(snackbarStatus) {
      if (snackbarStatus) {
        this.showSnackbar(this.$i18n.t("TXT_ITEM_SAVE"), "success");
      } else {
        this.showSnackbar(this.$i18n.t("TXT_ITEM_SAVE_PROBLEM"), "error");
      }
    },
    seila() {
      this.getPriceAlert(this.osIds);
    },
    attData() {
      this.$refs["search-products"].searchProducts();
    },
    getResults(data, search) {
      this.mapUsaLoading = true;
      this.mapBrLoading = true;
      this.mapEuLoading = true;

      this.getChartTableResults(data, search);
      this.getBoxPlotResults(data, search);
      this.getAnnouncementMapData(data, search);
    },
    // getPriceAlert(ids) {
    //   this.priceCardLoading = true;
    //   this.priceAlertList = [];
    //   this.osIds = ids;
    //   this.priceAlertService
    //     .getPriceAlertList(ids)
    //     .then((res) => {
    //       this.priceAlertList = res.data.priceAlerts;
    //       this.$refs["table-result"].formatPriceAlert(this.priceAlertList);
    //       this.priceCardLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log("erro ao buscar alerta de preços", error);
    //       this.priceCardLoading = false;
    //     });
    // },
    getChartTableResults(request, search) {
      this.dateCategories = this.getDateInterval(
        request.startDate,
        request.endDate
      );
      let searchAPI = null;
      if (this.hasResource("predimonitor.table.sellerpercent")) {
        searchAPI = "AvgInfoByProductMaskedSeller";
      } else {
        searchAPI = search === "v2" ? "AvgInfoByProductV2" : "AvgInfoByProduct";
      }
      this.predimonitorService[searchAPI](request)
        .then((res) => {
          this.infoQuery = res.data.answer.infoQueries;
          if (res.data.answerType != 0) {
            this.showSnackbar(this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT"), "error");
            this.isLoading = false;
            this.isDisable = true;
            this.closePanel = true;
          } else {
            if (res.data.answer.registers.length != 0) {
              this.priceTrendsData = res.data.answer.registers;
              this.tableResultData = res.data.answer.registers;

              // this.getPriceAlert(request.filters.idProducts);
              this.closePanel = false;
              this.mapUsaLoading = false;
              this.mapBrLoading = false;
              this.mapEuLoading = false;
            } else {
              this.showSnackbar(this.$i18n.t("TXT_NO_DATA_AVAILABLE"), "info");
              this.isLoading = false;
              this.isDisable = true;
              this.closePanel = true;
              this.$store.commit("setPredimonitorResults", []);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.isDisable = true;
          this.closePanel = true;
          this.$store.commit("setPredimonitorResults", []);
        });
    },

    getBoxPlotResults(request, search) {
      this.boxplotData = request;
      this.search = search;
    },

    getAnnouncementMapData(request) {
      this.announcementMapData = request;
    },

    getDateInterval(dateStart, dateEnd) {
      let startDate = moment(dateStart);
      let endDate = moment(dateEnd);

      let dateArray = [];

      dateArray.push(startDate.format("YYYY-MM-DD"));

      while (startDate.isBefore(endDate)) {
        startDate.add(1, "days");
        dateArray.push(startDate.format("YYYY-MM-DD"));
      }

      return dateArray;
    },

    updateGraphics(data) {
      this.updateData = data;
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    setLoading(data) {
      this.isLoading = data;
      this.isDisable = data;
    },

    verifyCompany() {
      // Verify the idCompany to set some features

      // idBamaq: 2087 - idSicredi: 2087 - Sicred nova: 2918
      if (this.hasResource("marketplace.machinery")) {
        this.idBamaq = true;
        this.showY2 = true;
      }

      // idCompraAgora: 2647
      if (
        this.$store.getters.userData.idCompany == 2647 ||
        this.$store.getters.userData.idCompany == 2648
      ) {
        this.idCompraAgora = true;
      }
    },

    getResourceGroup() {
      this.predimonitorService
        .getResourceGroup(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            this.resourceGroup = res.data.answer;
          } else {
            this.resourceGroup = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsers() {
      this.predimonitorService
        .getUsers(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            this.userList = res.data;
          } else {
            this.userList = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    setCurrency() {
      Service.getCurrency(this.idCompany)
        .then((res) => {
          console.log("resposta do buscar moeda", res);
          if (res.status != 500) {
            let currency = res.data.currencySymbol;

            if (!currency) {
              localStorage.setItem("currency", "R$");
            } else {
              localStorage.setItem("currency", currency);
            }
          }
        })
        .catch((error) => {
          console.log("Erro no buscar currency", error);
          localStorage.setItem("currency", "R$");
        });
    },

    async getMOCKEDData() {
      const req = await fetch("http://localhost:3000/products");
      const data = await req.json();
      data.forEach((el) => {
        el.editMonitoringModal = false;
      });
      this.tableResultData = data;
    },
  },

  computed: {
    // Displays other components if the company has the resources
    resources() {
      return this.$store.state.resources;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },

  created() {
    // this.getMOCKEDData();
    this.verifyCompany();
    this.getResourceGroup();
    this.getUsers();
    this.setCurrency();

    document.title = "PREDIFY | PREDIMONITOR";
  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 24px;
  font-weight: 600;
}

.page-breadcrumbs {
  font-size: 14px;
  opacity: 0.5;
}

.v-tooltip__content .menuable_content_active {
  border: 1px solid black;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}
</style>
