<template>
  <v-card :disabled="isLoading" class="pa-4" id="churn-table">
    <v-row no-gutters class="mb-4">
      <v-spacer></v-spacer>
      <prd-btn
        @click="extractReport"
        :title="$t('TXT_EXTRACT_REPPORT')"
        :outlined="true"
        :leftIcon="true"
        :smallIcon="true"
        :icon="'mdi-tray-arrow-up'"
      />
    </v-row>

    <v-data-table :headers="headers" :items="churnData" hide-default-footer>
      <!-- ITEMS -->
      <template v-slot:[`item.client`]="{ item }">
        <td class="pa-0 text-center">
          <div :class="setBorder(item.probabilityStayingAlive)">
            {{ item.client }}
          </div>
        </td>
      </template>
      <template v-slot:[`item.probabilityStayingAlive`]="{ item }">
        <td
          :class="setBackground(item.probabilityStayingAlive)"
          class="pa-0 text-center"
        >
          {{ item.probabilityStayingAlive }} %
        </td>
      </template>

      <template v-slot:[`item.monetaryValue`]="{ item }">
        <span>
          {{ `${currency} ${item.monetaryValue}` }}
        </span>
      </template>

      <!-- HEADERS -->
      <template v-slot:[`header.client`]="{ header }">
        <div class="header-box w-180 px-3">
          <div>{{ header.text }}</div>
        </div>
      </template>
      <template v-slot:[`header.frequency`]="{ header }">
        <div class="header-box w-100 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.recency`]="{ header }">
        <div class="header-box w-100 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.time`]="{ header }">
        <div class="header-box w-100 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.monetaryValue`]="{ header }">
        <div class="header-box w-100 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.purchaseForecast30`]="{ header }">
        <div class="header-box w-180 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.purchaseForecast60`]="{ header }">
        <div class="header-box w-180 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.purchaseForecast90`]="{ header }">
        <div class="header-box w-180 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.purchaseForecast120`]="{ header }">
        <div class="header-box w-180 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
      <template v-slot:[`header.probabilityStayingAlive`]="{ header }">
        <div class="header-box w-180 px-3">
          <div>{{ header.text }}</div>
          <tooltip-informations :rightIcon="true" :title="'Tooltip'" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import ChurnService from "@/service/churn-analysis/index.js";
const Service = new ChurnService();

export default {
  components: { prdBtn, TooltipInformations },
  props: ["churnData", "isLoading", "currency"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_CUSTUMER"),
          align: "center",
          sortable: false,
          value: "client",
        },
        {
          text: this.$i18n.t("TXT_FREQUENCY"),
          align: "center",
          sortable: false,
          value: "frequency",
        },
        {
          text: this.$i18n.t("TXT_RECENCY"),
          align: "center",
          sortable: false,
          value: "recency",
        },
        {
          text: this.$i18n.t("TXT_TIME"),
          align: "center",
          sortable: false,
          value: "time",
        },
        {
          text: this.$i18n.t("TXT_MONETARY_VALUE"),
          align: "center",
          sortable: false,
          value: "monetaryValue",
        },
        {
          text: this.$i18n.t("TXT_PURCHASE_FORECAST_30"),
          align: "center",
          sortable: false,
          value: "purchaseForecast30",
        },
        {
          text: this.$i18n.t("TXT_PURCHASE_FORECAST_60"),
          align: "center",
          sortable: false,
          value: "purchaseForecast60",
        },
        {
          text: this.$i18n.t("TXT_PURCHASE_FORECAST_90"),
          align: "center",
          sortable: false,
          value: "purchaseForecast90",
        },
        {
          text: this.$i18n.t("TXT_PURCHASE_FORECAST_120"),
          align: "center",
          sortable: false,
          value: "purchaseForecast120",
        },
        {
          text: this.$i18n.t("TXT_ALIVE_PROBABILITY"),
          align: "center",
          sortable: false,
          value: "probabilityStayingAlive",
        },
      ],
      customers: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
  },

  methods: {
    extractReport() {
      Service.extractReport(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            var downloadLink = document.createElement("a");
            var blob = new Blob(["\ufeff", res.data]);
            var url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = "churn.xlsx";

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        })
        .catch((error) => {
          console.log("Erro ao extrair relatório", error);
        });
    },

    setBorder(probabilityStayingAlive) {
      if (probabilityStayingAlive < 20) {
        return "red-border";
      } else if (probabilityStayingAlive > 20 && probabilityStayingAlive < 50) {
        return "yellow-border";
      } else if (probabilityStayingAlive > 50) {
        return "green-border";
      }
    },

    setBackground(probabilityStayingAlive) {
      if (probabilityStayingAlive < 20) {
        return "red-border-background";
      } else if (probabilityStayingAlive > 20 && probabilityStayingAlive < 50) {
        return "yellow-border-background";
      } else if (probabilityStayingAlive > 50) {
        return "green-border-background";
      }
    },
  },
};
</script>
<style lang="scss" >
#churn-table {
  .v-data-table-header {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .v-data-table__wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
  .table-header {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background-color: $neutral-color-low-light;
  }

  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 13px !important;
    height: 40px !important;
  }

  .header-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .w-100 {
    width: 100px;
  }
  .w-180 {
    width: 180px;
  }
  .red-border {
    border-left: 7px solid $feedback-color-warning-pure !important;
  }

  .yellow-border {
    border-left: 7px solid $feedback-color-helper-pure !important;
  }

  .green-border {
    border-left: 7px solid $feedback-color-success-pure !important;
  }
  .red-border-background {
    background: $feedback-color-warning-light;
  }

  .yellow-border-background {
    background: $feedback-color-helper-light;
  }

  .green-border-background {
    background: $feedback-color-success-light;
  }
}
</style>