<template>
  <div>
    <v-checkbox
      :label="label"
      v-model="checkBoxValue"
      v-bind="$attrs"
      @change="changeCheckBox"
      hide-details
    >
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "checkBoxComponent",
  data() {
    return {
      checkBoxValue: this.value,
    };
  },
  props: {
    label: {
      type: String,
      require: true,
    },
    value: Boolean,
  },
  methods: {
    changeCheckBox() {
      this.$emit("input", this.checkBoxValue);
    },
  },
  watch: {
    value() {
      if (this.value != this.checkBoxValue) {
        this.checkBoxValue = this.value;
      }
    },
  },
};
</script>
