import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class DashboardBamaqService extends ServiceBase {

    async getListGroup(idCompany) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Apriori/ListGroup", { params: { idCompany: idCompany } });
    }

    async getAffiliate(idCompany) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/SalesHistory/GetListAffiliate", { params: { idCompany: idCompany } });
    }

    async getNewSimulation(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Apriori/GenerateSimulation", request);
    }

    async getSavedSimulation(idCompany, idEnterpriseAprioriResultGroup) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Apriori/ListItem", { params: { idCompany: idCompany, idEnterpriseAprioriResultGroup: idEnterpriseAprioriResultGroup } });
    }

    async insertSimulation(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Apriori/Save", request);
    }
}