const initialValues = {
  email: null,
  hasUser: false,
  rememberLogin: false,
  useRefreshToken: null,
  adminRoles: []
};
const user = {
  state: Object.assign({}, initialValues),
  getters: {
    hasUser: (state) => state.hasUser,
    userData: (state) => state,
    isPredifyAdmin: (state) => {
      return state.adminRoles.includes('1003');
    }
  },
  mutations: {
    setUser(state, data) {
      state.email = data.email;
      state.hasUser = data.hasUser;
      state.rememberLogin = data.rememberLogin;
      state.useRefreshToken = data.useRefreshToken;
      state.token = data.token;
      state.idCompany = data.idCompany;
      state.adminRoles = data.adminRoles
    },
    clear(state) {
      Object.assign(state, initialValues);
    },
  },
  actions: {
    setUser(context, data) {
      context.commit("setUser", data);
    },
    clear(context) {
      context.commit("clear");
    },
  },
};

export default user;
