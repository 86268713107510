<template>
  <v-card
    class="pa-4 pt-0"
    flat
    :disabled="isDisable || isLoading"
    :loading="isLoading"
    v-ripple="false"
  >
    <h4 class="mt-4">{{ this.$i18n.t("TXT_CRAWLERS_ATTACHED_COMPANY") }}</h4>

    <div class="crawler-info pa-4 my-6" v-if="data">
      <h4 class="mb-4">
        {{ data.idEmpresa }} -
        {{ data.nomeRazaoSocial.toUpperCase() }}
      </h4>

      <span class="crawler-info-title"
        >{{ this.$i18n.t("TXT_CRAWLERS_ATTACHED") }}:</span
      >
      <span v-for="crawler in items" :key="crawler.idMonitoringCrawler">
        <span v-if="crawler.isLinked">
          {{ crawler.description }} - {{ crawler.idMonitoringCrawler }} |
        </span>
      </span>
    </div>

    <v-row no-gutters class="mt-4" style="justify-content: space-between">
      <prd-search
        style="width: 45%"
        @input="searchTerm = $event"
        :searchLabel="$t('TXT_SEARCH_BY_NAME_ID')"
        :useDelay="false"
      />
      <prd-combo-box
        class="mt-1"
        style="width: 35%"
        v-model="linkedFilter"
        :title="$t('TXT_FILTER_LOWERCASE')"
        :items="itemsFilter"
        :placeholder="$t('TXT_ALL')"
        :useDelay="false"
      />
    </v-row>

    <div class="crawler-list mt-4">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="crawler-item pa-4"
      >
        <div style="display: flex; align-items: center">
          <v-simple-checkbox
            v-model="item.isLinked"
            :color="$prdStyles('color-primary')"
            @click="arrayComparer(item)"
          ></v-simple-checkbox>
          <span>{{ item.description }}</span>
        </div>

        <div class="my-flex">
          <span class="font-weight-bold"
            >ID: {{ item.idMonitoringCrawler }}</span
          >
          |
          <span :class="[item.isOffline ? 'offline' : 'online']">{{
            item.isOffline ? "Offline" : "Online"
          }}</span>
          |
          <div class="mt-1" v-if="item.idCountry">
            <img
              width="25px"
              style="border-radius: 5px"
              :src="setToCountryFlag(item.idCountry)"
            />
          </div>
        </div>
      </div>
    </div>
    <v-row no-gutters class="mt-4" v-if="!isDisable">
      <v-spacer></v-spacer>
      <prd-btn
        @click="invertCard"
        :outlined="true"
        :title="$t('TXT_CANCEL')"
        class="mr-4"
      />
      <crawler-change-dialog
        v-if="newCrawlersLinked.length > 0 || crawlersUnlinked.length > 0"
        :data="bodyToDataChange"
        @buildBody="
          bodyToDataChange = buildDataToChange(
            newCrawlersLinked,
            crawlersUnlinked
          )
        "
        @invertCard="invertCard"
        @showSnackbar="$emit('showSnackbar', $event)"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdSearch from "@/Design_System/common/prd-search.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import CrawlerChangeDialog from "./management-crawler-change-dialog.vue";
import service from "@/service/crawlers/crawler-management.js";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
const Service = new service();
export default {
  components: { PrdSearch, PrdBtn, CrawlerChangeDialog, PrdComboBox },
  props: {
    isDisableSecondCard: {
      type: Boolean,
      default: true,
    },
    dataCrawlersLinked: {
      type: Object,
      default: null,
      deep: true,
    },
  },
  data() {
    return {
      data: null,
      isDisable: true,
      isLoading: false,
      originalItems: [],
      items: [],
      searchTerm: null,
      newCrawlersLinked: [],
      crawlersUnlinked: [],
      bodyToDataChange: null,
      linkedFilter: null,
      itemsFilter: [
        {
          text: this.$i18n.t("TXT_NOT_ATTACHED"),
          value: false,
        },
        {
          text: this.$i18n.t("TXT_ATTACHED_2"),
          value: true,
        },
        { text: this.$i18n.t("TXT_ALL"), value: "" },
      ],
    };
  },
  watch: {
    searchTerm: {
      handler(newTerm) {
        this.isLoading = true;
        this.items = this.search(
          ["idMonitoringCrawler", "description"],
          newTerm
        );
        this.items.sort(this.orderArrayById);
        this.isLoading = false;
      },
      immediate: true,
    },

    linkedFilter: {
      handler(newValue) {
        this.isLoading = true;
        this.items = this.search(["isLinked"], newValue.value);
        this.items.sort(this.orderArrayById);
        this.isLoading = false;
      },
    },

    dataCrawlersLinked: {
      handler(newValue) {
        if (newValue !== null) {
          this.isLoading = true;
          this.data = newValue;
          this.setLinkedCrawler(newValue.crawlerLinked);
          this.isLoading = false;
        }
      },
    },

    isDisableSecondCard: {
      handler(newValue) {
        this.isDisable = newValue;
      },
    },
  },
  methods: {
    getAllCrawler() {
      this.isLoading = true;
      Service.getAllCrawler()
        .then((res) => {
          if (res.status != 500) {
            let data = res.data.answer;
            data.forEach((element) => {
              this.$set(element, "isLinked", false);
            });
            data.sort(this.orderArrayById);
            this.items = data;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("Erro do getAllCrawler: ", error);
        });
    },
    orderArrayById(actual, next) {
      return actual.idMonitoringCrawler - next.idMonitoringCrawler;
    },
    setToCountryFlag(value) {
      switch (value) {
        case 29:
          return require("../../../../../../public/images/icons/flags/br.svg");
        case 232:
          return require("../../../../../../public/images/icons/flags/us.svg");
        case 205:
          return require("../../../../../../public/images/icons/flags/es.svg");
        default:
          return "";
      }
    },
    setLinkedCrawler(listCrawlerLinked) {
      this.isLoading = true;
      listCrawlerLinked.forEach((crawlerLinked) => {
        if (crawlerLinked !== null) {
          let foundedIndex = this.items.findIndex(
            (elementFoundedIndex) =>
              elementFoundedIndex.idMonitoringCrawler ===
              crawlerLinked.idMonitoringCrawler
          );

          this.items[foundedIndex].isLinked = true;
        }
      });
      this.originalItems = JSON.parse(JSON.stringify(this.items));
      this.isLoading = false;
    },
    buildDataToChange(newCrawlersLinked, crawlersUnlinked) {
      return {
        idEmpresa: this.data.idEmpresa,
        nomeRazaoSocial: this.data.nomeRazaoSocial,
        newCrawlersLinked: newCrawlersLinked,
        crawlersUnlinked: crawlersUnlinked,
      };
    },
    arrayComparer(crawler) {
      this.isLoading = true;

      const id = crawler.idMonitoringCrawler;

      const crawlerOriginal = this.originalItems.find(
        (element) => element.idMonitoringCrawler === id
      );
      const crawlerModificado = this.items.find(
        (element) => element.idMonitoringCrawler === id
      );

      if (
        crawlerOriginal.isLinked === false &&
        crawlerModificado.isLinked === true
      ) {
        this.newCrawlersLinked.push(crawler);
      } else if (
        crawlerOriginal.isLinked === true &&
        crawlerModificado.isLinked === false
      ) {
        const crawlerOriginalLikend = this.data.crawlerLinked.find(
          (element) => element.idMonitoringCrawler === id
        );
        this.crawlersUnlinked.push(crawlerOriginalLikend);
      } else {
        const indexNewCrawlersLinked = this.newCrawlersLinked.findIndex(
          (element) => element.idMonitoringCrawler === id
        );
        if (indexNewCrawlersLinked !== -1) {
          this.newCrawlersLinked.splice(indexNewCrawlersLinked, 1);
        }

        const indexCrawlersUnlinked = this.crawlersUnlinked.findIndex(
          (element) => element.idMonitoringCrawler === id
        );
        if (indexCrawlersUnlinked !== -1) {
          this.crawlersUnlinked.splice(indexCrawlersUnlinked, 1);
        }
      }
      this.isLoading = false;
    },
    search(fields, term) {
      this.isLoading = true;
      if (term === "" || term === null) {
        this.isLoading = false;
        return this.originalItems;
      } else {
        this.isLoading = false;
        return this.items.filter((item) => {
          for (let field of fields) {
            const fieldValue = String(item[field]).toLowerCase();
            if (
              fieldValue.includes(
                term == true || term == false ? term : term.toLowerCase()
              )
            ) {
              return true;
            }
          }
          return false;
        });
      }
    },
    invertCard() {
      this.isLoading = true;
      this.$emit("dataCrawlersLinked", null);
      this.searchTerm = "";
      this.linkedFilter = { text: this.$i18n.t("TXT_ALL"), value: "" };
      this.data = null;
      this.newCrawlersLinked = [];
      this.crawlersUnlinked = [];
      this.originalItems.map((element) => {
        element.isLinked = false;
      });
      this.items.map((element) => {
        element.isLinked = false;
      });
      this.isDisable = true;
      this.$emit("invertCard", false);
      this.isLoading = false;
    },
  },

  created() {
    this.getAllCrawler();
  },
};
</script>

<style lang="scss" scoped>
.crawler-list {
  max-height: 300px !important;
  overflow: auto;
}
.crawler-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $neutral-color-high-light;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.online {
  color: $brand-color-primary-pure;
}

.offline {
  color: $neutral-color-low-light;
}

.my-flex {
  display: flex;
  align-items: center;
  gap: 4px;
}

.crawler-info {
  background-color: $neutral-color-high-light;
}

.crawler-info-title {
  color: $brand-color-primary-pure;
}
</style>

