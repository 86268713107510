import { render, staticRenderFns } from "./actions-history.vue?vue&type=template&id=97993114&scoped=true&"
import script from "./actions-history.vue?vue&type=script&lang=js&"
export * from "./actions-history.vue?vue&type=script&lang=js&"
import style0 from "./actions-history.vue?vue&type=style&index=0&id=97993114&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97993114",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCheckbox,VDialog,VIcon})
