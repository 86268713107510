var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('prd-btn',{staticClass:"mr-4",attrs:{"outlined":true,"title":'Ver desconsiderados'},on:{"click":function($event){_vm.isModalOpen = true}}}),_c('prd-btn',{attrs:{"title":'Salvar'},on:{"click":function($event){return _vm.$emit('saveProducts', 'considerar')}}})],1),_c('v-dialog',{model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"1"}},[_c('h4',{staticClass:"mb-4"},[_vm._v("PRODUTOS DESCONSIDERADOS")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.disregardedProducts.priceResults},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-4"},[_c('div',{staticClass:"copy-box"},[_c('a',{attrs:{"href":item.productLink,"target":"_blank"}},[_vm._v(_vm._s(item.productName))])])])]}},{key:"item.sellerName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"table-product-name"},[_c('div',{staticClass:"copy-box"},[_vm._v(" "+_vm._s(item.sellerName)+" ")])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("setToIsoString")(item.price))+" ")])]}},{key:"item.crawlerDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.crawlerDate.replace("-", "/").replace("-", "/"))+" ")])]}},{key:"item.disregarded",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-simple-checkbox',{attrs:{"ripple":false,"color":"#3C5CA7"},model:{value:(item.disregarded),callback:function ($$v) {_vm.$set(item, "disregarded", $$v)},expression:"item.disregarded"}})],1)]}},{key:"item.price_unit_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unityToString")(item.price_unit_type))+" ")]}}],null,true)}),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('prd-btn',{attrs:{"title":_vm.$t('TXT_SAVE')},on:{"click":function($event){return _vm.$emit('saveProducts', 'desconsiderar')}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }