<template>
  <v-btn
    class="white--text action-button rounded-sm pa-0"
    :style="`background-color: ${borderColor}`"
    :disabled="disabled"
    x-small
    icon
    @click="$emit('click', $event)"
  >
    <slot>
      <v-icon x-small>{{ icons }}</v-icon>
    </slot>
  </v-btn>
</template>
<script>
export default {
  name: "actionButton",
  props: {
    borderColor: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    disabled: {
      type: Boolean,
    },
    icons: {
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
.action-button {
  width: 24px;
  height: 24px;
  top: 0px;
}
</style>
