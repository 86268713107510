<template>
  <span>
    <label class="label-comboBox">
      <slot name="title">{{ title }}</slot>
    </label>
    <v-row class="checkbox2 mt-2" no-gutters>
      <v-col>
        <v-combobox
          @input="onInput"
          hide-selected
          :multiple="multiple"
          outlined
          dense
          :small-chips="chips"
          :deletable-chips="chips"
          hide-details
          return-object
          :item-value="id"
          :item-text="text"
          v-bind="$attrs"
          append-icon="mdi-chevron-down"
          class="mt-2"
        >
        </v-combobox>
      </v-col>
      <v-col v-if="!hideIcon" cols="auto">
        <slot name="icon">
          <v-btn icon color="error" @click="$emit('filter-buttom')">
            <v-icon small>mdi-plus-box</v-icon>
          </v-btn>
        </slot>
      </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  name: "comboBoxComponent",
  data: () => ({}),
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },

    text: {
      type: String
    },
    chips: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onInput(values) {
      this.$emit("input", values);
    },
  },
};
</script>

<style lang="scss" scoped></style>
