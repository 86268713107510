import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class panelService extends ServiceBase {
    async getElasticity(idEnterprisePriceGroups, idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetElasticity", { params: { idEnterprisePriceGroups: idEnterprisePriceGroups, idEnterprisePriceProjection: idEnterprisePricesProjection } })
    }

    async getMarketResult(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetMarketResultItem", { params: { idEnterprisePriceProjection: idEnterprisePricesProjection } })
    }

    async getInfoProjection(idEnterprisePriceProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetInfoProjection", { params: { idEnterprisePriceProjection: idEnterprisePriceProjection } })
    }

    async getTableComparison(idEnterprisePriceGroups, idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetTableMarginComparison", { params: { idEnterprisePricesProjection: idEnterprisePricesProjection, idPriceGroup: idEnterprisePriceGroups } })
    }

    async getSimiliarityTable(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/TableMarketSimilarity", { params: { idEnterprisePricesProjection: idEnterprisePricesProjection } })
    }

    async getTableReply(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetTableReplicate", { params: { idEnterprisePricesProjection: idEnterprisePricesProjection } })
    }

    async insertReplicatePrices(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/InsertReplicatePrice", request)
    }

    async getMonitoringItems(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItemPaginated", request)
    }

    async saveMonitoringItems(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/InsertMarketSimilarity", request)
    }

    async deleteMonitoringItems(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/UpdateMarketSimilarity", request)
    }
}