<template>
  <div>
    <v-row no-gutters>
      <v-col v-for="(filter, index) in filters" :key="index" class="mr-3 mt-4">
        <label style="font-size: 14px">{{ filter.label }}</label>
        <v-combobox
          :v-model="filter.selected"
          :items="filter.items"
          placeholder="Selecione"
          outlined
          dense
          append-icon="mdi-chevron-down"
          class="mt-2"
          hide-details
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row justify="end" class="mr-3">
      <prd-btn @click="setFilters" :title="'Aplicar filtros'" />
    </v-row>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { prdBtn },
  data() {
    return {
      filters: [
        {
          label: "Categoria",
          items: [
            { text: "Moda", value: 0 },
            { text: "Moda", value: 1 },
            { text: "Moda", value: 2 },
          ],
          selected: null,
        },
        {
          label: "Regional",
          items: [
            { text: "Moda", value: 1 },
            { text: "Moda", value: 2 },
            { text: "Moda", value: 3 },
          ],
          selected: null,
        },
        {
          label: "Cluster de loja",
          items: [
            { text: "Moda", value: 4 },
            { text: "Moda", value: 5 },
            { text: "Moda", value: 6 },
          ],
          selected: null,
        },
      ],
    };
  },
  methods: {
    setFilters() {},
  },
};
</script>