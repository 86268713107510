<template>
  <highcharts
    constructor-type="mapChart"
    :options="mapOptions"
    class="map"
  ></highcharts>
</template>

<script>
export default {
  props: ["mapOptions"],
};
</script>

<style scoped>
.map {
  height: 35rem;
  width: 50%;
}
</style>