<template>
  <v-container>
    <v-row>
      <v-col align-self="center" class="text col-4">
        <span v-if="showResultLabel">        {{ getResultLabel }}
</span>
      </v-col>
      <v-col class="py-0 col-4 text-center">
        <v-pagination
          v-bind="$attrs"
          :value="value"
          :length="length"
          @input="($event) => $emit('input', Number($event))"
        ></v-pagination>
      </v-col>
      <v-col class="text col-4">
        <div class="float-right d-flex" v-if="showPageLabel">
          {{ getPageLabelBefore }}
          <input
            type="number"
            class="number text"
            :value="inputPage"
            min="1"
            :max="length"
            @input="($event) => updatePage($event.target.value)"
            v-if="showPageInput"
          />
          <span class="float-right">{{ getPageLabelAfter }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import prdDelay from "@/Design_System/modulos/prd-delay";
let myDelay = null;


export default {
  name: "PredifyDataTable",
  data() {
    return {
      inputPage: 1
    };
  },
  props: {
    value: Number,
    resultLabel: {
      type: String,
      default: "Mostrando ${result} de ${total} resultados",
    },
    length: {
      type: Number,
      default: 0,
    },
    showingRegistersLen: {
      type: Number,
      default: 0,
    },
    totalRegisters: {
      type: Number,
      default: 0,
    },
    pageLabel: {
      type: String,
      default: "Página ${page} de ${total}",
    },
    showResultLabel:{
      type:Boolean,
      default:true
    },
    showPageLabel:{
      type:Boolean,
      default:true
    },
    useDelay:{
      type:Boolean,
      default:true
    },
    delay:{
      type:Number,
      default:300
    },
    delayCharLen:{
      type:Number,
      default:1
    }

  },
  computed: {
    getResultLabel() {
      if (
        this.resultLabel &&
        this.showingRegistersLen !== null &&
        this.showingRegistersLen !== undefined &&
        this.totalRegisters !== null &&
        this.totalRegisters !== undefined
      ) {
        return this.resultLabel
          .replace("${result}", this.showingRegistersLen)
          .replace("${total}", this.totalRegisters);
      }
      return "";
    },
    showPageInput(){
      return this.pageLabel && this.pageLabel.includes("${page}")
    },
    getPageLabelBefore() {
      if (this.pageLabel) {
        let splitted = this.pageLabel.split("${page}");
        if (splitted.length >= 1)
          return splitted[0].replace("${total}", this.length);
      }
      return "";
    },
    getPageLabelAfter() {
      if (this.pageLabel) {
        let splitted = this.pageLabel.split("${page}");
        if (splitted.length > 1)
          return splitted[1].replace("${total}", this.length);
      }
      return "";
    }
  },
  methods: {
    async updatePage(value) {
      if (value.trim() == "")
        return
      let vi = Number(value)
      if (vi > Number(this.length))
        this.inputPage = this.length;
      else if (vi < 1)
        this.inputPage = 1
      else
        this.inputPage = value
      
      if (!this.useDelay)
      {
        this.$emit("input", Number(this.inputPage));
        return;
      }
      
      await myDelay.Process(this.inputPage, async (v) => {
        let val = Number(v);
        this.$emit("input", val);
        return this.inputPage;
      });
    },
  },
  watch:{
    value(newValue){
      if (Number(this.inputPage) != newValue)
        this.inputPage = newValue
    },
    delay(newValue){
      if (myDelay.delay != newValue)
        myDelay.delay = newValue
    },
    delayCharLen(newValue)    {
      if (myDelay.minLength != newValue)
        myDelay.minLength = newValue
    }
  },
  created(){
    myDelay = new prdDelay(this.delay, this.delayCharLen)
  }
};
</script>

<style lang="scss">
.text {
  color: $brand-color-primary-pure !important;
}

.number {
  border: 1px solid $neutral-color-high-dark;
  border-radius: 4px;
  margin: 0px 5px !important;
  text-align: center;
  width: 60px;
}

.v-pagination__item {
  background-color: transparent !important;
  box-shadow: none !important;
  color: $brand-color-primary-pure !important;
  line-height: 100% !important;
}

.v-pagination__item--active {
  color: $neutral-color-high-pure !important;
}

.v-pagination__navigation {
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-pagination__navigation--disabled {
  background-color: transparent !important;
  box-shadow: none !important;
}

.fas {
  font-size: 14px !important;
  color: $brand-color-primary-pure !important;
}
</style>