// eslint-disable-next-line no-unused-vars
export function validatePrecification(context , idCompany) {
  /** @type {string[]} */
  const problems = [];

  const name = context.name;
  if (!name) problems.push("Você deve fornecer um nome para a precificação");

  // if (context.filters.length === 0 && idCompany == 2565) {
  //   problems.push("Você deve selecionar algum filtro");
  // }

  const emptyFilters = context.filters.reduce((acc, { value }) => {
    if (!value) {
      return acc + 1;
    }
    return acc;
  }, 0);

  if (emptyFilters > 0) {
    if (emptyFilters == 1) {
      problems.push(`Você tem 1 filtro que está vazio.`);
    } else {
      problems.push(`Você tem ${emptyFilters} filtros que estão vazios.`);
    }
  }

  return problems.join("\n");
}
