<template>
  <v-dialog v-model="isOpen" persistent :max-width="1042" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="disabled ? '' : 'success'"
        class="mt-1 ml-1 cursor-pointer"
        large
        v-on="on"
        v-bind="attrs"
      >
        mdi-plus-box
      </v-icon>
    </template>
    <v-card class="pa-4 card font-size-12 d-flex flex-column gap-6">
      <h2 v-t="('TXT_NEW_PRICING')" class="font-weight-medium font-size-14"></h2>
      <div class="d-flex gap-6 align-end">
        <text-field
          v-model="name"
          class="flex-1 gap-2-important"
          :title="$t('TXT_PRICING_NAME')"
        />
        <text-field
          class="gap-2-important"
          :value="currentDate"
          disabled
          :title="$t('TXT_CREATION_DATE')"
        />
      </div>
      <create-pricing-modal-filters
        @input="selectFilters"
        :preFilters="lockedFilters"
      />
      <div class="d-flex align-self-end gap-6">
        <prd-btn-loading
          class="mt-6 align-self-end"
          outlined
          :title="$t('TXT_CANCEL')"
          @click="isOpen = false"
        />
        <prd-btn-loading
          class="mt-6 align-self-end"
          :is-loading="service.savePrecification.isLoading"
          :title="$t('TXT_SAVE_PRICING')"
          @click="savePricing"
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import textField from "@/Design_System/common/text-field";
import prdBtnLoading from "@/Design_System/common/prd-btn-loading";
import AiPricingService from "@/service/ai-pricing";
import { validatePrecification } from "../../validation/create-precification";
import createPricingModalFilters from "./filter-groups";

import { formatDate } from "@/utils/format-date";

export default {
  components: {
    prdBtnLoading,
    textField,
    createPricingModalFilters,
  },
  props: ["preFilters", "disabled"],
  data: () => {
    return {
      name: "",
      isOpen: false,
      service: new AiPricingService(),
      filters: [],
    };
  },
  computed: {
    lockedFilters() {
      return this.preFilters;
    },
    currentDate() {
      return formatDate(new Date(), "dd/MM/yyyy");
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    selectFilters(filters) {
      this.filters = filters
    },

    async savePricing() {
      const problems = validatePrecification(this, this.idCompany);
      if (problems) {
        return this.$store.commit("snackbar-error", problems);
      }

      try {        
        await this.service.savePrecification.call(
          this.idCompany,
          this.name,
          this.filters
        );
        this.$store.commit("snackbar", this.$i18n.t("TXT_CREATE_FILTER_SUCCESS"));
        this.isOpen = false;
        this.$emit("submit");
      } catch (e) {
        console.error(e);
        this.$store.commit("snackbar-error", this.$i18n.t("TXT_CREATE_FILTER_ERROR"));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.full-width {
  width: 100%;
}
.min-width-326 {
  min-width: 326px;
}
.width-max-content {
  width: max-content;
}
.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}
.gap-4 {
  column-gap: 24px;
  row-gap: 24px;
}
.flex-3 {
  flex: 3;
}
.flex-1 {
  flex: 1;
}
.gap-2-important {
  row-gap: 8px !important;
  column-gap: 8px !important;
}
</style>
