<template>
  <v-container id="tags-configure" class="padding-all-30">
    <v-row class="ma-0 pa-0 mb-4" align-content="end">
      <p class="card-title">CONFIGURAÇÃO DE TAGS</p>
      <v-spacer></v-spacer>
    </v-row>

    <v-card class="ma-0 pa-4" flat>
      <v-data-table :headers="headers" :items="crawlers" hide-default-footer>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span>{{ item.code }}</span>
            </td>

            <td>
              <span>{{ item.product }}</span>
            </td>

            <td>
              <span v-if="!item.isEditing">{{
                item.technicalCharacteristics
              }}</span>
              <v-text-field
                outlined
                v-if="item.isEditing"
                v-model="item.technicalCharacteristics"
                dense
                hide-details
              ></v-text-field>
            </td>

            <td>
              <span v-if="!item.isEditing">{{ item.marketplaceProduct }}</span>
              <v-text-field
                outlined
                v-if="item.isEditing"
                v-model="item.marketplaceProduct"
                dense
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-btn
                color="#3C5CA7"
                icon
                @click="editItem(item.id)"
                v-if="!item.isEditing"
              >
                <v-icon>mdi-pencil-box</v-icon>
              </v-btn>

              <div style="display: flex; justify-content:center;">
                <v-icon
                  @click="cancelEditing(item.id)"
                  color="var(--msg-error)"
                  v-if="item.isEditing"
                >
                  mdi-close-box
                </v-icon>
                <v-icon
                  @click="saveEditing(item.id)"
                  color="var(--msg-success)"
                  v-if="item.isEditing"
                >
                  mdi-checkbox-marked
                </v-icon>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "tags-configure",
  data() {
    return {
      headers: [
        {
          text: "Código",
          value: "code",
          sortable: true,
          align: "center",
        },
        {
          text: "Produto",
          value: "product",
          sortable: true,
          align: "center",
        },
        {
          text: "informações 1",
          value: "technicalCharacteristics",
          sortable: true,
          align: "center",
        },
        {
          text: "Produto no Marketplace",
          value: "marketplaceProduct",
          sortable: true,
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: true,
          align: "center",
        },
      ],
      crawlers: [
        {
          id: 1,
          code: "34323441",
          product: "Kiboa + quibeleza",
          technicalCharacteristics: "Aguá Rás / vaso / pia",
          marketplaceProduct: "",
          isEditing: false,
        },
        {
          id: 2,
          code: "21872625",
          product: "Amaciante Downy + int Brisa",
          technicalCharacteristics: "Amaciante Downy (concentrado azul)",
          marketplaceProduct: "",
          isEditing: false,
        },
        {
          id: 3,
          code: "1018716",
          product: "Desinfetante",
          technicalCharacteristics: "Desinfetante pinheiro",
          marketplaceProduct: "",
          isEditing: false,
        },
        {
          id: 4,
          code: "34323441",
          product: "Kiboa + quibeleza",
          technicalCharacteristics: "Aguá Rás / vaso / pia",
          marketplaceProduct: "",
          isEditing: false,
        },
        {
          id: 5,
          code: "21872625",
          product: "Amaciante Downy + int Brisa",
          technicalCharacteristics:
            "Abs Hig S Livre Espec Adap S A 8un (Absorvente; Sempre Livre; Adapt; 8 un; exemplo exemplo exemplo)",
          marketplaceProduct: "",
          isEditing: false,
        },
      ],
      item: null,
    };
  },

  methods: {
    editItem(id) {
      this.crawlers.find((e) => {
        if (e.id == id) {
          e.isEditing = true;
          this.item = Object.assign({}, e);
        } else {
          e.isEditing = false;
        }
      });
    },

    cancelEditing(id) {
      this.crawlers.find((e) => {
        if (e.id == id) {
          e.technicalCharacteristics = this.item.technicalCharacteristics;
          e.marketplaceProduct = this.item.marketplaceProduct;
          e.isEditing = false;
        }
      });
      this.item = null;
    },

    saveEditing(id) {
      this.crawlers.find((e) => {
        if (e.id == id) {
          e.isEditing = false;
          this.item = null;
        }
      });
    },
  
  
  },
   created() {
    document.title = "PREDIFY | CONFIGURAR TAGS";
  },
};
</script>

<style>
#tags-configure * {
  font-family: "Ubuntu", sans-serif;
  text-align: center;
}
#tags-configure .page-title {
  font-size: 24px;
  font-weight: 600;
}

#tags-configure .page-breadcrumbs {
  font-size: 14px;
  opacity: 0.5;
}

#tags-configure .card-title {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bolder;
}

#tags-configure .tags-btn {
  width: 8rem !important;
}

#tags-configure .v-data-table-header {
  background-color: #e0e0e0;
}

#tags-configure .v-data-table-header th {
  font-size: 14px;
  border: 1px solid white;
}
#tags-configure .v-data-table-header .v-icon {
  display: none;
}
#tags-configure tbody {
  background-color: #f5f5f5;
}
#tags-configure tbody td {
  border: 1px solid white;
  max-width: 110px;
  white-space: pre-wrap;
}

</style>