import { render, staticRenderFns } from "./crawler-management-main.vue?vue&type=template&id=529f62ca&scoped=true&"
import script from "./crawler-management-main.vue?vue&type=script&lang=js&"
export * from "./crawler-management-main.vue?vue&type=script&lang=js&"
import style0 from "./crawler-management-main.vue?vue&type=style&index=0&id=529f62ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529f62ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow,VSnackbar,VSpacer})
