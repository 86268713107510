<template id="load-page">
<div class="text-center">
    <v-progress-circular
      :width="3"
      color="prymary"
      indeterminate
    ></v-progress-circular>
</div>
</template>

<script>
export default {
  name:'loading-progress'
}
</script>

<style scoped>
#load-page{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-top: 20%;
}
</style>