export default class prdDelay {
    constructor(delay=1000, minLength=3)
    {
        this.isSearching = false;
        this.delay = delay;
        this.searchProductTimer = null;
        this.searchValue = null;
        this.callbackCalled = false;
        this.minLength = minLength
    }

    startTimer = async (value, callback, thisClass) => {
        this.searchProductTimer = setTimeout(async function () {
          let newValue = null
          thisClass.callbackCalled = true;
          var searching = async (value, callback, thisClass) => {
            thisClass.searchValue = value;
            thisClass.isSearching = true;

            if (callback && typeof callback === 'function')
              newValue = await callback(value)
          };
          await searching(value,callback,thisClass);

          while (newValue && newValue != thisClass.searchValue && newValue.length >= thisClass.minLength)
            await searching(newValue, callback, thisClass)

          thisClass.StopSearch()
        }, this.delay);
    }

    StopSearch = () =>
    {
      clearTimeout(this.searchProductTimer);
      this.searchProductTimer = null;
      this.isSearching = false
      this.searchValue = null
      this.callbackCalled = false;
    }

    async Process(value, callback){
      if (value === null || value === undefined || value.length < this.minLength)
      {
        if (!this.callbackCalled && this.searchProductTimer)
          this.StopSearch()
        return;
      }

      if (this.searchProductTimer == null) {
        await this.startTimer(value,callback,this);
      } else if (!this.callbackCalled){
        clearTimeout(this.searchProductTimer);
        await this.startTimer(value,callback,this);
      }
    }


    waitforme(milisec) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("");
        }, milisec);
      });
    }

  
  }