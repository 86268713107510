<template>
  <v-container class="padding-all-30">
    <v-row v-if="showSearch || showItemsPerPage" :justify="'space-between'">
      <v-col cols="3">
        <prd-search v-model="search" v-bind="searchProps" v-if="showSearch" />
      </v-col>
      <v-col cols="3" v-if="showItemsPerPage">
        <prd-combo
          v-bind="itemsPerPageProps"
          v-model="itemsPerPageSelected"
          :items="itemsPerPageItems"
          :item-text="'text'"
          :item-value="'value'"
        />
      </v-col>
    </v-row>
    <v-container class="mt-5 mb-5">
      <v-row align="center">
        <div v-if="showingSubtitles">
          <v-row v-for="(indicator, index) in indicatorsValues" :key="index">
            <v-col
              col-12
              class="py-0 mb-1 legend-text"
              :class="indicator.class"
              >{{ indicator.title }}</v-col
            >
          </v-row>
        </div>
        <slot name="actions-left"></slot>

        <v-spacer></v-spacer>

        <slot name="actions-right"></slot>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-bind="$attrs"
          :headers="sortedHeaders"
          :search="getSearch"
          :items-per-page="-1"
          :items="items"
          :page="page"
          :disable-pagination="disablePagination"
          class="elevation-1"
          ref="datatable"
          v-on="$listeners"
          hide-default-footer
          hide-default-header
          :options.sync="pagination"
          @input="(v) => $emit('input', v)"
          @page-count="onPageCount"
          @pagination="onPagination"
        >
          <template v-slot:[`header`]="{ props }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  v-for="header in props.headers"
                  role="columnheader"
                  :key="header.text"
                  :class="[
                    'text-center sortable',
                    getHeaderSortDesc(header.value) ? 'desc' : 'asc',
                    pagination.sortBy.includes(header.value) ? 'active' : '',
                  ]"
                  @click="changeSort(header.value)"
                >
                  <!-- <v-icon small>arrow_upward</v-icon>
                <v-icon small>filter_list</v-icon> -->
                  <span>{{ header.text }}</span>
                  <v-icon
                    small
                    class="sort-icon"
                    :class="
                      pagination.sortBy.includes(header.value)
                        ? 'sort-icon-active'
                        : ''
                    "
                    >{{
                      getHeaderSortDesc(header.value)
                        ? "mdi-triangle-down"
                        : "mdi-triangle"
                    }}</v-icon
                  >
                  <span
                    class="v-data-table-header__sort-badge"
                    v-if="
                      pagination.sortBy.indexOf(header.value) >= 0 &&
                      pagination.multiSort
                    "
                    >{{ pagination.sortBy.indexOf(header.value) + 1 }}</span
                  >
                </th>
              </tr>
            </thead>
          </template>

          <template v-for="slot in parentSlots" #[slot]>
            <slot :name="slot" />
          </template>

          <template v-for="col in parentScopedSlots" v-slot:[col]="data">
            <slot :name="col" v-bind="data" />
          </template>

          <!-- Adiciona uma celula e verifica e faz o processamento para validar se é para mostrar os valores corretos, caso for, aplica a classe fundo --->
          <template
            v-slot:[getName()]="data"
            v-if="
              !parentScopedSlots.find((f) => f == 'item.' + getFirstHeaderValue)
            "
          >
            <td class="text-center px-0">
              <div :class="GetLegendColumn(data.item, data.header)">
                <slot :name="'item.first.content'" v-bind="data">
                  {{ data.item[getFirstHeaderValue] }}
                </slot>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="showPagination">
      <v-col class="py-0 col-12">
        <prd-pagination
          v-bind="paginationProps"
          v-model="paginationPage"
          :length="getTotalPages"
          :showing-registers-len="showingRegistersLen"
          :total-registers="getTotalRegisters"
          :total-visible="7"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import prdPagination from "./prd-pagination.vue";
import prdSearch from "./prd-search.vue";
import prdCombo from "./prd-comboBox.vue";

export default {
  components: { prdPagination, prdSearch, prdCombo },
  name: "PredifyDataTable",
  inheritAttrs: false,
  props: {
    headers: [],
    indicators: [],
    items: null,
    pagesCount: Number,
    totalRegisters: Number,
    showSearch: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showSubtitles: {
      type: Boolean,
      default: true,
    },
    showItemsPerPage: {
      type: Boolean,
      default: true,
    },
    dynamic: {
      type: Boolean,
      default: true,
    },
    itemsPerPageItems: {
      type: Array,
      default: () => [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "Todos", value: 0 },
      ],
    },
    itemsPerPage: {
      type: [Object, Number],
      default: null,
    },
  },
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      changePage: 1,
      showingRegistersLen: 0,
      registersLen: 0,
      disablePagination: true,
      pagination: {},

      paginationPage: 1,

      itemsPerPageSelected: { text: "25", value: 25 },

      lastChangeData: null,
    };
  },
  methods: {
    onPagination(pages) {
      this.updateShowingRegistersLen();
      this.registersLen = pages.itemsLength;
    },
    onPageCount(value) {
      this.pageCount = value;

      if (this.pageCount < this.page) this.paginationPage = this.pageCount;
    },
    updateShowingRegistersLen() {
      if (this.$refs.datatable && this.$refs.datatable.selectableItems)
        this.showingRegistersLen = this.$refs.datatable.selectableItems.length;
    },
    GetLegendColumn(item, header) {
      var classes = "text-center";
      if (header.align == "start") classes = "text-start";
      else if (header.align == "end") classes = "text-end";

      if (this.indicators instanceof Function) {
        var result = this.indicators(item, header);
        if (result) return result + " " + classes;
      } else {
        for (var l = 0; l < this.indicators.length; l++) {
          var indicator = this.indicators[l];
          for (var a = 0; a < indicator.activators.length; a++) {
            var activator = indicator.activators[a];
            if (activator.active instanceof Function) {
              if (activator.active(item, header))
                return activator.class + " " + classes;
            } else if (item[indicator.value] == activator.active) {
              return activator.class + " " + classes;
            }
          }
        }
      }
      return classes;
    },
    raiseOnChange() {
      let refe = this;
      let sortChanged = false;
      if (this.paginationPage == 0){
        this.paginationPage = 1
      }
      let data = {
        currentPage: this.paginationPage,
        search: this.search,
        itemsPerPage: this.itemsPerPageSelected.value,
        sortBy: [],
      };

      if (this.pagination && this.pagination.sortBy) {
        this.pagination.sortBy.forEach((e, i) => {
          data.sortBy.push({
            column: e,
            descendant: refe.pagination.sortDesc[i],
          });

          if (
            refe.lastChangeData &&
            data.sortBy.length <= refe.lastChangeData.sortBy.length
          ) {
            console.log(
              "Nome Diferente: ",
              data.sortBy[i].column !== refe.lastChangeData.sortBy[i].column
            );
            console.log(
              "Valor Diferente: ",
              data.sortBy[i].value !== refe.lastChangeData.sortBy[i].value
            );
            refe.lastChangeData.sortBy.forEach((sb, index) => {
              if (index == i) {
                sortChanged =
                  sortChanged ||
                  e !== sb.column ||
                  refe.pagination.sortDesc[i] !== sb.value;
              }
            });
          } else sortChanged = true;
        });
      }
      if (this.lastChangeData && this.lastChangeData.sortBy.length > 0)
        sortChanged = true;

      if (
        !this.lastChangeData ||
        this.lastChangeData.currentPage != data.currentPage ||
        this.lastChangeData.search != data.search ||
        this.lastChangeData.itemsPerPage != data.itemsPerPage ||
        sortChanged
      ) {
        this.lastChangeData = data;
        this.$emit("onChange", data);
      }
    },
    getName(headerValue) {
      if (!headerValue) return "item." + this.getFirstHeaderValue;

      return "item." + headerValue;
    },
    changeSort(column) {
      if (this.pagination.sortBy.includes(column)) {
        this.pagination.sortBy.forEach((e, index) => {
          if (e == column) {
            if (this.pagination.sortDesc[index] === false) {
              this.pagination.sortDesc.splice(index, 1, true);
            } else {
              this.pagination.sortBy.splice(index, 1);
              this.pagination.sortDesc.splice(index, 1);
              if (this.pagination.sortBy.length == 0)
                this.pagination.sortDesc.push(false);
            }
          }
        });
        this.pagination.descending = !this.pagination.descending;
      } else {
        if (!this.pagination.multiSort && this.pagination.sortBy.length > 0) {
          this.pagination.sortBy.splice(0, this.pagination.sortBy.length);
          this.pagination.sortDesc.splice(0, this.pagination.sortDesc.length);
          this.pagination.sortDesc.push(false);
        }

        this.pagination.sortBy.push(column);
        if (this.pagination.sortBy.length > 1)
          this.pagination.sortDesc.push(false);
      }
      this.$nextTick(this.raiseOnChange());
    },
    getHeaderSortDesc(column) {
      let index = this.pagination.sortBy.indexOf(column);
      let isDesc = false;
      if (index >= 0) {
        if (this.pagination.sortDesc[index]) isDesc = true;
      }
      return isDesc;
    },
  },

  watch: {
    items: {
      handler: function (newValue) {
        if (newValue) {
          if (this.itemsPerPage < newValue.length)
            this.showingRegistersLen = this.itemsPerPage;
          else this.showingRegistersLen = newValue.length;
        }
        //this.$nextTick(this.updateShowingRegistersLen())
      },
      immediate: true,
    },
    paginationPage(newValue) {
      if (!this.dynamic) this.page = newValue;
      this.raiseOnChange();
    },
    search() {
      this.raiseOnChange();
    },
    itemsPerPageSelected(value) {
      if (value) {
        this.disablePagination = value.value == 0;
        this.raiseOnChange();
      }
    },
    itemsPerPage: {
      handler: function (newValue) {
        if (newValue) {
          if (typeof newValue === "object")
            this.itemsPerPageSelected = newValue;
          else
            this.itemsPerPageItems.forEach((e) => {
              if (e.value == newValue) {
                this.itemsPerPageSelected = e;
              }
            });
          this.raiseOnChange();
        }
      },
      immediate: true,
    },
  },
  computed: {
    getTotalPages() {
      if (this.dynamic) return this.pagesCount;

      return this.pageCount;
    },
    getTotalRegisters() {
      if (this.dynamic) return this.totalRegisters;
      return this.registersLen;
    },
    getSearch() {
      if (!this.dynamic) return this.search;
      return "";
    },
    getItemsPerPage() {
      return this.itemsPerPageSelected.value;
    },
    indicatorsValues() {
      let indicators = [];
      if (this.indicators) {
        this.indicators.forEach((e) => {
          if (e.activators) {
            e.activators.forEach((a) => {
              let v = {};
              if (a.title) v.title = a.title;
              else v.title = e.title;

              v.class = a.class;

              indicators.push(v);
            });
          }
        });
      }

      return indicators;
    },
    parentSlots() {
      if (this.$slots) {
        return Object.keys(this.$slots);
      }
      return [];
    },
    parentScopedSlots() {
      if (this.$scopedSlots) {
        return Object.keys(this.$scopedSlots);
      }
      return [];
    },
    getFirstHeaderValue() {
      if (this.headers.length > 0) return this.headers[0].value;
      return "notFound";
    },
    paginationProps() {
      return {
        showResultLabel:
          ((this.$attrs["showResultLabel"] === null ||
            this.$attrs["showResultLabel"] === undefined) &&
            (this.$attrs["show-result-label"] === null ||
              this.$attrs["show-result-label"] === undefined)) ||
          this.$attrs.showResultLabel === true ||
          this.$attrs["show-result-label"] === true,
        showPageLabel:
          ((this.$attrs["showPageLabel"] === null ||
            this.$attrs["showPageLabel"] === undefined) &&
            (this.$attrs["show-page-label"] === null ||
              this.$attrs["show-page-label"] === undefined)) ||
          this.$attrs.showPageLabel === true ||
          this.$attrs["show-page-label"] === true,
        resultLabel: this.$attrs.resultLabel || this.$attrs["result-label"],
        pageLabel: this.$attrs.pageLabel || this.$attrs["page-label"],
        useDelay: this.dynamic,
        delay: this.$attrs.paginationDelay || this.$attrs["pagination-delay"],
        delayCharLen:
          this.$attrs.paginationDelayCharLen ||
          this.$attrs["pagination-delay-char-len"],
      };
    },
    searchProps() {
      return {
        searchLabel: this.$attrs.searchLabel || this.$attrs["search-label"],
        searchPlaceholder:
          this.$attrs.searchPlaceholder || this.$attrs["search-placeholder"],
        useDelay: this.dynamic,
        delay: this.$attrs.searchDelay || this.$attrs["search-delay"],
        delayCharLen:
          this.$attrs.searchDelayCharLen ||
          this.$attrs["search-delay-char-len"],
      };
    },
    itemsPerPageProps() {
      return {
        title:
          this.$attrs.itemsPerPageTitle || this.$attrs["items-per-page-title"],
      };
    },
    showingSubtitles() {
      return this.showSubtitles && this.indicatorsValues.length > 0;
    },
    sortedHeaders() {
      if (this.headers) {
        let he = this.headers;
        return he.sort((a, b) => a.index - b.index);
      }

      return this.headers;
    },
  },
  created() {
  },
};
</script>

<style lang="scss">
//Cores específicas de componentes
$prd-v-data-table-header-color: $neutral-color-high-medium;
$prd-v-data-table-cell-background-color: $neutral-color-high-light;
$prd-v-data-table-row-height: 48px;

.sort-icon {
  font-size: 12px !important;
  color: $neutral-color-high-dark !important;
  margin-left: 5px;
}
.sort-icon-active {
  color: $neutral-color-low-medium !important;
}

.v-data-table__wrapper > table {
  border-collapse: collapse;
}

.v-data-table__wrapper > table > tbody > tr > td > div {
  line-height: $prd-v-data-table-row-height !important;
}

.v-data-table-header > tr > th {
  border-right-width: $border-width-thin !important;
  border-left-width: $border-width-thin !important;
  border-top: $border-width-hairline solid $prd-v-data-table-header-color !important;
  border-bottom-width: $border-width-none !important;
}
.v-data-table-header > tr {
  background-color: $prd-v-data-table-header-color !important;
}

.v-data-table-header > tr > th:last-of-type {
  border-right-width: $border-width-none !important;
  border-top: $border-width-hairline solid $prd-v-data-table-header-color !important;
  border-top-right-radius: $border-radius-sm !important;
}

.v-data-table-header > tr > th:first-of-type {
  border-left-width: $border-width-none !important;
  border-top: $border-width-hairline solid $prd-v-data-table-header-color !important;
  border-top-left-radius: $border-radius-sm !important;
}

.v-data-table__wrapper > table > tbody > tr {
  background-color: $prd-v-data-table-cell-background-color !important;
}

.v-data-table__wrapper > table > tbody > tr > td {
  border-right-width: $border-width-thin !important;
  border-left-width: $border-width-thin !important;
  border-top-width: $border-width-thin !important;
  border-bottom-width: $border-width-thin !important;
}

.v-data-table__wrapper > table > tbody > tr > td:last-of-type {
  border-right-width: $border-width-none !important;
}

.v-data-table__wrapper > table > tbody > tr > td:first-of-type {
  border-left-width: $border-width-none !important;
}

.v-data-table__wrapper > table > tbody > tr:first-of-type > td {
  border-top-width: $border-width-none !important;
}

.v-data-table__wrapper > table > tbody > tr:last-of-type > td {
  border-bottom-width: $border-width-none !important;
}

.v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: $border-width-thin solid white !important;
  border-top: $border-width-thin solid white !important;
}

.text {
  color: $brand-color-primary-pure !important;
}

.number {
  border: 1px solid $neutral-color-high-dark;
  border-radius: 4px;
  margin: 0px 5px !important;
  text-align: center;
  width: 60px;
}

.legend-text {
  font-size: 0.875rem;
}
</style>