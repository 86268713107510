<template>
  <v-card class="pa-4">
    <v-row no-gutters>
      <label class="font-size-16"
        >Nº de itens reprovados na regra de
        <span class="font-size-18 font-weight-bold">{{ cardType }}</span></label
      >
      <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
      <v-spacer></v-spacer>
      <!-- <v-icon :color="$prdStyles('color-primary')">
        mdi-arrow-right-bold-box-outline
      </v-icon> -->
    </v-row>
    <span class="font-size-18 font-weight-bold destaq">{{ cardValue }}</span>

    <div class="product-main">
      <div
        v-for="(product, index) in newData"
        :key="index"
        class="product-info mt-2"
      >
        <v-row no-gutters>
          <span class="font-weight-bold">{{ product.productName }}</span>
          <v-spacer></v-spacer>
          <span class="font-weight-bold">
            {{ productInfo }}:
            <span class="product-price">
              {{ showFormatedData(product.data) }}</span
            >
          </span>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
// import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  props: ["data"],
  components: { /*TooltipInformations*/ },
  data() {
    return {};
  },

  methods: {
    showFormatedData(value) {
      if (
        this.data?.cardType.toLowerCase() == "margem" ||
        this.data?.cardType.toLowerCase() == "ìndice de competitividade"
      ) {
        return "+" + value + " %";
      } else {
        return "R$ " + value;
      }
    },
  },

  computed: {
    newData(){
      return this.data?.products ?? []
    },
    cardValue() {
      return this.data?.cardValue ?? null;
    },
    productInfo() {
      if (!this.data?.cardType) return "-";
      if (this.data?.cardType.toLowerCase() == "margem") return "Margem";
      if (this.data?.cardType.toLowerCase() == "ìndice de competitividade")
        return "IC";
      else return "Preço";
    },

    cardType() {
      if (!this.data?.cardType) return "-";
      return this.data?.cardType.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.product-main {
  max-height: 400px;
  overflow-y: scroll;
  .product-info {
    background-color: $neutral-color-high-light;
    border-left: 6px solid $feedback-color-warning-pure;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px;

    .product-price {
      color: $feedback-color-warning-pure;
    }
  }
}
.destaq {
  color: $feedback-color-warning-pure;
}
</style>