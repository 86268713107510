<template>
  <div>
    <v-card flat class="pa-4">
      <h3 v-t="'TXT_SYSTEM_FILE_TEXT'" class="mb-4"></h3>
      <v-divider class="my-4"></v-divider>

      <!-- TABLE MODEL -->
      <v-expansion-panels flat class="mb-6">
        <v-expansion-panel>
          <v-expansion-panel-header
            style="background: #f5f5f5"
            class="pl-4"
            expand-icon="mdi-chevron-down"
          >
            <h4 v-t="'TXT_TABEL_MODEL'"></h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-4" no-gutters align="center" justify="center">
              <v-data-table
                :headers="tableModelHeaders"
                :items="tableModelItems"
                hide-default-footer
              >
              </v-data-table>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <span><v-icon left color="red">mdi-alert</v-icon></span>
      <span v-t="'TXT_FILE_IMPORT_CAUTION'"></span>

      <v-row class="mt-6" no-gutters align="center" justify="center">
        <!-- TABLE -->
        <v-data-table
          :headers="tableHeaders"
          :items="systemAtribute"
          :items-per-page="100000"
          hide-default-footer
        >
          <!-- SYSTEM ATRIBUTE COLUMN -->
          <template v-slot:[`item.sistemAtribute`]="{ item }">
            <td style="text-align: center; max-width: 500px">
              <span v-show="item.required == true">*</span>
              <span class="font-weight-bold">{{ item.sistemAtribute }}</span>
              <br />
              <span
                style="font-size: 12px"
                v-show="item.msg && item.value == null"
                >{{ item.msg }}</span
              >
            </td>
          </template>

          <!-- SHEET COLUMN -->
          <template v-slot:[`item.planColumn`]="{ item }">
            <v-combobox
              v-model="item.value"
              :items="getComboItems(item.required)"
              outlined
              dense
              append-icon="mdi-chevron-down"
              hide-details
              clearable
              :rules="item.required ? rules : []"
            >
            </v-combobox>
          </template>

          <!-- OBSERVATION -->
          <template v-slot:[`item.filedObservation`]="{ item }">
            <v-combobox
              v-if="item.dateField"
              v-model="item.observationValue"
              outlined
              dense
              append-icon="mdi-chevron-down"
              hide-details
              :items="dateFormat"
              single-line
              label="Formato da data"
            >
            </v-combobox>

            <v-combobox
              v-if="item.numberField"
              v-model="item.observationValue"
              :items="dotFormat"
              outlined
              dense
              append-icon="mdi-chevron-down"
              hide-details
              single-line
              label="Separador decimal"
            >
            </v-combobox>
          </template>
        </v-data-table>
      </v-row>

      <!-- PROCESS FILE BUTTON -->
      <v-row no-gutters class="mt-6">
        <v-spacer></v-spacer>
        <prd-btn
          @click.native="processFile"
          :disabled="!allRequiredFieldsFilled"

          :title="$t('TXT_PROCESS_DATA')"
        />
      </v-row>

      <v-dialog max-width="500px" v-model="processFileDialog" persistent>
        <process-file-dialog
          @closeModal="processFileDialog = false"
          @showSnackbar="showSnackbar"
          @cleanData="cleanDataImport"
          :fileResults="fileResults"
          :fileName="fileName"
          :archiveType="archiveType"
          :delimiterType="delimiterType"
          :data="systemAtribute"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import ProcessFileDialog from "./process-file-dialog.vue";
export default {
  components: { prdBtn, ProcessFileDialog },
  props: ["fileResults", "archiveType", "fileName","delimiterType"],

  data() {
    return {
      processFileDialog: false,

      rules: [(v) => !!v || "Este campo é obrigatório"],

      // table model headers
      tableModelHeaders: [],
      tableModelItems: [],

      comboboxItems: [],

      tableHeaders: [
        {
          text: this.$i18n.t("TXT_SYSTEM_ATRIBUTE"),
          value: "sistemAtribute",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_SHEET_COLUMN"),
          value: "planColumn",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_FIELD_OBSERVATION"),
          value: "filedObservation",
          align: "center",
          sortable: true,
        },
      ],

      systemAtribute: [
        /**
         * @param {string} systemAtribute -  Name that will appear in the system attributes column (REQUIRED),
         * @param {boolean} required -  Shows a * before the name saying if it is mandatory,
         * @param {string} value -  Is the value of the field that will be sent to the back (REQUIRED),
         * @param {string} msg -  Is the message that will be shown under the name,
         * @param {string} dateField -  Shows a field to select the date,
         * @param {string} numberField -  Shows a field to select a dot,
         * @param {string} observationValue - Is the value of the dataField or dotField (REQUIRED IF DATAFIELD OR DOTFIELD IS TRUES)
         */

        {
          sistemAtribute: "UPC",
          field: "UPC",
          value: null,
          numberField: false,
          observationValue: null,
          required: true,
        },
        {
          sistemAtribute: "MODEL",
          value: null,
          field: "model",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "BRAND",
          value: null,
          field: "brand",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "DESCRIPTION",
          value: null,
          field: "description",
          numberField: false,
          observationValue: null,
          required: true,
        },
        {
          sistemAtribute: "COMPETITIVE PRODUCT LINK",
          value: null,
          field: "competitive_Product_Link",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "KEYWORD FOR SEARCH",
          value: null,
          field: "keywordForSearch",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "LOWER PRICE RANGE",
          value: null,
          field: "lowerPriceRange",
          numberField: true,
          observationValue: {
            text: "1000,00",
            value: ",",
          },
        },
        {
          sistemAtribute: "UPPER PRICE RANGE",
          value: null,
          field: "upperPriceRange",
          numberField: true,
          observationValue: {
            text: "1000,00",
            value: ",",
          },
        },
      ],

      dateFormat: [
        "DD-MM-AAAA",
        "DD/MM/AAAA",
        "DD/MM/AA",
        "MM-DD-AAAA",
        "DD/MM/AAAA",
        "AAAA-MM-DD",
        "AAAA/MM/DD",
      ],

      dotFormat: [
        {
          text: "1000,00",
          value: ",",
        },
        {
          text: "1000.00",
          value: ".",
        },
      ],
    };
  },

  watch: {
    fileResults() {
      this.cleanData();

      if (this.fileResults.length != 0) {
        this.tableModelHeaders = this.fileResults.headers;

        let headers = [];

        this.fileResults.headers.forEach((el, index) => {
          if (el.trim() != "") {
            headers.push({
              text: el,
              value: index,
            });
          }
        });

        this.comboboxItems = headers;

        this.tableModelItems = this.fileResults.lines;

        this.mountTableModel();
      }
    },
  },

  computed: {
    allRequiredFieldsFilled() {
      return this.systemAtribute.every((field) =>
        field.required ? field.value !== null && field.value !== "" : true
      );
    },
  },

  methods: {
    processFile() {
      this.processFileDialog = true;
    },

    showSnackbar(snackbar) {
      this.$emit("showSnackbar", snackbar);
    },

    mountTableModel() {
      let items = [];
      this.tableModelHeaders = this.tableModelHeaders.map((header) => ({
        text: header,
        value: header.toLowerCase().replace(/ /g, "_"),
      }));

      this.tableModelItems.forEach((el) => {
        let itm = {};

        el.forEach((item, index) => {
          itm[this.tableModelHeaders[index].value] = item;
        });

        items.push(itm);
      });

      this.tableModelItems = items;
    },

    getComboItems(isRequired) {
      if (!isRequired) {
        return [{ text: "[NENHUM]", value: -1 }].concat(this.comboboxItems);
      }
      return this.comboboxItems;
    },

    cleanDataImport() {
      this.systemAtribute = [
        /**
         * @param {string} systemAtribute -  Name that will appear in the system attributes column (REQUIRED),
         * @param {boolean} required -  Shows a * before the name saying if it is mandatory,
         * @param {string} value -  Is the value of the field that will be sent to the back (REQUIRED),
         * @param {string} msg -  Is the message that will be shown under the name,
         * @param {string} dateField -  Shows a field to select the date,
         * @param {string} numberField -  Shows a field to select a dot,
         * @param {string} observationValue - Is the value of the dataField or dotField (REQUIRED IF DATAFIELD OR DOTFIELD IS TRUES)
         */

        {
          sistemAtribute: "UPC",
          field: "UPC",
          value: null,
          numberField: false,
          observationValue: null,
          required: true,
        },
        {
          sistemAtribute: "MODEL",
          value: null,
          field: "model",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "BRAND",
          value: null,
          field: "brand",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "DESCRIPTION",
          value: null,
          field: "description",
          numberField: false,
          observationValue: null,
          required: true,
        },
        {
          sistemAtribute: "COMPETITIVE PRODUCT LINK",
          value: null,
          field: "competitive_Product_Link",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "KEYWORD FOR SEARCH",
          value: null,
          field: "keywordForSearch",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "LOWER PRICE RANGE",
          value: null,
          field: "lowerPriceRange",
          numberField: true,
          observationValue: {
            text: "1000,00",
            value: ",",
          },
        },
        {
          sistemAtribute: "UPPER PRICE RANGE",
          value: null,
          field: "upperPriceRange",
          numberField: true,
          observationValue: {
            text: "1000,00",
            value: ",",
          },
        },
      ];
      this.tableModelHeaders = [];
      this.tableModelItems = [];
      this.comboboxItems = [];

      this.$emit("cleanData");
    },

    cleanData() {
      this.systemAtribute = [
        /**
         * @param {string} systemAtribute -  Name that will appear in the system attributes column (REQUIRED),
         * @param {boolean} required -  Shows a * before the name saying if it is mandatory,
         * @param {string} value -  Is the value of the field that will be sent to the back (REQUIRED),
         * @param {string} msg -  Is the message that will be shown under the name,
         * @param {string} dateField -  Shows a field to select the date,
         * @param {string} numberField -  Shows a field to select a dot,
         * @param {string} observationValue - Is the value of the dataField or dotField (REQUIRED IF DATAFIELD OR DOTFIELD IS TRUES)
         */

        {
          sistemAtribute: "UPC",
          field: "UPC",
          value: null,
          numberField: false,
          observationValue: null,
          required: true,
        },
        {
          sistemAtribute: "MODEL",
          value: null,
          field: "model",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "BRAND",
          value: null,
          field: "brand",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "DESCRIPTION",
          value: null,
          field: "description",
          numberField: false,
          observationValue: null,
          required: true,
        },
        {
          sistemAtribute: "COMPETITIVE PRODUCT LINK",
          value: null,
          field: "competitive_Product_Link",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "KEYWORD FOR SEARCH",
          value: null,
          field: "keywordForSearch",
          numberField: false,
          observationValue: null,
        },
        {
          sistemAtribute: "LOWER PRICE RANGE",
          value: null,
          field: "lowerPriceRange",
          numberField: true,
          observationValue: {
            text: "1000,00",
            value: ",",
          },
        },
        {
          sistemAtribute: "UPPER PRICE RANGE",
          value: null,
          field: "upperPriceRange",
          numberField: true,
          observationValue: {
            text: "1000,00",
            value: ",",
          },
        },
      ];
      this.tableModelHeaders = [];
      this.tableModelItems = [];
      this.comboboxItems = [];
    },
  },

  mounted() {},
};
</script>