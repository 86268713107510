var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.name))]),_c('label',[_vm._v(_vm._s(_vm.subtitle))]),(_vm.subtitle == 'FUTURE VALUE | CANAL GERAL' && _vm.name == 'DELIBERATION'
        )?_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.unitPriceListUSD",fn:function(ref){
        var item = ref.item;
return [(!item.isEditUSD)?_c('div',[_vm._v(" "+_vm._s(item.unitPriceListUSD)+" "),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditUSD = true}}},[_vm._v(" mdi-pencil-box")])],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.unitPriceListUSD),callback:function ($$v) {_vm.$set(item, "unitPriceListUSD", $$v)},expression:"item.unitPriceListUSD"}}),_c('v-icon',{attrs:{"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPriceDeliberation(item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"color":"#B00020"},on:{"click":function($event){item.isEditUSD = false}}},[_vm._v(" mdi-close-box ")])],1)]}},{key:"item.unitPriceListBRL",fn:function(ref){
        var item = ref.item;
return [(!item.isEditBRL)?_c('div',[_vm._v(" "+_vm._s(item.unitPriceListBRL)+" "),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditBRL = true}}},[_vm._v(" mdi-pencil-box")])],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.unitPriceListBRL),callback:function ($$v) {_vm.$set(item, "unitPriceListBRL", $$v)},expression:"item.unitPriceListBRL"}}),_c('v-icon',{attrs:{"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPriceDeliberation(item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"color":"#B00020"},on:{"click":function($event){item.isEditBRL = false}}},[_vm._v(" mdi-close-box ")])],1)]}},{key:"item.coments",fn:function(ref){
        var item = ref.item;
return [(!item.isEditComments)?_c('div',[_vm._v(" "+_vm._s(item.coments)+" "),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditComments = true}}},[_vm._v(" mdi-pencil-box")])],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.coments),callback:function ($$v) {_vm.$set(item, "coments", $$v)},expression:"item.coments"}}),_c('v-icon',{attrs:{"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPriceDeliberation(item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"color":"#B00020"},on:{"click":function($event){item.isEditComments = false}}},[_vm._v(" mdi-close-box ")])],1)]}}],null,true)}):(_vm.subtitle == '' && _vm.name == 'INFO'
        )?_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.priceBook",fn:function(ref){
        var item = ref.item;
return [(!item.isEditPriceBook)?_c('div',[_vm._v(" "+_vm._s(item.priceBook)+" "),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditPriceBook = true}}},[_vm._v(" mdi-pencil-box")])],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.priceBook),callback:function ($$v) {_vm.$set(item, "priceBook", $$v)},expression:"item.priceBook"}}),_c('v-icon',{attrs:{"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPrice(item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"color":"#B00020"},on:{"click":function($event){item.isEditPriceBook = false}}},[_vm._v(" mdi-close-box ")])],1)]}},{key:"item.keepOnList",fn:function(ref){
        var item = ref.item;
return [(!item.isEditKeepOnList)?_c('div',[_vm._v(" "+_vm._s(item.keepOnList)+" "),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditKeepOnList = true}}},[_vm._v(" mdi-pencil-box")])],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.keepOnList),callback:function ($$v) {_vm.$set(item, "keepOnList", $$v)},expression:"item.keepOnList"}}),_c('v-icon',{attrs:{"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPrice(item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"color":"#B00020"},on:{"click":function($event){item.isEditKeepOnList = false}}},[_vm._v(" mdi-close-box ")])],1)]}}],null,true)}):_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }