<template>
  <v-card :loading="isLoading" :disabled="isLoading" flat>
    <prd-datatable
      :headers="headersTable"
      :items="contentTable"
      :indicators="indicatorsTable"
      :dynamic="true"
      :resultLabel="this.$i18n.t('TXT_PAGINATION')"
      :pageLabel="this.$i18n.t('TXT_PAGE_LABEL')"
      :showItemsPerPage="true"
      :itemsPerPageTitle="`${this.$i18n.t('TXT_RESULTS_BY_PAGE')}:`"
      :showSubtitles="false"
      :showPagination="true"
      :totalRegisters="this.totalCrawlersFound"
      :pagesCount="this.pageCount"
      :itemsPerPageSelected="itemsPerPage"
      @onChange="getCrawler"
      :searchLabel="$t('TXT_SEARCH_TERM')"
    >
      <!-- Button Add New Crawler -->
      <template v-slot:actions-right>
        <crawler-config-add-dialog
          :countryList="countryList"
          :sourceList="sourceList"
          :languageList="languageList"
          :typeList="typeList"
          @ActualizarTable="getCrawler"
          @showSnackbar="showSnackbar"
        />
      </template>

      <!-- Tittle Table -->
      <template v-slot:actions-left>
        <h3 v-t="$t('TXT_CRAWLER_LIST')"></h3>
      </template>

      <!-- Description Field -->
      <template v-slot:[`item.description`]="{ item }">
        <td class="truncate" :style="{ maxWidth: '400px' }">
          <span class="pa-0" v-if="!item.isEdit">
            {{ item.description }}
          </span>
          <v-textarea
            single-line
            outlined
            v-if="item.isEdit"
            v-model="item.description"
            hide-details
            no-resize
          ></v-textarea>
        </td>
      </template>

      <!-- Type Field -->
      <template v-slot:[`item.crawlerType`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{
            getNameById(item.crawlerType, typeList)
          }}</span>
          <prd-combo-box
            v-if="item.isEdit"
            v-model="item.crawlerType"
            :items="typeList"
          />
        </td>
      </template>

      <!-- toggle Online or Offline -->
      <template v-slot:[`item.isOffline`]="{ item }">
        <div
          class="ml-4"
          style="display: flex; justify-content: center; align: center"
        >
          <v-switch
            dense
            hide-details
            v-model="item.isOffline"
            inset
            class="ma-0 pa-0"
            :disabled="!item.isEdit"
          ></v-switch>
        </div>
      </template>

      <!-- Source Field -->
      <template v-slot:[`item.source`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{
            getNameById(item.source, sourceList)
          }}</span>
          <prd-combo-box
            v-if="item.isEdit"
            v-model="item.source"
            :items="sourceList"
          />
        </td>
      </template>

      <!-- Country Field -->
      <template v-slot:[`item.idCountry`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{
            getNameById(item.idCountry, countryList)
          }}</span>
          <prd-combo-box
            v-if="item.isEdit"
            v-model="item.idCountry"
            :items="countryList"
          />
        </td>
      </template>

      <!-- Language Field -->
      <template v-slot:[`item.language`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{ item.language | setLanguage }}</span>
          <prd-combo-box
            v-if="item.isEdit"
            v-model="item.language"
            :items="languageList"
          />
        </td>
      </template>

      <!-- Actions Field -->
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display: flex; justify-content: center; gap: 8px">
          <v-icon
            v-if="!item.isEdit"
            @click="UpdateMode(true, item.idMonitoringCrawler)"
            :color="$prdStyles('color-primary')"
            >mdi-pencil-box
          </v-icon>
          <crawler-config-delete-dialog
            v-if="!item.isEdit"
            :item="item"
            @ActualizarTable="getCrawler"
            :countryList="countryList"
            :sourceList="sourceList"
            :typeList="typeList"
            @showSnackbar="showSnackbar"
          />
        </div>

        <div
          v-if="item.isEdit"
          style="display: flex; justify-content: center; gap: 8px"
        >
          <v-icon
            @click="UpdateDataCrawler(item)"
            :color="$prdStyles('color-success')"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon
            @click="UpdateMode(false, item.idMonitoringCrawler)"
            :color="$prdStyles('color-warning')"
            >mdi-close</v-icon
          >
        </div>
      </template>
    </prd-datatable>

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import PrdDatatable from "@/Design_System/common/prd-datatable.vue";
import CrawlerConfigDeleteDialog from "./crawler-config-delete-dialog.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import CrawlerConfigAddDialog from "./crawler-config-add-dialog.vue";
import CrawlerConfigService from "@/service/crawlers/crawler-config.js";
export default {
  components: {
    PrdDatatable,
    PrdComboBox,
    CrawlerConfigDeleteDialog,
    CrawlerConfigAddDialog,
  },
  data() {
    return {
      service: new CrawlerConfigService(),
      headersTable: [
        { text: "ID", value: "idMonitoringCrawler", align: "center" },
        {
          text: this.$i18n.t("TXT_DESCRIPTION"),
          value: "description",
          align: "center",
          width: "400px",
        },
        {
          text: this.$i18n.t("TXT_TYPE"),
          value: "crawlerType",
          align: "center",
          width: "100px",
        },
        {
          text: this.$i18n.t("TXT_OFFLINE"),
          value: "isOffline",
          align: "center",
          width: "100px",
        },
        {
          text: this.$i18n.t("TXT_SOURCE"),
          value: "source",
          align: "center",
          width: "100px",
        },
        {
          text: this.$i18n.t("TXT_COUNTRY"),
          value: "idCountry",
          align: "center",
          width: "100px",
        },
        {
          text: this.$i18n.t("TXT_LANGUAGE"),
          value: "language",
          align: "center",
          width: "80px",
        },
        {
          text: this.$i18n.t("TXT_ACTIONS"),
          value: "actions",
          align: "center",
        },
      ],
      contentTable: [],
      indicatorsTable: [
        {
          value: "isOnline",
          title: this.$i18n.t("TXT_CRAWLER_ONLINE"),
          activators: [
            {
              active: true,
            },
          ],
        },
        {
          value: "isOnline",
          title: this.$i18n.t("TXT_CRAWLER_OFFLINE"),
          activators: [
            {
              active: false,
            },
          ],
        },
      ],
      sourceList: [
        { text: "Predify", value: 1 },
        { text: "Linx", value: 2 },
        { text: "Horus_Search", value: 3 },
        { text: "Smarket", value: 4 },
      ],
      typeList: [
        { text: "Search", value: 1 },
        { text: "Paginate", value: 2 },
        { text: "Driver", value: 3 },
      ],
      countryList: [],
      totalCrawlersFound: null,
      currentPage: 1,
      itemsPerPage: 25,
      pageCount: 0,
      languageList: ["PT-BR", "EN"],
      oldID: null,
      isLoading: false,
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  methods: {
    async getCrawler(data) {
      try {
        this.isLoading = true;
        let request = {};
        if (data) {
          request = {
            currentPage: data.currentPage,
            itemsPerPage: data.itemsPerPage,
            search: data.search,
          };
        } else {
          request = {
            currentPage: this.currentPage,
            itemsPerPage: this.itemsPerPage,
          };
        }
        let list = [];
        if (request.itemsPerPage === 0) {
          list = await this.service.GetAllCrawler();
          this.contentTable = list.data.answer;
          this.totalCrawlersFound = list.data.answer.length;
          this.pageCount = 1;
        } else {
          list = await this.service.GetAllCrawlerPerPage(
            request.currentPage,
            request.itemsPerPage,
            request.search
          );
          let newResponse = list.data.records.map((item) => {
            if (item.idCountry) {
              let country = this.countryList.find(
                (country) => country.value === item.idCountry
              );
              return { ...item, idCountry: country };
            } else {
              return item;
            }
          });

          newResponse = newResponse.map((item) => {
            if (item.source) {
              let source = this.sourceList.find(
                (source) => source.value === item.source
              );
              return { ...item, source: source };
            } else {
              return item;
            }
          });
          this.totalCrawlersFound = list.data.totalRecords;
          this.pageCount = list.data.totalPages;
          this.contentTable = newResponse;
        }
        if (this.contentTable.length === 0) {
          return;
        } else {
          this.contentTable.forEach((el) => {
            this.$set(el, "isEdit", false);
          });
        }
        this.isLoading = false;
      } catch (error) {
        console.log(this.$i18n.t("TXT_ERROR_METHOD"), " 'getCrawler':", error);
      }
    },

    async getCountries() {
      const request = await this.service.GetCountries();
      const list = request.data;
      list.forEach((el) => {
        this.countryList.push({ text: el.country, value: el.idCountries });
      });
    },

    UpdateMode(isOpen, id) {
      let indexCrawler = this.contentTable.findIndex(
        (linha) => linha.idMonitoringCrawler == id
      );

      // first click
      if (isOpen == true && this.oldID == null && this.oldIndex == null) {
        this.oldIndex = indexCrawler;
        this.oldID = id;
        this.contentTable[indexCrawler].isEdit = true;
      }

      // other clicks
      else if (
        isOpen == true &&
        this.oldID != id &&
        this.oldIndex != indexCrawler
      ) {
        this.contentTable[this.oldIndex].isEdit = false;
        this.contentTable[indexCrawler].isEdit = true;
        this.oldIndex = indexCrawler;
        this.oldID = id;
      }

      // close mode
      else {
        this.contentTable[indexCrawler].isEdit = false;
        this.contentTable[this.oldIndex].isEdit = false;
        this.oldIndex = null;
        this.oldID = null;
      }
    },

    buildRequest(item) {
      return {
        idMonitoringCrawler: item.idMonitoringCrawler,
        description: item.description,
        crawlerType: item.crawlerType?.value || null,
        isOffline: item.isOffline,
        source: item.source?.value || null,
        language: item.language,
        idCountry: item.idCountry?.value || null,
        isDeleted: false,
      };
    },

    async UpdateDataCrawler(item) {
      try {
        let request = this.buildRequest(item);
        console.log("Corpo criado no Update: ", request);
        let response = await this.service.InsertUpdateDeleteCrawler(request);
        if (response.status != 500) {
          console;
          this.UpdateMode(false, item.idMonitoringCrawler);
          this.getCrawler();
          this.showSnackbar(
            `${this.$i18n.t("TXT_SUCCESS_ALTER")} CRAWLER !`,
            "success"
          );
        } else {
          this.showSnackbar(
            `${this.$i18n.t("TXT_ERROR_ALTER")} CRAWLER !`,
            "error"
          );
          this.UpdateMode(false, item.idMonitoringCrawler);
        }
      } catch (error) {
        console.log(error);
      }
    },

    getNameById(value, list) {
      let localValue = null;
      if (value == null || value == "") return "-";
      if (value.value) {
        localValue = value.value;
      } else {
        localValue = value;
      }
      let nameFounded = list.find((el) => el.value == localValue);
      return nameFounded.text;
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
  },
  async mounted() {
    await this.getCountries();
    await this.getCrawler();
  },
  filters: {
    setLanguage(value) {
      return value ? value : "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
</style>