<template>
  <v-container fluid>
    <div v-if="this.editedProduct == null && this.newProduct == false">
      <titleBreadCrumbsComponent :breadCrumbs="breadCrumbs" :title="'ONE PAGE'" />
      <v-card class="pa-4">
        <div class="airport-sign">
          <span v-if="this.textoVariaveisTributacao != null" id="showVariaveisTributacao">{{ this.textoVariaveisTributacao
          }}</span>
        </div>
        <v-row no-gutters>
          <v-col cols="12" md="3">

            <label>Selecione um produto</label>
            <v-combobox dense outlined append-icon="mdi-chevron-down" hide-details class="mt-2" :items="products"
              item-value="id" item-text="nome" v-on:change="loadProductDetails($event.id)" v-model="selectedProduct">
            </v-combobox>
          </v-col>

          <prd-btn @click="createNewProduct" :title="'Novo Produto'" class="mt-8 mx-4" />

          <prd-btn @click="editProduct" :title="'Editar Produto'" class="mt-8" />
          <VariaveisFinanceiras v-if="variaveisTributacao != null" v-bind:variaveisTributacao="variaveisTributacao" />
          <VariavelTempoIa />

          <v-spacer></v-spacer>
          <prd-btn @click="$router.go('main/deliberation-main')" :outlined="true" :title="'Deliberação'"
            class="mt-8 mr-4" />
          <prd-btn @click="relatorio" :outlined="true" :title="'Relatório'" class="mt-8" />

        </v-row>
      </v-card>

      <info-product-main v-bind:key="this.selectedProduct.id" v-bind:product="this.selectedProduct" />
      <price-table-main v-bind:key="this.selectedProduct.id" v-bind:product="this.selectedProduct" />

      <charts-main v-bind:key="this.selectedProduct.id" v-bind:product="this.selectedProduct" />
    </div>

    <div v-else-if="this.editedProduct != null">
      <inputs-main v-bind:key="this.editedProduct.id" v-bind:editedProduct="this.editedProduct"></inputs-main>
    </div>

    <div v-if="this.newProduct == true">
      <NewProduct></NewProduct>
    </div>

  </v-container>
</template>

<script>
import VariaveisFinanceiras from "./components/info-product/variaveis-financeiras.vue";
import VariavelTempoIa from "./components/info-product/variavel-tempo-ia.vue";
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import InfoProductMain from "./components/info-product/info-product-main.vue";
import PriceTableMain from "./components/price-table/price-table-main.vue";
import InputsMain from "./components/inputs/inputs-main.vue";
import NewProduct from "./components/inputs/new-product.vue";
import ServiceUPL from "@/service/upl/index";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ChartsMain from "./components/charts/charts-main.vue";

export default {
  name: "upl-main",
  components: {
    titleBreadCrumbsComponent,
    InfoProductMain,
    PriceTableMain,
    InputsMain,
    NewProduct,
    VariaveisFinanceiras,
    PrdBtn,
    ChartsMain,
    VariavelTempoIa
  },
  data() {
    return {
      breadCrumbs: [
        {
          text: "Upl",
        },
        {
          text: "Home",
        },
      ],
      products: [],
      variaveisTributacao: null,
      selectedProduct: null,
      editedProduct: null,
      newProduct: false,
      textoVariaveisTributacao: null,
      service: new ServiceUPL(),
    };
  },
  methods: {
    async getProducts() {
      await this.service.getProducts().then((res) => {
        this.products = res.data;
        this.selectedProduct = this.products[0];
      });
    },
    async getVariaveisTributacao() {
      await this.service.getVariaveisTributacao().then((res) => {
        this.variaveisTributacao = res.data;
      });
    },
    async relatorio() {
      await this.service
        .getRelatorio()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "RelatorioUPL.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadProductDetails(id) {
      this.selectedProduct = this.products.find((x) => x.id == id);
    },
    editProduct() {
      this.editedProduct = this.selectedProduct;
      this.newProduct = false;
    },
    createNewProduct() {
      this.newProduct = true;
      this.editedProduct = null;
    },
  },
  async beforeMount() {
    await this.getProducts();
    await this.getVariaveisTributacao();
    this.textoVariaveisTributacao = `Frete: ${this.variaveisTributacao.frete}%                   
                                    - Dolar: R$${this.variaveisTributacao.dolar}
                                    - Juros Real: ${this.variaveisTributacao.jurosBRL}%
                                    - Juros Dólar: ${this.variaveisTributacao.jurosUSD}%
                                    - Imposto: ${this.variaveisTributacao.imposto}%
                                    - Período: ${this.variaveisTributacao.periodoMedio} dias`;
  },
};

</script>
<style>
.airport-sign {
  width: 100%;
  height: 20px;
  overflow: hidden;
  position: relative;
  color: black;
}

#showVariaveisTributacao {
  position: absolute;
  white-space: nowrap;
  font-size: 15px;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}
</style>