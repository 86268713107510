<template>
  <div class="mt-10">
    <!-- CALENDAR BUTTON -->
    <div class="my-flex">
      <v-tabs
        v-model="tab"
        color="black"
        slider-color="#3c5ca7"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <h4>CALENDÁRIO PROMOCIONAL</h4>
        </v-tab>
      </v-tabs>
      <new-action-dialog
        @listActions="getActions"
        :loading="promotionalCalendarLoading"
      />
    </div>

    <!-- ACTION INFO -->
    <v-card class="pa-4 pt-0" :disabled="promotionalCalendarLoading">
      <div class="flex mr-4 pt-4">
        <v-tabs
          v-model="actionTab"
          color="black"
          slider-color="#EC4C37"
          background-color="transparent"
          class="group-filters mb-4 mr-4"
          height="25px"
        >
          <v-tab class="mr-6">
            <h4>VIGENTES ({{ currentActions.length }})</h4>
          </v-tab>
          <v-tab>
            <h4>PRÓXIMAS ({{ nexActions.length }})</h4>
          </v-tab>
        </v-tabs>
        <prd-btn
          @click="exportData"
          :title="'Exportar'"
          :outlined="true"
          :icon="'mdi-upload-outline'"
          :smallIcon="true"
          :leftIcon="true"
        />
      </div>

      <v-row v-if="promotionalCalendarLoading" no-gutters justify="center">
        <v-progress-circular
          :color="$prdStyles('color-primary')"
          indeterminate
        ></v-progress-circular>
      </v-row>
      
      <v-tabs-items v-else v-model="actionTab">
        <v-tab-item>
          <carousel-item
            v-if="currentActions != null"
            :actionData="currentActions"
          />
        </v-tab-item>
        <v-tab-item>
          <carousel-item :actionData="nexActions" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import CarouselItem from "./components/carousel-item.vue";
import NewActionDialog from "./components/new-action-dialog.vue";
import ServiceHome20 from "@/service/home20/index";
export default {
  props: ["promotionalCalendarData", "promotionalCalendarLoading"],
  components: { prdBtn, CarouselItem, NewActionDialog },
  data() {
    return {
      service: new ServiceHome20(),
      tab: 0,
      actionTab: 0,
      currentActions: [],
      nexActions: [],
    };
  },

  watch: {
    promotionalCalendarData: {
      handler(newValue) {
        console.log(newValue);
        this.currentActions = newValue.currentActions;
        this.nexActions = newValue.nextActions;
      },
      deep: true,
    },
  },

  methods: {
    getActions() {
      this.$emit("updatePromotionalCalendar");
    },
    exportData() {
      this.service
        .getRelatorioAcaoPromocional()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "RelatoriosPromocionais.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  // beforeMount() {
  //   this.service.getAcaoPromocional().then((res) => {
  //     this.currentActions = res.data.currentActions;
  //     this.nexActions = res.data.nextActions;
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>