<template>
    <div id="titlePage">
      <v-row class="view-title">{{ title }}
        <slot name="tooltip" class="tootip"></slot>
      </v-row>
      <v-breadcrumbs :items="breadCrumbs" class="pa-0 mx-0 mt-0 breadcrumb">
        <template v-slot:divider>
          <v-icon color="#BAB8B8">$chevronRight</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
  </template>
  
  <script>
  export default {
    name: "titlePage",
    data() {
      return {};
    },
    props: {
      title: {
        type: String,
        requiired: true,
      },
      breadCrumbs: {
        type: Array,
        required: false,
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  #titlePage * {
    font-family: "Ubuntu", sans-serif;
  }
  
  
  .view-title {
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
    color: $neutral-color-low-pure;
    margin: 24px 5px 0px 0px;
  }
  
  .breadcrumb {
    color: $neutral-color-low-light;
    font-size: $font-size-xxxs;
    font-weight: $font-weight-regular;
    @extend .mb-xxs;
  }
  
  </style>