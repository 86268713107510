<template>
  <v-container v-if="this.editedProduct != null">
    <titleBreadCrumbsComponent :breadCrumbs="breadCrumbs" :title="this.editedProduct.nome" />
    <v-btn small class="white--text pa-3 rounded-sm margin-y-5 mt-4" color="warning"
      @click="$router.go('main/upl-main')">Voltar</v-btn>

    <v-card v-if="!showInputs" :loading="isLoading" :disabled="isLoading" :loading-height="6" class="pa-4 pt-0">
      <v-row no-gutters align="center" class="pt-4">

        <v-col cols="12" md="4">
          <label class="font-weight-bold">Nome</label>
          <v-text-field v-model="name" :label="this.editedProduct.nome" dense outlined hide-details
            class="mt-2 mr-4"></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <br />
          <v-btn small color="red" class="white--text pa-3 rounded-sm margin-y-5 mt-4" @click="excluirProduct()">Excluir
            {{ this.editedProduct.nome }}</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <editAboutProduct v-bind:product="this.editedProduct"></editAboutProduct>
    <editMarketProduct v-bind:product="this.editedProduct"></editMarketProduct>

    <editCustoProduct v-if="this.editedProduct.tipoPrecificacao == 'Custo'" v-bind:product="this.editedProduct">
    </editCustoProduct>
    <editConcorrenteProduct v-if="this.editedProduct.tipoPrecificacao == 'Concorrente'"
      v-bind:product="this.editedProduct"></editConcorrenteProduct>

  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import EditAboutProduct from './componentes/edit-about-product.vue'
import EditMarketProduct from './componentes/edit-market-product.vue'
import EditCustoProduct from './componentes/edit-custo-product.vue'
import EditConcorrenteProduct from './componentes/edit-concorrente-product.vue'
import ServiceUPL from "@/service/upl/index";

export default {
  components: { titleBreadCrumbsComponent, EditAboutProduct, EditMarketProduct, EditCustoProduct, EditConcorrenteProduct },
  name: "inputs-main",
  props: ["editedProduct"],
  data() {
    return {
      externalProducts: [],
      service: new ServiceUPL(),
      breadCrumbs: [
        {
          text: "upl",
        },
        {
          text: "Inputs",
        },
      ],
      typeItems: [
        {
          text: "Concorente",
        },
        {
          text: "Custo",
        },
      ],

      isLoading: false,

      name: null,
      type: null,

      showInputs: false,
    };
  },

  methods: {
    addTarget() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.showInputs = true;
        this.breadCrumbs.push({ text: "outro input" });
      }, 1000);
    },
    async excluirProduct() {
      if (confirm('Tem certeza que deseja excluir ?')) {
        await this.service.deleteProduct(this.editedProduct.id).then(() => {
          this.$router.go('main/upl-main');
        })
      }
    },
    closeInputs() {
      this.name = null;
      this.type = null;
      this.showInputs = false;
      this.breadCrumbs.pop();
    },

    backOnePage() {
    }
  },

  mounted() {
    document.title = 'PREDIFY | Inputs'
  },
  beforeMount() {
  }
};
</script>