/**
 * @typedef {{
 *   productName: string;
 *   region: { price: { idEnterprisePricesProjection: number } };
 * }} ProductRegion
 *
 * @typedef {{
 *   approvedProductRegions: Record<string, ProductRegion>;
 *   numberOfApprovedRegionsInProduct: Record<string, number>;
 * }} State
 */

export default {
  state: () =>
    /** @type {State} */
    ({
      approvedProductRegions: {},
      numberOfApprovedRegionsInProduct: {},
    }),

  getters: {
    isProductRegionApproved: (state) => (idEnterprisePricesProjection) =>
      Boolean(state.approvedProductRegions[idEnterprisePricesProjection]),

    getApprovedRegionsCount: (state) => (productName) =>
      state.numberOfApprovedRegionsInProduct[productName] || 0,

    totalApproved: (state) =>
      Object.values(state.numberOfApprovedRegionsInProduct).reduce(
        (acc, num) => acc + num,
        0
      ),
  },

  mutations: {
    /**
     * @param {State} state
     * @param {state["approvedProductRegions"][string]} approvedProductRegion
     */
    approveProductRegion(state, approvedProductRegion) {
      const {
          idEnterprisePricesProjection,
        productName
      } = approvedProductRegion;

      if (state.approvedProductRegions[idEnterprisePricesProjection]) {
        console.warn("Warning: tried to approve an already approved product.");
        return;
      }

      state.approvedProductRegions = {
        ...state.approvedProductRegions,
        [idEnterprisePricesProjection]: approvedProductRegion,
      };

      state.numberOfApprovedRegionsInProduct = {
        ...state.numberOfApprovedRegionsInProduct,
        [productName]:
          (state.numberOfApprovedRegionsInProduct[productName] || 0) + 1,
      };
    },

    /**
     * @param {State} state
     * @param {number} idProductPredify
     */
    unapproveProductRegion(state, idEnterprisePricesProjection) {
      // Check if the product was previously approved
      if (!state.approvedProductRegions[idEnterprisePricesProjection]) {
        console.warn(
          "Warning: tried to unapprove a product that wasn't previously approved."
        );
        return;
      }

      // Removes the product from the approved list.
      const newApprovedProductRegions = { ...state.approvedProductRegions };
      const { productName } =
        newApprovedProductRegions[idEnterprisePricesProjection];
      delete newApprovedProductRegions[idEnterprisePricesProjection];

      // Remove the product from the products in regions list.
      const newNumberOfApprovedRegionsInProduct = {
        ...state.numberOfApprovedRegionsInProduct,
      };
      newNumberOfApprovedRegionsInProduct[productName] -= 1;
      if (newNumberOfApprovedRegionsInProduct[productName] <= 0)
        delete newNumberOfApprovedRegionsInProduct[productName];

      // Update the state.
      state.numberOfApprovedRegionsInProduct =
        newNumberOfApprovedRegionsInProduct;
      state.approvedProductRegions = newApprovedProductRegions;
    },

    /** @param {State} state */
    resetState(state) {
      state.numberOfApprovedRegionsInProduct = {};
      state.approvedProductRegions = {};
    },
  },
};
