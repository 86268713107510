<template>
  <v-card class="pa-4">
    <v-row
      v-if="infoProjectionLoading"
      class="mt-10"
      no-gutters
      justify="center"
    >
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <div
      v-else
      v-for="(info, index) in infoProjection"
      :key="index"
      class="my-flex"
    >
      <span>{{ info.propertyName }}</span>
      <span class="font-weight-bold">{{ info.value }}</span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["infoProjection", "infoProjectionLoading"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

span {
  font-size: 12px;
}
</style>