<template>
  <div>
    <v-card :loading="isLoading" :disabled="isLoading" class="pa-4 pt-0" flat>
      <!-- DATA PICKER -->
      <v-row no-gutters class="pt-4">
        <v-col cols="12" sm="4" md="3">
          <span>
            <label v-t="'TXT_REFERENCE_PERIOD'"></label>
          </span>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            ref="menu"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                :label="formattedDateLabel"
                :placeholder="$t('TXT_SELECT_PERIOD')"
                hide-details="auto"
                outlined
                dense
                readonly
                class="input-datapicker mt-1"
                :error="verifyDataPicker"
                persistent-hint
                :hint="verifyDataPicker ? $t('VAL_FIELD_REQUIRED') : ''"
                single-line
              >
                <template v-slot:append>
                  <v-icon
                    class="append-calendar"
                    color="white"
                    small
                    :style="{
                      backgroundColor: verifyDataPicker ? '#EC4C37' : '#3c5ca7',
                    }"
                  >
                    mdi-calendar</v-icon
                  >
                </template>
              </v-text-field>
            </template>
            <!-- TODO - properly use user's locale -->
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              range
              :min="minDay"
              :max="maxDay"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="error"
                @click="menu = false && dateRangeText == null"
                v-t="'TXT_CANCEL'"
              ></v-btn>
              <v-btn
                color="primary"
                @click="$refs.menu.save(date)"
                v-t="'TXT_OK'"
              ></v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <prd-btn
          :title="$t('TXT_SIMULATE')"
          class="mt-7 ml-4"
          @click="getSimulation"
        />
      </v-row>

      <div class="my-6" v-if="companyFilters.length > 0">
        <h4 v-t="$t('TXT_FILTERS')"></h4>
        <v-row no-gutters class="mt-4">
          <v-col
            v-for="companyFilter in companyFilters"
            :key="companyFilter.idEnterpriseChurnDefaultFilter"
            cols="12"
            md="3"
          >
            <prd-combo-box
              v-model="companyFilter.selectedValues"
              :items="companyFilter.values"
              :title="companyFilter.fieldName"
              :disabled="false"
              :loading="false"
              multiple
              small-chips
              deletable-chips
              :label="$t('TXT_SELECT')"
              single-line
              class="mr-4"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Card dos filtros de tabela -->
    <v-card
      class="mt-4 pa-4 pt-0"
      flat
      v-if="isDisableSecondCard"
      :loading="isFilterLoading"
      :disabled="isFilterLoading"
    >
      <v-divider></v-divider>
      <h4 v-t="$t('TXT_RESULTS_UPPERCASE')" class="my-4"></h4>
      <v-row no-gutters align="center">
        <v-col cols="12" md="4">
          <prd-combo-box
            v-model="custumer"
            :items="custumersItems"
            :title="$t('TXT_CUSTUMER')"
            :useTooltipInformation="true"
            :textTooltipInformation="'tooltip'"
            :disabled="
              isFilterLoading ||
              custumersItems.length == 0 ||
              churnData.length == 0
            "
            :loading="isFilterLoading"
            multiple
            small-chips
            deletable-chips
            :label="$t('TXT_SELECT')"
            single-line
            class="mr-4"
          />
        </v-col>
        <v-col cols="12" md="3">
          <prd-combo-box
            v-model="probabilityStayingAlive"
            :title="$t('TXT_ALIVE_PROBABILITY')"
            :items="probabilityStayingAliveItems"
            :disabled="
              isFilterLoading ||
              probabilityStayingAliveItems.length == 0 ||
              churnData.length == 0
            "
            multiple
            small-chips
            deletable-chips
            :label="$t('TXT_SELECT')"
            single-line
          />
        </v-col>
        <prd-btn
          @click="getCustomers"
          :disabled="isFilterLoading || churnData.length == 0"
          :title="$t('TXT_FILTER_LOWERCASE')"
          class="mt-6 ml-4"
        />
        <v-spacer></v-spacer>
        <v-col cols="12" md="2">
          <prd-combo-box
            v-model="recordsPerPage"
            :items="recordsPerPageItems"
            :title="$t('TXT_RESULTS_BY_PAGE')"
            :disabled="isFilterLoading || churnData.length == 0"
            :label="$t('TXT_SELECT')"
            single-line
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ChurnService from "@/service/churn-analysis/index.js";

const Service = new ChurnService();

export default {
  components: {
    PrdComboBox,
    PrdBtn,
  },
  props: ["currentPage", "isLoading"],
  data() {
    return {
      search: "",

      // Data picker vars
      menu: false,
      date: [],
      minDay: null,
      maxDay: null,

      isFilterLoading: false,
      isDisableSecondCard: false,

      churnData: [],
      custumer: null,
      custumersItems: [],
      companyFilters: [],

      probabilityStayingAlive: null,
      probabilityStayingAliveItems: [],

      recordsPerPageItems: [
        {
          text: 5,
          value: 5,
        },
        {
          text: 10,
          value: 10,
        },
        {
          text: 20,
          value: 20,
        },
        {
          text: 50,
          value: 50,
        },
      ],
      recordsPerPage: { text: 5, value: 5 },
      verifyDataPicker: false,
    };
  },

  watch: {
    search() {
      this.getCustomers();
    },

    recordsPerPage() {
      this.getCustomers();
    },

    currentPage() {
      this.getCustomers();
    },

    date() {
      if (this.verifyDataPicker) {
        this.verifyDataPicker = !this.verifyDataPicker;
      }
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    dateRangeText() {
      if ((this.date, length == 0)) return;
      return this.date.join("  ~  ");
    },

    formattedDateLabel() {
      let initialDate = this.date[0];
      if (!initialDate) {
        initialDate = "-";
      }
      let finalDate = this.date[1];
      if (!finalDate) {
        finalDate = "-";
      }

      if (initialDate != "-" || finalDate != "-") {
        return `${initialDate} ~ ${finalDate}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    buildFilterRequest() {
      let request = {
        filters: {
          client: [],
          probabilityStayingAlive: [],
        },
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage.value,
        idCompany: this.idCompany,
      };

      request.filters.client =
        this.custumer && this.custumer.length > 0
          ? this.custumer.map((el) => {
              return { value: el.value };
            })
          : [];

      request.filters.probabilityStayingAlive =
        this.probabilityStayingAlive && this.probabilityStayingAlive.length > 0
          ? this.probabilityStayingAlive.map((el) => {
              return { value: el.value / 100 };
            })
          : [];

      return request;
    },

    getCustomers() {
      this.$emit("setLoading", true);
      let request = this.buildFilterRequest();
      Service.getCustomers(request)
        .then((res) => {
          if (res.status != 500) {
            this.churnData = res.data.result;
            if (this.churnData.length != 0) {
              this.$emit("churnData", this.churnData);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            } else {
              this.$emit(
                "showSnackbar",
                this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
                "info"
              );
            }
          }
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log("erro no buscar churn", error);
          this.$emit("setLoading", false);
        });
    },

    getCompanyFilters() {
      this.$emit("setLoading", true);
      Service.getCompanyFilters(this.idCompany)
        .then((res) => {
          let response = res.data;
          let data = response.result;
          if (response.status != 500 && data.length > 0) {
            data.forEach((el) => {
              el.values = el.values.map((value) => ({
                value: value.value,
                text: value.value,
              }));
              el.selected = null;
            });
            this.companyFilters = data;
            this.$emit("setLoading", false);
          }
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          this.$emit("setLoading", false);
          console.log("Erro ao buscar filtros da companhia", error);
        });
    },

    getCustumerFilters() {
      this.isFilterLoading = true;

      Service.getFilters(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            let response = res.data.result;
            this.custumersItems = response.client;
            this.custumersItems.forEach((el) => {
              el.text = el.value;
            });
            this.probabilityStayingAliveItems =
              response.probabilityStayingAlive;
            this.probabilityStayingAliveItems.forEach((el) => {
              el.text = el.value;
            });
          }

          this.isFilterLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao buscar filtros", error);
          this.isFilterLoading = false;
        });
    },

    filterDataProcessing() {
      let filterAndValue = [];
      this.companyFilters.forEach((element) => {
        if (element.selectedValues?.length > 0) {
          let valueFilter = [];
          element.selectedValues.forEach((elementSelectedValues) => {
            valueFilter.push(elementSelectedValues.value);
          });
          filterAndValue.push({
            field: element.field,
            value: valueFilter,
          });
        }
      });
      return filterAndValue;
    },
    buildSimulationRequest() {
      let request = {
        idCompany: this.idCompany,
        environment: null,
        filter: this.filterDataProcessing(),
        start_date: this.date[0],
        end_date: this.date[1],
      };

      return request;
    },
    getSimulation() {
      if (this.date[0] && this.date[1]) {
        this.$emit("setLoading", true);
        this.verifyDataPicker = false;
        Service.getSimulation(this.buildSimulationRequest())
          .then((res) => {
            // console.log("resposta do getSimulation: ", res);
            if (res.status != 500) {
              this.isDisableSecondCard = !this.isDisableSecondCard;
              this.$emit(
                "showSnackbar",
                this.$i18n.t("TXT_SIMULATION_SUCCESS"),
                "success"
              );
              this.getCustomers();
            } else {
              this.$emit(
                "showSnackbar",
                this.$i18n.t("TXT_SIMULATION_PROBLEM"),
                "error"
              );
              this.$emit("setLoading", false);
            }
          })
          .catch((error) => {
            this.$emit("setLoading", false);
            this.$emit(
              "showSnackbar",
              this.$i18n.t("TXT_SIMULATION_PROBLEM"),
              "error"
            );
            console.log("Erro do getSimulation: ", error);
          });
      } else {
        this.verifyDataPicker = true;
      }
    },
  },
  created() {
    this.getCompanyFilters();
    this.getCustumerFilters();
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>
