<template>
    <div>
        <titleBreadCrumbsComponent :breadCrumbs="breadCrumbs" :title="name" />
        <v-card class="pa-4 pt-0 mt-2" v-if="this.newProduct != null">

            <v-row no-gutters class="mb-12"
                v-if="this.custoReposicao != null && this.internalItems != null && this.externalProducts != null">
                <v-col cols="12" md="2">
                    <h4>PREMISSAS </h4>
                </v-col>
                <v-col cols="12" md="3">
                    <h4>MQ Stat: {{ convertToDate(this.externalProducts[0].dataInsercao.toString()) }} </h4>
                </v-col>
                <v-col cols="12" md="3">
                    <h4>Data Goal: {{ convertToDate(this.internalItems[0].dataInsercao.toString()) }} </h4>
                </v-col>
                <v-col cols="12" md="3">
                    <h4>Custo Reposição: {{ convertToDate(this.custoReposicao[0].dataInsercao.toString()) }} </h4>
                </v-col>
            </v-row>

            <v-row no-gutters align="center" class="pt-4">
                <v-col md="3">
                    <label class="font-weight-bold">Tipo Precificação</label>
                    <v-combobox dense outlined hide-details v-model="newProduct.tipoPrecificacao"
                        :items="this.tipoPrecificacaoList" append-icon="mdi-chevron-down" class="mt-2 mr-4"
                        v-on:change="loadTiposPrecificacao()"></v-combobox>
                </v-col>
                <v-col cols="12" md="1" class="mt-7">
                    <v-icon @click="saveChanges()" :disabled="jaExisteNome" title="Salvar"
                        :color="$prdStyles('color-success')" style="font-size: 40px">mdi-content-save</v-icon>
                </v-col>
            </v-row>

            <div class="mt-5" v-if="newProduct.tipoPrecificacao != null && newProduct.tipoPrecificacao != 'Custo'">
                <v-row no-gutters align="center" class="pt-4">
                    <v-col cols="12" md="4">
                        <label class="font-weight-bold">Nome</label>
                        <v-text-field v-model="name" dense outlined hide-details class="mt-2 mr-4"
                            v-on:change="checkName"></v-text-field>
                        <span v-if="jaExisteNome" style="color: red;">Já existe um produto com esse mesmo nome</span>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" class="pt-4">
                    <v-col>
                        <label class="font-weight-bold">Dose</label>
                        <v-text-field type="number" v-model="newProduct.dose" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Posicionamento</label>
                        <v-text-field type="number" v-model="newProduct.posicionamento" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Remuneração Canal</label>
                        <v-text-field type="number" v-model="newProduct.remuneracaoPorCanal" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Corredor Preço</label>
                        <v-text-field type="number" v-model="newProduct.corredorPreco" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                </v-row>

                <v-row v-for="(item, index) in items" :key="index" no-gutters align="center">
                    <v-col>
                        <label>Tipo</label>
                        <v-text-field :disabled="true" filled v-model="item.tipoValue" dense outlined hide-details
                            class="mt-1 mr-4">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <div v-if="item.tipoValue == 'Externa'">
                            <label>Externa</label>
                            <v-combobox :items="item.capturas" v-model="item.selectedCaptura" dense outlined hide-details
                                append-icon="mdi-chevron-down" class="mt-1 mr-4" item-value="id" item-text="product"
                                v-on:change="loadCapturaDetails($event.id, index)">
                            </v-combobox>
                        </div>
                        <div v-if="item.tipoValue == 'Interna'">
                            <label>Interna</label>
                            <v-combobox :items="item.capturas" v-model="item.selectedCaptura" dense outlined hide-details
                                append-icon="mdi-chevron-down" class="mt-1 mr-4" item-value="id" item-text="descricaoMQ"
                                v-on:change="loadCapturaDetails($event.id, index)">
                            </v-combobox>
                        </div>
                        <div v-if="item.tipoValue == 'Produtos UPL'">
                            <label>Produtos UPL</label>
                            <v-combobox :items="item.capturas" v-model="item.selectedCaptura" dense outlined hide-details
                                append-icon="mdi-chevron-down" class="mt-1 mr-4" item-value="id" item-text="nome"
                                v-on:change="loadCapturaDetails($event.id, index)">
                            </v-combobox>
                        </div>
                    </v-col>
                    <v-col v-if="item.tipoValue != null">
                        <label>Preço</label>
                        <v-text-field :items="externalItems" v-model="item.preco" dense outlined hide-details
                            class="mt-1 mr-4">
                        </v-text-field>
                    </v-col>
                    <v-col v-if="item.tipoValue != null">
                        <label>Dose</label>
                        <v-text-field :items="externalItems" v-model="item.dose" dense outlined hide-details
                            class="mt-1 mr-4">
                        </v-text-field>
                    </v-col>
                    <v-col v-if="item.tipoValue != null && item.tipoValue != 'Produtos UPL'">
                        <label>Peso</label>
                        <v-row no-gutters>
                            <v-text-field :items="externalItems" v-model="item.peso" dense outlined hide-details
                                class="mt-1 mr-4">
                            </v-text-field>

                        </v-row>
                    </v-col>
                </v-row>
            </div>

            <div v-if="newProduct.tipoPrecificacao != null && newProduct.tipoPrecificacao == 'Custo'">
                <v-row no-gutters align="center" class="pt-4">
                    <v-col>
                        <label class="font-weight-bold">Brand</label>
                        <v-combobox dense outlined append-icon="mdi-chevron-down" hide-details class="mt-2 mr-4"
                            :items="custoReposicao" item-value="id" item-text="brand"
                            v-on:change="setCustoReposicao($event.id)" v-model="selectedCustoReposicao">
                        </v-combobox>
                        <span v-if="jaExisteNome" style="color: red;">Já existe um produto com esse mesmo nome</span>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Descrição Unificada</label>
                        <v-text-field :disabled="true" v-model="descricaoUnificada" filled dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Unit Cogs</label>
                        <v-text-field :disabled="true" v-model="unitCogs" filled dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters align="center" class="pt-4">
                    <v-col>
                        <label class="font-weight-bold">Margem de Contribuição</label>
                        <v-text-field type="number" v-model="newProduct.margemContribuicao" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Remuneração por canal</label>
                        <v-text-field type="number" v-model="newProduct.remuneracaoPorCanal" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>
                    <v-col>
                        <label class="font-weight-bold">Corredor Preço</label>
                        <v-text-field type="number" v-model="newProduct.corredorPreco" dense outlined hide-details
                            class="mt-2 mr-4"></v-text-field>
                    </v-col>

                </v-row>
            </div>
        </v-card>
    </div>
</template>
<script>

import ServiceUPL from "@/service/upl/index";
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";

export default {
    components: { titleBreadCrumbsComponent },
    data() {
        return {
            name: '',
            service: new ServiceUPL(),
            breadCrumbs: [
                {
                    text: "upl",
                },
                {
                    text: "Inputs",
                },
            ],
            newProduct: null,
            externalItems: [],
            internalItems: [],
            productItems: [],
            corredorPreco: null,
            descricaoUnificada: "",
            unitCogs: "",
            selectedCustoReposicao: null,
            custoReposicao: [],
            tipoPrecificacao: null,
            estrategiaPrecificacao: null,
            jaExisteNome: false,
            tipoPrecificacaoList: ['Value Price', 'Down Price', 'Preço de competição', 'Oportunidade de Mercado', 'Produto Líder', 'Custo', 'Mistura Interna', 'Mistura Externa'],
            items: [
                {
                    capturas: [],
                    selectedCaptura: null,
                    preco: "",
                    dose: "",
                    peso: "",
                    tipoValue: null,
                    tipo: ['Externa', 'Interna', 'Produtos UPL']
                },
            ],
        };
    },
    methods: {
        convertToDate(dateString) {
            let d = dateString.split("-");
            let dat = d[2].slice(0, 2) + '/' + d[1] + '/' + d[0];
            return dat;
        },
        checkName() {
            if (this.productItems != null) {
                this.jaExisteNome = this.productItems.find(x => x.nome.toUpperCase() == this.name.toUpperCase());
            }
        },
        loadTiposPrecificacao() {
            this.items = [];
            if (this.newProduct.tipoPrecificacao == 'Value Price' ||
                this.newProduct.tipoPrecificacao == 'Down Price' ||
                this.newProduct.tipoPrecificacao == 'Preço de competição' ||
                this.newProduct.tipoPrecificacao == 'Oportunidade de Mercado' ||
                this.newProduct.tipoPrecificacao == 'Produto Líder') {
                this.addProductToItem();
                this.items[0].capturas = this.externalProducts;
                this.items[0].tipoValue = 'Externa';
                this.addProductToItem();

                this.items[1].capturas = this.internalItems;
                this.items[1].tipoValue = 'Interna';
            }
            else if (this.newProduct.tipoPrecificacao == 'Mistura Interna') {
                this.addProductToItem();
                this.items[0].capturas = this.externalProducts;
                this.items[0].tipoValue = 'Externa';
                this.addProductToItem();

                this.items[1].capturas = this.internalItems;
                this.items[1].tipoValue = 'Interna';

                this.addProductToItem();
                this.items[2].capturas = this.productItems;
                this.items[2].tipoValue = 'Produtos UPL';
            }
            else if (this.newProduct.tipoPrecificacao == 'Mistura Externa') {
                this.addProductToItem();
                this.items[0].capturas = this.externalProducts;
                this.items[0].tipoValue = 'Externa';

                this.addProductToItem();
                this.items[1].capturas = this.internalItems;
                this.items[1].tipoValue = 'Interna';

                this.addProductToItem();
                this.items[2].capturas = this.externalProducts;
                this.items[2].tipoValue = 'Externa';

                this.addProductToItem();
                this.items[3].capturas = this.internalItems;
                this.items[3].tipoValue = 'Interna';
            }
        },
        loadTipoCaptura(tipo, index) {
            this.items[index].selectedCaptura = null;
            if (tipo == 'Externa') {
                this.items[index].capturas = this.externalProducts;
            } else if (tipo == 'Interna') {
                this.items[index].capturas = this.internalItems;
            } else if (tipo == 'Produtos UPL') {
                this.items[index].capturas = this.productItems;
            } else {
                this.items[index].capturas = [];
            }
        },
        loadCapturaDetails(id, index) {
            if (this.items[index].tipoValue == 'Externa') {
                let selectedExternalProduct = this.externalProducts.find(x => x.id == id);
                this.items[index].preco = selectedExternalProduct.medianaBRL.toString().replace(".", ",");
                this.items[index].dose = selectedExternalProduct.doseha != null ? selectedExternalProduct.doseha.toString().replace(".", ",") : '';
            } else if (this.items[index].tipoValue == 'Interna') {
                let internalSelectedItem = this.internalItems.find(x => x.id == id);
                this.items[index].preco = internalSelectedItem.mediaPrecoPVBRL.toString().replace(".", ",");
                this.items[index].dose = "";
            } else if (this.items[index].tipoValue == 'Produtos UPL') {
                let productSelected = this.productItems.find(x => x.id == id);
                this.items[index].preco = productSelected.precoQuilo.toString().replace(".", ",");
                this.items[index].dose = productSelected.dose != null ? productSelected.dose.toString().replace(".", ",") : '';
            }
        },
        addProductToItem() {
            this.items.push({
                capturas: [],
                selectedCaptura: null,
                preco: "",
                dose: "",
                peso: "",
                tipoValue: null,
                tipo: ['Externa', 'Interna', 'Produtos UPL']
            });
        },
        setCustoReposicao(id) {
            this.newProduct.custoReposicao = id;
            this.selectedCustoReposicao = this.custoReposicao.find(x => x.id == id);
            this.newProduct.nome = this.selectedCustoReposicao.brand;
            this.descricaoUnificada = this.selectedCustoReposicao.descricaoUnificada;
            this.unitCogs = this.selectedCustoReposicao.unitCogs;

            if (this.productItems != null) {
                this.jaExisteNome = this.productItems.find(x => x.nome.toUpperCase() == this.newProduct.nome.toUpperCase());
            }
        },
        async saveChanges() {
            if (this.newProduct.tipoPrecificacao == 'Value Price' ||
                this.newProduct.tipoPrecificacao == 'Down Price' ||
                this.newProduct.tipoPrecificacao == 'Preço de competição' ||
                this.newProduct.tipoPrecificacao == 'Oportunidade de Mercado' ||
                this.newProduct.tipoPrecificacao == 'Produto Líder') {
                this.newProduct.estrategiaPrecificacao = this.newProduct.tipoPrecificacao;
                this.newProduct.tipoPrecificacao = 'Concorrente';
            }

            if (this.newProduct.tipoPrecificacao == 'Concorrente') {
                this.salvarConcorrente();
            }
            else if (this.newProduct.tipoPrecificacao == 'Mistura Interna') {
                this.salvarConcorrente();
                this.newProduct.capturas[0].idProdutoUPL = this.items[2].selectedCaptura.id;
            }
            else if (this.newProduct.tipoPrecificacao == 'Mistura Externa') {
                this.salvarConcorrente();
                this.salvarConcorrenteExtra();
            }
            if (this.newProduct.tipoPrecificacao != 'Custo') {
                this.newProduct.nome = this.name;
            }

            await this.service.createProduct(this.newProduct);
            this.newProduct.tipoPrecificacao = this.newProduct.estrategiaPrecificacao;
            alert("Salvo com sucesso");
            this.$router.go('main/upl-main');
        },
        salvarConcorrente() {
            let nomeCaptura = "";
            this.newProduct.capturas = [];
            if (this.items[0].selectedCaptura == null && this.items[1].selectedCaptura == null) {
                alert('Selecione pelo menos um produto para essa estratégia');
            }

            if (this.items[0].selectedCaptura != null && this.items[0].selectedCaptura.product != null)
                nomeCaptura = this.items[0].selectedCaptura.product;
            else {
                nomeCaptura = this.items[1].selectedCaptura.descricaoMQ;
            }

            this.newProduct.capturas.push({
                nomeCaptura: nomeCaptura,
                mediaCapturaExterna: this.items[0].preco == "" ? "0" : this.items[0].preco.replace(",", "."),
                pesoCapturaExterna: this.items[0].peso == "" ? "0" : this.items[0].peso.replace(",", "."),
                mediaCapturaInterna: this.items[1].preco == "" ? "0" : this.items[1].preco.replace(",", "."),
                pesoCapturaInterna: this.items[1].peso == "" ? "0" : this.items[1].peso.replace(",", "."),
                doseCompetidor: this.items[0].dose == "" ? this.items[1].dose.replace(",", ".") : this.items[0].dose.replace(",", "."),
            });
        },
        salvarConcorrenteExtra() {
            this.newProduct.capturas.push({
                nomeCaptura: this.items[2].selectedCaptura.product,
                mediaCapturaExterna: this.items[2].preco.replace(",", "."),
                pesoCapturaExterna: this.items[2].peso.replace(",", "."),
                mediaCapturaInterna: this.items[3].preco.replace(",", "."),
                pesoCapturaInterna: this.items[3].peso.replace(",", "."),
                doseCompetidor: this.items[2].dose.replace(",", "."),
            });
        }
    },
    async beforeMount() {
        await this.service.getExternalFiles().then((res) => {
            this.externalProducts = res.data;
        });
        await this.service.getInternalFiles().then((res) => {
            this.internalItems = res.data;
        });
        await this.service.getProducts().then((res) => {
            this.productItems = res.data;
        });
        await this.service.getCustoReposicao().then((res) => {
            this.custoReposicao = res.data;
        });
        await this.service.getEmptyProduct().then((res) => {
            this.newProduct = res.data;
        });
    }
};
</script>