<template>
  <v-expansion-panel-header @click="$emit('openPanel')" hide-actions>
    <div class="header-info-main">
      <!-- PRODUCT NAME -->
      <div class="my-flex-space-between font-weight-bold font-size-16">
        <div class="my-flex">
          <v-checkbox
            v-model="workFlowValue"
            v-if="workFlowAccess"
            :disabled="workFlowRules"
            @click.stop="aproveProduct"
            color="#3c5ca7"
          ></v-checkbox>
          <span class="font-weight-bold mr-6"
            >Artigo - {{ product.product }}</span
          >
          <span class="font-weight-bold">{{ product.productName }}</span>
        </div>
        <div>
          <prd-btn
            @click.stop="sendERP"
            :title="'Enviar para ERP'"
            :outlined="true"
            :disabled="erpLoading || !isPublished"
          />
        </div>
      </div>

      <v-row v-if="isLoading" no-gutters justify="center">
        <v-progress-circular
          indeterminate
          :color="$prdStyles('color-primary')"
        ></v-progress-circular>
      </v-row>

      <div v-else style="display: flex">
        <!-- LEFT CARD -->
        <div class="left-card">
          <div>
            <!-- PML -->
            <div class="content-box">
              <span>PML </span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
              <span v-else>{{ this.formatPercentage(pml) }}</span>
            </div>
            <!-- ULT COMPRA -->
            <div class="content-box">
              <span>Últ. compra</span>
              <span v-if="!loadingManualPrice">{{ lastPurchase }}</span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
            </div>
          </div>

          <div>
            <!-- PISO -->
            <div class="content-box">
              <span>Piso </span>
              <span v-if="!loadingManualPrice">{{
                this.formatPercentage(floor)
              }}</span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
            </div>
            <!-- ESTOQUE -->
            <div class="content-box">
              <span>Estoque </span>
              <span v-if="!loadingManualPrice">{{ stock }}</span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
            </div>
          </div>

          <div>
            <!-- PREÇO ATUAL -->
            <div class="content-box">
              <span>Preço atual </span>
              <span v-if="!loadingManualPrice">{{
                setCurrency(currentPrice)
              }}</span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
            </div>
            <!-- WEEKS -->
            <div class="content-box">
              <span>Week's </span>
              <span v-if="!loadingManualPrice">{{ weeks }}</span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
            </div>
          </div>

          <div>
            <!-- RO -->
            <div class="content-box">
              <span>(%) RO </span>
              <span v-if="!loadingManualPrice">{{
                formatPercentage(margin)
              }}</span>
              <v-skeleton-loader
                v-if="loadingManualPrice"
                type="card"
                height="22px"
              />
            </div>

            <!-- Referencia -->
            <div class="content-box">
              <span>Referência</span>
              <span
                @click.stop
                @click="setIsMargin"
                class="card-destaq card-color-info ref"
                >{{ marginDestination }}
              </span>
            </div>
          </div>
        </div>

        <!-- RIGTH CARD -->
        <div class="right-card">
          <!-- MENOR CONC -->
          <div>
            <span>Menor Concorrente</span>
            <span v-if="!loadingManualPrice">{{
              setCurrency(minMarketPrice)
            }}</span>
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span
              v-if="!loadingManualPrice"
              class="card-destaq mb-4 card-color-info teste"
            >
              {{ minMarketSellerName }}
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <!-- GAP MENOR -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço do menor concorrente dividido pelo preço atual'"
              />
            </span>

            <span
              v-if="!loadingManualPrice"
              class="card-destaq"
              :class="setMarginColor(gapMinMarket)"
              >{{ formatPercentage(gapMinMarket) }}</span
            >
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
            />
          </div>

          <!-- MÉDIA MERCADO -->
          <div>
            <span>Média mercado </span>
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span v-else>{{ setCurrency(avgMarketPrice) }}</span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span
              v-else
              :class="setMarginColor(qtdMarketCompetitor)"
              class="card-destaq mb-4"
            >
              {{ qtdMarketCompetitor }}
            </span>

            <!-- GAP MERCADO -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço médio de mercado dividido pelo atual'"
            /></span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span
              v-else
              class="card-destaq"
              :class="setMarginColor(gapAvgMarket)"
            >
              {{ formatPercentage(gapAvgMarket) }}
            </span>
          </div>

          <!-- PREÇO SUG IA -->
          <div>
            <span>Preço Sug. IA </span>
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span v-else>
              {{ setCurrency(suggestedPrice) }}
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span
              v-else
              class="card-destaq mb-4"
              :class="setMarginColor(suggestedPriceMargin)"
              >RO: {{ formatPercentage(suggestedPriceMargin) }}</span
            >

            <!-- GAP SUG -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço concorrente dividido pelo preço da IA'"
            /></span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span
              v-else
              class="card-destaq"
              :class="setMarginColor(gapSuggested)"
            >
              {{ formatPercentage(gapSuggested) }}
            </span>
          </div>

          <!-- AJUSTE MANUAL -->
          <div>
            <span>Ajuste manual </span>
            <span class="mb-4">
              <span style="font-size: 18px" v-show="!showAdjusPrice">{{
                setCurrency(manualPrice)
              }}</span>
              <v-icon
                @click.stop="showAdjusPrice = true"
                v-show="!showAdjusPrice"
                :disabled="erpLoading"
                right
                :color="$prdStyles('color-primary')"
                >mdi-pencil-box</v-icon
              >
              <div
                v-show="showAdjusPrice && !erpLoading"
                style="display: flex; align-items: center"
              >
                <v-text-field
                  v-model="manualPrice"
                  :loading="manualPriceLoading"
                  :disabled="manualPriceLoading"
                  @click.stop
                  dense
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details
                ></v-text-field>
                <div class="flex-direction: column">
                  <v-icon
                    @click.stop="adjustPrice"
                    :disabled="manualPriceLoading"
                    color="#1D8527"
                    small
                  >
                    mdi-checkbox-marked
                  </v-icon>
                  <v-icon
                    @click.stop="showAdjusPrice = false"
                    :disabled="manualPriceLoading"
                    color="#B00020"
                    small
                  >
                    mdi-close-box
                  </v-icon>
                </div>
              </div>
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span v-else class="card-destaq card-color-warning mb-4"
              >RO: {{ formatPercentage(manualPriceMargin) }}</span
            >

            <!-- GAP MANUAL  -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço concorrente dividido pelo preço do ajuste manual'"
              />
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span v-else class="card-destaq" :class="setMarginColor(gapManual)">
              {{ formatPercentage(gapManual) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { formatPercentage } from "@/utils/format-percentage.js";
import { setCurrency } from "@/utils/format-currency.js";
import headerService from "@/service/new-ai-pricing/header.js";
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const HeaderService = new headerService();

export default {
  components: { tooltipInformations, PrdBtn },
  props: ["product", "index"],
  data() {
    return {
      isLoading: false,
      erpLoading: false,
      manualPriceLoading: false,
      loadingManualPrice: false,
      showAdjusPrice: false,
      headerData: null,
      isMargin: false,
      workFlowValue: this.verifyWorkflow(),
    };
  },

  watch: {
    product: {
      handler() {
        this.getHeaderPricing();
      },

      immediate: true,
    },
  },

  computed: {
    pml() {
      return this.headerData?.pml ?? "-";
    },
    floor() {
      return this.headerData?.floor ?? "-";
    },
    stock() {
      return this.headerData?.stock ?? "-";
    },
    weeks() {
      return this.headerData?.weeks ?? "-";
    },
    lastPurchase() {
      return this.headerData?.lastPurchase ?? "-";
    },
    markupSug() {
      return this.headerData?.markupSug.toFixed(2) ?? "-";
    },
    currentPrice() {
      return this.headerData?.currentPrice ?? "-";
    },
    minMarketPrice() {
      return this.headerData?.minMarketPrice ?? "-";
    },
    minMarketSellerName() {
      return this.headerData?.minMarketSellerName ?? "-";
    },
    avgMarketPrice() {
      return this.headerData?.avgMarketPrice ?? "-";
    },
    qtdMarketCompetitor() {
      return this.headerData?.qtdMarketCompetitor ?? "-";
    },
    suggestedPrice() {
      return this.headerData?.suggestedPrice ?? "-";
    },
    suggestedPriceMargin() {
      return this.headerData?.suggestedPriceMargin ?? "-";
    },
    manualPrice: {
      get() {
        return this.headerData?.manualPrice ?? "-";
      },
      set(value) {
        this.headerData.manualPrice = value;
      },
    },
    manualPriceMargin() {
      return this.headerData?.manualPriceMargin ?? "-";
    },
    margin() {
      if (this.isMargin)
        return this.headerData?.marginDestinationDFXGOValue ?? "-";
      else return this.headerData?.marginDestinationGOxDFValue ?? "-";
    },
    marginDestination() {
      if (this.isMargin) return this.headerData?.marginDestinationDFXGO ?? "-";
      else return this.headerData?.marginDestinationGOxDF ?? "-";
    },
    gapMinMarket() {
      return this.headerData?.gapMinMarket ?? "-";
    },
    gapAvgMarket() {
      return this.headerData?.gapAvgMarket ?? "-";
    },
    gapSuggested() {
      return this.headerData?.gapSuggested ?? "-";
    },
    gapManual() {
      return this.headerData?.gapManual ?? "-";
    },
    isPublished() {
      if (
        this.product?.workflowApproval?.lastAction?.toSend &&
        !this.product?.isPublished
      )
        return true;

      return false;
    },
    workFlowAccess() {
      if (!this.product?.workflowApproval?.actions) return false;
      let access = this.product.workflowApproval.actions.filter((el) => {
        return el.action == 0 || el.action == 1;
      });

      if (access.length != 0) return true;
      else return false;
    },
    workFlowRules() {
      if (!this.product?.workflowApproval) return false;

      let action = this.product.workflowApproval.actions.filter((el) => {
        return el.action == 0;
      });

      if (
        this.workFlowValue &&
        action.filter((el) => {
          if (el.action == 1) return true;
        })
      ) {
        return false;
      }

      if (
        !this.workFlowValue &&
        action.filter((el) => {
          if (el.action == 0) return true;
        })
      ) {
        return false;
      }

      return true;
    },
  },

  methods: {
    formatPercentage,
    setCurrency,

    verifyWorkflow() {
      let workFlow = this.product.workflowApproval;
      if (workFlow.lastAction.userType == 0 && workFlow.lastAction.action == 0)
        return true;
      else return false;
    },

    getHeaderPricing(isManual) {
      if (isManual) this.loadingManualPrice = true;
      else this.isLoading = true;

      HeaderService.getHeaderPricing(this.product.idEnterprisePricesProjection)
        .then((res) => {
          this.headerData = res.data.result;
          this.isLoading = false;
          this.loadingManualPrice = false;
          this.$emit("headerPricing", this.headerData);
        })
        .catch((error) => {
          console.log("Erro ao buscar pricing do header", error);
          this.isLoading = false;
          this.loadingManualPrice = false;
        });
    },

    aproveProduct() {
      this.$emit("setLoading", true);
      let request = {
        idEnterprisePricesProjection: this.product.idEnterprisePricesProjection,
        action: this.workFlowValue ? 0 : 1,
      };

      HeaderService.setWorkflow(request)
        .then((res) => {
          console.log("Resposta do workflow", res);
          this.$emit("enableErp", this.index);
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log("Erro no aproveProduct", error);
          this.$emit("setLoading", false);
        });
    },

    sendERP() {
      this.erpLoading = true;
      let request = [
        {
          IdEnterprisePricesProjection:
            this.product.idEnterprisePricesProjection,
        },
      ];
      HeaderService.sendToERP(request)
        .then((res) => {
          console.log("Resposta do enviar para ERP", res);
          this.erpLoading = false;
        })
        .catch((error) => {
          console.log("Erro no sendERP", error);
          this.erpLoading = false;
        });
    },

    adjustPrice() {
      this.manualPriceLoading = true;
      let request = {
        idEnterprisePricesProjection: this.product.idEnterprisePricesProjection,
        manualPrice: parseFloat(this.manualPrice),
        manualContributionMargin: null,
        isManual: true,
      };

      HeaderService.adjustPrice(request)
        .then((res) => {
          console.log("Resposta do editar preço manual", res);
          if (res.data.result) {
            this.showAdjusPrice = false;
            this.manualPriceLoading = false;
            this.getHeaderPricing(true);
            this.$emit("setProductContent");
          }
        })
        .catch((error) => {
          console.log("Erro ao editar preço manual", error);
          this.manualPriceLoading = false;
        });
    },

    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);
      this.$store.commit("snackbar", this.$i18n.t("TXT_COPY"));
    },

    setMarginColor(margin) {
      if (margin == "-") return "card-color-info";

      if (margin < 0) return "card-color-error";

      return "card-color-success";
    },

    setIsMargin() {
      this.isMargin = !this.isMargin;
    },
  },
};
</script>

<style lang="scss" scoped>
.ref {
  transition: 0.1s;
}
.ref:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.teste {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teste:hover {
  white-space: break-spaces;
  overflow: visible;
}
.header-info-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .left-card {
    display: flex;
    width: 45%;
    gap: 8px;
    margin-right: 8px;

    > div {
      width: 25%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        .content-box {
          span:first-child {
            font-size: 12px;
            margin-bottom: 8px !important;
          }

          span:nth-child(2) {
            font-size: 16px;
            font-weight: bold;
            color: $brand-color-primary-pure;
          }
        }
      }
    }

    .content-box {
      span:first-child {
        font-size: 12px;
        margin-bottom: 8px !important;
      }

      span:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        color: $brand-color-primary-pure;
      }
    }
  }

  .right-card {
    display: flex;
    width: 55%;

    > div {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      flex-grow: 1;

      span:first-child {
        font-size: 12px;
        margin-bottom: 8px !important;
      }

      span:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
        color: $brand-color-primary-pure;
      }
    }
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .content-box {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    flex-grow: 1;
  }

  .content-box span:first-child {
    margin-bottom: 4px;
  }

  .more-info {
    color: #3c5ca7;
    font-weight: bold;
  }
  .gap {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .error-color {
    color: #b00020;
    font-size: 16px;
  }
  .success-color {
    color: #1d8527;
    font-size: 16px;
  }
}
.card-label {
  font-size: 12px;
  margin-bottom: 8px !important;
}

.card-value {
  font-size: 16px;
  font-weight: bold;
  color: $brand-color-primary-pure;
}

.card-destaq {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
}

.card-color-info {
  background-color: $brand-color-primary-light;
  color: black;
  font-weight: 400;
}
.card-color-success {
  background-color: $feedback-color-success-light;
  color: black;
  font-weight: 400;
}
.card-color-error {
  background-color: $feedback-color-warning-light;
  color: black;
  font-weight: 400;
}
.card-color-warning {
  background-color: $feedback-color-helper-light;
  color: black;
  font-weight: 400;
}

.my-flex {
  display: flex;
  align-items: center;
}
.my-flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>