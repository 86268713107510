import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CompanySelect extends ServiceBase {

    async getCompanyList() {
        return await axiosInstance.get(API_URL + "api/Empresa/GetEmpresasSelecionar")
    }




}