<template>
  <v-card class="pa-4">
    <v-row no-gutters>
      <h4 style="font-size: 14px">
        VARIÁVEIS QUE MAIS IMPACTAM NA PRECIFICAÇÃO POR IA
      </h4>
      <v-spacer></v-spacer>

      <v-icon color="#3C5CA7">mdi-dots-vertical</v-icon>
    </v-row>

    <highcharts class="mt-6" :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
export default {
  data() {
    return {
      chartData: {
        chart: {
          type: "bar",
        },

        title: {
          text: "",
        },

        yAxis: {
          title: {
            text: "Score de importância",
          },
        },

        xAxis: {
          categories: [
            "Adesivos",
            "Cola",
            "Fitas",
            "PE",
            "PP",
            "PSA",
            "Polímeros",
            "IPCA",
            "YUAN",
            "Dólar",
            "Celulose",
          ],
        },

        tooltip: {
          valueSuffix: " millions",
        },

        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },

        series: [
          {
            name: "Score",
            color: "#3c5ca7",
            data: [631, 727, 480, 721, 706, 820, 489, 871, 741, 877],
          },
        ],
      },
    };
  },
};
</script>