<template id="load-page">
  <div class="text-center">
    <v-progress-circular
      :width="width"
      indeterminate
      :rotate="rotate"
      :color="color"
      :value="value"
      v-bind="$attrs"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "progressComponent",
  props: {
    rotate: {
      type: Number || String,
    },
    color: {
      type: String,
    },
    width: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      interval: {},
      value: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
};
</script>

<style lagn="scss" scoped>
#load-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}
</style>
