var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"flat":""}},[_c('prd-datatable',{attrs:{"headers":_vm.headersTable,"items":_vm.contentTable,"indicators":_vm.indicatorsTable,"dynamic":true,"resultLabel":this.$i18n.t('TXT_PAGINATION'),"pageLabel":this.$i18n.t('TXT_PAGE_LABEL'),"showItemsPerPage":true,"itemsPerPageTitle":((this.$i18n.t('TXT_RESULTS_BY_PAGE')) + ":"),"showSubtitles":false,"showPagination":true,"totalRegisters":this.totalCrawlersFound,"pagesCount":this.pageCount,"itemsPerPageSelected":_vm.itemsPerPage,"searchLabel":_vm.$t('TXT_SEARCH_TERM')},on:{"onChange":_vm.getCrawler},scopedSlots:_vm._u([{key:"actions-right",fn:function(){return [_c('crawler-config-add-dialog',{attrs:{"countryList":_vm.countryList,"sourceList":_vm.sourceList,"languageList":_vm.languageList,"typeList":_vm.typeList},on:{"ActualizarTable":_vm.getCrawler,"showSnackbar":_vm.showSnackbar}})]},proxy:true},{key:"actions-left",fn:function(){return [_c('h3',{directives:[{name:"t",rawName:"v-t",value:(_vm.$t('TXT_CRAWLER_LIST')),expression:"$t('TXT_CRAWLER_LIST')"}]})]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"truncate",style:({ maxWidth: '400px' })},[(!item.isEdit)?_c('span',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e(),(item.isEdit)?_c('v-textarea',{attrs:{"single-line":"","outlined":"","hide-details":"","no-resize":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_vm._e()],1)]}},{key:"item.crawlerType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm.getNameById(item.crawlerType, _vm.typeList)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.typeList},model:{value:(item.crawlerType),callback:function ($$v) {_vm.$set(item, "crawlerType", $$v)},expression:"item.crawlerType"}}):_vm._e()],1)]}},{key:"item.isOffline",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-4",staticStyle:{"display":"flex","justify-content":"center","align":"center"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":"","inset":"","disabled":!item.isEdit},model:{value:(item.isOffline),callback:function ($$v) {_vm.$set(item, "isOffline", $$v)},expression:"item.isOffline"}})],1)]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm.getNameById(item.source, _vm.sourceList)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.sourceList},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}}):_vm._e()],1)]}},{key:"item.idCountry",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm.getNameById(item.idCountry, _vm.countryList)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.countryList},model:{value:(item.idCountry),callback:function ($$v) {_vm.$set(item, "idCountry", $$v)},expression:"item.idCountry"}}):_vm._e()],1)]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm._f("setLanguage")(item.language)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.languageList},model:{value:(item.language),callback:function ($$v) {_vm.$set(item, "language", $$v)},expression:"item.language"}}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"8px"}},[(!item.isEdit)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary')},on:{"click":function($event){return _vm.UpdateMode(true, item.idMonitoringCrawler)}}},[_vm._v("mdi-pencil-box ")]):_vm._e(),(!item.isEdit)?_c('crawler-config-delete-dialog',{attrs:{"item":item,"countryList":_vm.countryList,"sourceList":_vm.sourceList,"typeList":_vm.typeList},on:{"ActualizarTable":_vm.getCrawler,"showSnackbar":_vm.showSnackbar}}):_vm._e()],1),(item.isEdit)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"8px"}},[_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-success')},on:{"click":function($event){return _vm.UpdateDataCrawler(item)}}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-warning')},on:{"click":function($event){return _vm.UpdateMode(false, item.idMonitoringCrawler)}}},[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.snackbarColor,"timeout":2000},model:{value:(_vm.snackbar.snackbar),callback:function ($$v) {_vm.$set(_vm.snackbar, "snackbar", $$v)},expression:"snackbar.snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }