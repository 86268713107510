import Vue from "vue";
import VueRouter from "vue-router";

//Angular
import mainAngular from "@/views/angular/main";

import testeDataTable from "@/views/main/teste-table";

//loginArea
import loginArea from "@/views/login/login";
import signIn from '@/views/login/sign-in.vue'
import signOut from "@/views/login/sign-out";
import signUp from "@/views/login/sign-up";
import companyInformation from "@/views/login/company-information";
import passwordRecovery from "@/views/login/password-recovery";

//mainArea
import main from "@/views/main/main";

//CompanySelect
import companySelect from "@/views/main/company-select/company-select-main.vue"

//home
import home from "@/views/main/home/home-container";

//market-intelligence
import marketIntelligence from "@/views/main/market-intelligence/market-intelligence";

//pricing
import pricing from "@/views/main/pricing/pricing";
import aiPricing from "@/views/main/pricing/ai-pricing";

//redirect
import redirect from "@/views/redirect";

//PrediMonitor
import prediMonitor from "@/views/main/predi-monitor/predi-monitor";

//Predimonitor V2
import predimonitorV2 from "@/views/main/predimonitor-V2/predimonitor-V2-main.vue"

//Cliens
import dashboardBamaq from "@/views/main/clients/BAMAQ/dashboard-bamaq";

// Predifut
import predifutMain from "@/views/main/predifut/predifut-main";
import predifutSelectGame from "@/views/main/predifut/predifut-select-game";
import predifutSimulateMatch from "@/views/main/predifut/predifut-simulate-match";

// File import
import fileImport from '@/views/main/file-import/file-import-main'

// Product management v2
import productManagementV2 from '@/views/main/product-management-v2/product-management-main.vue'

// Tags config
import tagsConfig from '@/views/main/tags-config/tags-config-main.vue'

//Churn main
import churnAnalysis from "@/views/main/churn/churn-analysis-main.vue"

// UPL
import uplMain from '@/views/main/upl/upl-main.vue'
import uplDeliberationMain from '@/views/main/deliberation/deliberation-main.vue'
import inputMain from '@/views/main/upl/components/inputs/inputs-main.vue'

// Deliberation
import deliberationMain from '@/views/main/deliberation/deliberation-main.vue'

// Crawler Config
import crawlerConfig from '@/views/main/crawlers/crawler-config/crawler-config-main.vue'
// Crawlers Management
import crawlerManagement from "@/views/main/crawlers/crawler-management/crawler-management-main.vue"
// Crawler execute
import crawlerExecute from "@/views/main/crawlers/crawler-execute/crawler-execute-main.vue"

//Mix Products Apriori
import mixProductsApriori from '@/views/main/mix-products-apriori/mix-products-apriori-main.vue'

//New ai pricing
import newAiPricing from '@/views/main/new-pricing/new-pricing-main'



Vue.use(VueRouter);

const routes = [
  {
    path: "" || "/",
    redirect: { name: "sign-in" },
  },
  {
    path: "/login",
    component: loginArea,
    children: [
      {
        path: "sign-in",
        name: "sign-in",
        component: signIn,
      },
      {
        path: "sign-up",
        name: "sign-up",
        component: signUp,
      },
      {
        path: "sign-out",
        name: "sign-out",
        component: signOut,
      },
      {
        path: "company-information",
        name: "company-information",
        component: companyInformation,
      },
      {
        path: "password-recovery",
        name: "password-recovery",
        component: passwordRecovery,
      },
      {
        path: "" || "/",
        redirect: { name: "sign-in" },
      },
    ],
  },
  {
    path: "/main",
    component: main,
    children: [
      {
        path: "" || "/",
        redirect: { name: "company-select" },
      },
      {
        path: "home",
        name: "home-container",
        component: home,
      },
      {
        path: "company-select",
        name: "company-select",
        component: companySelect,
      },
      {
        path: "predifut",
        name: "predifut",
        component: predifutMain,
        children: [
          {
            path: "select-game",
            name: "select-game",
            component: predifutSelectGame,
          },
          {
            path: "simulate/:idEnterpriseEventHistory",
            name: "simulate",
            component: predifutSimulateMatch,
          },
        ],
      },
      {
        path: "pricing",
        name: "pricing",
        component: pricing,
        children: [
          {
            path: "ai-pricing",
            name: "ai-pricing",
            component: aiPricing,
          },
        ],
      },
      {
        path: "predimonitor",
        name: "predimonitor",
        component: prediMonitor,
      },
      {
        path: "predimonitor-V2",
        name: "predimonitor-V2",
        component: predimonitorV2,
      },
      {
        path: "tags-config",
        name: "tags-config",
        component: tagsConfig,
      },
      {
        path: "upl-main",
        name: "upl-main",
        component: uplMain,
      },
      {
        path: "upl-deliberation-main",
        name: "upl-deliberation-main",
        component: uplDeliberationMain,
      },
      {
        path: "inputs-main",
        name: "inputs-main",
        component: inputMain,
      },
      {
        path: "upl-main",
        name: "upl-main",
        component: uplMain,
      },
      {
        path: "inputs-main",
        name: "inputs-main",
        component: inputMain,
      },
      {
        path: "deliberation-main",
        name: "deliberation-main",
        component: deliberationMain,
      },
      {
        path: "market-intelligence",
        name: "market-intelligence",
        component: marketIntelligence,
        alias: ["monitoring-product", "monitoring-lead", "tags-configure"],
      },

      {
        path: "dashboard-bamaq",
        name: "dashboard-bamaq",
        component: dashboardBamaq,
      },
      {
        path: "file-import",
        name: "file-import-main",
        component: fileImport,
      },
      {
        path: "product-management-v2",
        name: "product-management-v2",
        component: productManagementV2,
      },
      {
        path: "churn-analysis",
        name: "churn-analysis",
        component: churnAnalysis,
      },
      {
        path: "crawler-management",
        name: "crawler-management",
        component: crawlerManagement,
      },
      {
        path: "crawler-execute",
        name: "crawler-execute-main",
        component: crawlerExecute,
      },
      {
        path: "crawler-config",
        name: "crawler-config",
        component: crawlerConfig,
      },
      {
        path: "teste-table",
        name: "teste-table",
        component: testeDataTable,
      },

      {
        path: "mix-products-apriori",
        name: "mix-products-apriori",
        component: mixProductsApriori,
      },
      {
        path: "ai-pricing-fujioka",
        name: "new-ai-pricing",
        component: newAiPricing,
      },

    ],
  },
  {
    path: "/angular",
    component: mainAngular,
    children: [
      {
        path: "" || "/",
        redirect: { name: "home" },
      },
      {
        path: "home",
        name: "home-container",
        component: home,
      },
      {
        path: "market-intelligence",
        name: "market-intelligence",
        component: marketIntelligence,
      },
      {
        path: "predimonitor",
        name: "predimonitor",
        component: prediMonitor,
      },
      {
        path: "predimonitor-V2",
        name: "predimonitor-V2",
        component: predimonitorV2,
      },
      {
        path: "dashboard-bamaq",
        name: "dashboard-bamaq",
        component: dashboardBamaq,
      },
      {
        path: "file-import",
        name: "file-import-main",
        component: fileImport,
      },
      {
        path: "product-management-v2",
        name: "product-management-v2",
        component: productManagementV2,
      },
      {
        path: "churn-analysis",
        name: "churn-analysis",
        component: churnAnalysis,
      },
      {
        path: "crawler-config",
        name: "crawler-config",
        component: crawlerConfig,
      },
      {
        path: "crawler-management",
        name: "crawler-management",
        component: crawlerManagement,
      },
      {
        path: "crawler-execute",
        name: "crawler-execute-main",
        component: crawlerExecute,
      },
      {
        path: "predifut",
        name: "predifut",
        component: predifutMain,
        children: [
          {
            path: "/",
            redirect: { path: "/angular/predifut/select-game" },
          },
          {
            path: "select-game",
            name: "select-game",
            component: predifutSelectGame,
          },
          {
            path: "simulate/:idEnterpriseEventHistory",
            name: "simulate",
            component: predifutSimulateMatch,
          },
        ],
      },
      {
        path: "pricing",
        name: "pricing",
        component: pricing,
        children: [
          {
            path: "ai-pricing",
            name: "ai-pricing",
            component: aiPricing,
          },

        ],
      },
      {
        path: "ai-pricing-fujioka",
        name: "new-ai-pricing",
        component: newAiPricing,
      },
      {
        path: "upl",
        name: "upl",
        component: uplMain,
      },
      {
        path: "deliberation-main",
        name: "deliberation",
        component: deliberationMain,
      },
      {
        path: "mix-products-apriori",
        name: "mix-products-apriori",
        component: mixProductsApriori,
      },
    ],
  },
  {
    path: "/redirect",
    component: redirect,
    name: "redirect",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
