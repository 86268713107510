<template>
  <div class="mt-6">
    <div class="my-flex">
      <v-tabs
        v-model="tab"
        color="black"
        slider-color="#B00020"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <v-row no-gutters>
            <h4>ALERTAS</h4>
            <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
          </v-row>
        </v-tab>
      </v-tabs>
    </div>
    <div>
      <div style="display: flex; gap: 1rem">
        <reproved-card style="width: 50%" :data="marginCard" />
        <reproved-card style="width: 50%" :data="psicologyPriceCard" />
      </div>
      <div class="mt-4" style="display: flex; gap: 1rem">
        <reproved-card style="width: 50%" :data="icCard" />
        <reproved-card style="width: 50%" :data="competitorPriceCard" />
      </div>
    </div>
  </div>
</template>

<script>
// import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import ReprovedCard from "./components/reproved-card.vue";

export default {
  props: ["alertsData"],
  components: { /*TooltipInformations*/ ReprovedCard },
  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    marginCard() {
      return this.alertsData?.marginCard ?? null;
    },
    psicologyPriceCard() {
      return this.alertsData?.psicologyPriceCard ?? null;
    },
    icCard() {
      return this.alertsData?.icCard ?? null;
    },
    competitorPriceCard() {
      return this.alertsData?.competitorPriceCard ?? null;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>