<template>
  <v-dialog max-width="500" v-model="showSaveFilterDialog" persistent>
    <v-card class="pa-4" :disabled="isLoading">
      <h4 class="mb-4">SALVAR FILTRO</h4>
      <p class="font-weight-bold">Escolha como deseja salvar esses filtros</p>

      <v-radio-group v-model="dialogFilter" row hide-details class="mb-4">
        <v-radio label="Salvar como novo filtro" value="newFilter"></v-radio>
        <v-radio
          v-if="false"
          label="Sobrescrever"
          value="override"
          :disabled="true"
        ></v-radio>
      </v-radio-group>

      <v-col class="pa-0">
        <label>Dê um nome para esse filtro</label>
        <v-text-field
          v-model="newFilterName"
          dense
          outlined
          hide-details
          class="mb-2"
        ></v-text-field>
        <p style="font-size: 12px; color: #b00020">
          O filtro ”Período de referência” não será salvo nesse conjunto de
          filtros. Ao carregar esse filtro será necessário escolher um novo
          periodo de referência.
        </p>
      </v-col>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <prd-btn
          @click="showSaveFilterDialog = false"
          :outlined="true"
          :title="'Cancelar'"
        />
        <prd-btn
          :loading="isLoading"
          @click.native="saveFilters"
          :title="'Salvar'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import Service from "@/service/predimonitor/filtros-predmonitor-service";

const service = new Service();

export default {
  components: { prdBtn },
  props: [
    "idCompany",
    "products",
    "filters",
    "locationFilters",
    "range",
    "yearsFilterSelected",
    "regionFilterSelected",
    "machineryResource",
  ],
  data() {
    return {
      dialogFilter: "newFilter",
      showSaveFilterDialog: false,
      newFilterName: null,
      isLoading: false,
    };
  },

  computed: {},

  methods: {
    openDialog() {
      this.showSaveFilterDialog = true;
    },

    saveFilters() {
      this.isLoading = true;
      let request = this.setRequestForSaveFilters();
      service
        .CUDFilters(request)
        .then((res) => {
          if (res.status != 500) {
            this.showSaveFilterDialog = false;
            this.$emit("saveFilterSuccess", res.data.answer);
            this.newFilterName = null;
            this.isLoading = false;
          } else {
            this.$emit("showSnackbar", "Erro ao salvar filtro", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    cancelFilter() {
      this.newFilterName = null;

      this.showSaveFilterDialog = false;
    },

    buildBodyTosaveFilter(value, valueType) {
      return {
        idMonitoringPredimonitorFilterValue: 0,
        value: value,
        valueType: valueType,
        delete: false,
      };
    },

    setRequestForSaveFilters() {
      let formated = {
        idMonitoringPredimonitorFilter: 0,
        name: this.newFilterName,
        delete: false,
        idCompany: this.idCompany,
        values: [],
        monitoringItems: [],
      };

      if (this.dialogFilter == "newFilter") {
        this.products.forEach((el) => {
          let data = {
            idPredimonitorFiltersMonitoringItem: 0,
            monitoringId: el.idMonitoringItem,
            description: el.getDescription,
            ean: el.ean,
            isDeleted: false,
          };

          formated.monitoringItems.push(data);
          data = {};
        });

        this.filters.forEach((filter) => {
          if (filter.selected !== null) {
            filter.selected.forEach((item) => {
              formated.values.push(
                this.buildBodyTosaveFilter(item.id, filter.valueType)
              );
            });
          }
        });

        if (this.idCompany == 2699) {
          this.locationFilters.forEach((filter, index) => {
            if (
              filter.selected == null ||
              filter.selected.length == 0 ||
              filter.selected == undefined
            ) {
              return;
            } else {
              if (index == 0) {
                formated.values.push(
                  this.buildBodyTosaveFilter(
                    filter.selected.idEstado,
                    filter.valueType
                  )
                );
              } else if (index == 1) {
                formated.values.push(
                  this.buildBodyTosaveFilter(
                    filter.selected.idMunicipio,
                    filter.valueType
                  )
                );
              } else if (index == 2) {
                filter.selected.forEach((item) => {
                  formated.values.push(
                    this.buildBodyTosaveFilter(
                      Number(item.postalCode),
                      filter.valueType
                    )
                  );
                });
              }
            }
          });
        }

        if (this.machineryResource) {
          this.yearsFilterSelected?.forEach((el) => {
            formated.values.push(this.buildBodyTosaveFilter(el, 8));
          }) ?? [];

          this.regionFilterSelected?.forEach((el) => {
            formated.values.push(this.buildBodyTosaveFilter(el, 7));
          }) ?? [];
        }
      } else {
        console.log("entrou aqui");
      }
      return formated;
    },
  },
};
</script>