<template>
  <v-card :disabled="mapEuLoading" class="pa-3">
    <v-expansion-panels accordion flat multiple>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="ma-0 pa-0"
          expand-icon="mdi-chevron-down"
        >
          <v-row class="ma-0 pa-0" justify="space-between">
            <div style="display: flex; align-items: center">
              <label
                class="card-subtitle text-uppercase"
                v-t="'TXT_PRICE_MAP'"
              ></label>
              <v-tooltip
                max-width="550px"
                color="#F5F5F5"
                style="pointer-events: all"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span
                  style="color: #000"
                  v-html="$t('TXT_PRICE_TOOLTIP')"
                ></span>
              </v-tooltip>
            </div>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!--  SELECIONAR PRODUTOS  -->
          <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 mb-0 mt-0">
            <div v-if="mapEuLoading">
              <v-skeleton-loader
                class="my-6 px-6"
                type="text, divider, divider, paragraph, paragraph, paragraph, paragraph"
              ></v-skeleton-loader>
            </div>
            <div v-if="!mapEuLoading">
              <label v-t="'TXT_SELECT_A_PRODUCT'" />
              <select-product
                class="mt-4"
                v-for="product in predimonitorResults"
                :key="product.idMonitoringItem"
                :product="product"
                :isLoading="mapEuLoading"
                :currency="currency"
                @showSnackbar="showSnackbar"
              />
            </div>
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import selectProduct from "./components/select-product.vue";

export default {
  components: { selectProduct },
  props: ["mapEuLoading", "predimonitorResults"],

  computed: {
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },
  methods: {
    showSnackbar(text, type) {
      this.$emit("showSnackbar", text, type);
    },
  },
};
</script>
