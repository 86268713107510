<template>
  <div class="div-table">
    <loading-progress v-if="wait" />
    <v-expansion-panels class="mt-2" :disabled="wait">
      <v-expansion-panel v-for="(result, id) in formatedSearchResults" :key="id">
        <v-expansion-panel-header class="pa-0 ma-0" expand-icon>
          <v-col cols="auto">
            <v-row class="mt-2" no-gutters style="justify-content: space-between">
              <span>
                <label class="ml-4 mr-2 label">{{ result.predify_product_description }}</label>
                <span class=" label">({{ (result.results || []).length }})</span>
              </span>
              <p class="font-weight-bold">
                <v-icon>mdi-chevron-down</v-icon>
              </p>
            </v-row>
            <v-col class="envio mx-1">
              <label>Data de recebimento:
                <span>{{result.createDate.split('T')[0] + ' ' + result.createDate.split('T')[1].split('.')[0]}}</span>
              </label>
            </v-col>
            <v-row no-gutters class="informations mr-8">
              <v-col cols="2">
                <label>Cliente</label>
                <v-text-field v-model="message1" :label="result.lead.name" dense disabled outlined
                  class="my-2 input-fontSize" hide-details></v-text-field>
              </v-col>
              <v-col cols="2" class="ml-2">
                <label>Whatsapp</label>
                <v-text-field v-model="message1" :label="result.lead.phone" disabled outlined
                  class="my-2 input-fontSize" hide-details dense></v-text-field>
              </v-col>
              <v-col cols="2" class="ml-2">
                <label>Email</label>
                <v-text-field v-model="message1" :label="result.lead.email" disabled outlined
                  class="my-2 input-fontSize" hide-details dense></v-text-field>
              </v-col>
              <v-col cols="2" class="ml-2">
                <label>Preço máximo</label>
                <v-text-field v-model="message1" :label="result.maxPrice" disabled outlined
                  class="my-2 input-fontSize color1" hide-details dense></v-text-field>
              </v-col>
              <v-col cols="2">
                <label class="mx-2">Preço médio</label>
                <v-text-field v-model="message1" :label="result.avgPrice" disabled outlined
                  class="my-2 ml-2 input-fontSize" hide-details dense></v-text-field>
              </v-col>
              <v-col cols="2" class="ml-2">
                <label>Preço minimo</label>
                <v-text-field v-model="message1" :label="result.minPrice" disabled outlined class="my-2 input-fontSize"
                  hide-details dense></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table id="smart-table" :headers="headers" :items="result.results"
            class="elevation-1 text-center input-fontSize" :loading="wait" dense>
            <!-- eslint-disable-next-line -->
            <template #item.product_name="{ item }">
              <span><a target="_blank" :href="item.product_link">{{ item.product_name }}</a></span>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.price="{ item }">
              <span>{{ item.price }} {{ item.price_currency }}</span>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.price_type="{ item }">
              <span>{{ item.price_type }}</span>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.crawler_name="{ item }">
              <span>{{ item.crawler_name }}</span>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.price_payment_type="{ item }">
              <span>{{ item.price_payment_type }}</span>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.seller_name="{ item }">
              <span><a v-if="item.seller_link" target="_blank" :href="item.seller_link">{{ item.seller_name
              }}</a></span>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.crawler_date="{ item }">
              <span>{{ item.crawler_date.split('T')[0] + ' ' + item.crawler_date.split('T')[1].split('.')[0] }}</span>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import loadingProgress from "./loading-progress.vue";
import { calculateMinMaxAvg } from "@/utils/calculate-min-max-avg.js";
// import tableComponent from "@/components/common/tableComponent.vue";
export default {
  name: "table-results",
  components: {
    loadingProgress,
    // tableComponent
  },
  props: [
    'searchResult',
    'wait',
  ],
  data() {
    return {
      message1: null,
      headers: [
        { text: "Produto", align: "left", value: "product_name" },
        { text: "Site", align: "center", value: "crawler_name" },
        { text: "Vendedor", align: "center", value: "seller_name" },
        { text: "Preço", align: "center", value: "price" },
        { text: "Tipo de Preço", align: "center", value: "price_type" },
        { text: "Forma de Pagamento", align: "center", value: "price_payment_type" },
        { text: "Data da coleta", align: "center", value: "crawler_date" },
      ],
    };
  },
  computed: {
    formatedSearchResults() {
      if (this.searchResult == null || this.searchResult.records == null)
        return []

      let productsToShow = []

      this.searchResult.records.forEach(e1 => {
        let formatedResults = [];
        (e1.results || []).forEach(product => {
          (product.sellers || []).forEach(seller => {
            (seller.prices || []).forEach(price => {
              formatedResults.push({
                id: product.id,
                crawler_name: product.crawler_name,
                crawler_date: product.crawler_date,
                product_name: product.product_name,
                product_link: product.product_link,
                product_brand: product.product_brand,
                product_ean: product.product_ean,
                product_ncm: product.product_ncm,
                product_model: product.product_model,
                stock_quantity: seller.stock_quantity,
                seller_name: seller.seller_name,
                seller_link: seller.seller_link,
                price: price.price.toFixed(2),
                price_type: price.price_type,
                price_from: price.price_from,
                price_payment_type: price.price_payment_type,
                price_currency: price.price_currency,
                cashback_value: price.cashback_value,
                cashback_is_percent: price.cashback_is_percent,
                discount_value: price.discount_value,
                discount_is_percent: price.discount_is_percent,
                fabrication_year: product.product_manufacture_year,
                shippings: seller.shippings || product.shippings,
                working_hours: product.product_used_hours,
                product_local: product.product_local || {},
                installments: (price.installments || []).filter(installment => installment.quantity > 1),
              })
            })
          })
        })

        if (e1.lead === null) {
          e1.lead = {
            name: "none",
            phone: "none",
            email: "none"
          }
        }

        let { min, max, avg } = calculateMinMaxAvg(formatedResults.map(result => parseFloat(result.price)));

        productsToShow.push({
          lead: e1.lead,
          createDate: e1.monitoringCreateDate,
          maxPrice: max.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          minPrice: min.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          avgPrice: avg.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          predify_product_description: e1.predify_product_description,
          predify_product_code: e1.predify_product_code,
          results: formatedResults,
        })
      })

      return productsToShow
    }
  }
};
</script>

<style  scoped>
#smart-table * {
  font-family: "Ubuntu" !important;
  text-align: center !important;
  font-size: 12px;
}

.label {
  font-weight: bold;
}

.envio {
  font-size: 11px;
  margin-top: -27px;
  margin-bottom: 12px;
}

.informations {
  font-size: 12px;
  margin-left: 16px;
  flex-wrap: nowrap;
}

.v-input .v-label {
  font-size: 10px;
  font-family: "Ubuntu" !important;
}

.div-table {

  margin-right: 16px;
}

.v-data-table-header__icon {
  margin-left: 12px;
}
</style>
