<template>
  <div class="mt-6">
    <!-- PRODUCT INFO -->
    <div>
      <h4>PRODUTOS ({{ totalRecords }})</h4>
      <v-row no-gutters align="center">
        <div class="info-product-box">
          <span class="info-product-reproved">Preços Reprovados </span>
          <span class="info-product-approved">Preços Aprovados </span>
        </div>
        <v-spacer></v-spacer>

        <prd-combo-box
          v-model="selectedPages"
          :items="itemsPerPage"
          :title="'Resultados por página'"
        />
      </v-row>
    </div>

    <!-- PRODUCT PANEL -->
    <v-card class="pa-4 mt-6" :disabled="productListLoading">
      <v-expansion-panels>
        <product-panel
          v-for="(product, index) in products"
          :key="index"
          :product="product"
          :index="index"
          @enableErp="$emit('enableErp', $event)"
          class="mb-2"
        />
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import ProductPanel from "./components/product-panel.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  components: { ProductPanel, PrdComboBox },
  props: ["recordsPerPage", "products", "totalRecords", "productListLoading"],
  data() {
    return {
      itemsPerPage: [5, 10, 25, 50],
    };
  },

  computed: {
    selectedPages: {
      get() {
        return this.recordsPerPage;
      },
      set(newValue) {
        this.$emit("setRecordsPerPage", newValue);
      },
    },
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.info-product-box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  .info-product-reproved {
    padding: 0;
    border-left: 6px solid #b00020;
    padding-left: 4px;
  }
  .info-product-approved {
    padding: 0;
    border-left: 6px solid #1d8527;
    padding-left: 4px;
  }
}
</style>
    