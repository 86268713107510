<template>
  <v-container>
    <pageTitle :title="`Olá, ${userName}!`" :hasTooltip="false" />

    <add-company v-if="showAddModal" @showAddModal="showAddModal = $event" />
    
    <div v-else>
      <v-card class="pa-4 pt-0" :disabled="isLoading" :loading="isLoading">
        <company-filters
          @companyList="setCompanyList"
          @setLoading="isLoading = $event"
          @showAddModal="showAddModal = $event"
          class="pt-4"
        />
        <company-list :companyList="companyCurrentPage" />
      </v-card>
      <prd-pagination
        v-model="currentPage"
        v-if="companyCurrentPage.length != 0"
        :totalRegisters="totalRecords"
        :length="totalPages"
        :showingRegistersLen="numberOfRecords"
        :resultLabel="$t('TXT_PAGINATION')"
        :pageLabel="$t('TXT_PAGE_LABEL')"
        class="mt-4"
      />
    </div>
  </v-container>
</template>

<script>
import pageTitle from "@/Design_System/common/title.vue";
import CompanyFilters from "./components/company-filters.vue";
import CompanyList from "./components/company-list.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import AddCompany from "./components/add-company.vue";

export default {
  name: "company-select",
  components: {
    pageTitle,
    CompanyFilters,
    CompanyList,
    PrdPagination,
    AddCompany,
  },
  data() {
    return {
      isLoading: false,
      companyListPaginated: [],
      companyCurrentPage: [],

      // Pagination vars
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,

      showAddModal: false,
    };
  },

  watch: {
    currentPage() {
      this.companyCurrentPage = this.companyListPaginated.find((el) => {
        return el.index == this.currentPage;
      });

      this.numberOfRecords = this.companyCurrentPage?.list.length ?? [];

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },

  computed: {
    userName() {
      return this.$store.getters?.userData?.email ?? null;
    },
  },

  methods: {
    setCompanyList(data) {
      let list = this.setPaginatedList(data, 10);
      this.companyListPaginated = list;

      this.companyCurrentPage =
        this.companyListPaginated.find((el) => {
          return el.index == this.currentPage;
        }) ?? [];
      this.totalRecords = data.length;
      this.totalPages = this.companyListPaginated.length;
      this.numberOfRecords = this.companyCurrentPage?.list?.length ?? [];
    },

    setPaginatedList(array, groupSize) {
      const groupedArray = [];

      for (let i = 0; i < array.length; i += groupSize) {
        const group = {
          index: Math.floor(i / groupSize) + 1,
          list: array.slice(i, i + groupSize),
        };
        groupedArray.push(group);
      }
      return groupedArray;
    },
  },

  created() {
    document.title = "PREDIFY | Seleção de empresa";
  },
};
</script>