<template>
    <v-card class="pa-4 pt-0 mt-2" v-if="this.product != null && this.newProduct != null">
        <v-row no-gutters class="mb-4">
            <v-col cols="12" md="4">
                <h4>PREMISSAS </h4>
            </v-col>
            <v-col cols="12" class="pa-1" offset="7" align="right" md="1">
                <v-icon @click="saveChanges()" title="Salvar" :color="$prdStyles('color-success')">mdi-content-save</v-icon>
            </v-col>
        </v-row>

        <v-row v-if="this.product != null" no-gutters align="center" class="pt-4">
            <v-col md="3">
                <label class="font-weight-bold">Tipo</label>
                <v-combobox dense outlined :disabled="true" filled hide-details v-model="this.tipo"
                    append-icon="mdi-chevron-down" class="mt-2 mr-4"></v-combobox>
            </v-col>
        </v-row>

        <v-row no-gutters align="center" class="pt-4" v-if="this.newProduct != null">
            <v-col>
                <label class="font-weight-bold">Dose</label>
                <v-text-field type="number" v-model="newProduct.dose" dense outlined hide-details
                    class="mt-2 mr-4"></v-text-field>
            </v-col>
            <v-col>
                <label class="font-weight-bold">Posicionamento</label>
                <v-text-field type="number" v-model="newProduct.posicionamento" dense outlined hide-details
                    class="mt-2 mr-4"></v-text-field>
            </v-col>
            <v-col>
                <label class="font-weight-bold">Remuneração Canal</label>
                <v-text-field type="number" v-model="newProduct.remuneracaoPorCanal" dense outlined hide-details
                    class="mt-2 mr-4"></v-text-field>
            </v-col>
            <v-col>
                <label class="font-weight-bold">Corredor Preço</label>
                <v-text-field type="number" v-model="newProduct.corredorPreco" dense outlined hide-details
                    class="mt-2 mr-4"></v-text-field>
            </v-col>
        </v-row>

        <div class="mt-5">

            <v-row v-for="(item, index) in items" :key="index" no-gutters align="center">
                <v-col>
                    <label>Tipo</label>
                    <v-combobox :items="item.tipo" :disabled="true" filled v-model="item.tipoValue" dense outlined
                        hide-details append-icon="mdi-chevron-down" class="mt-1 mr-4"
                        v-on:change="loadTipoCaptura($event, index)">
                    </v-combobox>
                </v-col>
                <v-col>
                    <div v-if="item.tipoValue == 'Externa'">
                        <label>Externa</label>
                        <v-combobox :items="item.capturas" v-model="item.selectedCaptura" dense outlined hide-details
                            append-icon="mdi-chevron-down" class="mt-1 mr-4" item-value="id" item-text="product"
                            v-on:change="loadCapturaDetails($event.id, index)">
                        </v-combobox>
                    </div>
                    <div v-if="item.tipoValue == 'Interna'">
                        <label>Interna</label>
                        <v-combobox :items="item.capturas" v-model="item.selectedCaptura" dense outlined hide-details
                            append-icon="mdi-chevron-down" class="mt-1 mr-4" item-value="id" item-text="descricaoMQ"
                            v-on:change="loadCapturaDetails($event.id, index)">
                        </v-combobox>
                    </div>
                    <div v-if="item.tipoValue == 'Produtos UPL'">
                        <label>Produtos UPL</label>
                        <v-combobox :items="item.capturas" v-model="item.selectedCaptura" dense outlined hide-details
                            append-icon="mdi-chevron-down" class="mt-1 mr-4" item-value="id" item-text="nome"
                            v-on:change="loadCapturaDetails($event.id, index)">
                        </v-combobox>
                    </div>
                </v-col>
                <v-col v-if="item.tipoValue != null">
                    <label>Preço</label>
                    <v-text-field :items="externalItems" v-model="item.preco" dense outlined hide-details class="mt-1 mr-4">
                    </v-text-field>
                </v-col>
                <v-col v-if="item.tipoValue != null">
                    <label>Dose</label>
                    <v-text-field :items="externalItems" v-model="item.dose" dense outlined hide-details class="mt-1 mr-4">
                    </v-text-field>
                </v-col>
                <v-col v-if="item.tipoValue != null && item.tipoValue != 'Produtos UPL'">
                    <label>Peso</label>
                    <v-row no-gutters>
                        <v-text-field :items="externalItems" v-model="item.peso" dense outlined hide-details
                            class="mt-1 mr-4">
                        </v-text-field>

                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>
<script>
import ServiceUPL from "@/service/upl/index";

export default {
    props: ["product"],
    data() {
        return {
            service: new ServiceUPL(),
            nome: "",
            newProduct: null,
            externalItems: [],
            internalItems: [],
            productItems: [],
            tipo: null,
            items: [
                {
                    capturas: [],
                    selectedCaptura: null,
                    preco: "",
                    dose: "",
                    peso: "",
                    tipoValue: null,
                    tipo: ['Externa', 'Interna', 'Produtos UPL']
                },
            ],
        };
    },
    methods: {
        loadTipoCaptura(tipo, index) {
            this.items[index].selectedCaptura = null;
            if (tipo == 'Externa') {
                this.items[index].capturas = this.externalProducts;
            } else if (tipo == 'Interna') {
                this.items[index].capturas = this.internalItems;
            } else if (tipo == 'Produtos UPL') {
                this.items[index].capturas = this.productItems;
            } else {
                this.items[index].capturas = [];
            }
        },
        loadCapturaDetails(id, index) {
            if (this.items[index].tipoValue == 'Externa') {
                let selectedExternalProduct = this.externalProducts.find(x => x.id == id);
                this.items[index].preco = selectedExternalProduct.medianaBRL.toString().replace(".", ",");
                this.items[index].dose = selectedExternalProduct.doseha != null ? selectedExternalProduct.doseha.toString().replace(".", ",") : '';
            } else if (this.items[index].tipoValue == 'Interna') {
                let internalSelectedItem = this.internalItems.find(x => x.id == id);
                this.items[index].preco = internalSelectedItem.mediaPrecoPVBRL.toString().replace(".", ",");
                this.items[index].dose = "";
            } else if (this.items[index].tipoValue == 'Produtos UPL') {
                let productSelected = this.productItems.find(x => x.id == id);
                this.items[index].preco = productSelected.precoMinimo.toString().replace(".", ",");
                this.items[index].dose = productSelected.dose != null ? productSelected.dose.toString().replace(".", ",") : '';
            }
        },
        addProductToItem() {
            this.items.push({
                capturas: [],
                selectedCaptura: null,
                preco: "",
                dose: "",
                peso: "",
                tipoValue: null,
                tipo: ['Externa', 'Interna', 'Produtos UPL']
            });
        },
        deleteItem(index) {
            this.items.splice(index, 1);
        },
        getCustoReposicao() {
            this.service.getCustoReposicao().then((res) => {
                this.custoReposicao = res.data;
                this.selectedCustoReposicao = this.custoReposicao.find(x => x.brand == this.product.nome);
                this.descricaoUnificada = this.selectedCustoReposicao.descricaoUnificada;
                this.unitCogs = this.selectedCustoReposicao.unitCogs;
            })
        },
        setCustoReposicao(id) {
            this.selectedCustoReposicao = this.custoReposicao.find(x => x.id == id);
            this.descricaoUnificada = this.selectedCustoReposicao.descricaoUnificada;
            this.unitCogs = this.selectedCustoReposicao.unitCogs;
        },
        async saveChanges() {
            this.newProduct.capturas[0].nomeCaptura = this.items[0].selectedCaptura.product;
            this.newProduct.capturas[0].mediaCapturaExterna = this.items[0].preco != null ? parseFloat(this.items[0].preco.toString().replace(",", ".")) : 0;
            this.newProduct.capturas[0].pesoCapturaExterna = this.items[0].peso != null ? parseFloat(this.items[0].peso.toString().replace(",", ".")) : 0;
            this.newProduct.capturas[0].mediaCapturaInterna = this.items[1].preco != null ? parseFloat(this.items[1].preco.toString().replace(",", ".")) : 0;
            this.newProduct.capturas[0].pesoCapturaInterna = this.items[1].peso != null ? parseFloat(this.items[1].peso.toString().replace(",", ".")) : 0;
            this.newProduct.capturas[0].doseCompetidor = this.items[0].dose != null ? parseFloat(this.items[0].dose.toString().replace(",", ".")) : 0;
            if (this.newProduct.capturas[0].doseCompetidor == null || this.newProduct.capturas[0].doseCompetidor == 0)
                this.newProduct.capturas[0].doseCompetidor = this.items[1].dose != null ? parseFloat(this.items[1].dose.toString().replace(",", ".")) : 0;

            console.log(this.newProduct.dose);

            await this.service.updateProduct(this.newProduct);
            alert("Salvo com sucesso");
            this.$router.go('main/upl-main');
        }
    },
    async beforeMount() {
        await this.service.getExternalFiles().then((res) => {
            this.externalProducts = res.data;
        });
        await this.service.getInternalFiles().then((res) => {
            this.internalItems = res.data;
        });
        await this.service.getProducts().then((res) => {
            this.productItems = res.data;
        });
        if (this.product != null) {
            this.newProduct = this.product;
            this.tipo = this.product.tipoPrecificacao;
            this.margemContribuicao = this.product.margemContribuicao;
            this.remuneracaoCanal = this.product.remuneracaoPorCanal;
            this.corredorPreco = this.product.corredorPreco;
            this.nome = this.product.nome;

            this.items.push({
                capturas: this.externalProducts,
                selectedCaptura: await this.externalProducts.find(x => x.product == this.product.capturas[0].nomeCaptura),
                preco: this.product.capturas[0].mediaCapturaExterna,
                dose: this.externalProducts.find(x => x.product == this.product.capturas[0].nomeCaptura).doseha,
                peso: this.product.capturas[0].pesoCapturaExterna,
                tipoValue: 'Externa',
                tipo: ['Externa', 'Interna', 'Produtos UPL']
            });

            this.items.push({
                capturas: this.internalItems,
                selectedCaptura: await this.internalItems.find(x => x.descricaoMQ == this.product.capturas[0].nomeCaptura),
                preco: this.product.capturas[0].mediaCapturaInterna,
                dose: '',
                peso: this.product.capturas[0].pesoCapturaInterna,
                tipoValue: 'Interna',
                tipo: ['Externa', 'Interna', 'Produtos UPL']
            });

            this.items.splice(0, 1);
        }
        this.getCustoReposicao();
    }
};
</script>