import axiosInstance, { axiosRaw, API_URL } from "@/configs/axiosConfig";
import ServiceBase from "@/service/service-base";
import { makeServiceMethod } from "./smart-service";

const client_id = "multiverso-emp-angularjs-app";
export default class LoginService extends ServiceBase {
  login = makeServiceMethod(
    /**
     * Sends a login request.
     * @param { { email: string, password: string } } user
     *
     */
    async (user) => {
      const data =
        "grant_type=password&username=" +
        user.email +
        "&password=" +
        user.password +
        "&client_id=" +
        client_id;

      return await axiosRaw.post(API_URL + "token", data, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });
    }
  );

  async getUserRoles(userEmail = null) {
    return await axiosInstance.get(API_URL + 'api/Usuario/GetUsuario', {
      params: {
        email: userEmail
      }
    });
  }

  async createAccount() {
    return;
  }

  async refreshToken() {
    return await axiosInstance.get(API_URL + "user/refreshtoken");
  }
}
