import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class DashboardBamaqService extends ServiceBase {

  async getProducts(data) {
    return await axiosInstance.post(API_URL + "/api/V2/Monitoring/ListMonitoringItems", data);
  }

  async getFilters(data) {
    return await axiosInstance.get(API_URL + "/api/V2/PrediMonitor/Filters", { params: data });
  }

  async AvgInfoByProduct(data) {
    return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/AvgInfoByProduct", data);
  }

  async priceHistory(data) {
    return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/TemporalEvolutionGraph", data);
  }

  async annnoucementMap(data) {
    return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/MapGraph", data);
  }


}