<template>
  <div class="my-6">
    <progress-component v-if="isLoading"> </progress-component>
    <table-component
      v-else-if="tickets"
      :rows="makeTableRows()"
      :headers="tableHeaderData"
    >
      <template v-slot:default="slotProps">
        <div
          v-for="(item, index) in slotProps.items"
          :key="index"
          class="table-item"
        >
          <checkbox-component
            v-if="typeof item === 'boolean'"
            disabled
            :value="item"
            class="pa-0 mt-n5"
          >
          </checkbox-component>
          <span v-else>{{ item }}</span>
        </div>
      </template>
    </table-component>
    <div v-else-if="error">Erro buscando informações de tickets</div>
  </div>
</template>
<script>
import progressComponent from "@/Design_System/common/progressComponent";
import checkboxComponent from "@/Design_System/common/checkBoxComponent";
import tableComponent from "./table-component.vue";

export default {
  props: [
    "error",
    "isLoading",
    "idCompany",
    "place",
    "crowding",
    "tickets",
    "disableInput",
  ],

  components: {
    progressComponent,
    checkboxComponent,
    tableComponent,
  },
  data: () => ({}),
  methods: {
    makeTableRows() {
      if (!this.place) {
        return this.tickets.map((ticket) => [ticket.name, ticket.total_member]);
      }

      const sectors = this.place.sectors;

      if (!this.crowding) {
        return this.tickets.map((ticket) =>
          [ticket.name, sectors.map(() => false), ticket.total_member].flat()
        );
      }

      const crowding_estimate = this.crowding.default_estimate_public;
      const sectorReleases = this.crowding.sector_releases;

      const sectorsReleaseDict = sectorReleases.reduce(
        (dict, sectorRelease) => {
          dict[sectorRelease.idEnterpriseEventSector] = sectorRelease;
          sectorRelease.ticketsSelectedDict =
            sectorRelease.tickets_selected.reduce((acc, idTicket) => {
              acc[idTicket] = true;
              return acc;
            }, {});
          return dict;
        },
        {}
      );

      return this.tickets.map((ticket) =>
        [
          ticket.name,
          sectors.map((sector) => {
            if (!sectorsReleaseDict[sector.idEnterpriseEventSector])
              return false;
            return (
              sectorsReleaseDict[sector.idEnterpriseEventSector]
                .ticketsSelectedDict[ticket.idEnterpriseEventTicket] || false
            );
          }),
          ticket.total_member,
          crowding_estimate + "%",
          ticket.total_member / (crowding_estimate / 100),
        ].flat()
      );
    },
  },
  computed: {
    tableHeaderData() {
      if (this.tickets && !this.place) {
        return ["Tipo de Ingresso", "Total de Sócios"];
      } else if (this.tickets && this.place && !this.crowding) {
        return [
          "Tipo de Ingresso",
          this.place.sectors.map((sector) => sector.name),
          "Total de Sócios",
        ].flat();
      } else {
        return [
          "Tipo de Ingresso",
          this.place.sectors.map((sector) => sector.name),
          "Total de Sócios",
          "Estimativa de Público",
          "Total de sócios esperada",
        ].flat();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  width: 100%;
  overflow-x: auto;
  column-gap: 4px;
  row-gap: 4px;
}
.table-header {
  background-color: $neutral-color-high-dark;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-item {
  background-color: $neutral-color-high-light;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
