<template>
  <div>
    <v-card
      :disabled="isLoading"
      :loading="isLoading"
      loader-height="6px"
      flat
      class="px-4 pb-4 pt-0"
    >
      <v-row no-gutters align="center" class="pt-4">
        <!-- UPLOAD FILE -->
        <v-col>
          <label v-t="'TXT_SELECT_SPREADSHEET'"></label>
          <v-file-input
            v-model="file"
            accept=".csv"
            :disabled="isLoading"
            multiple
            dense
            outlined
            class="mt-2"
            :placeholder="$t('TXT_CHOOSE_FILE')"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>

        <!-- ARCHIVE TYPE -->
        <v-col class="ml-4">
          <label v-t="'TXT_FILE_TYPE'"></label>
          <v-combobox
            v-model="archiveType"
            outlined
            dense
            append-icon="mdi-chevron-down"
            :items="archiveTypeItems"
            item-text="text"
            item-value="value"
            class="mt-2"
            :placeholder="$t('TXT_CHOOSE_FILE_TYPE')"
          >
          </v-combobox>
        </v-col>

        <!-- DELIMITER -->
        <v-col class="ml-4">
          <label v-t="'TXT_DELIMITER'"></label>
          <v-combobox
            v-model="delimiterType"
            outlined
            dense
            append-icon="mdi-chevron-down"
            :items="delimiterItems"
            item-text="text"
            item-value="value"
            class="mt-2"
            :placeholder="$t('TXT_CHOOSE_DELIMITER')"
          >
          </v-combobox>
        </v-col>

        <v-spacer></v-spacer>

        <!-- IMPORT FILE BUTTON -->
        <prd-btn
          @click.native="uploadFile"
          :disabled="
            isLoading ||
            file == null ||
            file == '' ||
            file == undefined ||
            archiveType == null
          "
          :title="$t('TXT_SEND_FILE')"
          :icon="'mdi-upload'"
          :smallIcon="true"
          :loading="isLoading"
        />
      </v-row>
    </v-card>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import fileImportService from "@/service/file-import/index.js";

export default {
  components: {
    PrdBtn,
  },
  data() {
    return {
      fileImportService: new fileImportService(),

      isLoading: false,

      file: null,
      archiveType: null,
      delimiterType: null,

      archiveTypeItems: [
        {
          text: "CSV",
          value: 0,
        },
        // {
        //   text: "Excel",
        //   value: 1,
        // },
      ],
      delimiterItems: [
        {
          text: "vírgula( , )",
          value: ",",
        },
        {
          text: "ponto e vírgula( ; )",
          value: ";",
        },
      ],
    };
  },

  methods: {
    // Sends the data to the endpoint and with the return sends it to the file-import-main and then to the file-results
    uploadFile() {
      this.isLoading = true;

      const formData = new FormData();
      formData.append("file", this.file[0]);
      formData.append("idCompany", 2616);

      this.fileImportService
        .importFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$emit(
            "uploadFile",
            res.data.result,
            this.file[0].name,
            this.archiveType.value,
            this.delimiterType.value
          );
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>