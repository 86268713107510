<template>
  <v-card class="pa-4 mt-4 mb-6">
    <v-expansion-panels :disabled="isLoading" accordion flat multiple>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="ma-0 pa-0"
          style="pointer-events: none"
          hide-actions
        >
          <v-row class="ma-0 pa-0" justify="space-between" align="center">
            <div style="display: flex; align-items: center">
              <label class="card-subtitle">HISTÓRICO DE PREÇOS</label>
              <v-tooltip right color="#F5F5F5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: #000">Alguma informação</span>
              </v-tooltip>
            </div>
            <div class="expansion-panel-actions">
              <v-icon
                :disabled="isLoading"
                :class="{ rotateIcon: rotateIcon }"
                @click="rotateIcon = !rotateIcon"
                style="pointer-events: all"
                large
                >mdi-chevron-down</v-icon
              >
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row
            class="ma-0 pa-0 mt-n6"
            justify="space-between"
            align-content="start"
          >
            <div class="group-box mt-6">
              <p class="pa-0 ma-0" v-t="'TXT_GROUP_BY'"></p>
              <v-tabs class="group-filters ml-n3" height="25px">
                <v-tab
                  @click="(selectedTab = 1), showResults()"
                  v-t="'TXT_PRODUCT'"
                ></v-tab>
                <v-tab
                  @click="(selectedTab = 2), showResults()"
                  v-t="'TXT_NETWORK'"
                ></v-tab>
                <v-tab
                  class="mr-4"
                  @click="(selectedTab = 3), showResults()"
                  v-t="'TXT_MANUFACTURER'"
                ></v-tab>
                <v-tab
                  @click="(selectedTab = 0), showResults()"
                  v-t="'TXT_CONSOLIDATED'"
                ></v-tab>
              </v-tabs>
            </div>
          </v-row>

          <div v-show="isLoading">
            <v-skeleton-loader
              class="my-6"
              type="image, table-row, text"
            ></v-skeleton-loader>
          </div>

          <div v-show="!isLoading">
            <v-tabs-items justify="center" v-model="selectedTab">
              <v-tab-item>
                <!-- Historical Price Chart -->
                <highcharts class="hc mt-4" :options="chartData"></highcharts>
              </v-tab-item>

              <v-tab-item>
                <!-- Historical Price Chart -->
                <highcharts class="hc mt-4" :options="chartData"></highcharts>
              </v-tab-item>

              <v-tab-item>
                <!-- Historical Price Chart -->
                <highcharts class="hc mt-4" :options="chartData"></highcharts>
              </v-tab-item>

              <v-tab-item>
                <!-- Historical Price Chart -->
                <highcharts class="hc mt-4" :options="chartData"></highcharts>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta dados em PNG, JPEG, PDF, SVG, imprime e visualiza tela cheia
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// Exporta dados CSV, XLS, e muda visualização por tabela
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

import DashboardBamaqService from "@/service/predimonitor/dashboard-bamaq-service";

export default {
  name: "history-price-chart",
  props: ["requestData"],
  data() {
    return {
      isLoading: true,
      rotateIcon: false,
      selectedTab: 1,
      chartData: {
        chart: {
          type: "spline", // template do gráfico
        },

        title: {
          text: "",
        },

        legend: {
          symbolWidth: 20, // largura do simbolo da legenda inferior
        },
        lang: {
          // variáveis para renomear as ações de exportação de dados
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
        },

        // informações lateral esquerda
        yAxis: [
          {
            title: {
              text: this.$i18n.t("TXT_AVERAGE_PRICE"), // Texto lateral esquerda
            },

            labels: {
              format: "", // Símbolo da moeda e separador vem do banco de dados?
            },
          },
          {
            title: {
              text: this.$i18n.t("TXT_AVERAGE_PRICE"), // Texto lateral direita
            },

            labels: {
              format: "", // Símbolo da moeda e separador vem do banco de dados?
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true,
        },

        xAxis: {
          title: {
            text: this.$i18n.t("TXT_TIME_DAYS"), // legenda dos dados inferiores
          },
          categories: [], // IMPORTADO DO BANCO DE DADOS
          crosshair: true,
          labels: {
            format: "{value}", // Personalizar label inferior
          },
        },
        series: [], // IMPORTADO DO BANCO DE DADOS
      },
    };
  },

  methods: {
    showResults() {
      this.isLoading = true;
      let data = this.requestData;
      data.groupBy = this.selectedTab;

      let dashboardBamaqService = new DashboardBamaqService();
      this.$emit("onLoadingStarted");

      dashboardBamaqService.priceHistory(data).then((res) => {
        this.chartData.yAxis[0].labels.format = res.data.answer.priceCurrency;
        this.chartData.xAxis.categories = res.data.answer.categories;

        this.chartData.series = res.data.answer.series.map((serie) => ({
          name: serie.name,
          data: serie.data,
          type: 'spline',
          yAxis: 0,
          color: serie.color,
          maxPointWidth: 50,
          zoneAxis: serie.zoneAxis,
          zones: serie.zones,
        }));

        this.chartData.series.push(...res.data.answer.series2.map((serie) => ({
          zIndex:-1,
          name: serie.name,
          data: serie.data,
          type: serie.type,
          yAxis: 1,
          color: serie.color,
          maxPointWidth: 50,
          zoneAxis: serie.zoneAxis,
          zones: serie.zones,
        })));

        this.isLoading = false;
      }).finally(() => {
          this.$emit("onLoadingFinished");
        });
    },
  },

  watch: {
    requestData() {
      this.showResults();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.group-box {
  display: flex;
  align-items: center;
  gap: 26px;
  font-size: 14px;
  font-weight: 500;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}
</style>