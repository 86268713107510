import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PredimonitorV2 extends ServiceBase {
    async getProductList(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItemPaginated", request);
    }
    async getProductGroups(idCompany) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/ListMonitoringGroup", { params: { idCompany: idCompany } });
    }

    async searchV3(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/Search", request);
    }

}