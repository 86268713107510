<template>
  <div>
    <div style="display: flex">
      <!-- TOTAL RECIPE -->
      <v-card style="width: 50%" class="pa-4 mr-6" :disabled="isLoading">
        <v-row no-gutters>
          <h4>RECEITA TOTAL</h4>
        </v-row>

        <!-- RECIPE  -->
        <v-row no-gutters class="mt-4">
          <v-col>
            <label>Receita Diária</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">{{ setCurrency(lastWeek) }}</h4>
          </v-col>

          <v-col>
            <label>Projeção 7 dias</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ setCurrency(weekProjection) }}
            </h4>
          </v-col>

          <v-col>
            <label>Projeção mês atual</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ setCurrency(monthProjection) }}
            </h4>
          </v-col>

          <v-col>
            <label>Projeção último mês</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ setCurrency(lastMonthProjection) }}
            </h4>
          </v-col>
        </v-row>

        <!-- RECIPE IA -->
        <v-row no-gutters class="mt-4">
          <v-col class="mr-6">
            <label>Receita projetada Diária - IA</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">{{ setCurrency(lastWeekIA) }}</h4>
          </v-col>
          <v-col>
            <label>Projeção para 7 dias - IA</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ setCurrency(weekProjectionIA) }}
            </h4>
          </v-col>
          <v-col>
            <label>Projeção do mês atual - IA</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ setCurrency(monthProjectionIA) }}
            </h4>
          </v-col>
        </v-row>
      </v-card>

      <!-- TOTAL MARGIN -->
      <v-card style="width: 50%" class="pa-4" :disabled="isLoading">
        <v-row no-gutters>
          <h4>MARGEM TOTAL</h4>
        </v-row>

        <div class="mt-4" style="display: flex">
          <div style="flex-grow: 1">
            <label>Venda com margem atual</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatPercentage(marginLastWeek) }}
            </h4>
          </div>

          <div style="flex-grow: 1">
            <label>Venda com margem Projetada </label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatPercentage(marginWeekProjection) }}
            </h4>
          </div>

          <div style="flex-grow: 1">
            <label>Variação</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatPercentage(marginVariation) }}
            </h4>
          </div>
        </div>
      </v-card>
    </div>

    <v-card class="pa-4 mt-4" :disabled="chartIALoading">
      <v-row no-gutters>
        <h4>ADOÇÃO DA IA</h4>
        <!-- <tooltip-informations :title="''" :rightIcon="true" /> -->
      </v-row>

      <v-row class="ma-0 pa-0 mt-4" justify="space-between">
        <div class="group-box">
          <p class="pa-0 ma-0 mr-5">Visualização:</p>
          <v-tabs class="group-filters" height="25px" v-model="tab">
            <v-tab class="mr-4"> Nº de Produtos </v-tab>
            <v-tab class="mr-4"> % de Produtos </v-tab>
            <v-tab class="mr-4"> Receita </v-tab>
            <v-tab class="mr-4"> Margem média </v-tab>
          </v-tabs>
        </div>
      </v-row>

      <v-row v-if="chartIALoading" no-gutters justify="center" class="mt-6">
        <v-progress-circular
          :color="$prdStyles('color-primary')"
          indeterminate
        ></v-progress-circular>
      </v-row>

      <v-tabs-items v-else v-model="tab">
        <v-tab-item v-for="(chart, index) in dayAdoptionChart" :key="index">
          <chart-data
            :yAxisTitle="chart.yAxisTitle"
            :data="chart.data"
            :tab="tab"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ChartData from "./chart-data.vue";
import { setCurrency } from "@/utils/format-currency";
import { formatPercentage } from "@/utils/format-percentage";

export default {
  props: [
    "geralVisionData",
    "isLoading",
    "loadingProjection",
    "totalProjectionData",
    "chartIALoading",
  ],
  components: { ChartData },
  data() {
    return {
      tab: 0,
      charts: [],
    };
  },

  computed: {
    lastWeek() {
      return this.totalProjectionData?.totalRecipe?.lastWeek ?? null;
    },
    weekProjection() {
      return this.totalProjectionData?.totalRecipe?.weekProjection ?? null;
    },
    monthProjection() {
      return this.totalProjectionData?.totalRecipe?.monthProjection ?? null;
    },

    lastWeekIA() {
      return this.totalProjectionData?.totalRecipe?.lastWeekIA ?? null;
    },
    weekProjectionIA() {
      return this.totalProjectionData?.totalRecipe?.weekProjectionIA ?? null;
    },
    monthProjectionIA() {
      return this.totalProjectionData?.totalRecipe?.monthProjectionIA ?? null;
    },

    marginLastWeek() {
      return this.totalProjectionData?.totalMargin?.lastWeek ?? null;
    },
    marginWeekProjection() {
      return this.totalProjectionData?.totalMargin?.weekProjection ?? null;
    },
    lastMonthProjection() {
      return this.totalProjectionData?.lastMonthProjection ?? null;
    },

    marginVariation() {
      return this.marginWeekProjection - this.marginLastWeek ?? null;
    },
    dayAdoptionChart() {
      return this.geralVisionData?.dayAdoption ?? null;
    },
  },

  methods: {
    setCurrency,
    formatPercentage,
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 14px;
}
.money-destaq {
  color: $brand-color-primary-pure;
  font-size: 16px;
  font-weight: 600;
}

.group-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.group-filters {
  border-bottom: 1px solid #00000048;
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}
</style>