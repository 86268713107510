<template>
  <div>
    <prd-btn :title="'Número dias IA'" class="mt-8 ml-5" @click="isDialogOpen = true" />
    <v-dialog v-model="isDialogOpen" :max-width="400" persistent>
      <v-card class="pa-4 pt-0" :loading="isLoading" :disabled="isLoading" flat id="new-action-dialog">
        <h4 class="pt-4">Variáveis de tributação</h4>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Histórico de dias</label>
            <v-text-field v-model="historicoIa.dias" dense outlined hide-details type="number" class="mt-1">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <prd-btn @click="cancelChanges" :title="'Cancelar'" :outlined="true" class="mr-4" />
          <prd-btn :title="'Salvar'" @click="saveHistorico" />
        </v-row>
        <br>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceUPL from "@/service/upl/index";

export default {
  components: { prdBtn },
  data() {
    return {
      isDialogOpen: false,
      actionName: null,
      isLoading: false,
      historicoIa: null,
      service: new ServiceUPL(),
    };
  },
  methods: {
    async saveHistorico() {
      this.isLoading = true;
      await this.service.addHistoricoIaVariavel(this.historicoIa);
      this.isLoading = false;
      this.isDialogOpen = false;
    },

    cancelChanges() {
      this.isDialogOpen = false;
    },
  },
  async beforeMount() {
    await this.service.getHistoricoIaVariavel().then((res) => {
      this.historicoIa = res.data;
    });
  }
};
</script>
