var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"flat":"","id":"marketplace-data"}},[_c('h4',{staticClass:"mb-4"},[_vm._v("MONITORAMENTO DE MERCADO")]),(_vm.marketResultLoading)?_c('v-row',{staticClass:"mt-10",attrs:{"no-gutters":"","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.$prdStyles('color-primary')}})],1):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.marketResult,"hide-default-footer":"","items-per-page":200000},scopedSlots:_vm._u([{key:"header.crawlerName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.price",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.quantityInstallments",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.deflatedValue",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(_vm.setCurrency(item.price)))])])]}},{key:"item.quantityInstallments",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(" "+_vm._s(item.quantityInstallments ? item.quantityInstallments : "-")+" ")])])]}},{key:"item.deflatedValue",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(_vm.setCurrency(item.deflatedValue)))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }