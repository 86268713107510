import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class FiltrosService extends ServiceBase {

  async postProductList(data) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItemPaginated", data);
  }

  async getFilters(data) {
    return await axiosInstance.get(API_URL + "api/V2/PrediMonitor/Filters", { params: data });
  }

  async ListSavedFilters(data) {
    return await axiosInstance.get(API_URL + "api/V2/Monitoring/predimonitor/filters/list", { params: data });
  }

  async CUDFilters(data) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/predimonitor/filters", data);
  }

  async getState(idCompany) {
  return await axiosInstance.get(API_URL + `/api/V2/PrediMonitor/State?param.idCompany=${idCompany}`);
  }

  async getCity(id, uf, name,idCompany) {
    return await axiosInstance.get(API_URL + `/api/V2/PrediMonitor/City?param.idEstado=${id}&param.uf=${uf}&param.nome=${name}&param.idCompany=${idCompany}`);
  }

  async getNeighborhood(idCity) {
    return await axiosInstance.post(API_URL + "/api/V2/PrediMonitor/Neighborhood", idCity);
  }
}