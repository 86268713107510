<template>
  <div class="my-flex my-4">
    <div class="about-product mr-4">
      <about-product style="height: 100%" v-bind:product="this.product" />
    </div>

    <div class="chart mr-4">
      <column-chart
        style="height: 70%"
        class="mb-4"
        v-bind:product="this.product"
      />
      <card-info
        :title="'ESTRATÉGIA DE POSICIONAMENTO'"
        :text="this.product.estrategiaDePosicionamento"
        :productId="this.product.id"
        @newValueatt="positionEstrategy = $event"
        :cardType="'estrategia'"
        style="height: 30%"
      />
    </div>

    <div class="card-infos">
      <card-info
        :title="'PROPOSTA DE VALOR'"
        :text="this.product.propostaValor"
        @newValueatt="valueProposal = $event"
        class="mb-4"
        :cardType="'proposta'"
        :productId="this.product.id"
        style="height: 33%"
      />
      <card-info
        :title="'OBJETIVO DO NEGÓCIO'"
        :text="this.product.objetivoNegocio"
        @newValueatt="bussinesObjective = $event"
        class="mb-4"
        :cardType="'objetivo'"
        :productId="this.product.id"
        style="height: 33%"
      />
      <card-info
        :title="'GATILHOS DE REPOSICIONAMENTO'"
        :text="this.product.gatilhosPosicionamento"
        @newValueatt="repositionTrigger = $event"
        :cardType="'gatilho'"
        :productId="this.product.id"
        style="height: 33%"
      />
    </div>
  </div>
</template>

<script>
import CardInfo from "../card-info.vue";
import aboutProduct from "./components/about-product.vue";
import ColumnChart from "./components/column-chart.vue";
export default {
  components: { aboutProduct, CardInfo, ColumnChart },
  props: ["product"],
  data() {
    return {
      valueProposal: "",
      bussinesObjective: "",
      repositionTrigger: "",
      positionEstrategy: "",
    };
  },
  beforeMount() {},
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.about-product {
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.chart {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.card-infos {
  width: 35%;
  display: flex;
  flex-direction: column;
}
</style>