import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PriceAlertService extends ServiceBase {
    async getPriceAlert(data) {
        return await axiosInstance.get(API_URL + "api/V2/PrediMonitor/PriceAlerts/list", data);
    }

    async getMonitoringItem(idMonitoring, isParentItem, link, seller) {
        console.log(idMonitoring, isParentItem,link,seller);
        return await axiosInstance.get(API_URL + "api/V2/PrediMonitor/PriceAlerts/listByMonitoringItem", {
            params: {
                idMonitoringItem: idMonitoring,
                isParentItem: isParentItem,
                link: link,
                seller: seller,
                includeUsers: true
            }
        })
    }
    async savePriceAlert(request) {
        return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/PriceAlerts", request);
    }

    async getPriceAlertList(request) {
        return await axiosInstance.get(API_URL + "api/V2/PrediMonitor/PriceAlerts/list", {
            params: {
                ids: request
            }
        });
    }


}