<template>
  <v-card class="pa-4">
    <h4 v-t="'TXT_SEND_DATA'">ENVIAR DADOS</h4>

    <v-row no-gutters class="my-4">
      <!-- REGION COMBOBOX -->
      <v-col class="mr-4">
        <label v-t="'TXT_REGION'"></label>
        <v-combobox
          v-model="region"
          :items="regionItems"
          :loading="loadingRegions"
          @input="fillCompanyDefault($event)"
          item-text="regionName"
          itemid="idCompany"
          :disabled="loading || loadingRegions"
          hide-details
          outlined
          dense
          append-icon="mdi-chevron-down"
        >
        </v-combobox>
      </v-col>

      <!-- CUSTOMER COMPANY -->
      <v-col class="mr-4">
        <label v-t="'TXT_CUSTUMER_COMPANY'"></label>
        <v-combobox
          v-model="idCompanyClient"
          :items="companyClientItems"
          item-text="nomeRazaoSocial"
          itemid="idEmpresa"
          :disabled="
            region == null || loading || companyClientItems.length == 0
          "
          :loading="loadingCompanydefault"
          @input="fillPrefix($event.idEmpresa)"
          hide-details
          outlined
          dense
          append-icon="mdi-chevron-down"
        >
          <template v-slot:item="{ item }">
            <div>{{ item.idEmpresa }} - {{ item.nomeRazaoSocial }}</div>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <!-- BUTTONS -->
    <v-card-actions class="pa-0">
      <v-spacer></v-spacer>
      <prd-btn
        @click.native="closeModal"
        :disabled="loading"
        :outlined="true"
        :title="$t('TXT_CANCEL')"
      />
      <prd-btn
        @click.native="exportFile"
        :disabled="!idCompanyDefault || !idCompanyClient || !region || !prefix"
        :title="$t('TXT_TOIMPORT')"
        :loading="loading"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import fileImportService from "@/service/file-import/index.js";
export default {
  props: ["fileResults", "data", "fileName", "archiveType", "delimiterType"],
  components: { prdBtn },
  data() {
    return {
      fileImportService: new fileImportService(),
      loading: false,
      loadingRegions: false,

      loadingCompanydefault: false,

      nameCompanyDefault: null,
      idCompanyDefault: null,

      idCompanyClient: null,

      region: null,
      prefix: null,

      regionItems: [],
      companyClientItems: [],
    };
  },

  computed: {
    // Join the name with the id, to display in company default combobox
    companyDefault() {
      if (this.idCompanyDefault) {
        return `${this.idCompanyDefault} - ${this.nameCompanyDefault}`;
      } else {
        return null;
      }
    },
  },

  methods: {
    // Export the archive
    exportFile() {
      this.loading = true;

      let request = {
        fileToken: this.fileName,
        idCompanyDefault: null,
        idEmpresa: this.idCompanyClient.idEmpresa,
        tipoArquivo: this.archiveType,
        separator: this.delimiterType,
      };

      this.data.forEach((el) => {
        if (el.value) {
          request[el.field] = {
            index: el.value.value,
            conversor: el.observationValue?.value,
          };
        } else {
          request[el.field] = {
            index: -1,
            conversor: null,
          };
        }
      });

      // Request
      this.fileImportService
        .importFileDialog(request)
        .then((res) => {
          if (res.data.success == true) {
            let snackbar = {
              snackbar: true,
              snackbarText: "Sucesso ao importar produtos",
              snackbarColor: "#56a667",
            };
            this.$emit("showSnackbar", snackbar);
            this.loading = false;
            this.$emit("cleanData");
            this.cleanData();
            this.closeModal();
          } else {
            let snackbar = {
              snackbar: true,
              snackbarText: "Erro ao importar planilha",
              snackbarColor: "#ec4c37",
            };
            this.$emit("showSnackbar", snackbar);
            this.cleanData();
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    // Call the endpoint to list the regions on the combobox
    listRegion() {
      this.loadingRegions = true;
      this.fileImportService
        .listRegion()
        .then((res) => {
          this.regionItems = res.data;
          this.loadingRegions = false;
        })
        .catch((error) => {
          console.log(error);

          let snackbar = {
            snackbar: true,
            snackbarText: "Erro ao carregar regiões",
            snackbarColor: "#ec4c37",
          };
          this.$emit("showSnackbar", snackbar);

          this.loadingRegions = false;
        });
    },

    // After selecting the region, it automatically selects the region's default company and lists the customer companies in that region
    fillCompanyDefault(region) {
      this.loadingCompanydefault = true;
      this.cleanData();

      this.idCompanyDefault = region.idCompany;
      this.nameCompanyDefault = region.corporateReasonName;

      this.fileImportService
        .listCompanyByRegion(region.accessLevel)
        .then((res) => {
          this.companyClientItems = res.data;
          this.loadingCompanydefault = false;
        })
        .catch((error) => {
          console.log(error);

          let snackbar = {
            snackbar: true,
            snackbarText: "Erro ao carregar empresas",
            snackbarColor: "#ec4c37",
          };
          this.$emit("showSnackbar", snackbar);

          this.loadingCompanydefault = false;
        });
    },

    // After selecting the company it automatically adds the prefix between []
    fillPrefix(idEmpresa) {
      this.prefix = `[${idEmpresa}]`;
    },

    // Reset all combobox values
    cleanData() {
      this.companyClientItems = [];
      this.prefix = null;
      this.idCompanyDefault = null;

      this.nameCompanyDefault = null;
      this.idCompanyClient = null;
    },

    closeModal() {
      this.cleanData();
      this.region = null;
      this.$emit("closeModal");
    },
  },

  created() {
    this.listRegion();
  },
};
</script>