<template>
  <v-card class="pa-4" style="">
    <v-row no-gutters class="mb-4">
      <h4>{{ title }}</h4>
      <v-spacer></v-spacer>
      <v-icon v-if="!isEditOpen" @click="isEditOpen = true" :color="$prdStyles('color-primary')">mdi-pencil-box</v-icon>
      <div v-if="isEditOpen">
        <v-icon @click="confirNewTextValue" color="#1d8527">mdi-checkbox-marked</v-icon>
        <v-icon @click="isEditOpen = false" color="#b00020">mdi-close-outline</v-icon>
      </div>
    </v-row>
    <span class="spanText" v-if="!isEditOpen">{{ this.oldText }}</span>
    <v-textarea no-resize outlined v-model="newValue" v-if="isEditOpen"></v-textarea>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
export default {
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    cardType: {
      type: String,
    },
    productId: {
      type: String,
    }
  },
  data() {
    return {
      oldText: null,
      isEditOpen: false,
      newValue: null,
      service: new ServiceUPL(),
    };
  },
  methods: {
    async confirNewTextValue() {
      this.service.updateInfo(this.productId, this.cardType, this.newValue)
      this.oldText = this.newValue;
      this.isEditOpen = false
    },
  },
  beforeMount() {
    this.oldText = this.text
  }
};
</script>
<style>
.spanText {
  display: flex;
  overflow-y: auto;
  max-height: 125px;
}
</style>