<template>
  <div>
    <prd-btn
      v-if="isAdmin"
      :title="'Nova ação promocional'"
      :disabled="loading"
      :icon="'mdi-plus'"
      :smallIcon="true"
      :leftIcon="true"
      @click="isDialogOpen = true"
    />
    <v-dialog v-model="isDialogOpen" :max-width="800" persistent>
      <v-card
        class="pa-4 pt-0"
        :loading="isLoading"
        :disabled="isLoading"
        flat
        id="new-action-dialog"
      >
        <h4 class="pt-4">NOVA AÇÃO PROMOCIONAL</h4>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="4" class="mr-6">
            <label>Nome da ação</label>
            <v-text-field
              v-model="nomeAcao"
              dense
              outlined
              hide-details
              class="mt-1"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-row no-gutters>
              <label>Período de vigência</label>
              <tooltip-informations :title="'Tooltip'" :rightIcon="true" />
            </v-row>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="input-datapicker mt-1"
                  v-model="dateRangeText"
                  hide-details
                  outlined
                  single-line
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :label="'Selecione'"
                  :placeholder="'Selecione'"
                >
                  <template v-slot:append>
                    <v-icon class="append-calendar" color="white" small>
                      mdi-calendar</v-icon
                    >
                  </template>
                </v-text-field>
              </template>
              <!-- TODO - properly use user's locale -->
              <v-date-picker
                v-model="date"
                no-title
                scrollable
                range
                :min="minDay"
                :max="maxDay"
              >
                <v-spacer></v-spacer>

                <v-btn
                  text
                  color="error"
                  @click="menu = false && dateRangeText == null"
                  v-t="'TXT_CANCEL'"
                ></v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.menu.save(date)"
                  v-t="'TXT_OK'"
                ></v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <h5 class="my-4">Adicione os produtos participantes da ação</h5>

        <v-row
          v-for="(product, index) in produtos"
          :key="index"
          no-gutters
          class="mb-4"
        >
          <v-col cols="12" md="4">
            <label>Produto</label>
            <v-text-field
              v-model="product.nomeProduto"
              dense
              outlined
              hide-details
              class="mt-1 mr-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label>Preço atual</label>
            <v-text-field
              v-model="product.precoAtual"
              type="number"
              hide-spin-buttons
              dense
              outlined
              hide-details
              class="mt-1 mr-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label>Preço na ação</label>
            <v-text-field
              v-model="product.precoAcao"
              type="number"
              hide-spin-buttons
              dense
              outlined
              hide-details
              class="mt-1 mr-4"
            >
            </v-text-field>
          </v-col>

          <v-icon @click="deleteProduct(index)" class="mt-7" color="#B00020">
            mdi-trash-can-outline
          </v-icon>
        </v-row>

        <v-icon @click="addProduct" color="#1D8527"> mdi-plus-box </v-icon>

        <v-row no-gutters>
          <v-spacer></v-spacer>
          <prd-btn
            @click="cancelChanges"
            :title="'Cancelar'"
            :outlined="true"
            class="mr-4"
          />
          <prd-btn :title="'Salvar'" @click="saveprodutos" />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceHome20 from "@/service/home20/index";
import prdBtn from "@/Design_System/common/prd-btn.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import store from "@/store";
export default {
  props: ["loading"],
  components: { prdBtn, TooltipInformations },
  data() {
    return {
      service: new ServiceHome20(),
      isDialogOpen: false,
      nomeAcao: null,
      isLoading: false,

      // Data picker vars
      date: [],
      minDay: null,
      maxDay: null,
      menu: false,
      isAdmin: false,

      produtos: [
        {
          nomeProduto: "",
          precoAtual: null,
          precoAcao: null,
          isEdit: false,
        },
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.date.join("  ~  ");
    },
  },
  methods: {
    saveprodutos() {
      this.isLoading = true;
      let request = this.buildRequest();

      // Aqui o endpoint pra salvar a promoção
      this.service.createAcaoPromocional(request).then();

      this.$emit("listActions");

      this.isLoading = false;
      this.isDialogOpen = false;
    },

    buildRequest() {
      let request = {
        nomeAcao: this.nomeAcao,
        startDate: this.date[0],
        endDate: this.date[1],
        produtos: [],
      };

      this.produtos.forEach((el) => {
        let product = {
          nomeProduto: el.nomeProduto,
          precoAtual: el.precoAtual,
          precoAcao: el.precoAcao,
        };

        request.produtos.push(product);
      });

      return request;
    },

    addProduct() {
      let newProduct = {
        nomeProduto: "",
        precoAtual: null,
        precoAcao: null,
        isEdit: false,
      };
      this.produtos.push(newProduct);
    },

    deleteProduct(index) {
      this.produtos.splice(index, 1);
    },

    cancelChanges() {
      this.produtos = [
        {
          nomeProduto: "",
          precoAtual: null,
          precoAcao: null,
          isEdit: false,
        },
      ];
      this.isDialogOpen = false;
    },

    hasAdmin() {
      console.log("this.store", this.store);
      console.log("this.$store", this.$store);
      console.log("store", store);
      this.service
        .checkAdm(store)
        .then((res) => {
          this.isAdmin = res.data.isAdmin;
        })
        .catch((error) => {
          console.log("Erro ao gerar admin", error);
          this.isAdmin = false;
        });
    },
  },

  created() {
    this.hasAdmin();
  },
};
</script>

<style lang="scss">
#new-action-dialog {
  .append-pencil {
    background: #3c5ca7;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .append-minus {
    background: red;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .input-datapicker .v-input__app end-inner {
    margin-top: 0px !important;
  }

  .append-calendar {
    height: 40px;
    width: 40px;
    background: #3c5ca7;
    margin-right: -11px;
    border-radius: 0px 4px 4px 0px;
    color: white;
  }
}
</style>